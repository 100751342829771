import { AfterViewInit, Component, OnInit } from "@angular/core";
import { RootScope } from "../shared/RootScope.service";
import { ApiService } from "../shared/Api.service";
import { FileDownloadService, SwxModule } from "swx.front-end-lib";
import { interval } from 'rxjs';
import { map, startWith, tap } from "rxjs/operators";
import { NgIf, NgFor, AsyncPipe, DatePipe } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: 'AcarsRequest.component.html',
    styleUrls: ['AcarsRequest.component.scss'],
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgIf,
        NgFor,
        AsyncPipe,
        DatePipe,
    ],
})
export class AcarsRequestCompoment implements OnInit, AfterViewInit {
    acarsRequest: any;
    response: {
        NbCharactersPerLine: any;
        Output: any;
        OutputNoRoutingInfo: any;
        NbLinesPerPage: any;
        HotResponse: {
          Id: any;
          WingMaterialType: any;
          Message: any;
          Error: any;
          MetarUsageReason: any;
          HotResponseHots: Array<{
              FluidType: any;
              FluidName: any;
              FluidDilution: any;
              Message: any;
          }>;
        };
    };
    decodedString: any;
    priorities: any;
    mailSample: any;

    constructor(
        private api: ApiService,
        public root: RootScope,
        private fileDownload: FileDownloadService,
    ) {
    }

    ngOnInit() {
        this.decodedString = null;
    }

    ngAfterViewInit() {
        this.priorities = this.api.AcarsPriority.query();
    }

    decode() {
        this.api.AcarsIncomingMessageDecoder.post({ Req: this.acarsRequest }).then(result => {
            this.decodedString = result;
            this.mailSample = result.MailSample.replace("\r\n", "<br/>").replace("\n", "<br/>");
        })
    }

    getResponse(request) {
        this.api.AcarsRequestProcessor.post({
                Req: request
            }).then(result => {
                this.response = result;
        })
    }

    download(text) {
        const blob = new Blob([text], { type: 'text/plain' });
        this.fileDownload.download(blob, 'ACARS.txt');
    }

    localDate$ = interval(1000).pipe(
        startWith(new Date()),
        map(_ => new Date())
    );

}
