import {Component, Input} from "@angular/core";
import { SwxModule } from "swx.front-end-lib";
import { NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './TableauUserDialog.component.html',
    standalone: true,
    imports: [FormsModule, NgIf, SwxModule]
})
export class TableauUserDialogComponent {
    @Input() portalUser: any;
    @Input() tableauUserProvisioning: any;
    @Input() createTableauUser: any;
    @Input() deleteTableauUser: any;
}
