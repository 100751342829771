import { Component, Input } from '@angular/core';
import { ControlContainer, NgModelGroup, FormsModule } from "@angular/forms";
import { NgIf } from '@angular/common';

@Component({
    selector: '[sensor-calibration-configuration]',
    templateUrl: 'SensorCalibrationConfiguration.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
    standalone: true,
    imports: [NgIf, FormsModule]
})
export class SensorCalibrationConfigurationComponent {
    @Input('sensor-calibration-configuration') configuration;
    @Input() name: string;
    @Input() type: string;
    @Input() property: string;
}
