import { AfterViewInit, Component } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { NgFor, NgIf, JsonPipe, KeyValuePipe } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SwxModule } from "swx.front-end-lib";

@Component({
    templateUrl: './ManualSensorEntry.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, NgIf, JsonPipe, KeyValuePipe]
})
export class ManualSensorEntryComponent implements AfterViewInit {
    sensorTypes: any;
    result: any;
    type: any;
    rawData: any;

    constructor(private api: ApiService) {
    }

    ngAfterViewInit() {
        Promise.resolve(this.api.SensorType.query()).then(result => {
            this.sensorTypes = result
            console.log(this.sensorTypes)
        });
    }

    decode() {
        console.log('type=', this.type);
        console.log('rawData=', this.rawData);

        this.api.SensorDecode.queryObject({
            type: this.type,
            rawData: this.rawData
        }).$promise.then(result => this.result = result);
    }
}
