import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { ChangesService, OrderByPipe, SwxModule } from "swx.front-end-lib";
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'WeatherTypeNgToCategoryMappingProfileDetail.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, NgFor, HasPermissionPipe]
})
export class WeatherTypeNgToCategoryMappingProfileDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', {static: true}) ngForm;
    tab;
    weatherCategories = this.api.WeatherCategory.query();
    weatherTypeNgs = this.api.WeatherTypeNg.query();

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        public orderByPipe: OrderByPipe,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = api.WeatherTypeNgToCategoryMappingProfile.get({id: copyId});
            this.item.$promise.then(item => {
                delete this.item.Id;

                if (this.item.WeatherTypeNgToCategoryMappings) {
                    this.item.WeatherTypeNgToCategoryMappings.forEach(related => {
                        delete related.WeatherTypeNgToCategoryMappingProfileId;
                    });

                    item.WeatherTypeNgToCategoryMappings = this.orderByPipe.transform(item.WeatherTypeNgToCategoryMappings, ['WeatherTypeNg']);
                }
            });
        } else if (isNew) {
            this.item = this.api.WeatherTypeNgToCategoryMappingProfile.create({
                Active: true,
            });
        } else {
            this.item = api.WeatherTypeNgToCategoryMappingProfile.get({id: id});
            this.item.$promise.then(item => {
                item.WeatherTypeNgToCategoryMappings = this.orderByPipe.transform(item.WeatherTypeNgToCategoryMappings, ['WeatherTypeNg']);
            });
        }
    }

    save() {
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['WeatherTypeNgToCategoryMappingProfile'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    addMapping() {
        this.item.WeatherTypeNgToCategoryMappings = this.item.WeatherTypeNgToCategoryMappings || [];
        this.item.WeatherTypeNgToCategoryMappings.push({});
        this.ngForm.form.markAsDirty();
    }

    removeMapping(mapping) {
        this.item.WeatherTypeNgToCategoryMappings.splice(this.item.WeatherTypeNgToCategoryMappings.indexOf(mapping), 1);
        this.ngForm.form.markAsDirty();
    }
}
