import {Component, OnInit, ViewChild} from "@angular/core";
import { IAgGridColumnDef, StorageService, ValueFormatters, SwxModule, GridModule } from "swx.front-end-lib";
import {ApiService} from "../shared/Api.service";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './NowcastsLwe12.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, GridModule]
})
export class NowcastsLwe12Component implements OnInit {
    @ViewChild('grid') grid;
    storeKey = 'nowcastsLwe12Query';
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    query: any;

    airports = this.api.Airport.query();
    resource = this.api.NowcastsLWE12;
    exportResourcePost = this.api.NowcastsLWE12Export;

    constructor(private storage: StorageService,
                private api: ApiService,
                private formatters: ValueFormatters) {
    }

    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery){
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    };

    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }

    columnDefs: IAgGridColumnDef[] = [
        { colId: "Nowcast.Id", field: "Nowcast.Id", headerName: "#", width: 70, filterType: "integer", pinned: 'left' },
        { colId: "RetrievedDate", field: "RetrievedDate", headerName: "Retrieved date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left', filterType: "date" },
        { colId: "RetrievedTime", field: "RetrievedDate", headerName: "Retrieved time", width: 80, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, pinned: 'left', "searchable": false },
        { colId: "AirportICAOCode", field: "AirportICAOCode", headerName: "Airport", width: 70 },
        { colId: "Nowcast.StartDate", field: "Nowcast.StartTime", headerName: "Start date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "Nowcast.StartTime", field: "Nowcast.StartTime", headerName: "Start time", width: 80, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
        { colId: "Nowcast.ValidFor", field: "Nowcast.ValidFor", headerName: "Valid for (minutes)", width: 100, filterType: "integer" },
        { colId: "Nowcast.LeadTime", field: "Nowcast.LeadTime", headerName: "Lead time (minutes)", width: 100, filterType: "integer" },
        { colId: "Nowcast.Prediction", field: "Nowcast.Prediction", headerName: "Prediction", width: 100 },
        { colId: "Nowcast.Confidence", field: "Nowcast.Confidence", headerName: "Confidence", width: 100, filterType: "float" },
    ];
} 
