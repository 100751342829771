import {Component, ViewChild, ViewContainerRef} from "@angular/core";
import { ConfirmService, ConfigGridComponent, IAgGridColumnDef, IResourceItem, IResourceItemList, TranslateService, ValueFormatters, yesNo, SwxModule, GridModule } from "swx.front-end-lib";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import {RootScope} from "../shared/RootScope.service";
import {ApiService} from "../shared/Api.service";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";
import { HasPermissionService, HasPermissionPipe } from "../shared/HasPermission.pipe";
import { NgFor, NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './FluidList.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, NgIf, RouterLink, GridModule, HasPermissionPipe]
})
export class FluidListComponent {
    items: IResourceItemList<any>;
    searchQuery;
    query;
    @ViewChild('grid', { static: true }) grid: ConfigGridComponent;
    allFluids = this.api.FluidDetail.query({ IncludeInactive: true });
    fluids = [];
    fluidProfiles = this.api.FluidProfile.query({ IncludeInactive: true });

    columnDefs: IAgGridColumnDef[] = [
        { colId: "Id", field: "Id", headerName: "#", width: 60, pinned: "left", filterType: "integer" },
        { colId: "Name", field: "Name", headerName: "Name", width: 300,  pinned: 'left', cellTemplate: `<a [routerLink]="['/fluids/', params.data?.Id]">{{params.data?.Name}}</a>` },
        { colId: "FluidProfileName", field: "FluidProfileName", headerName: "Fluid profile", width: 200, },
        { 
            colId: "Active", 
            field: "Active", 
            headerName: "Active?", 
            width: 70, 
            cellRenderer: yesNo,
        },
        { colId: "Dilution", field: "Dilution", headerName: "Dilution", width: 80 },
        { colId: "Type", field: "Type", headerName: "Fluid type", width: 80, filterType: "enum", source: "FluidType" },
        { colId: "FluidVersionNames", field: "FluidVersionNames", headerName: "Versions", width: 300 },
        { colId: "WingMaterialType", field: "WingMaterialType", headerName: "Wing surface types", width: 200, filterType: "enum", source: "WingMaterialType" },
        { colId: "DeicingFluidName", field: "DeicingFluidName", headerName: "Deicing fluid", width: 300 },
        { colId: "HOTAdjustmentPercentage", field: "HOTAdjustmentPercentage", headerName: "LWE HOT adjustment % for flaps/slats", width: 150 },
        {colId: 'LastModified', field: 'LastModified', headerName: 'Modified date', width: 150, valueFormatter: this.formatters.utcDateTimeFormatter()},
        {colId: 'LastModifiedByUserName', field: 'LastModifiedByUserName', headerName: 'Modified by', width: 100},
        {colId: 'CreatedDate', field: 'CreatedDate', headerName: 'Created date', width: 150, valueFormatter: this.formatters.utcDateTimeFormatter()},
        {colId: 'CreatedByUserName', field: 'CreatedByUserName', headerName: 'Created by', width: 100},
        { colId: "NumberOfHotSpecs", field: "NumberOfHotSpecs", headerName: "Number of HOT specs", width: 80, filterType: "integer" },
        { colId: "NumberOfMetarSpecs", field: "NumberOfMetarSpecs", headerName: "Number of METAR specs", width: 80, filterType: "integer" },
        { colId: "NumberOfFluidDegreeSpecificMetarSpecs", field: "NumberOfFluidDegreeSpecificMetarSpecs", headerName: "Number of DS METAR specs", width: 80, filterType: "integer" },
        { colId: "InUseAtLWEAirports", field: "InUseAtLWEAirports", headerName: "In use at LWE airport", width: 250, filterType: "boolean" },
        { colId: "InUseBy", field: "InUseBy", headerName: "In use by", width: 250 },
        { colId: "LWELOUT", field: "LWELOUT", headerName: "LWE LOUT (°C)", width: 80, filterType: "float",  valueGetter: params => Number(params.data?.LWELOUT).toFixed()},
        { colId: "METARLOUT", field: "METARLOUT", headerName: "METAR LOUT (°C)", width: 80, filterType: "float", valueGetter: params => Number(params.data?.METARLOUT).toFixed() },
        { colId: "Actions", field: "Id", headerName: "", width: 80, cellClass: "actions", cellRenderer: c => {
                if (c.data == null) return '';
                var container = document.createElement('div');
                container.innerHTML = '<a href="/fluids/' + c.data.Id + '" class="mdi mdi-pencil" title="' + this.translateService.translate('Edit') + '" onclick="event.preventDefault();"></a>'
                    + (this.hasPermission.hasPermission('ConfigEditFluids') ? '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>' : '')
                    + (this.hasPermission.hasPermission('ConfigEditFluids') ? '<a href="/fluids/new?copy=' + c.data.Id + '" class="mdi mdi-content-copy" title="' + this.translateService.translate('Copy') + '" onclick="event.preventDefault();"></a>' : '');
                Array.from(container.querySelectorAll('a[href]')).forEach(n => n.addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href'))));
                Array.from(container.querySelectorAll('.mdi-delete')).forEach(n => n.addEventListener('click', () => this.delete(c.data)));
                return container;
            }, pinned: "right", searchable: false, excelIgnore: true },
    ];

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private $root: RootScope,
        public api: ApiService,
        private translateService: TranslateService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private confirmService: ConfirmService,
        private hasPermission: HasPermissionService,
        private formatters: ValueFormatters,
    ) {
    }

    ngOnInit() {
        this.query = {};
        Promise.all([this.allFluids.$promise, this.fluidProfiles.$promise]).then(() => {
            this.refresh();
        });
    }

    refresh() {
        this.fluids = this.allFluids.filter(f => !this.fluidProfiles.find(p => p.Id === f.FluidProfileId)._unselected);
    }
    
    export() {
        this.grid.export({
            FluidProfileId: this.fluidProfiles.filter(p => !p._unselected).map(p => p.Id),
        });
    }

    delete(item: IResourceItem) {
        this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.ticketTrackingSystem.trackAndDelete(this.viewContainerRef, item, () => this.refresh());
        }).catch(() => {});
    }

    isFluidProfileSelected(fluidProfile) {
        return !fluidProfile._unselected;
    }

    toggleFluidProfile(fluidProfile) {
        fluidProfile._unselected = !fluidProfile._unselected;
        this.refresh();
    }
}
