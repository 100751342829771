<div id="weatherProfileData" class="noMargins">
    <page-title>{{'Weather profile data'|translate}}</page-title>
    <form #form="ngForm" class="toolbar gridFilters">
        <a class="tab" (click)="switchTab('grid')" [class.active]="tab == 'grid'"><span class="mdi mdi-table-large"></span> {{'Grid'|translate}}</a>
        <a class="tab" (click)="switchTab('graph')" [class.active]="tab == 'graph'"><span class="mdi mdi-chart-line"></span> {{'Graph'|translate}}</a>
        <div class="field inline">
            <span class="caption">{{'Station'|translate}}</span>
            <multiselect [(ngModel)]="query.StationId" name="query.StationId">
                <multiselect-option *ngFor="let station of stations|orderBy:'Name'" [value]="station.Id" [name]="station.Name"></multiselect-option>
            </multiselect>
        </div>
        <div class="field inline">
            <span class="caption">{{'Sensor select matrix'|translate}}</span>
            <multiselect [(ngModel)]="query.SensorSelectMatrixId" name="query.SensorSelectMatrixId">
                <multiselect-option *ngFor="let sensorSelectMatrix of sensorSelectMatrices" [value]="sensorSelectMatrix.Id" [name]="sensorSelectMatrix.Name"></multiselect-option>
            </multiselect>
        </div>
        <label class="inline">
            <span class="caption">{{'METAR HOT reference client'|translate}}</span>
            <select [(ngModel)]="query.ClientId" name="query.ClientId">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let client of clients|orderBy:'Name'" [value]="client.Id">
                    {{client.Name}}
                </option>
            </select>
        </label>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="query.FromDate">
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="query.ToDate">
        </label>
        <label class="field inline">
            <input type="checkbox" [(ngModel)]="query.SkipIntermediate" name="query.StkipIntermediate">
            <span class="caption">{{'Skip intermediate'|translate}}</span>
        </label>
        <label>
            <span class="caption">{{'Fluid HOTs'|translate}}</span>
            <multiselect [all]="false" [width]="600" [(ngModel)]="query.FluidId" name="query.FluidId" (change)="updateColumnDefs()">
                <multiselect-option *ngFor="let fluid of fluids|orderBy:['FluidProfileName','Type','Name']" [value]="fluid.Id" [name]="fluid.FluidProfileName + ' - ' + fluid.Type + ' - ' + fluid.Name + ' (' + fluid.Dilution + ')'"></multiselect-option>
            </multiselect>
        </label>
        <label>
            <span class="caption">{{'Comparison LWE equation profiles'|translate}}</span>
            <multiselect [all]="false" [width]="300" [(ngModel)]="query.LWEEquationProfileId" name="query.LWEEquationProfileId" (change)="updateColumnDefs()">
                <multiselect-option *ngFor="let lweEquationProfile of lweEquationProfiles|orderBy:'Name'" [value]="lweEquationProfile.Id" [name]="lweEquationProfile.Name"></multiselect-option>
            </multiselect>
        </label>
        <filters [(ngModel)]="query.Filters" [columnDefs]="columnDefs" name="query.Filters"></filters>
        <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        <button type="button" (click)="apply()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        <button type="button" (click)="grid.export()" [disabled]="!form.valid" ><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
        <button type="button" (click)="grid.selectColumns()" [disabled]="!form.valid" ><span class="mdi mdi-table-edit"></span>{{'Columns'|translate}}</button>
    </form>

    <div [hidden]="tab !== 'graph'" class="graphContainer">
        <div class="series">
            <label *ngFor="let seriesInfo of series; let i = index" class="field inline">
                <input type="checkbox" [(ngModel)]="seriesInfo.enabled" [name]="'seriesInfo[' + i + '].enabled'" (change)="drawChart();">
                <span class="caption">{{seriesInfo.name}}</span>
            </label>
        </div>
        <div class="graph" #chartEl></div>
    </div>
    
    <grid
        [hidden]="tab !== 'grid'"
        #grid
        gridId='preferences.WeatherProfileData.ColumnExport'
        [resource]="resource"
        [exportResource]="exportResourcePost"
        [query]="query"
        [columnDefs]="columnDefs"
        [cacheBlockSize]="2880"
        (dataLoaded)="gridData = $event; drawChart();"
        [headerHeight]="90">
    </grid>
</div>
