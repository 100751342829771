import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'values',
    pure: false,
    standalone: true
})
export class ValuesPipe implements PipeTransform {
    transform(value: Object): string[] {
        return Object.values(value);
    }
}
