import { Component, OnInit } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { StorageService, SwxModule } from "swx.front-end-lib";
import { NgIf, JsonPipe } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    selector: 'swx-event-test',
    templateUrl: 'EventTest.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, JsonPipe]
})
export class EventTestComponent implements OnInit {
    private storageKey = 'eventTestQuery';
    query: { EventName: string, Data: string };
    result: any;

    constructor(
        private api: ApiService,
        private storage: StorageService,
    ) {}

    ngOnInit() {
        this.query = this.storage.load(this.storageKey) || { EventName: '', Data: '' };
    }

    send() {
        this.storage.save(this.storageKey, this.query);
        this.api.EventTest.post(this.query).then(result => {
            this.result = result;
        });
    }
}
