import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import {ApiService} from "../shared/Api.service";
import { StorageService, SwxModule } from "swx.front-end-lib";
import { NgFor, NgIf, KeyValuePipe } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './WeatherProfiler.component.html',
    styles: [`
        .requestJson {
            display: none;
        }
    `],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgFor,
        NgIf,
        KeyValuePipe,
    ],
})
export class WeatherProfilerComponent implements OnInit {
    private storageKey = 'weatherProfilerRequest';
    vm: any;
    request: any;
    result: any;
    datePickerOptions: any;
    stations = this.api.Station.query({ IncludeInactive: true });
    sensorSelectMatrices = this.api.SensorSelectMatrix.query({ IncludeInactive: true });
    metarWarnings = this.api.MetarWarning.query();
    weatherTypeNgs = this.api.WeatherTypeNg.query();

    constructor(
        private api: ApiService,
        private storage: StorageService,
        private sanitizer: DomSanitizer,
    ) { }

    ngOnInit() {
        let maxDateTime = new Date();
        maxDateTime.setDate(maxDateTime.getDate() + 1);
        this.datePickerOptions = {
            showTime: true,
            timezone: 0,
            showTimezone: true,
            maxDateTime: maxDateTime,
            dateFormat: 'yy-mm-dd',
            timeFormat: 'HH:mm:ss',
            useUtc: true,
        };

        this.request = this.storage.load(this.storageKey) || {};
    }

    profile() {
        this.storage.save(this.storageKey, this.request);
        this.api.DebugWeatherProfile.queryObject(this.request).$promise.then(result => {
            result.Trace = this.sanitizer.bypassSecurityTrustHtml(result.Trace?.join('\n'));
            this.result = result;
            setTimeout(() => {
                var requestElement = document.querySelector('.showRequestJson') as HTMLElement;
                var requestJson = requestElement.dataset['json'];
                var requestData = JSON.parse(requestJson);
                requestData = typeof requestData['dataframe_split'] === 'undefined' ? requestData : requestData['dataframe_split'];
                
                var table = document.createElement('table');
                requestElement.parentNode.insertBefore(table, requestElement.nextSibling);
                
                table.className = 'table';
                var thead = document.createElement('thead');
                table.appendChild(thead);
                var thr = document.createElement('tr');
                thead.appendChild(thr);
                
                requestData.columns.forEach(column => {
                    var th = document.createElement('th');
                    th.innerHTML = column;
                    thr.appendChild(th);
                });

                var tbdoy = document.createElement('tbody');
                table.appendChild(tbdoy);
                requestData.data.forEach(row => {
                    var tr = document.createElement('tr');
                    row.forEach(value => {
                        var td = document.createElement('td');
                        td.innerHTML = value === null ? 'null' : value;
                        tr.appendChild(td);
                    });
                    tbdoy.appendChild(tr);
                });

                var requestJsonElement = document.createElement('pre');
                requestElement.parentNode.insertBefore(requestJsonElement, requestElement.nextSibling);
                requestJsonElement.innerHTML = requestJson;

                table.style.display = 'none';
                requestJsonElement.style.display = 'none';
                
                requestElement.addEventListener('click', _ => {
                    table.style.display = (table.style.display == 'none' ? '' : 'none');
                    requestJsonElement.style.display = (requestJsonElement.style.display == 'none' ? '' : 'none');
                });
            });
        });
    }
}
