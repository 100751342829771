<div class="noMargins">
    <page-title>{{'Station performance'|translate}}</page-title>
    <form #form="ngForm">
        <div class="toolbar">
            <label class="inline">
                <span class="caption">{{'Station'|translate}}</span>
                <multiselect [(ngModel)]="query.StationId" name="StationId">
                    <multiselect-option *ngFor="let station of stations|orderBy:'Name'" [value]="station.Id" [name]="station.Name"></multiselect-option>
                </multiselect>
            </label>
            <label class="inline">
                <span class="caption">{{'Station type'|translate}}</span>
                <multiselect [(ngModel)]="query.StationType" name="StationType">
                    <multiselect-option *ngFor="let stationType of stationTypes|keys" [value]="stationType" [name]="stationTypes[stationType]"></multiselect-option>
                </multiselect>
            </label>
            <label class="inline">
                <span class="caption">{{'From'|translate}}</span>
                <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" required placeholder="Start Date" name="query.FromDate">
            </label>
            <label class="inline">
                <span class="caption">{{'To'|translate}}</span>
                <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="query.ToDate">
            </label>
            <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
            <button type="button" (click)="apply()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
            <button type="button" (click)="export()" [disabled]="!form.valid" ><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
        </div>
    </form>

    <config-grid #grid
                 [query]="query"
                 [parent]="this"
                 [columnDefs]="columnDefs"
                 [headerHeight]="96">
    </config-grid>
</div>
