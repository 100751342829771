import { Component, Input } from '@angular/core';
import { TranslatePipe } from '../pipes/Translate.pipe';
import { NgFor, NgIf } from '@angular/common';

@Component({
    template: `<table class="table">
    <tr>
        <td style="background-color: #ffffff; width: 200px;"></td>
        <th>{{'Before'|translate}}</th>
        <th>{{'After'|translate}}</th>
    </tr>
    <tr *ngFor="let property of diff.ChangedProperties">
        <th>{{property.Property}}</th>
        <td style="background-color: #ffecec; max-width: 250px; word-wrap: break-word;">{{property.OldValue}}</td>
        <td style="background-color: #eaffea; max-width: 250px; word-wrap: break-word;">{{property.NewValue}}</td>
    </tr>

    <ng-container *ngFor="let collection of diff.ChangedCollections">
        <tr>
            <td colspan="3" style="background-color: #ffffff;">&nbsp;</td>
        </tr>
        <tr>
            <td style="background-color: #ffffff;">&nbsp;</td>
            <th colspan="2">{{collection.Property}}</th>
        </tr>
        <ng-container *ngFor="let properties of collection.ChangedItems">
            <ng-container *ngIf="properties.length">
                <tr>
                    <td style="background-color: #ffffff;">&nbsp;</td>
                    <th>{{'Before'|translate}}</th>
                    <th>{{'After'|translate}}</th>
                </tr>
                <tr *ngFor="let property of properties">
                    <th>{{property.Property}}</th>
                    <td style="background-color: #{{property.Modified ? 'ffecec' : 'ffffff'}}; max-width: 200px; word-wrap: break-word;">{{property.OldValue}}</td>
                    <td style="background-color: #{{property.Modified ? 'eaffea' : 'ffffff'}}; max-width: 200px; word-wrap: break-word;">{{property.NewValue}}</td>
                </tr>
            </ng-container>
        </ng-container>
        <ng-container *ngFor="let properties of collection.NewItems">
            <ng-container *ngIf="properties.length">
                <tr>
                    <td style="background-color: #ffffff;">&nbsp;</td>
                    <th colspan="2">{{'Added'|translate}}</th>
                </tr>
                <tr *ngFor="let property of properties">
                    <th>{{property.Property}}</th>
                    <td style="background-color: #eaffea; max-width: 200px; word-wrap: break-word;" colspan="2">{{property.NewValue}}</td>
                </tr>
            </ng-container>
        </ng-container>
        <ng-container *ngFor="let properties of collection.RemovedItems">
            <ng-container *ngIf="properties.length">
                <tr>
                    <td style="background-color: #ffffff;">&nbsp;</td>
                    <th colspan="2">{{'Removed'|translate}}</th>
                </tr>
                <tr *ngFor="let property of properties">
                    <th>{{property.Property}}</th>
                    <td style="background-color: #ffecec; max-width: 200px; word-wrap: break-word;" colspan="2">{{property.OldValue}}</td>
                </tr>
            </ng-container>
        </ng-container>
    </ng-container>
</table>`,
    standalone: true,
    imports: [NgFor, NgIf, TranslatePipe]
})
export class DiffDialogComponent {
    @Input('diff') diff;
}
