import { Component } from '@angular/core';
import { BusyIndicatorService } from '../BusyIndicator.service';
import { NgClass } from '@angular/common';

@Component({
    selector: 'busy-indicator',
    templateUrl: './busyIndicator.component.html',
    standalone: true,
    imports: [NgClass]
})
export class BusyIndicatorComponent {
    constructor(private busyIndicatorService: BusyIndicatorService) { }

    get isBusy(): boolean {
        return this.busyIndicatorService.busy;
    }
}
