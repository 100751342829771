import {Component, ViewChild, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { Base64UploadAdapterPlugin, ChangesService, SwxModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'FreezingFogAlertProfileDetail.component.html',
    encapsulation: ViewEncapsulation.None,
    styles: [`.ck-editor__editable_inline {
                min-height: 200px;
            }`,],
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgIf,
        NgFor,
        CKEditorModule,
        HasPermissionPipe,
    ],
})
export class FreezingFogAlertProfileDetailComponent {
    item: any;
    returnPath;
    tab;
    equipmentTypes = this.api.EquipmentType.query();
    datalinkServiceProviders = this.api.DatalinkServiceProvider.query();
    clients = this.api.Client.query();
    ibmMqServerConfigurations = this.api.IBMMQServerConfiguration.query();
    emailFroms = this.api.EmailFrom.query();
    @ViewChild('ngForm', {static: true}) ngForm;
    freezingFogAlertProfileEquipmentTypeOption;
    IntervalEditor = ClassicEditor;
    InEditor = ClassicEditor;
    OutEditor = ClassicEditor;
    OffEditor = ClassicEditor;
    ckConfig = {
        extraPlugins: [ Base64UploadAdapterPlugin ],
        toolbar: { items: [ 'bold', 'italic','|', 'link', 'imageUpload', 'heading', 'insertTable', 'bulletedList', 'numberedList', 'outdent', 'indent', '|', 'undo', 'redo' ]}
    };

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.FreezingFogAlertProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.FreezingFogAlertProfileEquipmentTypes) {
                    this.item.FreezingFogAlertProfileEquipmentTypes.forEach(related => {
                        delete related.FreezingFogAlertProfileId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.FreezingFogAlertProfile.create({
                Active: true,
            });
        } else {
            this.item = this.api.FreezingFogAlertProfile.get({ id: id });
        }
    }

    save() {
        this.item.$promise.then(item => {
            this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
        });
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['FreezingFogAlertProfile'],
            SubjectId: this.item.Id
        });
    }

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    }

    equipmentTypesNotEnabled() {
        this.item.FreezingFogAlertProfileEquipmentTypes = this.item.FreezingFogAlertProfileEquipmentTypes || [];
        return this.equipmentTypes.filter(r => !this.item.FreezingFogAlertProfileEquipmentTypes.some(x => x.EquipmentTypeId === r.Id));
    }

    addFreezingFogAlertProfileEquipmentType(token, equipmentType) {
        token.FreezingFogAlertProfileEquipmentTypes = token.FreezingFogAlertProfileEquipmentTypes || [];
        token.FreezingFogAlertProfileEquipmentTypes.push({
            EquipmentTypeId: equipmentType.Id,
            Name: ((equipmentType.IATACode + '/' + (equipmentType.ICAOCode || 'N/A')) + ' ' + equipmentType.ManufacturerAndModelDescription)
        });
        this.ngForm.form.markAsDirty();
    };

    removeFreezingFogAlertProfileEquipmentType(token, freezingFogAlertProfileEquipmentType) {
        token.FreezingFogAlertProfileEquipmentTypes.splice(token.FreezingFogAlertProfileEquipmentTypes.indexOf(freezingFogAlertProfileEquipmentType), 1);
        this.ngForm.form.markAsDirty();
    };
}
