import { Component, ElementRef, forwardRef, OnInit, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'file-as-data',
    template: `<input type="file" [accept]="accept" />`,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileAsDataComponent),
            multi: true
        }
    ],
    standalone: true
})
export class FileAsDataComponent implements ControlValueAccessor, OnInit {
    value: string;
    onchange = (_: any) => { };
    ontouched = (_: any) => { };
    accept;
    @Output() fileLoaded = new EventEmitter<File>();

    constructor(
        private elementRef: ElementRef
    ) {
        this.accept = elementRef.nativeElement.getAttribute('accept');
    }

    ngOnInit(): void {
        const element: HTMLInputElement = this.elementRef.nativeElement;
        element.onchange = (e: Event) => {
            var file = (e.target as HTMLInputElement).files[0];
            if (file) {
                var reader = new FileReader();
                reader.onload = () => {
                    this.value = reader.result as string;
                    this.onchange(this.value);
                    this.ontouched(this.value);
                    this.fileLoaded.emit(file);
                };
                reader.readAsBinaryString(file);
            }
        };
    }

    writeValue(value): void {
        this.value = value;
    }

    registerOnChange(fn): void {
        this.onchange = fn;
    }

    registerOnTouched(fn): void {
        this.ontouched = fn;
    }
}

