import {Component, ViewChild, ViewContainerRef} from "@angular/core";
import { ConfirmService, GridComponent, IAgGridColumnDef, IResourceItem, IResourceItemList, TranslateService, ValueFormatters, SwxModule, GridModule } from "swx.front-end-lib";
import { Router, RouterLink } from "@angular/router";
import {RootScope} from "../shared/RootScope.service";
import {ApiService} from "../shared/Api.service";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";
import { HasPermissionService, HasPermissionPipe } from "../shared/HasPermission.pipe";
import { NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './MQTTBrokerList.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, RouterLink, GridModule, HasPermissionPipe]
})
export class MQTTBrokerListComponent {
    items: IResourceItemList<any>;
    searchQuery;
    query: any;
    includeInactive = false;
    @ViewChild('grid', { static: true }) grid: GridComponent;
    columnDefs: IAgGridColumnDef[] = [
        { colId: "Id", field: "Id", headerName: "#", width: 60, pinned: "left", filterType: "integer" },
        { colId: "Active", field: "Active", headerName: "Active?", width: 70, valueFormatter: c => c.value == null ? '' : c.value ? 'Yes' : 'No' },
        { colId: "Name", field: "Name", headerName: "Name", width: 200, pinned: 'left', cellTemplate: `<a [routerLink]="['/mqttBrokers', params.data?.Id]">{{params.data?.Name}}</a>` },
        {colId: 'Hostname', field: 'Hostname', headerName: 'Hostname', width: 400},
        {colId: 'Port', field: 'Port', headerName: 'Port', width: 80, filterType: "integer"},
        {colId: 'CreatedDate', field: 'CreatedDate', headerName: 'Created date', width: 150, valueFormatter: this.formatters.utcDateTimeFormatter()},
        {colId: 'CreatedByUserName', field: 'CreatedByUserName', headerName: 'Created by', width: 100},
        {colId: 'LastModified', field: 'LastModified', headerName: 'Modified date', width: 150, valueFormatter: this.formatters.utcDateTimeFormatter()},
        {colId: 'LastModifiedByUserName', field: 'LastModifiedByUserName', headerName: 'Modified by', width: 100},
        { colId: "Actions", field: "Id", headerName: "", width: 80, cellClass: "actions", cellRenderer: c => {
            if (c.data == null) return '';
            var container = document.createElement('div');
            container.innerHTML = '<a href="/mqttBrokers/' + c.data.Id + '" class="mdi mdi-pencil" title="' + this.translateService.translate('Edit') + '" onclick="event.preventDefault();"></a>'
                + (this.hasPermission.hasPermission('ConfigEditMQTTBrokers') ? '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>' : '')
                + (this.hasPermission.hasPermission('ConfigEditMQTTBrokers') ? '<a href="/mqttBrokers/new?copy=' + c.data.Id + '" class="mdi mdi-content-copy" title="' + this.translateService.translate('Copy') + '" onclick="event.preventDefault();"></a>' : '');
            Array.from(container.querySelectorAll('a[href]')).forEach(n => n.addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href'))));
            Array.from(container.querySelectorAll('.mdi-delete')).forEach(n => n.addEventListener('click', () => this.delete(c.data)));
            return container;
        }, pinned: "right", "searchable": false, excelIgnore: true },
    ];

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private $root: RootScope,
        public api: ApiService,
        private translateService: TranslateService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private confirmService: ConfirmService,
        private hasPermission: HasPermissionService,
        private formatters: ValueFormatters,
    ) {
    }

    ngOnInit() {
        this.refresh();
    }
    
    refresh() {
        this.grid.refresh();
    }

    delete(item: IResourceItem) {
        this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.ticketTrackingSystem.trackAndDelete(this.viewContainerRef, item, () => this.refresh());
        }).catch(() => {});
    }
}
