import { Component, Input } from '@angular/core';
import { SwxModule } from 'swx.front-end-lib';
import { NgIf } from '@angular/common';

@Component({
    selector: '[sensor-calibration-configuration-header]',
    templateUrl: 'SensorCalibrationConfigurationHeader.component.html',
    standalone: true,
    imports: [NgIf, SwxModule]
})
export class SensorCalibrationConfigurationHeaderComponent {
    @Input() type;
}
