import { Component, OnInit } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { UntypedFormGroup, FormsModule } from "@angular/forms";
import { FileDownloadService, SwxModule } from "swx.front-end-lib";
import { NgIf, NgFor } from "@angular/common";

@Component({
    templateUrl: 'MetarValidation.component.html',
    standalone: true,
    imports: [NgIf, SwxModule, FormsModule, NgFor]
})
export class MetarValidationComponent implements OnInit {
    vm: any;

    constructor(
        private api: ApiService,
        private fileDownload: FileDownloadService) {
    }

    ngOnInit() {
        Promise.all([
            this.api.Client.query().$promise,
            this.api.Fluid.query().$promise
        ]).then(([clients, fluids]) => {
            this.vm = {
                clients: clients,
                fluids: fluids,
                datePickerOptions: {
                    maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
                    dateFormat: 'yy-mm-dd',
                    useUtc: true
                }
            }
        });
    }

    validate(form: UntypedFormGroup) {
        const f = form.value;
        this.api.MetarValidation.exportPost({
            ClientId: f.clientId,
            Data: f.data,
            ReferenceDate: f.referenceDate,
            UseAdjustedHot: !!f.useAdjustedHot,
            UseMetarLWERegressionHotIfAvailable: !!f.useMetarLWERegressionHotIfAvailable,
        }).then((response) => this.fileDownload.download(response.body, (header) => response.headers.get(header)));
    }
}
