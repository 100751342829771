import {Component, OnInit, ViewContainerRef} from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { AuthenticationService, SwxModule } from "swx.front-end-lib";
import { TicketTrackingSystemService } from "../shared/TicketTrackingSystem.service";
import { NgFor, NgIf, KeyValuePipe } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: 'MetarReadingDataUpload.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, NgIf, KeyValuePipe]
})
export class MetarReadingDataUploadComponent implements OnInit {
    airportId: number;
    skipLines: number;
    airports: Array<any>;
    data: string;
    result: any;

    constructor(
        private api: ApiService,
        private viewContainerRef: ViewContainerRef,
        private auth: AuthenticationService,
        private ticketTracker: TicketTrackingSystemService) {
    }

    ngOnInit() {
        this.api.Airport.query({ IncludeInactive: true }).$promise.then(airports => {
            this.skipLines = 6;
            this.airports = airports;
        })
    }

    upload() {
        const query = {
            AirportId: this.airportId,
            Data: this.data,
            SkipLines: this.skipLines,
        };

        this.auth.getCurrentUser().then(user => {
            if (user.TicketTrackingRequired) {
                this.ticketTracker.getComment(this.viewContainerRef).then(result => {
                    this.api.MetarReadingDataUpload.post({
                        ...result,
                        ...query
                    }).then(result => {
                        this.result = result;
                    })
                })
            }
            else {
                this.api.MetarReadingDataUpload.post(query).then(result => {
                    this.result = result;
                })
            }
        });
    }
}
