import {Component, EventEmitter, Output, ViewChild, ViewContainerRef, Input} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import { FileDownloadService, IAgGridColGroupDef, NotificationService, ValueFormatters, yesNo, SwxModule, GridModule } from "swx.front-end-lib";
import {RootScope} from "../shared/RootScope.service";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";
import {BarrelIcingEmailsComponent} from "./BarrelIcingEmails.component";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    selector: 'swx-barrel-icing-data',
    templateUrl: 'BarrelIcingData.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule, NgFor, GridModule]
})
export class BarrelIcingDataComponent {
    @ViewChild('grid') grid;
    @Output() airplaneRefresh = new EventEmitter();
    @Input() emails: BarrelIcingEmailsComponent;
    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    resource = this.api.BarrelIcingMonitoringData;
    query: any = {};
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    
    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private formatters: ValueFormatters, 
                private fileDownload: FileDownloadService,
                private notification: NotificationService,
                private root: RootScope,
                private ticketTracking: TicketTrackingSystemService,
                ) {
    }
    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: "Airplanes",
            children: [
                { colId: "StartMonitoringEnteringDate", field: "StartMonitoringEnteringDate", headerName: "Start monitoring At (Entering)", valueFormatter: this.formatters.utcDateTimeFormatter(), pinned: 'left' },
                { colId: "Id", field: "Id", headerName: "Id", width: 60 },
                { colId: "ClientName", field: "ClientName", headerName: "Client", width: 120, editable: false }, 
                { colId: "AirportICAOCode", field: "AirportICAOCode", headerName: "Airport", width: 80, editable: false },
                { colId: "TailNumber", field: "TailNumber", headerName: "Tailnumber", width: 80, editable: false },
                { colId: "EndMonitoringEnteringDate", field: "EndMonitoringEnteringDate", headerName: "End monitoring At (Entering)", valueFormatter: this.formatters.utcDateTimeFormatter() },
                { colId: "ReachedDate", field: "ReachedDate", headerName: "Reached date", width: 140, valueFormatter: this.formatters.utcDateTimeFormatter()},
                { colId: "AcarResponse", field: "AcarResponse", headerName: "Generated ACARS", 
                    cellTemplate: `
                        <a *ngIf='params.data?.HasAcars' (click)='parent.showAcars(params.data)' class='mdi mdi-package-down'></a>
                        <span *ngIf='!params.data?.HasAcars'>N/A</span>`, 
                    width: 120  },
                { 
                    colId: "AcarsSent", 
                    field: "AcarsSent", 
                    headerName: "Sent ACARS", 
                    cellRenderer: yesNo, 
                    width: 80, 
                    editable: false
                },
                { colId: "StartMonitoringExitingDate", field: "StartMonitoringExitingDate", headerName: "Start monitoring At (Exiting)", valueFormatter: this.formatters.utcDateTimeFormatter() },
                { colId: "EndMonitoringExitingDate", field: "EndMonitoringExitingDate", headerName: "End monitoring At (Exiting)", valueFormatter: this.formatters.utcDateTimeFormatter() },
                { colId: "ExitedDate", field: "ExitedDate", headerName: "Exited date", width: 140, valueFormatter: this.formatters.utcDateTimeFormatter() }
            ]
        }
    ];

    showAcars(data) {
        const response = data.AcarResponse;
        const blob = new Blob([response], { type: 'text/plain' });
        this.fileDownload.download(blob, 'ACARS.txt');
    }
    
    refresh() {
        this.airplaneRefresh.emit(this.query);
        this.grid.refresh();
    }

    export() {
        this.api.BarrelIcingExport
            .exportPost({
                MonitoringColumnDefs: this.columnDefs,
                EmailColumnDefs: this.emails.columnDefs,
                ...this.query
            })
            .then((response) => this.fileDownload.download(response.body, (header) => response.headers.get(header)));
    }
    
    sendExitEmails() {
        if (typeof this.query.ClientId === 'undefined' || (typeof this.query.AirportId === 'undefined')) {
            this.notification.show("Please select specific clients/airports", { type: 'error' })
            return;
        }
       
        if (this.root.currentUser.TicketTrackingRequired) {
            this.ticketTracking.getComment(this.viewContainerRef).then(result => {
                this.exit({...result, ...this.query });
            })
        } else {
            this.exit(this.query);
        }
    }
    
    private exit(query) {
        this.api.BarrelIcingManual.post(query).then(result => {
            if (result.Message) {
                alert(result.Message);
            } else {
                this.notification.show('Exiting Emails Sent.');
            }
        })
    }
}
