import { Pipe, PipeTransform } from '@angular/core';

export interface IWithId {
    Id: number;
}

@Pipe({
    name: 'findById',
    pure: false,
    standalone: true
})
export class FindByIdPipe implements PipeTransform {
    transform<T extends IWithId>(items: T[], id: number) : T | null {
        if (items == null) return null;
        return items.filter(r => r.Id === id)[0] ?? null;
    }
}
