import {Component, ElementRef, Input, forwardRef, AfterViewInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import {IEnumObject} from "../ApiFactory.service";
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'multiselect-enum[enum]',
    styleUrls: ['Multiselect.component.scss'],
    template: `
<div *ngIf="!optionsVisible" class="display" unselectable="on" onselectstart="return false;" (click)="displayValueClick();" [style.width]="width + 'px'">{{getDisplayValue()}}</div>
<input *ngIf="optionsVisible" class="display" type="text" [value]="search" (keyup)="updateSearch($event.target.value)" [placeholder]="getDisplayValue()" [style.width]="width + 'px'" />
<div (click)="optionsVisible = !optionsVisible; updateSearch('');" class="button mdi mdi-chevron-down"></div>
<div class="overlay" *ngIf="optionsVisible" (click)="optionsVisible = false; updateSearch('');"></div>
<div class="options" [style.width]="width + 'px'" *ngIf="optionsVisible">
    <label *ngFor="let option of options" class="option" [style.display]="option.visible ? 'block' : 'none'"><input type="checkbox" [disabled]="max && !isOptionEnabled(option.value) && selected.length >= max" [checked]="isOptionEnabled(option.value)" (click)="toggleOption($event, option.value)" />{{option.name}}</label>
</div>
`,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiselectEnumComponent),
            multi: true
        }
    ],
    standalone: true,
    imports: [NgIf, NgFor],
})
export class MultiselectEnumComponent implements ControlValueAccessor, AfterViewInit {
    @Input('none') noneLabel = 'None';
    @Input('max') max: number;
    @Input('width') width = 272;
    @Input('enum') enum: IEnumObject<string>;
    @Input('format') format = 'string';
    search: string;
    optionsVisible = false;
    selected = [];
    value: any;
    displayValue = null;
    destroyed = false;
    onchange: (_: any) => void = () => { };
    ontouched: (_: any) => void = () => { };
    options = new Array<{
        value: string,
        name: string,
        visible: boolean,
    }>();

    constructor(
        private el: ElementRef
    ) {
    }

    ngAfterViewInit() {
        this.enum.$promise.then(options => {
            this.options = Object.entries(options).map(([k, v]) => ({
                value: k,
                name: v as string,
                visible: true,
            }));
        });
    }

    isOptionEnabled(value) {
        if (this.selected.length === 0) return false;
        return this.selected.indexOf(value) > -1;
    }

    updateSearch(search) {
        this.search = search;
        this.options.forEach(o => {
            o.visible = this.search == null
                || this.search === ''
                || o.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
        });
    }

    writeValue(value: any) {
        if (typeof value === 'string') {
            value = value.split(',').map(v => v.trim());
        }

        this.selected = (value || []);
        this.displayValue = null;
        this.value = this.format === 'string'
            ? this.options
                .map(o => o.value)
                .filter(o => this.selected.some(s => s === o))
                .join(',')
            : this.selected;
    }

    toggleOption(e, value) {
        if (this.selected.length === 1 && this.selected[0] === -1) {
            this.selected.length = 0;
        }

        var index = this.selected.indexOf(value);
        if (index > -1) {
            this.selected.splice(index, 1);
            e.target.checked = false;
        } else {
            this.selected.push(value);
            e.target.checked = true;
        }

        this.setValue(this.selected);
    }

    setValue(value: any) {
        this.writeValue(value);
        this.onchange(this.value);
        this.ontouched(this.value);
    }

    getDisplayValue() {
        if (this.displayValue === null) {
            if (this.selected.length === 0) {
                this.displayValue = this.noneLabel;
            } else if (this.selected.length === 1 && this.selected[0] === -1) {
                this.displayValue = this.noneLabel;
            } else {
                var displayValue = '';
                this.options.forEach(option => {
                    if (this.selected.indexOf(option.value) > -1) {
                        displayValue += ', ' + option.name;
                    }
                });
                this.displayValue = displayValue.substring(2);
            }
        }

        return this.displayValue;
    }

    displayValueClick() {
        this.optionsVisible = true;
        this.search = '';
        setTimeout(() => this.el.nativeElement.querySelector('input.display').focus());
    }

    registerOnChange(fn: (value: any) => any) {
        this.onchange = fn;
    }

    registerOnTouched(fn) {
        this.ontouched = fn;
    }
}
