import { Component, Input, Attribute } from '@angular/core';
import { ControlContainer, NgModelGroup, FormsModule } from "@angular/forms";
import { SwxModule } from 'swx.front-end-lib';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: '[sensor-selection-configuration]',
    templateUrl: 'SensorSelectionConfiguration.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, SwxModule]
})
export class SensorSelectionConfigurationComponent {
    @Input('sensor-selection-configuration') configuration;
    @Attribute('[sensor-selection-configuration]') test;
    @Input() item;
    @Input() name: string;
    @Input() type: string;
    @Input() property: string;
    @Input() group;
    @Input() weatherTypeMappingProfileProperty;
    @Input() stationType;
    @Input() weatherTypeMappingProfiles;
    get aggregations() {
        return this.type == 'WMO'
            ? {
                "Mode": "Mode",
                "Minimum": "Minimum",
                "Maximum": "Maximum"
            }
            : {
                "Average": "Average",
                "Median": "Median",
                "Minimum": "Minimum",
                "Maximum": "Maximum",
                "DirectionAverage": "Direction Average",
            };
    };
    
    enabledUpdated(enabled) {
        if (!enabled) {
            delete this.group[this.name];
        }
    }
}
