import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'includeInactive',
    pure: false,
    standalone: true
})
export class IncludeInactivePipe implements PipeTransform {
    transform(items: any[], includeInactive: boolean): any[] {
        if (includeInactive || !items) return items;
        var result = [];

        items.forEach(item => {
            if (item.Active) result.push(item);
        });
        return result;
    }
}