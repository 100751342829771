import { Component, OnDestroy, OnInit } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { StorageService, SwxModule } from "swx.front-end-lib";
import { HttpBackend, HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Subscription } from "rxjs";
import { NgIf, JsonPipe } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: 'WeatherApi.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, JsonPipe]
})
export class WeatherApiComponent implements OnInit, OnDestroy {
    private storageKey: 'debugWeatherApiRequest';
    private http: HttpClient;
    request: any;
    result: any;
    authSub: Subscription;
    getSub: Subscription;

    constructor(
        private api: ApiService,
        private storage: StorageService,
        private handler: HttpBackend) {

        this.http = new HttpClient(handler);
    }

    ngOnInit() {
        const storedRequest = this.storage.load(this.storageKey);

        this.request = storedRequest || {
            apiUrl: 'https://api.surewx.com',
            airportCode: 'CYUL'
        };
    }

    // TODO: error handling
    send() {
        this.storage.save(this.storageKey, this.request);
        this.authSub = this.http.post(`${this.request.apiUrl}/oauth/access_token`, this.encodeCredentials(this.request.username, this.request.password))
            .subscribe((token: any) => {
                this.getSub = this.http.get(`${this.request.apiUrl}/weather/${this.request.airportCode}`, {
                    headers: {
                        'Authorization': `Bearer ${token.access_token}`
                    }
                }).subscribe(result => {
                    this.result = result;
                })
            })
    }

    ngOnDestroy() {
        if (this.authSub) this.authSub.unsubscribe();
        if (this.getSub) this.getSub.unsubscribe();
    }

    // TODO: duplicated code
    private encodeCredentials(username, password) {
        return new HttpParams()
            .set('grant_type', 'password')
            .set('username', username)
            .set('password', password);
    }
}
