import { Component, ElementRef, forwardRef, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ApiService } from '../shared/Api.service';
import { FileDownloadService, SwxModule } from 'swx.front-end-lib';
import { NgIf } from '@angular/common';

@Component({
    selector: 'file-upload',
    template: `<input type="button" value="{{'Browse...'|translate}}" (click)="browse()"/>&nbsp;
<a *ngIf="filename" (click)="download()">{{filename}}</a>
<span *ngIf="!filename" (click)="browse()">{{'No file selected.'|translate}}</span>&nbsp;
<input *ngIf="filename" type="button" value="{{'Clear'|translate}}" (click)="clear()"/>
<input type="file" #fileInput style="width: 0; height: 0;"/>`,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadComponent),
            multi: true
        }
    ],
    standalone: true,
    imports: [NgIf, SwxModule]
})
export class FileUploadComponent implements ControlValueAccessor, OnInit {
    value: string;
    onchange = (_: any) => { };
    ontouched = (_: any) => { };
    filename: string;

    @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>;
    
    constructor(
	    private api: ApiService,
	    private fileDownloadService: FileDownloadService,
    ) {
    }

    ngOnInit(): void {
        const element: HTMLInputElement = this.fileInput.nativeElement;
        element.onchange = (e: Event) => {
            var file = (e.target as HTMLInputElement).files[0];
            this.filename = null;
            if (file) {
                var reader = new FileReader();
                reader.onload = () => {
	                this.api.FileUpload.post({
		                Filename: file.name,
		                DataUri: reader.result as string
	                }).then(result => {
		                this.value = result;
		                this.onchange(this.value);
		                this.ontouched(this.value);
		                this.filename = file.name;
	                });
                };
                reader.readAsDataURL(file);
            }
        };
    }

    writeValue(value): void {
	    if (this.value !== value) {
		    this.value = value;
		    this.filename = null;
		    if (this.value != null) {
			    this.api.FileUpload.get({ id: this.value }).$promise.then(result => {
				    this.filename = result.Filename;
			    });
		    }
	    }
    }

    registerOnChange(fn): void {
        this.onchange = fn;
    }

    registerOnTouched(fn): void {
        this.ontouched = fn;
    }

    browse() {
	    this.fileInput.nativeElement.click();
    }

    clear() {
	    this.filename = null;
	    this.value = null;
	    this.onchange(this.value);
	    this.ontouched(this.value);
    }

    download() {
	    this.api.FileUploadUrl.get(this.value).$promise
		    .then(response => this.fileDownloadService.downloadUrl(response.Url, response.Filename));
    }
}
