<form #form="ngForm" class="noMargins">
    <page-title>{{('Mobile document profile'|translate) + ': ' + item.Name}}</page-title>
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [ngClass]="{active : tab === 'basicInfo', invalid: form.form.controls.basicInfoForm?.invalid }">{{'Basic info'}}</a>
        <a class="tab" (click)="switchTab('procedures')" [ngClass]="{active : tab === 'procedures', invalid: form.form?.invalid}">{{'Procedures'}}</a>
        <a class="tab" (click)="switchTab('guidelines')" [ngClass]="{active : tab === 'guidelines', invalid: form.form.controls.guidelinesForm?.invalid}">{{'Guidelines'}}</a>
        <input type="submit" (click)="save()" [disabled]="!form.valid || !form.dirty" value="{{'Save'|translate}}" />
        <input type="button" (click)="export()" [disabled]="!form.valid || form.dirty" value="{{'Export'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="'DataChanges'|hasPermission" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        <button *ngIf="('ConfigEditMobileDocumentProfiles'|hasPermission) && tab === 'procedures'" type="button" (click)="addMobileProcedure()" class="green">{{'Add procedure'|translate}}</button>
        <button *ngIf="('ConfigEditMobileDocumentProfiles'|hasPermission) && tab === 'guidelines'" type="button" (click)="addMobileGuidelineSection()" class="green">{{'Add section'|translate}}</button>
    </div>

    <div class="form">
        <fieldset class="contentMargins" [disabled]="!('ConfigEditMobileDocumentProfiles'|hasPermission)" *ngIf="tab === 'basicInfo'" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'Name'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Name" name="Name" style="width: 300px;" />
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="Active" />
            </label>
            <label>
                <span class="caption">{{'Header title'|translate}}</span>
                <input type="text" [(ngModel)]="item.HeaderTitle" name="HeaderTitle" required style="width: 300px;" />
            </label>
            <label>
                <span class="caption">{{'Header logo'|translate}}</span>
                <input type="text" [(ngModel)]="item.HeaderLogo" name="HeaderLogo" style="width: 100px;" />
            </label>
            <label>
                <span class="caption">{{'Version'|translate}}</span>
                <textarea required [(ngModel)]="item.Version" name="Version" style="width: 300px; height: 50px;"></textarea>
            </label>
            <label>
                <span class="caption">{{'Auto update?'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.AutoUpdate" name="AutoUpdate" />
            </label>
            <label *ngIf="!item.AutoUpdate">
                <span class="caption">{{'Use a bigger callout for documents update?'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.UseBigDocumentUpdate" name="UseBigDocumentUpdate" />
            </label>
            <label *ngIf="!item.AutoUpdate">
                <span class="caption">{{'Update message'|translate}}</span>
                <input type="text" [required]="!item.AutoUpdate" style="width: 300px;" [(ngModel)]="item.UpdateMessage" name="UpdateMessage" />
            </label>
            <div class="field">
                <span class="caption">{{'Checklists bundle'|translate}}</span>
                <file [(ngModel)]="item.ChecklistsData" name="ChecklistsData" [filename]="item.ChecklistsFilename" (filenameChange)="item.ChecklistsFilename = $event" [contentType]="item.ChecklistsContentType" (contentTypeChange)="item.ChecklistsContentType = $event"></file>
            </div>

            <div class="field">
                <span class="caption">{{'Header flowchart'|translate}}</span>
                <file [(ngModel)]="item.HeaderFlowchartData" name="HeaderFlowchartData" [filename]="item.HeaderFlowchartFilename" (filenameChange)="item.HeaderFlowchartFilename = $event" [contentType]="item.HeaderFlowchartContentType" (contentTypeChange)="item.HeaderFlowchartContentType = $event"></file>
            </div>
            <label *ngIf="item.HeaderFlowchartFilename">
                <span class="caption">{{'Header flowchart as popup (HTML files only)?'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.HeaderFlowchartPopup" name="HeaderFlowchartPopup" />
            </label>
            <div class="field">
                <span class="caption">{{'Snow visibility'|translate}}</span>
                <file [(ngModel)]="item.SnowVisibilityData" name="SnowVisibilityData" [filename]="item.SnowVisibilityFilename" (filenameChange)="item.SnowVisibilityFilename = $event" [contentType]="item.SnowVisibilityContentType" (contentTypeChange)="item.SnowVisibilityContentType = $event"></file>
            </div>
            <label *ngIf="item.SnowVisibilityFilename">
                <span class="caption">{{'Snow visibility title'|translate}}</span>
                <input type="text" [required]="item.SnowVisibilityFilename" style="width: 300px;" [(ngModel)]="item.SnowVisibilityTitle" name="SnowVisibilityTitle" />
            </label>
            <label *ngIf="item.SnowVisibilityFilename">
                <span class="caption">{{'Snow visibility as popup (HTML files only)?'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.SnowVisibilityPopup" name="SnowVisibilityPopup" />
            </label>
            <label *ngIf="item.SnowVisibilityFilename">
                <span class="caption">{{'Snow visibility text'|translate}}</span>
                <textarea [(ngModel)]="item.SnowVisibilityText" name="SnowVisibilityText" style="width: 45%;" rows="6"></textarea>
                <a href title="preview tool example : https://html5-editor.net" class="help mdi mdi-help-circle"></a>
            </label>
        </fieldset>

        <fieldset [disabled]="!('ConfigEditMobileDocumentProfiles'|hasPermission)" *ngIf="tab === 'procedures'" ngModelGroup="proceduresForm">
            <table class="table">
                <tr>
                    <th sortable-column="procedure.Title">{{'Title'|translate}}</th>
                    <th sortable-column="procedure.EquipmentTypeId">{{'Equipment type'|translate}}</th>
                    <th sortable-column="procedure.AirportId">{{'Airport'|translate}}</th>
                    <th sortable-column="procedure.Filename">{{'File'|translate}}</th>
                    <th sortable-column="procedure.Order">{{'Order'|translate}}</th>
                    <th sortable-column="procedure.Popup">{{'Popup (HTML files only)?'|translate}}</th>
                    <th></th>
                </tr>
                <tr *ngFor="let procedure of item.MobileProcedures; index as i">
                    <td><input required type="text" [(ngModel)]="procedure.Title" [name]="'procedure.Title' + i" style="width: 350px;" /></td>
                    <td>
                        <select [(ngModel)]="procedure.EquipmentTypeId" [name]="'procedure.EquipmentTypeId' + i">
                            <option [ngValue]="null">{{'All'|translate}}</option>
                            <option *ngFor="let equipmentType of equipmentTypes|orderBy:['IATACode', 'ICAOCode']" [ngValue]="equipmentType.Id">
                                {{(equipmentType.IATACode + '/' + (equipmentType.ICAOCode || ('N/A'|translate))) + ' ' + equipmentType.ManufacturerAndModelDescription}}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select style="width: 180px;" [(ngModel)]="procedure.AirportId" [name]="'procedure.AirportId' + i">
                            <option [ngValue]="null">{{'All'|translate}}</option>
                            <option *ngFor="let airport of airports|orderBy:['ICAOCode', 'IATACode']" [ngValue]="airport.Id">
                                {{(airport.ICAOCode + '/' + (airport.IATACode)) + ' - ' + airport.Name}}
                            </option>
                        </select>
                    </td>
                    <td><file required [(ngModel)]="procedure.Data" [name]="'procedure.Data' + i" [filename]="procedure.Filename" (filenameChange)="procedure.Filename = $event" [contentType]="procedure.ContentType" (contentTypeChange)="procedure.ContentType = $event"></file></td>
                    <td><input required type="number" step="1" min="0" [(ngModel)]="procedure.Order" [name]="'procedure.Order' + i" style="width: 60px;" /></td>
                    <td><input type="checkbox" [(ngModel)]="procedure.Popup" [name]="'procedure.Popup' + i"/></td>
                    <td>
                        <a *ngIf="'ConfigEditMobileDocumentProfiles'|hasPermission" (click)="removeMobileProcedure(procedure)" class="mdi mdi-delete"></a>
                    </td>
                </tr>
            </table>
        </fieldset>

        <fieldset [disabled]="!('ConfigEditMobileDocumentProfiles'|hasPermission)" *ngIf="tab === 'guidelines'" ngModelGroup="guidelinesForm">
            <table class="table">
                <tr>
                    <th sortable-column="section.Title">{{'Title'|translate}}</th>
                    <th sortable-column="section.Filename">{{'File'|translate}}</th>
                    <th sortable-column="section.Order">{{'Order'|translate}}</th>
                    <th></th>
                </tr>
                
                <ng-container *ngFor="let section of item.MobileGuidelineSections; index as i">
                    <tr>
                        <td><input required type="text" [(ngModel)]="section.Title" [name]="'section.Title_' + i" style="width: 350px;" /></td>
                        <td><file [(ngModel)]="section.Data" [name]="'section.Data_' + i" [filename]="section.Filename" (filenameChange)="section.Filename = $event" [contentType]="section.ContentType" (contentTypeChange)="section.ContentType = $event"></file></td>
                        <td><input required type="number" step="1" min="0" [(ngModel)]="section.Order" [name]="'section.Order_' + i" style="width: 60px;" /></td>
                        <td rowspan="2" valign="top">
                            <a *ngIf="'ConfigEditMobileDocumentProfiles'|hasPermission" (click)="removeMobileGuidelineSection(section)" class="mdi mdi-delete"></a>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style="padding-left: 30px;">
                            <h3 style="margin: 0;">{{'Documents'|translate}}</h3>
                            <table class="table">
                                <tr>
                                    <th sortable-column="document.Title">{{'Title'|translate}}</th>
                                    <th sortable-column="document.Filename" *ngIf="section.Filename">{{'Link'|translate}}</th>
                                    <th sortable-column="document.Filename" *ngIf="!section.Filename">{{'File'|translate}}</th>
                                    <th sortable-column="document.Order">{{'Order'|translate}}</th>
                                    <th sortable-column="document.Popup">{{'Popup (HTML files only)?'|translate}}</th>
                                    <th></th>
                                </tr>
                                <tr *ngFor="let document of section.MobileGuidelineSectionDocuments; index as j">
                                    <td><input required type="text" [(ngModel)]="document.Title" [name]="'document.Title' + i + '_' + j" style="width: 350px;" /></td>
                                    <td *ngIf="section.Filename"><input [required]="section.Filename" type="text" [(ngModel)]="document.Link" [name]="'document.Link' + i + '_' + j" style="width: 150px;" /></td>
                                    <td *ngIf="!section.Filename"><file [required]="!section.Filename" [(ngModel)]="document.Data" [name]="'document.Data' + i + '_' + j" [filename]="document.Filename" (filenameChange)="document.Filename = $event" [contentType]="document.ContentType" (contentTypeChange)="document.ContentType = $event"></file></td>
                                    <td><input required type="number" step="1" min="0" [(ngModel)]="document.Order" [name]="'document.Order' + i + '_' + j" style="width: 60px;" /></td>
                                    <td><input type="checkbox" [(ngModel)]="document.Popup" [name]="'document.Popup' + i + '_' + j"/></td>
                                    <td>
                                        <a *ngIf="'ConfigEditMobileDocumentProfiles'|hasPermission" (click)="removeMobileGuidelineSectionDocument(section, document)" class="mdi mdi-delete"></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td *ngIf="'ConfigEditMobileDocumentProfiles'|hasPermission">
                                        <button type="button" (click)="addMobileGuidelineSectionDocument(section)">{{'Add document'|translate}}</button>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </fieldset>
    </div>
</form>
