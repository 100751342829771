import { Component } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { NgFor, NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SwxModule } from "swx.front-end-lib";

@Component({
    templateUrl: 'IbmmqPutTest.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, NgIf]
})
export class IbmmqPutTestComponent {
    query: any = {};
    result: any;
    configurations = this.api.IBMMQServerConfiguration.query();
    configuration: any;

    constructor(private api: ApiService) {
        if (window.localStorage['IbmmqPutTestQuery']) {
            this.query = JSON.parse(window.localStorage['IbmmqPutTestQuery']);
        }
    }

    send() {
        window.localStorage['IbmmqPutTestQuery'] = JSON.stringify(this.query);

        this.api.IBMMQTest.post(this.query).then(result => {
            this.result = result;
        });
    }

    selectConfiguration() {
        this.query = {
            ...this.configuration,
        };
    }
}

