import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment';

/**
 * Shared value formaters for use in AgGrid
 */
@Injectable({ providedIn: 'root' })
export class ValueFormatters {
    dateTimeFormat = 'YYYY-MM-DD HH:mm';
    dateFormat = 'YYYY-MM-DD';
    timeFormat = 'HH:mm';
    timeFormatLong = 'HH:mm:ss';
    currentUser = null;

    constructor(private decimalPipe: DecimalPipe,
                ) { }

    // Value formatter for temperatures, show to 1 decimal precision
    temperatureFormatter() {
        return (params: ValueFormatterParams) => this.decimalFormat(params, { maxFractionDigits: 1 });
    }

    // Value formatter for volumes, show to 1 decimal precision
    volumeFormatter() {
        return (params: ValueFormatterParams) => this.decimalFormat(params, { maxFractionDigits: 1 });
    }

    // Value formatter for UTC times
    utcTimeFormatter() {
        return (params: ValueFormatterParams) => params.value ? moment.utc(params.value).format(this.timeFormat) : '';
    }

    utcTimeLongFormatter() {
        return (params: ValueFormatterParams) => params.value ? moment.utc(params.value).format(this.timeFormatLong) : '';
    }

    utcDateFormatter() {
        return (params: ValueFormatterParams) => params.value ? moment.utc(params.value).format(this.dateFormat) : '';
    }

    // Value for UTC date/time
    utcDateTimeFormatter() {
        return (params: ValueFormatterParams) => params.value
            ? moment.utc(params.value).format(this.dateTimeFormat)
            : '';
    }
    
    // Wrap Angular's decimal pipe transform
    private decimalFormat(params: ValueFormatterParams, digitsInfo: IDigitsInfo): string {
        if (params.value != null) {
            return this.decimalPipe.transform(params.value, this.getDigitsInfo(digitsInfo));
        }

        return '';
    }

    // Digits info string used by Angular's decimal pipe
    private getDigitsInfo(digitsInfo: IDigitsInfo): string {
        return `${digitsInfo.minIntegerDigits || 1}.${digitsInfo.minFractionDigits || 0}-${digitsInfo.maxFractionDigits || 3}`;
    }
}

// Digits info, used by Angular's decimal pipe
interface IDigitsInfo {
    minIntegerDigits?: number;
    minFractionDigits?: number;
    maxFractionDigits?: number;
}
