import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {ActivatedRoute, Router} from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { CellRendererFactories, ChangesService, ConfirmService, FileDownloadService, ConfigGridComponent, IAgGridColGroupDef, IAgGridColumnDef, NotificationService, OrderByPipe, TranslateService, DialogService, ValueFormatters, SwxModule, GridModule } from "swx.front-end-lib";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";
import {ClientSearchFilterPipe} from "./ClientSearchFilter.pipe";
import {FluidSortPipe} from "../shared/FluidSort.pipe";
import { HasPermissionService, HasPermissionPipe } from "../shared/HasPermission.pipe";
import {TemplatePreviewDialogComponent} from "./TemplatePreviewDialog.component";
import { AirportsNotEnabledPipe } from "./AirportsNotEnabled.pipe";
import { FileUploadComponent } from "../fileUpload/FileUpload.component";
import { NgIf, NgClass, NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './ClientEdit.component.html',
    styleUrls: ['./ClientEdit.component.scss'],
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgIf,
        NgClass,
        NgFor,
        GridModule,
        FileUploadComponent,
        AirportsNotEnabledPipe,
        HasPermissionPipe,
    ],
})
export class ClientEditComponent implements OnInit {
    item: any;
    @ViewChild('ngForm', {static: true}) ngForm;
    returnPath;
    tab;
    initPromise: Promise<any>;
    lastStatus = null;

    wingMaterialTypes = this.api.WingMaterialType.query();
    dashboardGroups = this.api.DashboardGroup.query();
    priorities = this.api.AcarsPriority.query();
    hotResponseReceivers = this.api.AcarsTo.query();
    datalinkServiceProviders = this.api.DatalinkServiceProvider.query();
    portalUserPermissions = this.api.PortalUserPermission.query();

    sensorSelectMatrices = this.api.SensorSelectMatrix.query();
    metarMessageMappingProfiles = this.api.MetarMessageMappingProfile.query();
    metarWeatherMappingProfiles = this.api.MetarWeatherMappingProfile.query();
    mobileWeatherMappingProfiles = this.api.MobileWeatherMappingProfile.query();
    fluidProfiles = this.api.FluidProfile.query();
    fluidSeasons = this.api.FluidSeason.query();
    lweMessageMappingProfiles = this.api.LWEMessageMappingProfile.query();
    equipmentTypes = this.api.EquipmentType.query();
    airlines = this.api.DeicingAirline.query();
    templates = this.api.AcarsTemplate.query();
    users = this.api.User.query();
    hotConditions = this.api.HotCondition.query();
    holdoverTaxiTimeModelConfigurations = this.api.HoldoverTaxiTimeModelConfiguration.query();
    ibmMqServerConfigurations = this.api.IBMMQServerConfiguration.query();
    airports = this.api.Airport.query();
    fluids = this.api.Fluid.query();
    availableFluids = [];
    stations = this.api.Station.query();
    weatherAlertProfiles = this.api.WeatherAlertProfile.query();

    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };

    fluid;
    airport;
    station;
    equipmentType;
    sensorSelectMatrix = {};

    stationsByAirportSimulation = {};
    stationsByAirportGen2 = {};
    stationsByAirportGen3 = {};
    stationsByAirportGenV = {};
    
    currentTemplate;
    airportsDict = {};
    fluidsDict = {};
    previews;

    exceptionsColumnDefs: IAgGridColumnDef[];
    exceptionsData =  [
        {'Field': 'AirportError', 'Name': 'Unknown airport'},
        {'Field': 'TailNumberError', 'Name': 'Unknown tailnumber'},
        {'Field': 'UnsubscribedAirportError', 'Name': 'Airport not subscribed'},
        {'Field': 'TWAHOTStartTimeRequiredError', 'Name': 'TWA HOT start time required'},
        {'Field': 'TWAHOTStartTimeInvalidError', 'Name': 'TWA HOT start time invalid'},
        {'Field': 'MetarMappingProfileNotConfiguredError', 'Name': 'METAR mapping profile not configured'},
        {'Field': 'MetarHotNotAllowedError', 'Name': 'METAR HOT not allowed'},
        {'Field': 'MetarReadingUnavailableError', 'Name': 'METAR reading unavailable'},
        {'Field': 'MetarIncompleteError', 'Name': 'METAR incomplete'},
        {'Field': 'NoSuitableFluidsFoundError', 'Name': 'No suitable fluids found'},
        {'Field': 'WeatherProfileUnavailableError', 'Name': 'Weather profile unavailable'},
        {'Field': 'WeatherCategoryNotConfiguredError', 'Name': 'Weather category not configured'},
        {'Field': 'LWEHOTNotAllowedError', 'Name': 'LWE HOT not allowed'},
        {'Field': 'StationUnderMaintenanceError', 'Name': 'Station under maintenance'},
        {'Field': 'SystemError', 'Name': 'All other errors'},
    ];
    fluidExceptionsDate = [
        {'Field': 'FluidHotSpecNotFoundError', 'Name': 'LWE HOT does not exist for these conditions'},
        {'Field': 'OATBelowFluidLOUTError', 'Name': 'OAT below fluid LOUT'},
        {'Field': 'WeatherCategoryNotSupportedError', 'Name': 'Weather category not supported'},
        {'Field': 'FluidMetarSpecNotFoundError', 'Name': 'METAR HOT does not exist for these conditions'},
        {'Field': 'TWATooManyMissingHotsError', 'Name': 'Too many missing HOTs for TWA calculation'},
        {'Field': 'TWATooManyConsecutiveMissingHotsError', 'Name': 'Too many consecutive missing HOTs for TWA calculation'},
        {'Field': 'DefaultHotText', 'Name': 'Default HOT'},
        {'Field': 'TWAOATBelowFluidLOUTError', 'Name': 'TWA intermediate OAT below fluid LOUT'},
    ];

    fluidsSearchQuery = '';

    @ViewChild('airportsGrid', { static: true }) airportsGrid: ConfigGridComponent;
    airportsGridColumnDefs;
    aircraftSearchQuery = '';
    airportsData: Array<any>;

    @ViewChild('fluidAirportsGrid', { static: true }) fluidAirportsGrid: ConfigGridComponent;
    fluidAirportsColumnDefs: IAgGridColGroupDef[];
    fluidAirportsSearchQuery = '';
    fluidAirportsData: Array<any>;

    @ViewChild('aircraftGrid', { static: true }) aircraftGrid: ConfigGridComponent;
    aircraftColumnDefs: IAgGridColGroupDef[];

    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private route: ActivatedRoute,
                private http: HttpClient,
                private confirm: ConfirmService,
                private ticketTrackingSystem: TicketTrackingSystemService,
                private translate: TranslateService,
                private router: Router,
                private changes: ChangesService,
                private translateService: TranslateService,
                private notification: NotificationService,
                private fileDownload: FileDownloadService,
                private formatters: ValueFormatters,
                private clientSearchFliterPipe: ClientSearchFilterPipe,
                private cellRenderers: CellRendererFactories,
                private orderByPipe: OrderByPipe,
                public hasPermissionService: HasPermissionService,
                private dialogService: DialogService,
    ) {}

    ngOnInit() {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';
        
        const load = new Promise<void>(resolve => {
            if (copyId) {
                this.item = this.api.Client.get({id: copyId});
                this.item.$promise.then(() => {
                    delete this.item.Id;

                    if (this.item.ClientAirports) {
                        this.item.ClientAirports.forEach(related => {
                            delete related.ClientId;
                        });
                    }

                    if (this.item.Airplanes) {
                        this.item.Airplanes.forEach(related => {
                            delete related.ClientId;
                        });
                    }

                    if (this.item.ClientDatalinkServiceProviders) {
                        this.item.ClientDatalinkServiceProviders.forEach(related => {
                            delete related.ClientId;
                        });
                    }

                    if (this.item.ClientFluids) {
                        this.item.ClientFluids.forEach(related => {
                            delete related.ClientId;

                            if (related.ClientFluidAirports) {
                                related.ClientFluidAirports.forEach(subRelated => {
                                    delete subRelated.ClientId;
                                });
                            }
                        });
                    }

                    if (this.item.ClientEquipmentTypes) {
                        this.item.ClientEquipmentTypes.forEach(related => {
                            delete related.ClientId;

                            if (related.ClientEquipmentTypeAirports) {
                                related.ClientEquipmentTypeAirports.forEach(subRelated => {
                                    delete subRelated.ClientId;
                                });
                            }
                        });
                    }

                    if (this.item.ClientPortalLWEGridStations) {
                        this.item.ClientPortalLWEGridStations.forEach(related => {
                            delete related.ClientId;
                        });
                    }

                    this.item.ClientPortalCode = '';
                    this.item.IATACode = '';
                    this.item.ICAOCode = '';
                    this.item.ClientDomains = [];
                    this.item.ClientContacts = [];
                    this.item.PortalUsers = [];

                    resolve();
                });
            } else if (isNew) {
                this.item = this.api.Client.create();
                this.exceptionsData.forEach(r => {
                    this.item[r.Field] = {
                        IsRestrictiveCondition: false,
                        IsPTOCCNotAuthorized: false,
                    };
                });
                this.fluidExceptionsDate.forEach(r => {
                    this.item[r.Field] = {
                        IsRestrictiveCondition: false,
                        IsPTOCCNotAuthorized: false,
                    };
                });
                resolve();
            } else {
                this.item = this.api.Client.get({id: id});
                this.item.$promise.then(() => {
                    this.lastStatus = this.item.Active;
                    resolve();
                });
            }
        });

        this.stations.$promise.then(stations => {
            stations.forEach((station) => {
                if (typeof this.stationsByAirportSimulation[station.AirportId] === 'undefined') {
                    this.stationsByAirportSimulation[station.AirportId] = [];
                }
                if (typeof this.stationsByAirportGen2[station.AirportId] === 'undefined') {
                    this.stationsByAirportGen2[station.AirportId] = [];
                }
                if (typeof this.stationsByAirportGen3[station.AirportId] === 'undefined') {
                    this.stationsByAirportGen3[station.AirportId] = [];
                }
                if (typeof this.stationsByAirportGenV[station.AirportId] === 'undefined') {
                    this.stationsByAirportGenV[station.AirportId] = [];
                }
                if (station.StationType === 'Simulation') {
                    this.stationsByAirportSimulation[station.AirportId].push(station);
                } else if (station.StationType === 'Gen2') {
                    this.stationsByAirportGen2[station.AirportId].push(station);
                } else if (station.StationType === 'Gen3') {
                    this.stationsByAirportGen3[station.AirportId].push(station);
                } else if (station.StationType === 'GenV') {
                    this.stationsByAirportGenV[station.AirportId].push(station);
                }
            });
        });

        this.initPromise = load.then(() => {
            this.item.ClientDatalinkServiceProviders = this.item.ClientDatalinkServiceProviders || [];
            this.setDspOptions();

            this.item.ClientAirports = this.item.ClientAirports || [];
            this.item.ClientAirports.sort((a, b) => {
                const airportA = this.airports.find(r => r.Id === a.AirportId);
                const airportB = this.airports.find(r => r.Id === b.AirportId);
                if (airportA && airportB && airportA.ICAOCode < airportB.ICAOCode) return -1;
                if (airportA && airportB && airportA.ICAOCode > airportB.ICAOCode) return 1;
                return 0;
            });
            
            this.item.ClientFluids = this.item.ClientFluids || [];
            this.item.ClientFluids = this.orderByPipe.transform<any>(this.item.ClientFluids, ['Order', 'Name']);

            this.item.Airplanes = this.item.Airplanes || [];
            this.item.Airplanes = this.orderByPipe.transform<any>(this.item.Airplanes, ['TailNumber']);

            this.setTimestamp(this.item);

            return this.airports.$promise.then(() => {
                this.airportsDict = {};
                this.airports.forEach(airport => {
                    this.airportsDict[airport.Id] = airport;
                });

                return Promise.all([this.fluids.$promise, this.fluids.$promise]).then(() => {
                    this.fluidsDict = {};
                    this.fluids.forEach(fluid => {
                        this.fluidsDict[fluid.Id] = fluid;
                    });
                    
                    this.updateAvailableFluids();
                });
            });
        });

        this.setTemplatePreviews();
        
        this.buildFluidAirportsGrid();
        this.buildAirportsGrid();
        this.buildExceptionsGrids();
        this.buildAircraftGrid();

        this.switchTab(location.hash ? location.hash.substring(1) : 'basicInfo');
    }
    
    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    }

    setDspOptions() {
        this.datalinkServiceProviders.$promise.then(() => {
            if (this.item.ClientDatalinkServiceProviders.length === 0) {
                Object.keys(this.datalinkServiceProviders).filter(p => p[0] !== '$').forEach(dsp => {
                    this.item.ClientDatalinkServiceProviders.push(
                        {ClientId: this.item.Id, DatalinkServiceProvider: dsp}
                    );
                });
            }
        });
    }

    setTimestamp(item) {
        let result = this.templates.filter(val => {
            return val.Id === item.DefaultAcarsTemplateId;
        });

        this.currentTemplate = result.shift();

        if (typeof this.currentTemplate !== 'undefined' && !this.currentTemplate.HasCustomMessageLabel) {
            item.AcarsResponseMessageLabel = null;
        }

        if (typeof this.currentTemplate !== 'undefined' && !this.currentTemplate.HasTimestamp) {
            item.HasTimestampInACARSResponse = false;

            if (item.Airplanes != null) {
                item.Airplanes.forEach(elt => {
                    elt.AcarsHasTimestamp = false;
                });
            }
        }
    }

    save() {
        var showConfirmDialog = this.item.Id && (this.lastStatus !== null && this.lastStatus === !this.item.Active);
        var confirmText = "Client status changed from " + (this.lastStatus ? "activated" : "deactivated") + " to " + (this.item.Active ? "activated" : "deactivated") + ". Are you sure ?";

        if (!showConfirmDialog) {
            this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
        } else {
            this.confirm.confirm(this.translate.translate(confirmText)).then(() => {
                this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
            });
        }
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: 'Client',
            SubjectId: this.item.Id
        });
    }
    
    notEnabled(options, enabled, key) {
        return (options || [])
            .filter(s => (enabled || [])
                .every(e => e[key] !== s.Id));
    }

    addClientAirport() {
        this.item.ClientAirports.unshift({
            AirportId: this.airport.Id
        });
        this.updateAirportsData();
        this.buildFluidAirportsGrid();
        this.airport = null;

        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removeClientAirport(clientAirport) {
        this.item.ClientAirports.splice(this.item.ClientAirports.indexOf(clientAirport), 1)
        this.airportsData.splice(this.airportsData.indexOf(clientAirport), 1);
        this.item.ClientFluids.forEach(cf => {
            const cfaIndex = cf.ClientFluidAirports.findIndex(cfa => cfa.AirportId === clientAirport.AirportId);
            if (cfaIndex !== -1) {
                cf.ClientFluidAirports.splice(cfaIndex, 1);
            }
        });
        this.airportsGrid.gridApi.applyTransaction({ addIndex: 0, add: this.item.Airplanes.slice(0, 1) })
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();

        this.airportsGrid.updateFilteredRows();
    }

    buildAirportsGrid() {
        this.initPromise.then(() => {
            let columnDefs: IAgGridColumnDef[] = [
                {
                    colId: "Airport",
                    field: "AirportName",
                    headerName: "Airport",
                    width: 400,
                    pinned: 'left',
                },
                {
                    colId: "StationSimulationId",
                    field: "StationSimulationId",
                    headerName: "Station Simulation",
                    width: 150,
                    cellTemplate: `<select *ngIf='parent.stationsByAirportSimulation[params.data.AirportId]?.length' [(ngModel)]="params.data.StationSimulationId" [name]="'ClientAirport[' + params.node.id + '].StationSimulationId'" style="width: 99%;">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let option of parent.stationsByAirportSimulation[params.data.AirportId]|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>`,
                },
                {
                    colId: "SSMSimulationId",
                    field: "SSMSimulationId",
                    headerName: "Sensor select matrix Simulation",
                    width: 300,
                    cellTemplate: `<select *ngIf='params.data.StationSimulationId != null' required [(ngModel)]="params.data.SSMSimulationId" [name]="'ClientAirport[' + params.node.id + '].SSMSimulationId'" style="width: 99%;">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let option of parent.sensorSelectMatrices|filter:{StationType:'Simulation'}|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>`,
                },
                {
                    colId: "StationGen3Id",
                    field: "StationGen3Id",
                    headerName: "Station Gen 3",
                    width: 150,
                    cellTemplate: `<select *ngIf='parent.stationsByAirportGen3[params.data.AirportId]?.length' [(ngModel)]="params.data.StationGen3Id" [name]="'ClientAirport[' + params.node.id + '].StationGen3Id'" style="width: 99%;">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let option of parent.stationsByAirportGen3[params.data.AirportId]|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>`,
                },
                {
                    colId: "SSMGen3AId",
                    field: "SSMGen3AId",
                    headerName: "Sensor select matrix Gen 3 A",
                    width: 300,
                    cellTemplate: `<select *ngIf='params.data.StationGen3Id != null' required [(ngModel)]="params.data.SSMGen3AId" [name]="'ClientAirport[' + params.node.id + '].SSMGen3AId'" style="width: 99%;">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let option of parent.sensorSelectMatrices|filter:{StationType:'Gen3'}|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>`,
                },
                {
                    colId: "SSMGen3BId",
                    field: "SSMGen3BId",
                    headerName: "Sensor select matrix Gen 3 B",
                    width: 300,
                    cellTemplate: `<select *ngIf='params.data.StationGen3Id != null' [(ngModel)]="params.data.SSMGen3BId" [name]="'ClientAirport[' + params.node.id + '].SSMGen3BId'" style="width: 99%;">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let option of parent.sensorSelectMatrices|filter:{StationType:'Gen3'}|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>`,
                },
                {
                    colId: "StationGen2Id",
                    field: "StationGen2Id",
                    headerName: "Station Gen 2",
                    width: 150,
                    cellTemplate: `<select *ngIf='parent.stationsByAirportGen2[params.data.AirportId]?.length' [(ngModel)]="params.data.StationGen2Id" [name]="'ClientAirport[' + params.node.id + '].StationGen2Id'" style="width: 99%;">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let option of parent.stationsByAirportGen2[params.data.AirportId]|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>`,
                },
                {
                    colId: "SSMGen2Id",
                    field: "SSMGen2Id",
                    headerName: "Sensor select matrix Gen 2",
                    width: 300,
                    cellTemplate: `<select *ngIf='params.data.StationGen2Id != null' required [(ngModel)]="params.data.SSMGen2Id" [name]="'ClientAirport[' + params.node.id + '].SSMGen2Id'" style="width: 99%;">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let option of parent.sensorSelectMatrices|filter:{StationType:'Gen2'}|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>`,
                },
                {
                    colId: "StationGenVId",
                    field: "StationGenVId",
                    headerName: "Station Gen V",
                    width: 150,
                    cellTemplate: `<select *ngIf='parent.stationsByAirportGenV[params.data.AirportId]?.length' [(ngModel)]="params.data.StationGenVId" [name]="'ClientAirport[' + params.node.id + '].StationGenVId'" style="width: 99%;">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let option of parent.stationsByAirportGenV[params.data.AirportId]|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>`,
                },
                {
                    colId: "SSMGenVId",
                    field: "SSMGenVId",
                    headerName: "Sensor select matrix Gen V",
                    width: 300,
                    cellTemplate: `<select *ngIf='params.data.StationGenVId != null' required [(ngModel)]="params.data.SSMGenVId" [name]="'ClientAirport[' + params.node.id + '].SSMGenVId'" style="width: 99%;">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let option of parent.sensorSelectMatrices|filter:{StationType:'GenV'}|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>`,
                },
                {
                    colId: "UseMetar",
                    field: "UseMetar",
                    headerName: "Use METAR?",
                    width: 100,
                    cellRenderer: params => this.cellRenderers.input('checkbox', {
                        checked: params.data.UseMetar,
                        onclick: () => {
                            params.data.UseMetar = !params.data.UseMetar;
                            this.ngForm.form.updateValueAndValidity();
                            this.ngForm.form.markAsDirty();
                        },
                    }),
                },
            ];

            columnDefs.push({
                colId: "Preferred",
                field: "Preferred",
                headerName: "Preferred?",
                width: 100,
                cellRenderer: params => this.cellRenderers.input('checkbox', {
                    checked: params.data.Preferred,
                    onclick: () => {
                        params.data.Preferred = !params.data.Preferred;
                        this.ngForm.form.updateValueAndValidity();
                        this.ngForm.form.markAsDirty();
                    },
                }),
            });

            columnDefs.push({
                colId: "UseSureForecastAllClear",
                field: "UseSureForecastAllClear",
                headerName: "Use SureForecast AllClear?",
                width: 120,
                cellRenderer: params => this.cellRenderers.input('checkbox', {
                    checked: params.data.UseSureForecastAllClear,
                    onclick: () => {
                        params.data.UseSureForecastAllClear = !params.data.UseSureForecastAllClear;
                        this.ngForm.form.updateValueAndValidity();
                        this.ngForm.form.markAsDirty();
                    },
                    disabled: !params.data.HasSureForecastAllClear,
                }),
            });

            columnDefs.push({
                colId: "UseNowcast",
                field: "UseNowcast",
                headerName: "Use UseNowcast?",
                width: 120,
                cellRenderer: params => this.cellRenderers.input('checkbox', {
                    checked: params.data.UseNowcast,
                    onclick: () => {
                        params.data.UseNowcast = !params.data.UseNowcast;
                        this.ngForm.form.updateValueAndValidity();
                        this.ngForm.form.markAsDirty();
                    },
                    disabled: !params.data.HasNowcast,
                }),
            });

            columnDefs.push({
                colId: "GenerateYvrWeatherHots",
                field: "GenerateYvrWeatherHots",
                headerName: "Generate YVR weather HOTs?",
                width: 120,
                cellRenderer: params => this.cellRenderers.input('checkbox', {
                    checked: params.data.GenerateYvrWeatherHots,
                    onclick: () => {
                        params.data.GenerateYvrWeatherHots = !params.data.GenerateYvrWeatherHots;
                        this.ngForm.form.updateValueAndValidity();
                        this.ngForm.form.markAsDirty();
                    },
                }),
            });


            if (this.item.UseHoldoverTaxiTimePrediction) {
                columnDefs.push({
                    colId: "HoldoverTaxiTimeModelConfigurationId",
                    field: "HoldoverTaxiTimeModelConfigurationId",
                    headerName: "Holdover taxi time configuration",
                    width: 150,
                    cellTemplate: `<select [(ngModel)]="params.data.HoldoverTaxiTimeModelConfigurationId" [name]="'ClientAirport[' + params.node.id + '].HoldoverTaxiTimeModelConfigurationId'" style="width: 99%;">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let option of parent.holdoverTaxiTimeModelConfigurations|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>`,
                });

                columnDefs.push({
                    colId: "HoldoverTaxiTimePredictionLower",
                    field: "HoldoverTaxiTimePredictionLower",
                    headerName: "Holdover taxi time static lower",
                    width: 120,
                    cellTemplate: `<input *ngIf='params.data.HoldoverTaxiTimeModelConfigurationId == null' type="number" step="any" min="0" [(ngModel)]="params.data.HoldoverTaxiTimePredictionLower" [name]="'ClientAirport[' + params.node.id + '].HoldoverTaxiTimePredictionLower'" style="width: 60px;" />`,
                });

                columnDefs.push({
                    colId: "HoldoverTaxiTimePredictionUpper",
                    field: "HoldoverTaxiTimePredictionUpper",
                    headerName: "Holdover taxi time static upper",
                    width: 120,
                    cellTemplate: `<input *ngIf='params.data.HoldoverTaxiTimeModelConfigurationId == null' type="number" step="any" min="0" [(ngModel)]="params.data.HoldoverTaxiTimePredictionUpper" [name]="'ClientAirport[' + params.node.id + '].HoldoverTaxiTimePredictionUpper'" style="width: 60px;" />`,
                });
            }

            columnDefs.push({
                colId: "DispatchThroughputGraph",
                field: "DispatchThroughputGraph",
                headerName: "Dispatch throughput graph?",
                width: 150,
                cellRenderer: params => this.cellRenderers.input('checkbox', {
                    checked: params.data.DispatchThroughputGraph,
                    onclick: () => {
                        params.data.DispatchThroughputGraph = !params.data.DispatchThroughputGraph;
                        this.ngForm.form.updateValueAndValidity();
                        this.ngForm.form.markAsDirty();
                    },
                }),
            });

            columnDefs.push({
                colId: "AntiIceRecommendationMinHot",
                field: "AntiIceRecommendationMinHot",
                headerName: "Anti-ice recommendation min. HOT",
                width: 200,
                cellTemplate: `<input type='number' style='width: 60px;' min='0' step='any' [(ngModel)]="params.data.AntiIceRecommendationMinHot" [name]="'ClientAirport[' + params.node.id + '].AntiIceRecommendationMinHot'" />`,
            });

            columnDefs.push({
                colId: "Actions",
                field: "AirportId",
                headerName: "",
                width: 80,
                pinned: 'right',
                excelIgnore: true,
                cellTemplate: `<a *ngIf="parent.hasPermissionService.hasPermission('ConfigDeleteClientsAirports')" (click)="parent.removeClientAirport(params.data)" class="mdi mdi-delete" title="{{'Delete'|translate}}"></a>`,
            });
            
            this.airportsGridColumnDefs = columnDefs;
            this.updateAirportsData();
        });
    }
    
    updateAirportsData() {
        this.airportsData = this.item.ClientAirports.map(clientAirport => {
            var airport = this.airportsDict[clientAirport.AirportId];
            var airportCode = airport ? (airport.ICAOCode + '/' + airport.IATACode) : clientAirport.AirportId;

            clientAirport.AirportName = airportCode + (airport ? ' - ' + airport.Name : '');
            clientAirport.HasSureForecastAllClear = airport ? airport.HasSureForecastAllClear : false;
            clientAirport.HasNowcast = airport ? airport.HasNowcast : false;

            return clientAirport;
        });
    }

    isClientFluidAirportEnabled(clientFluid, airportId) {
        if (!clientFluid.ClientFluidAirports) return false;
        return clientFluid.ClientFluidAirports.some(cfa => cfa.AirportId === airportId);
    }

    toggleClientFluidAirport(clientFluid, airportId) {
        clientFluid.ClientFluidAirports = clientFluid.ClientFluidAirports || [];
        const index = clientFluid.ClientFluidAirports.findIndex(cfa => cfa.AirportId === airportId);
        if (index === -1) {
            clientFluid.ClientFluidAirports.push({AirportId: airportId, FluidId: clientFluid.FluidId});
        } else {
            clientFluid.ClientFluidAirports.splice(index, 1);
        }

        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    buildFluidAirportsGrid() {
        this.initPromise.then(() => {
            let columnDefs: IAgGridColGroupDef[] = [
                {
                    children: [
                        { colId: "Airport", field: "Airport", headerName: "Airport", width: 400, pinned: 'left', },
                    ]
                }
            ];

            this.orderByPipe.transform<any>(this.item.ClientFluids, ['Order', 'Name']).forEach(clientFluid => {
                var fluid = this.fluidsDict[clientFluid.FluidId];
                columnDefs.push({
                        headerName: fluid.FluidProfileName,
                        children: [ {
                        colId: "fluid_" + clientFluid.FluidId,
                        field: "fluid_" + clientFluid.FluidId,
                        headerName: fluid.Name + ' (' + fluid.Dilution + ') (' + fluid.Type + ')',
                        width: 150,
                        cellRenderer: params => this.cellRenderers.input('checkbox', {
                            checked: this.isClientFluidAirportEnabled(clientFluid, params.data.AirportId),
                            onclick: () => this.toggleClientFluidAirport(clientFluid, params.data.AirportId),
                        }),
                        sortable: false,
                    }],
                });
            });
            
            this.fluidAirportsColumnDefs = columnDefs;

            this.fluidAirportsData = this.item.ClientAirports.map(ca => this.mapClientAirport(ca));
        });
    }

    mapClientAirport(clientAirport) {
        var airport = this.airportsDict[clientAirport.AirportId];
        var airportCode = airport ? (airport.ICAOCode + '/' + airport.IATACode) : clientAirport.AirportId;

        return {
            AirportId: clientAirport.AirportId,
            Airport: airportCode + (airport ? ' - ' + airport.Name : '')
        };
    }

    addClientFluid() {
        this.item.ClientFluids = this.item.ClientFluids || [];
        let maxOrder = Math.max.apply(null, this.item.ClientFluids
            .map(cf => cf.Order));
        this.item.ClientFluids.push({
            FluidId: this.fluid.Id,
            Name: this.fluid.Name,
            Order: ++maxOrder,
        });
        this.fluid = null;
        this.updateAvailableFluids();
        this.buildFluidAirportsGrid();
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removeClientFluid(clientFluid) {
        this.item.ClientFluids.splice(this.item.ClientFluids.indexOf(clientFluid), 1);
        this.updateAvailableFluids();
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }
    
    updateAvailableFluids() {
        this.availableFluids = this.fluids.filter(f => !this.item.ClientFluids.some(cfv => cfv.FluidId === f.Id));
    }

    resetOrdering() {
        const unordered = this.item.ClientFluids.map(function (fluid) {
            return {...fluid, Order: 0};
        });
        this.item.ClientFluids = unordered;
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    addMissingOverrideFluids(fluidProfileId) {
        this.item.ClientFluids = this.item.ClientFluids || [];

        let maxOrder = Math.max.apply(null, this.item.ClientFluids
            .map(cf => cf.Order));

        FluidSortPipe.sort(this.availableFluids
            .filter(f => f.FluidProfileId === fluidProfileId))
            .forEach(fluid => {
                this.item.ClientFluids.push({
                    FluidId: fluid.Id,
                    Name: fluid.Name,
                    Order: ++maxOrder,
                    IsOverridable: true
                });
            });

        this.fluid = null;
        this.updateAvailableFluids();
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    setTemplatePreviews() {
        this.templates.$promise.then(() => {
            this.previews = [];
            this.templates.forEach(tpl => {
                this.previews[tpl.Id] = {Id: tpl.Id, Message: tpl.Message, Clicked: false};
            });
        });
    }

    buildAircraftGrid() {
        this.initPromise.then(() => {
            let columnDefs: IAgGridColGroupDef[] = [
                {
                    children: [
                        { colId: "Airport", field: "Airport", headerName: "Airport", width: 400, pinned: 'left', },
                    ]
                }
            ];

            this.aircraftColumnDefs = [
                {
                    headerName: "",
                    children: [
                        {
                            colId: `TailNumber`,
                            field: `TailNumber`,
                            headerName: "Identifier",
                            width: 90,
                            cellRenderer: params => this.cellRenderers.input('text', {
                                required: true,
                                value: params.value,
                                onchange: e => params.data.TailNumber = (<HTMLInputElement>e.target).value,
                            }),
                        },
                        { 
                            colId: `SecondaryIdentifier`,
                            field: `SecondaryIdentifier`,
                            headerName: "Secondary dentifier",
                            width: 90,
                            cellTemplate: `<input [(ngModel)]="params.data.SecondaryIdentifier" [name]="'aircraft[' + params.node.id + '].SecondaryIdentifier'" [disabled]="!params.data.TailNumber || params.data.TailNumber === '*'" style="width: 99%;" />`,
                        },
                        { 
                            colId: `CustomEquipmentTypeCode`,
                            field: `CustomEquipmentTypeCode`,
                            headerName: "Custom equipment type code",
                            width: 90,
                            cellTemplate: `<input [(ngModel)]="params.data.CustomEquipmentTypeCode" [name]="'aircraft[' + params.node.id + '].CustomEquipmentTypeCode'" [required]="params.data.TailNumber === '*' && !params.data.EquipmentTypeId" style="width: 99%;" />`,
                        },
                        { 
                            colId: `Active`,
                            field: `Active`,
                            headerName: "Active?",
                            width: 80,
                            cellRenderer: params => this.cellRenderers.input('checkbox', {
                                checked: params.value,
                                onclick: () => {
                                    params.data.Active = !params.data.Active;
                                    this.ngForm.form.updateValueAndValidity();
                                    this.ngForm.form.markAsDirty();
                                },
                            }),
                        },
                        {
                            colId: "EquipmentTypeId",
                            field: "EquipmentTypeId",
                            headerName: "Equipment type",
                            width: 150,
                            cellTemplate: `
                    <select (ngModelChange)="parent.getScreenSize(params.data)" [required]="params.data.TailNumber === '*' && !params.data.CustomEquipmentTypeCode" [(ngModel)]="params.data.EquipmentTypeId" [name]="'aircraft[' + params.node.id + '].EquipmentTypeId'">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let equipmentType of parent.equipmentTypes|orderBy:['IATACode', 'ICAOCode']" [ngValue]="equipmentType.Id">
                            {{(equipmentType.IATACode + '/' + (equipmentType.ICAOCode || ('N/A'|translate))) + ' ' + equipmentType.ManufacturerAndModelDescription}}
                        </option>
                    </select>`,
                        },
                        {
                            colId: "WingMaterialType",
                            field: "WingMaterialType",
                            headerName: "Wing surface type",
                            width: 100,
                            cellTemplate: `
                    <select required [(ngModel)]="params.data.WingMaterialType" [name]="'aircraft[' + params.node.id + '].WingMaterialType'">
                        <option *ngFor="let option of parent.wingMaterialTypes|keys" [ngValue]="option">
                            {{parent.wingMaterialTypes[option]}}
                        </option>
                    </select>`,
                        },
                        {
                            colId: `MinHotOnly`,
                            field: `MinHotOnly`,
                            headerName: "Min HOT only?",
                            width: 90,
                            cellRenderer: params => this.cellRenderers.input('checkbox', {
                                checked: params.value,
                                onclick: () => {
                                    params.data.MinHotOnly = !params.data.MinHotOnly;
                                    this.ngForm.form.updateValueAndValidity();
                                    this.ngForm.form.markAsDirty();
                                },
                            }),
                        },
                    ]
                },
                {
                    headerName: 'ACARS',
                    children: [
                        {
                            colId: `AcarsStandardMessageIdentifier`,
                            field: `AcarsStandardMessageIdentifier`,
                            headerName: "Standard message identifier",
                            width: 90,
                            cellRenderer: params => this.cellRenderers.input('text', {
                                required: true,
                                value: params.value,
                                onchange: e => params.data.TailNumber = (<HTMLInputElement>e.target).value,
                                placeholder: 'CMD',
                                maxLength: 3,
                            }),
                        },
                        {
                            colId: `AcarsNumberOfCharactersPerLine`,
                            field: `AcarsNumberOfCharactersPerLine`,
                            headerName: "Number of characters per line",
                            width: 90,
                            cellTemplate: `<input type="number" min="24" step="1" [(ngModel)]="params.data.AcarsNumberOfCharactersPerLine" [name]="'aircraft[' + params.node.id + '].AcarsNumberOfCharactersPerLine'" [required]="params.data.AcarsNumberOfLinePerPage != null" style="width: 99%;" />`,
                        },
                        {
                            colId: `AcarsNumberOfLinePerPage`,
                            field: `AcarsNumberOfLinePerPage`,
                            headerName: "Number of characters per line",
                            width: 90,
                            cellTemplate: `<input type="number" min="9" step="1" [(ngModel)]="params.data.AcarsNumberOfLinePerPage" [name]="'aircraft[' + params.node.id + '].AcarsNumberOfLinePerPage'" [required]="params.data.AcarsNumberOfCharactersPerLine != null" style="width: 99%;" />`,
                        },
                        {
                            colId: `AcarsHasTimestamp`,
                            field: `AcarsHasTimestamp`,
                            headerName: "Add Timestamp?",
                            width: 90,
                            cellRenderer: params => this.cellRenderers.input('checkbox', {
                                checked: params.value,
                                onclick: () => {
                                    params.data.AcarsHasTimestamp = !params.data.AcarsHasTimestamp;
                                    this.ngForm.form.updateValueAndValidity();
                                    this.ngForm.form.markAsDirty();
                                },
                            }),
                        },
                        {
                            colId: "AcarsTemplateId",
                            field: "AcarsTemplateId",
                            headerName: "Override ACARS template",
                            width: 150,
                            cellTemplate: `
                    <select [(ngModel)]="params.data.AcarsTemplateId" [name]="'aircraft[' + params.node.id + '].AcarsTemplateId'" style="width: 120px; display: inline-block;">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let option of parent.templates" [ngValue]="option.Id">
                            {{option.Description}}
                        </option>
                    </select>
                    <a *ngIf="params.data.AcarsTemplateId" (click)="parent.previewTemplate(params.data.AcarsTemplateId, false)" class="mdi mdi-eye" style="padding: 5px;"></a>`,
                        },
                    ]
                },
                {
                    headerName: "",
                    children: [
                        { 
                            colId: "_spacer",
                            flex: 1, 
                            excelIgnore: true,
                        },
                        {
                            colId: "Actions",
                            field: "Id",
                            headerName: "",
                            width: 80,
                            pinned: 'right',
                            excelIgnore: true,
                            cellTemplate: `<a *ngIf="parent.hasPermissionService.hasPermission('ConfigDeleteClientsAircraft')" (click)="parent.removeAircraft(params.data)" class="mdi mdi-delete" title="{{'Delete'|translate}}"></a>`,
                        },
                    ]
                }
            ];
        });
    }

    addAircraft() {
        this.item.Airplanes = this.item.Airplanes || [];
        this.item.Airplanes.unshift({
            Active: true,
            TailNumber: '',
            SecondaryIdentifier: '',
            CustomEquipmentTypeCode: '',
            AcarsStandardMessageIdentifier: '',
            AcarsNumberOfCharactersPerLine: 24,
            AcarsNumberOfLinePerPage: 9,
            AcarsTemplateId: null,
        });
        this.aircraftGrid.gridApi.applyTransaction({ addIndex: 0, add: this.item.Airplanes.slice(0, 1) })
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removeAircraft(item) {
        this.item.Airplanes.splice(this.item.Airplanes.indexOf(item), 1);
        
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
        
        this.aircraftGrid.updateFilteredRows();
    }

    addPortalSaml2AttributeMapping() {
        this.item.ClientPortalSaml2AttributeMappings = this.item.ClientPortalSaml2AttributeMappings || [];
        this.item.ClientPortalSaml2AttributeMappings.push({});
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removePortalSaml2AttributeMapping(item) {
        const index = this.item.ClientPortalSaml2AttributeMappings.indexOf(item);
        this.item.ClientPortalSaml2AttributeMappings.splice(index, 1);
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    addPortalTableauDashboard() {
        this.item.ClientPortalTableauDashboards = this.item.ClientPortalTableauDashboards || [];
        this.item.ClientPortalTableauDashboards.push({Order: 0});
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removePortalTableauDashboard(item) {
        const index = this.item.ClientPortalTableauDashboards.indexOf(item);
        this.item.ClientPortalTableauDashboards.splice(index, 1);
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    previewTemplate(templateId, isMobile) {
        this.api.AcarsTemplate.post({ Client : this.item, AcarsTemplateId : templateId, IsMobile: isMobile }).then(result => {
            this.dialogService.show(this.viewContainerRef, TemplatePreviewDialogComponent, {
                cols: 24,
                rows: 9,
                output: result.RawData,
            },
            {
                title: "ACARS Preview",
                width: 350,
                height: 650,
                modal: true,
            });
        });
    }

    addDomain() {
        this.item.ClientDomains = this.item.ClientDomains || [];
        this.item.ClientDomains.push({});
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removeDomain(item) {
        var index = this.item.ClientDomains.indexOf(item);
        this.item.ClientDomains.splice(index, 1);
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    addClientPortalLWEGridStation() {
        this.item.ClientPortalLWEGridStations = this.item.ClientPortalLWEGridStations || [];
        this.item.ClientPortalLWEGridStations.push({
            StationId: this.station.Id
        });
        this.station = null;
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removeClientPortalLWEGridStation(row) {
        this.item.ClientPortalLWEGridStations.splice(this.item.ClientPortalLWEGridStations.indexOf(row), 1);
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    addClientPortalLWEGridSensorSelectMatrix(clientPortalLWEGridStation, sensorSelectMatrix) {
        clientPortalLWEGridStation.ClientPortalLWEGridSensorSelectMatrices = clientPortalLWEGridStation.ClientPortalLWEGridSensorSelectMatrices || [];
        clientPortalLWEGridStation.ClientPortalLWEGridSensorSelectMatrices.push({
            SensorSelectMatrixId: sensorSelectMatrix.Id
        });
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removeClientPortalLWEGridSensorSelectMatrix(clientPortalLWEGridStation, item) {
        clientPortalLWEGridStation.ClientPortalLWEGridSensorSelectMatrices = clientPortalLWEGridStation.ClientPortalLWEGridSensorSelectMatrices || [];
        clientPortalLWEGridStation.ClientPortalLWEGridSensorSelectMatrices.splice(clientPortalLWEGridStation.ClientPortalLWEGridSensorSelectMatrices.indexOf(item), 1);
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    getScreenSize(aircraft) {
        let result = this.equipmentTypes.filter(val => {
            return val.Id === aircraft.EquipmentTypeId;
        });

        const screenSize = result.shift();

        if (typeof screenSize == "undefined" || screenSize == null)
            return aircraft;

        if (aircraft.AcarsNumberOfCharactersPerLine === null || (typeof aircraft.AcarsNumberOfCharactersPerLine === "undefined")) {
            aircraft.AcarsNumberOfCharactersPerLine = screenSize.AcarsNumberOfCharactersPerLine;
        }

        if (aircraft.AcarsNumberOfLinePerPage === null || (typeof aircraft.AcarsNumberOfLinePerPage === "undefined")) {
            aircraft.AcarsNumberOfLinePerPage = screenSize.AcarsNumberOfLinePerPage;
        }

        if (aircraft.WingMaterialType === null || (typeof aircraft.WingMaterialType === "undefined")) {
            aircraft.WingMaterialType = screenSize.WingMaterialType;
        }

        return aircraft;
    }

    exportMessageMappings() {
        const query = { id: this.item.Id };
        this.api.ClientMessageMappingExport
            .export(query).then(response => {
            this.fileDownload.download(response.body, header => response.headers.get(header))
        })
    }

    exportFluids() {
        var query = { id: this.item.Id };
        this.api.ClientFluidExport
            .export(query).then(response => {
            this.fileDownload.download(response.body, header => response.headers.get(header))
        })
    }

    exportFluidNodes() {
        const query = { id: this.item.Id };
        this.api.ClientFluidNoteExport
            .export(query).then(response => {
            this.fileDownload.download(response.body, header => response.headers.get(header));
        });
    }

    exportExceptions() {
        const query = { id: this.item.Id };
        this.api.ClientErrorExport
            .export(query).then(response => {
            this.fileDownload.download(response.body, header => response.headers.get(header));
        });
    }

    exportFleet() {
        const query = { id: this.item.Id };
        this.api.ClientFleetExport
            .export(query).then(response => {
            this.fileDownload.download(response.body, header => response.headers.get(header));
        });
    }

    downloadServiceProviderMetadata(signed) {
        const query = {
            ClientPortalCode: this.item.ClientPortalCode,
            PortalSaml2SignRequests: this.item.PortalSaml2SignRequests,
            PortalSaml2EnableSingleLogout: this.item.PortalSaml2EnableSingleLogout,
            Signed: signed
        };

        this.api.ClientSaml2ServiceProviderMetadata
            .exportPost(query).then(response => {
            this.fileDownload.download(response.body, header => response.headers.get(header));
        });
    }

    downloadIdentityProviderMetadata(signed) {
        const query = {
            ClientPortalCode: this.item.ClientPortalCode,
            PortalSaml2SignRequests: this.item.PortalSaml2SignRequests,
            PortalSaml2EnableSingleLogout: this.item.PortalSaml2EnableSingleLogout,
            Signed: signed
        };

        this.api.ClientSaml2IdentityProviderMetadata
            .exportPost(query).then(response => {
            this.fileDownload.download(response.body, header => response.headers.get(header));
        });
    }

    logoChanged() {
        var img = new Image();
        img.src = this.item.ClientPortalLogo;
        var maxWidth = 400;
        var maxHeight = 120;

        img.onload = () => {
            if (img.width > maxWidth || img.height > maxHeight) {
                this.notification.show(this.translateService.translate('Client portal logo size error (' + img.width + ' / ' + img.height + ') : Maximum allowed (in pixels) is ' + maxWidth + ' (width) and ' + maxHeight + ' (height)'), { type: 'error' });
            }
        };
    }

    removeLogo() {
        this.confirm.confirm(this.translate.translate('Are you sure?')).then(() => {
            this.item.ClientPortalLogo = null;
            this.ngForm.form.updateValueAndValidity();
            this.ngForm.form.markAsDirty();
        });
    }

    buildExceptionsGrids() {
        this.initPromise.then(() => {
            this.exceptionsColumnDefs = [
                {
                    colId: "Name",
                    field: "Name",
                    headerName: "Name",
                    width: 300,
                    pinned: 'left',
                },
                {
                    colId: "Message",
                    field: "Field",
                    headerName: "Message for ACARS, web portal and API responses",
                    width: 300,
                    cellTemplate: `<input type="text" [(ngModel)]="parent.item[params.data.Field].Message" [name]="'parent.item.' + params.data.Field" style="width: 99%;" pattern="^[0-9a-zA-Z•\\t\\- ':\.,\\\\/@\+]+$" />`, // to avoid conflict with uppercase, use of lowercase in pattern
                },
                {
                    colId: "ShortMobileMessage",
                    field: "Field",
                    headerName: "Short message for mobile app only",
                    width: 300,
                    cellTemplate: `<input type="text" [(ngModel)]="parent.item[params.data.Field].ShortMobileMessage" [name]="'parent.item.ShortMobile' + params.data.Field" style="width: 99%;" pattern="^[0-9a-zA-Z•\\t\\- ':\.,\\\\/@\+]+$" />`, // to avoid conflict with uppercase, use of lowercase in pattern
                },
                {
                    colId: "LongMobileMessage",
                    field: "Field",
                    headerName: "Long message for mobile app only",
                    width: 350,
                    cellTemplate: `<textarea style="width: 99%; height: 52px;" [(ngModel)]="parent.item[params.data.Field].LongMobileMessage" [name]="'parent.item.LongMobile' + params.data.Field"></textarea>`,
                },
                {
                    colId: "IsRestrictiveCondition",
                    field: "Field",
                    headerName: "Is restrictive condition?",
                    width: 200,
                    cellRenderer: params => this.cellRenderers.input('checkbox', {
                        checked: this.item['IsRestrictiveCondition' + params.data.Field],
                        onclick: () => {
                            this.item['IsRestrictiveCondition' + params.data.Field] = !this.item[params.data.Field].IsRestrictiveCondition;
                            this.ngForm.form.updateValueAndValidity();
                            this.ngForm.form.markAsDirty();
                        },
                    }),
                },
                {
                    colId: "HotConditionId",
                    field: "Field",
                    headerName: "HOT condition for dispatch portal",
                    width: 200,
                    cellTemplate: `
                        <select style="width: 99%;" [(ngModel)]="parent.item['HotConditionId' + params.data.Field]" [name]="'parent.item.HotConditionId' + params.data.Field">
                            <option [ngValue]="null">{{'None'|translate}}</option>
                            <option *ngFor="let option of parent.hotConditions" [ngValue]="option.Id">
                                {{option.Name}}
                            </option>
                        </select>`,
                },
            ];
        });
    }
}
