import { Component, OnInit } from "@angular/core";
import { Airport } from "../shared/model/Airport";
import { ApiService } from "../shared/Api.service";
import { MetarWeatherMappingProfile } from "../shared/model/MetarWeatherMappingProfile";
import { IEnumObject, StorageService, SwxModule } from "swx.front-end-lib";
import { MetarWeatherTypeSummary } from "../shared/model/MetarWeatherTypeSummary";
import { FormsModule } from "@angular/forms";
import { NgIf, NgFor, DecimalPipe } from "@angular/common";

interface Query {
    AirportId: number;
    MetarWeatherMappingProfileId: number;
    MetarWeatherType: string[];
    Month: number[];
    Hour: number[];
    FromDate: string;
    ToDate: string;
}

@Component({
    templateUrl: 'ReportMetarWeatherTypes.component.html',
    standalone: true,
    imports: [NgIf, SwxModule, FormsModule, NgFor, DecimalPipe]
})
export class ReportMetarWeatherTypesComponent implements OnInit {
    private readonly storeKey = 'reportMetarWeatherTypesQueryV2';
    query: Query = null;
    airports: Airport[];
    metarWeatherTypes: IEnumObject<string>;
    metarWeatherMappingProfiles: MetarWeatherMappingProfile[];
    months: IEnumObject<string>;
    hours = Array(24).fill(0).map((e, idx) => idx);
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    metarWeatherTypeSummary: MetarWeatherTypeSummary[];

    constructor(
        private api: ApiService,
        private storage: StorageService,
    ) {
    }

    ngOnInit() {
        Promise.all([
            this.api.Airport.query().$promise,
            this.api.MetarWeatherMappingProfile.query().$promise,
            this.api.MetarWeatherType.query().$promise,
            this.api.Month.query(),
        ]).then(([airports, metarWeatherMappingProfiles, metarWeatherTypes, months]) => {
            this.airports = airports;
            this.metarWeatherMappingProfiles = metarWeatherMappingProfiles;
            this.metarWeatherTypes = metarWeatherTypes;
            this.months = months;

            const storedQuery = this.storage.load(this.storeKey);

            this.query = storedQuery || {
                AirportId: null,
                MetarWeatherMappingProfileId: null,
                MetarWeatherType: [],
                Month: [],
                Hour: [],
                FromDate: null,
                ToDate: null,
            }
        });
    }

    selectDeicingConditions() {
        let deicingConditions = [];

        Object.entries(this.metarWeatherTypes).forEach(([metarWeatherType, metarWeatherTypeName]) => {
            if (metarWeatherTypeName === 'FF'
                    || metarWeatherTypeName === '-FD'
                    || metarWeatherTypeName === 'FD'
                    || metarWeatherTypeName === '-FR'
                    || metarWeatherTypeName === '-IP'
                    || metarWeatherTypeName === '1IP'
                    || metarWeatherTypeName === '2IP'
                    || metarWeatherTypeName === '3IP'
                    || metarWeatherTypeName === '5IP'
                    || metarWeatherTypeName === '9IP'
                    || metarWeatherTypeName === '1SN'
                    || metarWeatherTypeName === '-SN'
                    || metarWeatherTypeName === 'SN'
                    || metarWeatherTypeName === '+SN') {
                deicingConditions.push(metarWeatherType);
            }
        });

        this.query.MetarWeatherType = deicingConditions;
    }

    refresh() {
        this.storage.save(this.storeKey, this.query);
        this.api.MetarWeatherTypeSummary.query(this.query).$promise.then(metarWeatherTypeSummary => {
            this.metarWeatherTypeSummary = metarWeatherTypeSummary;
        });
    }

    seasonLabel(season: string):string {
        if (parseInt(season) > 0) {
            return season + '-' + (parseInt(season) + 1);
        } else {
            return season;
        }
    }

    toHours(seconds: number): number {
        return Math.round((seconds / 3600) * 100) / 100;
    }

    toPercent(seconds: number, totalSeconds: number): number {
       return Math.round((seconds / totalSeconds) * 1000) / 10 || 0;
    }
}
