import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation} from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { RootScope } from "../shared/RootScope.service";
import { DialogService, GoogleMapsLoaderService, GridComponent, ConfirmService, NotificationService, TranslatePipe, SwxModule, GridModule } from "swx.front-end-lib";
import { HasPermissionService, HasPermissionPipe } from "../shared/HasPermission.pipe";
import jQuery from 'jquery';
import moment from 'moment';
import { StationProblemRawDataDialogComponent } from "./StationProblemRawDataDialog.component";
import { StationStatusLegendComponent } from "./StationStatusLegend.component";
import { StationWeatherIconComponent } from "./StationWeatherIcon.component";
import { NgIf, NgFor, DecimalPipe } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './StationStatus.component.html',
    styleUrls: ['./StationStatus.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgIf,
        NgFor,
        GridModule,
        StationWeatherIconComponent,
        StationStatusLegendComponent,
        DecimalPipe,
        HasPermissionPipe,
    ],
})
export class StationStatusComponent implements OnInit, OnDestroy {
    refreshInterval: any;
    tab: string = 'tiles';
    options: any = {};
    @ViewChild('stationMap', { static: true }) stationMap: ElementRef<HTMLElement>;
    @ViewChild('leftPane', { static: true }) leftPane: ElementRef<HTMLElement>;
    @ViewChild('border', { static: true }) border: ElementRef<HTMLElement>;
    @ViewChild('rightPane', { static: true }) rightPane: ElementRef<HTMLElement>;
    @ViewChild('stationSearchElement', { static: true }) stationSearchElement: ElementRef<HTMLInputElement>;
    dispatchWidth = 500;
    selectedStationInfo = null;
    stationSearch: string;
    sureLoggerConfigurationQuery: any = {};
    serialCommands = [];
    serialCommandQuery: any = {
        doUnescape: true,
        timeoutMs: 3000,
    };
    mapLoaded: Promise<any> = null;
    markers = [];
    hotRegex = new RegExp(`^([0-9]+)-?([0-9]+)? MIN\n?(.+)?`);
    clients = this.api.Client.query();
    fluids = this.api.Fluid.query();
    sensorSelectMatrices = this.api.SensorSelectMatrix.query();
    airports = this.api.Airport.query();
    stations = this.api.Station.query();
    stationProblemTypes = this.api.StationProblemType.query();
    weatherProfileErrors = this.api.WeatherProfileError.query();
    stationInfos: any;
    stationProblemLevels = {
        Notice: 0,
        Warning: 1,
        Critical: 2,
    };
    stationProblemUpdates = new Map<number, { Id: number, Notes: string, Cleared: boolean }>();
    sureLoggerLog: any = null;
    sureLoggerStatus: any = null;
    logOpen = false;
    sensorsOpen = false;
    sureLoggerConfigurationOpen = false;
    serialPortsOpen = false;
    powerRelayModuleOpen = false;
    @ViewChild('grid', { static: true }) grid: GridComponent;
    datePickerOptions: any;
    query: any = {};
    columnDefs = [
        { colId: "StationProblem.DateTimeDate", field: "StationProblem.DateTime", headerName: "Start date", width: 90, valueFormatter: c => c.value ? moment.utc(c.value).format(this.$root.dateFormat) : "", pinned: "left" },
        { colId: "StationProblem.DateTimeTime", field: "StationProblem.DateTime", headerName: "Start time", width: 70, valueFormatter: c => c.value ? moment.utc(c.value).format(this.$root.timeFormat) : "", excelIgnore: true, pinned: "left" },
        { colId: "StationProblem.DateTimeDate", field: "StationProblem.LastUpdated", headerName: "Last date", width: 90, valueFormatter: c => c.value ? moment.utc(c.value).format(this.$root.dateFormat) : "", pinned: "left" },
        { colId: "StationProblem.DateTimeTime", field: "StationProblem.LastUpdated", headerName: "Last time", width: 70, valueFormatter: c => c.value ? moment.utc(c.value).format(this.$root.timeFormat) : "", excelIgnore: true, pinned: "left" },
        { colId: "StationProblem.Id", field: "StationProblem.Id", headerName: "#", width: 70, filterType: "integer", pinned: 'left' },
        { colId: "StationName", field: "StationName", headerName: "Station", width: 80, pinned: "left" },
        { colId: "StationProblem.Type", field: "StationProblem.Type", headerName: "Problem", width: 400, pinned: "left", cellRenderer: c => {
                var container = document.createElement('div');
                if (c.value) {
                    if (c.data.StationProblem.Level === 'Warning') {
                        container.style.color = '#ffa500';
                        container.style.fontWeight = 'bold';
                    } else if (c.data.StationProblem.Level === 'Critical') {
                        container.style.color = '#ff0000';
                        container.style.fontWeight = 'bold';
                    }

                    container.innerText = this.stationProblemTypes[c.value];
                }
                return container;
            }, filterType: "enum", source: "StationProblemType"
        },
        { colId: "StationProblem.Cleared", field: "StationProblem.Cleared", headerName: "Cleared?", width: 70, valueFormatter: c => c.value == null ? '' : c.value ? 'Yes' : 'No', filterType: 'boolean' },
        { colId: "StationProblem.MinimumCountReached", field: "StationProblem.MinimumCountReached", headerName: "Minimum count/rate reached?", width: 110, valueFormatter: c => c.value == null ? '' : c.value ? 'Yes' : 'No' },
        { colId: "StationProblem.Count", field: "StationProblem.Count", headerName: "Count", width: 60 },
        { colId: "StationProblem.EventsPerHour", field: "StationProblem.EventsPerHour", headerName: "Events per hour", width: 60 },
        { colId: "StationProblem.RawData", field: "StationProblem.RawData", headerName: "Raw", width: 40, cellRenderer: c => {
                var container = document.createElement('div');
                if (c.data?.StationProblem.RawData) {
                    var viewButton = document.createElement('a');
                    viewButton.innerHTML = '<span class="mdi mdi-eye"></span>';
                    viewButton.addEventListener('click', () => {
                        this.showStationProblemRawData(c.data.StationProblem.RawData);
                    });
                    container.appendChild(viewButton)
                }
                return container;
            }},
        { colId: "StationProblem.Details", field: "StationProblem.Details", headerName: "Details", width: 350 },
        { colId: "StationProblem.Notes", field: "StationProblem.Notes", headerName: "Notes", width: 350 },
    ];
    
    constructor(
        public $root: RootScope,
        private viewContainerRef: ViewContainerRef,
        public api: ApiService,
        private googleMapsLoaderService: GoogleMapsLoaderService,
        private hasPermission: HasPermissionService,
        private elementRef: ElementRef,
        private dialogService: DialogService,
        private confirmService: ConfirmService,
        private translate: TranslatePipe,
        private notification: NotificationService,
    ) {
    }

    ngOnInit(): void {
        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        };
        
        if (window.localStorage['statusPage']) {
            this.options = JSON.parse(window.localStorage['statusPage']);
        } else {
            this.resetOptions();
        }

        this.resetQuery();

        this.tab = location.hash ? location.hash.substring(1) : 'tiles';
        this.switchTab(this.tab);

        this.dispatchWidth = Math.min(window.innerWidth - 4, parseInt(localStorage.getItem('dispatchWidth') ?? '400'));

        if (this.dispatchWidth) {
            this.applyWidth(this.dispatchWidth);
            this.border.nativeElement.style.left = this.dispatchWidth + 'px';
        }

        jQuery(this.border.nativeElement).draggable({
            axis: 'x',
            drag: (e, ui) => {
                ui.position.left = Math.min(window.innerWidth - 4, ui.position.left);
                this.applyWidth(ui.position.left);
                jQuery(window).trigger('resize');
            }
        });

        var resizeTimer;
        jQuery(window).resize(() => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                if (this.dispatchWidth && this.dispatchWidth > window.innerWidth) {
                    this.dispatchWidth = window.innerWidth - 20;
                    this.border.nativeElement.style.left = this.dispatchWidth + 'px';
                    this.applyWidth(this.dispatchWidth);
                }
            }, 250);
        });

        this.stations.$promise.then(() => {
            var wasOpen = false;
            var searchInput = jQuery(this.stationSearchElement.nativeElement);

            searchInput.autocomplete({
                delay: 0,
                minLength: 0,
                source: (request, responseCallback) => responseCallback(this.stations
                    .map(s => ({
                        id: s.Id,
                        label: s.Name,
                    }))
                    .filter(o => {
                        return !request.term || o.label.toLowerCase().indexOf(request.term.toLowerCase()) !== -1;
                    })),
                classes: {
                    'ui-autocomplete': 'autocomplete-component'
                },
                select: (e, ui) => {
                    var stationInfo = this.stationInfos.find(stationInfo => stationInfo.station.Id === ui.item.id);
                    if (stationInfo != null) {
                        this.selectStationInfo(stationInfo);
                    }

                    e.preventDefault();
                    this.stationSearch = null;
                    setTimeout(() => {
                        searchInput.val('');
                    });
                },
                close: () => {
                    setTimeout(() => {
                        this.stationSearch = null;
                        searchInput.val('');
                    }, 1500);
                }
            });

            searchInput
                .on('mousedown', () => wasOpen = searchInput.autocomplete('widget').is(':visible'))
                .on('click', () => {
                    searchInput.trigger('focus');
                    if (wasOpen) return;
                    searchInput.autocomplete('search', '');
                });
        });

        this.setRefreshInterval();
    }

    applyWidth(left) {
        this.leftPane.nativeElement.style.width = this.selectedStationInfo == null ? '100%' : (left + 'px');
        this.rightPane.nativeElement.style.left = (left + 4) + 'px';
        localStorage.setItem('dispatchWidth', left);
        this.dispatchWidth = left;
    }
    
    resetOptions() {
        this.options = {
            RefreshInterval: 60,
        };
    }
    
    resetQuery() {
        var fromDate = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds());
        fromDate.setDate(fromDate.getDate() - 1);
        
        this.query = {
            FromDate: fromDate.toISOString(),
            ToDate: null,
            MinimumCountReached: true,
            Filters: []
        };
    }

    switchTab(tab: string) {
        location.hash = tab;
        this.tab = tab;
        if (this.tab === 'tiles' || this.tab === 'map') {
            this.refresh();
        }
    }

    updateRefreshInterval() {
        clearInterval(this.refreshInterval);
        this.setRefreshInterval();
        this.refresh();
    }
    
    setRefreshInterval() {
        this.refreshInterval = setInterval(() => {
            if (this.tab !== 'alarms') {
                this.refresh();
            }
        }, (this.options.RefreshInterval) * 1000);
    }

    refresh() {
        window.localStorage['statusPage'] = JSON.stringify(this.options);

        if (this.tab === 'map') {
            if (this.mapLoaded == null) {
                this.mapLoaded = new Promise((resolve, _) => {
                    this.googleMapsLoaderService.load().then(maps => {
                        var mapElement = this.stationMap.nativeElement;

                        resolve(new maps.Map(mapElement,
                            {
                                zoom: 3,
                                center: new maps.LatLng(45.5017, -73.5673),
                                mapTypeId: maps.MapTypeId.TERRAIN
                            }));
                    });
                });
            }
        }
        
        if (this.tab === 'alarms') {
            this.grid.gridReadyPromise.then(() => {
                this.grid.refresh();
            });
        } else {
            Promise.all([this.clients.$promise, this.stations.$promise, this.airports.$promise, this.fluids.$promise]).then(() => {
                if (!this.options.ClientId) {
                    this.options.ClientId = this.clients[0].Id;
                }

                var stationStates = this.api.StationStatus.query({
                    ClientId: this.options.ClientId
                });
                
                var stations = this.api.Station.query();
                var sensorSelectMatrices = this.api.SensorSelectMatrix.query()

                Promise.all([stationStates.$promise, stations.$promise, sensorSelectMatrices.$promise]).then(() => {
                    this.stations = stations;
                    this.sensorSelectMatrices = sensorSelectMatrices;

                    var stationInfos = this.stations
                        .map(station => {
                            var airport = this.airports.filter(r => r.Id === station.AirportId)[0];
                            var stationState = stationStates?.find(candidate => candidate.Id === station.Id) || {};
                            if (!airport || !station.ShowOnStationStatus) return null;

                            var icon;
                            var cssClass
                            var rawData;

                            var hasLwe = stationState.WeatherProfile != null && stationState.WeatherProfile.WeatherCategory != null && stationState.WeatherProfile.WeatherCategory !== 'UP';

                            var currentProblems = this.getCurrentProblems(stationState);
                            var hasProblem = currentProblems
                                ?.some(sp => sp.Type !== 'ConnectionFailed'
                                    && sp.Type !== 'NoMessagesReceived'
                                    && sp.Level !== 'Notice');

                            if (station.UnderMaintenance) {
                                // under investigation
                                icon = "/images/marker_33FFFF.png";
                                cssClass = 'underMaintenance';
                            } else if (!station.HasConnections) {
                                // no connections configured
                                icon = "/images/marker_888888.png";
                                cssClass = 'noConnections';
                            } else if (hasProblem && hasLwe) {
                                // alarm
                                icon = "/images/marker_FFFF00.png";
                                cssClass = 'hasLwe';
                            } else if (!hasLwe) {
                                icon = "/images/marker_FF8000.png";
                                cssClass = 'hasProblem';
                            } else if (stationState.Status === 'Connected' && stationState.LastStationReadingRawData != null) {
                                // OK
                                icon = "/images/marker_00FF00.png";
                                cssClass = 'ok';
                            } else {
                                // connection problem
                                icon = "/images/marker_FF0000.png";
                                cssClass = 'connectionProblem';
                            }

                            if (stationState.HotResponse != null) {
                                stationState.HotResponse.HotResponseHots.forEach(hrh => {
                                    var hotDetails = this.parseHot(hrh.Message);

                                    var fluid = this.fluids.find(f => f.Id === hrh.FluidId);
                                    hrh.FluidTypeNumber = parseInt(fluid.Type.substring(4));
                                    hrh.MinHot = hotDetails.MinHot;
                                    hrh.MaxHot = hotDetails.MaxHot;
                                    hrh.NonHotMessage = hotDetails.Message;
                                    hrh.IsGeneric = fluid.Name.toUpperCase().indexOf("GENERIC") !== -1;
                                    hrh.IsComposite = fluid.WingMaterialType === 'Composite';
                                    hrh.SortableDilution = hrh.FluidType === 'Type1' || !hrh.FluidDilution || hrh.FluidDilution.indexOf("/") === -1
                                        ? 0
                                        : parseInt(hrh.FluidDilution.substr(0, hrh.FluidDilution.indexOf("/")));
                                });

                                var maxHot = Math.max.apply(null, stationState.HotResponse.HotResponseHots
                                    .map(h => h.MaxHot > h.MinHot ? h.MaxHot : h.MinHot));

                                stationState.HotResponse.maxHOTConfig = maxHot === 0 ? 120 : maxHot;

                                stationState.IsClear = (stationState.HotResponse.Type !== 'LWE'
                                        && (stationState.WeatherTypeNameResult === 'CLR'
                                            || stationState.WeatherTypeNameResult === 'OBS'
                                            || stationState.WeatherTypeNameResult === 'OTHN'))
                                    || stationState.HotResponse.WeatherType === 'CLR';

                                stationState.Type1Hot = stationState.HotResponse.HotResponseHots
                                    .filter(hrh => hrh.FluidType === 'Type1')
                                    .sort(this.unaryCompare(hrh => hrh.IsGeneric
                                        ? (hrh.IsComposite ? 3 : 2)
                                        : (hrh.IsComposite ? 1 : 0)))[0];

                                stationState.Type2Hot = stationState.HotResponse.HotResponseHots
                                    .filter(hrh => hrh.FluidType === 'Type2')
                                    .sort(this.unaryCompare(hrh => hrh.IsGeneric
                                        ? (hrh.FluidDilution === "100/0" ? 2 : 3)
                                        : (hrh.FluidDilution === "100/0" ? 0 : 1)))[0];

                                stationState.Type3Hot = stationState.HotResponse.HotResponseHots
                                    .filter(hrh => hrh.FluidType === 'Type3')
                                    .sort(this.unaryCompare(hrh => hrh.IsGeneric
                                        ? (hrh.FluidDilution === "100/0" ? 2 : 3)
                                        : (hrh.FluidDilution === "100/0" ? 0 : 1)))[0];

                                stationState.Type4Hot = stationState.HotResponse.HotResponseHots
                                    .filter(hrh => hrh.FluidType === 'Type4')
                                    .sort(this.unaryCompare(hrh => hrh.IsGeneric
                                        ? (hrh.FluidDilution === "100/0" ? 2 : 3)
                                        : (hrh.FluidDilution === "100/0" ? 0 : 1)))[0];
                            }

                            rawData = stationState
                                && stationState.LastStationReadingRawData
                                    ? (stationState.LastStationReadingRawData[0] == '{' ? JSON.stringify(JSON.parse(stationState.LastStationReadingRawData), null, 4) : stationState.LastStationReadingRawData)
                                    : null;

                            return {
                                station: station,
                                airport: airport,
                                stationState: stationState,
                                icon: icon,
                                cssClass: cssClass,
                                rawData: rawData,
                                currentProblems: currentProblems,
                            };
                        })
                        .filter(stationInfo => stationInfo != null);

                    if (this.tab === 'map') {
                        this.googleMapsLoaderService.load().then(maps => {
                            this.mapLoaded.then(map => {
                                var stationsAtAirport = {};

                                this.markers.forEach(marker => marker.setMap(null));
                                this.markers.length = 0;

                                stationInfos.forEach(stationInfo => {
                                    var loc = new maps.LatLng(stationInfo.airport.Latitude, stationInfo.airport.Longitude);

                                    if (stationsAtAirport[stationInfo.airport.Id]) {
                                        stationsAtAirport[stationInfo.airport.Id] += 1;
                                    } else {
                                        stationsAtAirport[stationInfo.airport.Id] = 1;
                                    }

                                    var offset = (stationsAtAirport[stationInfo.airport.Id] - 1) * 20;

                                    var marker = new maps.Marker({
                                        map: map,
                                        position: loc,
                                        label: stationInfo.station.Name + (stationInfo.station.MessageSent ? ' ✉' : ''),
                                        icon: new maps.MarkerImage(stationInfo.icon, new maps.Size(21, 34), new maps.Point(0, 0), new maps.Point(10 + offset, 34 + offset))
                                    });

                                    this.markers.push(marker);

                                    maps.event.addListener(marker, 'click', () => {
                                        this.selectStationInfo(stationInfo);
                                    });
                                });
                            });
                        });
                    }

                    this.stationInfos = stationInfos;

                    if (this.selectedStationInfo != null) {
                        var selectedStationId = this.selectedStationInfo.station.Id;
                        this.selectedStationInfo = null;
                        this.selectStationInfo(stationInfos.find(stationInfo => stationInfo.station.Id === selectedStationId));
                    }
                });
            });
        }
    }

    unaryCompare = (f) => (v1, v2) => f(v1) < f(v2) ? -1 : f(v1) > f(v2) ? 1 : 0;
    
    isActive = item => item.UnderInvestigation || item.FaultOccurred;
    
    formatRawString = value => value ? escape(value).replace(/%/g, '\\x') : '';

    isEmpty = value => value === null || typeof value === 'undefined' || value === '';

    getCurrentProblems(stationState) {
        let since = moment().subtract(1, 'minute');
        return stationState.Problems
                ?.filter(sp => !sp.Cleared
                    && sp.MinimumCountReached
                    && moment(sp.LastUpdated) > since)
            .sort((sp1, sp2) => this.stationProblemLevels[sp1.Level] > this.stationProblemLevels[sp2.Level] ? -1 : 1);
    }
    
    getStationTileProblem(stationInfo) {
        return stationInfo.currentProblems
            ?.filter(sp => sp.Type !== 'NoMessagesReceived' && sp.Type !== 'WeatherProfileError')
            [0];
    }
    
    getHotStyle(hot) {
        var targetLeftPercent = 50; // hack
        var targetRightPercent = 80; // to review
        var max = Math.max.apply(null,[hot.MinHot, hot.MaxHot]);
        var useStyle = Math.floor((max * 100) / this.selectedStationInfo.stationState.HotResponse.maxHOTConfig) >= targetLeftPercent;

        var style: any = { min: {}, max: {}, separator: " - " };
        var leftWidth = Math.floor((hot.MinHot * 100) / this.selectedStationInfo.stationState.HotResponse.maxHOTConfig);
        var rightWidth = (Math.floor((hot.MaxHot * 100) / this.selectedStationInfo.stationState.HotResponse.maxHOTConfig)) - leftWidth;

        if (useStyle) {
            style.separator = null;

            style.min = {
                'display': 'inline-block',
                'width': (leftWidth - (leftWidth > targetRightPercent && hot.MaxHot ? leftWidth - targetRightPercent : 0)),
                'text-align': 'right',
                'float':'left'
            };
            style.max = {
                'display': 'inline-block',
                'width': (rightWidth + (leftWidth > targetRightPercent && hot.MaxHot ? leftWidth - targetRightPercent : 0)),
                'text-align': 'right',
                'float':'left'
            };
        }

        return style;
    }

    parseHot = (hot) => {
        var hotMatch = hot === null || hot === '' ? null : this.hotRegex.exec(hot);
        var minHot = hotMatch !== null ? parseInt(hotMatch[1]) : null;
        var maxHot = hotMatch !== null && typeof hotMatch[2] !== 'undefined' ? parseInt(hotMatch[2]) : null;
        return {
            MinHot: minHot,
            MaxHot: maxHot,
            Message: hotMatch === null ? hot : hotMatch[3]
        };
    };

    selectStationInfo(stationInfo) {
        this.sureLoggerStatus = null;
        this.sureLoggerConfigurationQuery.configuration = null;
        this.serialCommands = [];
        this.serialCommandQuery.command = '';

        if (stationInfo.station.Id === this.selectedStationInfo?.station.Id) {
            this.selectedStationInfo = null;
        } else {
            this.selectedStationInfo = stationInfo;

            this.refreshSureLoggerStatus();
            this.refreshSureLoggerLog();
            this.refreshSureLoggerConfiguration();
            
            this.query.StationId = [
                stationInfo.station.Id
            ];
        }

        this.applyWidth(this.dispatchWidth);
    };

    sensorsOpenChanged(open) {
        this.sensorsOpen = open;
        this.refreshSureLoggerStatus();
    }

    serialPortsOpenChanged(open) {
        this.serialPortsOpen = open;
        this.refreshSureLoggerStatus();
    }

    refreshSureLoggerStatus() {
        if ((this.sensorsOpen || this.serialPortsOpen) && this.selectedStationInfo?.station.StationLoggerType === 'SerialOverTCP') {
            this.sureLoggerStatus = this.api.StationSureLoggerStatus.get({id: this.selectedStationInfo.station.Id}, { ignoreErrors: true });
        }
    }

    logOpenChanged(open) {
        this.logOpen = open;
        this.refreshSureLoggerLog();
    }

    refreshSureLoggerLog() {
        if (this.logOpen && this.selectedStationInfo?.station.StationLoggerType === 'SerialOverTCP') {
            this.api.StationSureLoggerLog.query({ id: this.selectedStationInfo.station.Id }, { ignoreErrors: true }).$promise.then(result => {
                this.sureLoggerLog = result.reverse().join("\n");
            });
        }
    }

    updateSureLoggerSerialMaintenanceMode(serialMaintenanceMode) {
        return this.confirmService.confirm(this.translate.transform('Are you sure you want to ' + (serialMaintenanceMode ? 'enable' : 'disable') + ' serial maintenance mode?')).then(() => {
            return this.api.StationSureLoggerSerialMaintenanceMode.put({ id: this.selectedStationInfo.station.Id }, { SerialMaintenanceMode: serialMaintenanceMode }).then(result => {
                this.notification.show(this.translate.transform('Serial maintenance mode has been ' + (serialMaintenanceMode ? 'enabled' : 'disabled') + '.'));
                this.refresh();
            });
        });
    }

    refreshSureLoggerConfiguration() {
        if ((this.sureLoggerConfigurationOpen || this.powerRelayModuleOpen) && this.selectedStationInfo?.station.StationLoggerType === 'SureLogger2') {
            this.api.StationSureLoggerConfiguration.get(this.selectedStationInfo.station.Id).$promise.then(result => {
                this.sureLoggerConfigurationQuery.configuration = JSON.stringify(result.Configuration, null, 4);
            });
        }
    }

    sureLoggerConfigurationOpenChanged(open) {
        this.sureLoggerConfigurationOpen = open;
        this.refreshSureLoggerConfiguration();
    }

    powerRelayModuleOpenChanged(open) {
        this.powerRelayModuleOpen = open;
        this.refreshSureLoggerConfiguration();
    }
    
    updateSureLoggerConfiguration() {
	    var configurationJson;
	    try {
		    configurationJson = JSON.parse(this.sureLoggerConfigurationQuery.configuration);
	    } catch(e) {
		    alert("Could not parse configuration as JSON:" + e);
		    return false;
	    }

        return this.confirmService.confirm(this.translate.transform('Are you sure you want to enable serial maintenance mode?')).then(() => {
	        return this.api.StationSureLoggerConfiguration.put({ id: this.selectedStationInfo.station.Id }, configurationJson).then(result => {
			    this.notification.show(this.translate.transform('The configuration has been updated.'));
		    });
        });
    }

    setSerialBusEnabled(id, enabled) {
        return this.api.StationSureLoggerSerialPortState.put({ id: this.selectedStationInfo.station.Id }, { SerialPortId: id, SerialBusEnabled: enabled }).then(result => {
            this.sureLoggerStatus = result;
            return result;
        });
    };

    setSensorPowerState(id, state) {
        return this.api.StationSureLoggerSensorPowerState.put({ id: this.selectedStationInfo.station.Id }, { Sensor: id, PowerState: state }).then(result => {
            this.sureLoggerStatus = result;
            return result;
        });
    };

    sendSerialCommand() {
        var commandInfo = {
            Id: this.serialCommandQuery.serialPortId,
            PortName: this.sureLoggerStatus.SerialPorts.find(sp => sp.Id === this.serialCommandQuery.serialPortId).PortName,
            DateTime: new Date(),
            Command: this.serialCommandQuery.doUnescape ? unescape(this.serialCommandQuery.command.replace(/\\0x/g, '%').replace(/\\x/g, '%')) : this.serialCommandQuery.command,
            RawCommand: this.serialCommandQuery.command,
            TimeoutMs: this.serialCommandQuery.timeoutMs,
            Error: false,
            Output: '',
        };
        
        this.serialCommands.push(commandInfo);

        this.api.StationSureLoggerSerialCommand.put({ id: this.selectedStationInfo.station.Id }, commandInfo)
            .then(response => {
                commandInfo.Error = response.Error;
                commandInfo.Output = response.Output;
            });
    };

    showStationProblemRawData(rawData) {
        this.dialogService.show(
            this.viewContainerRef,
            StationProblemRawDataDialogComponent,
            {
                rawData: rawData,
            },
            {
                title: 'Raw data',
                width: 640,
                height: 720,
                modal: true,
            });
    }
    
    getStationProblemUpdate(stationProblem) {
        if (!this.stationProblemUpdates.has(stationProblem.Id)) {
            this.stationProblemUpdates.set(stationProblem.Id, {
                Id: stationProblem.Id,
                Notes: stationProblem.Notes,
                Cleared: false,
            });
        }
        return this.stationProblemUpdates.get(stationProblem.Id);
    }
    
    updateStationProblems() {
        this.api.StationProblem.patch(null, Array.from(this.stationProblemUpdates.values())
            .filter(u => this.selectedStationInfo.stationState.Problems.some(p => p.Id == u.Id)))
            .then(result => {
                this.selectedStationInfo.stationState.Problems = result;
            });
    }

    toggleAllCleared(cleared) {
        var stationProblems = this.getCurrentProblems(this.selectedStationInfo.stationState)
        
        stationProblems.forEach(sp => this.getStationProblemUpdate(sp).Cleared = cleared);
    }

    ngOnDestroy(): void {
        clearInterval(this.refreshInterval);
    }
}
