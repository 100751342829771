import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService, IAgGridColGroupDef, SwxModule, GridModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import {ConfigGridComponent, NotificationService, TranslateService, FileDownloadService} from "swx.front-end-lib";
import {MetarWeatherTypeService} from "../hotSimulator/MetarWeatherType.service";
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'MetarWeatherCodeMappingProfileDetail.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, GridModule, HasPermissionPipe]
})
export class MetarWeatherCodeMappingProfileDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', {static: true}) ngForm;
    @ViewChild('mappingsGrid', {static: true}) mappingsGrid: ConfigGridComponent;
    tab;
    metarWeatherTypes = this.api.MetarWeatherType.query();
    duplicateWeatherCodeMappings = new Map();
    areWeatherCodeMappingsValid = true;
    columnDefs: IAgGridColGroupDef[] = [{
        children: [
            { colId: "Id", field: "Id", headerName: "#", width: 60, pinned: "left", filterType: 'integer', cellClass: "select", cellTemplate: "<div [class.invalid]=\"!params.data._valid\">{{params.data.Id || 'new'}}</div>" },
            { colId: "WeatherCode", field: "WeatherCode", headerName: "Weather code", width: 100, cellClass: "select", cellTemplate: `<input  type="text" required [(ngModel)]="params.data.WeatherCode" [name]="'params.data[' + params.node.id + '].WeatherCode'" style="width: 99%;" (change)='parent.checkDuplicateWeatherCodeMappings()' />` },
            { colId: "MaxTemp", field: "MaxTemp", headerName: "Temp <= (°C)", width: 85, cellTemplate: `<input required type="number" step="any" [(ngModel)]="params.data.MaxTemp" [name]="'params.data[' + params.node.id + '].MaxTemp'" style="width: 70px;" (change)='parent.checkDuplicateWeatherCodeMappings()' />` },
            { colId: "MetarWeatherType", field: "MetarWeatherType", headerName: "Weather type", width: 90, cellTemplate: `
                <select style="width: 190px;" [(ngModel)]="params.data.MetarWeatherType" [name]="'params.data[' + params.node.id + '].MetarWeatherType'" required>
                    <option *ngFor="let option of parent.metarWeatherTypes|keys" [ngValue]="option">
                        {{parent.metarWeatherTypes[option]}}
                    </option>
                </select>` },
            { colId: "Actions", field: "Id", headerName: "", width: 80, pinned: 'right', excelIgnore: true, cellClass: "actions", cellTemplate: `<a *ngIf="!params.data.IsUsed && parent.hasPermissionService.hasPermission('ConfigEditMETARWeatherCodeMappingProfiles')" (click)="parent.removeMetarMessageMapping(params.data)" class="mdi mdi-delete" title="{{'Remove'|translate}}"></a>` },
        ],
    }];

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        private fileDownload: FileDownloadService,
        private notification: NotificationService,
        private translateService: TranslateService,
        private metarWeatherTypeService: MetarWeatherTypeService,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.MetarWeatherCodeMappingProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                this.importItem(this.item);
            });
        } else if (isNew) {
            this.item = this.api.MetarWeatherCodeMappingProfile.create({
                Active: true,
            });
        } else {
            this.item = this.api.MetarWeatherCodeMappingProfile.get({ id: id });
        }

        if (this.item.$promise) {
            this.item.$promise.then(_ => {
                this.metarWeatherTypes.$promise.then(() => {
                    this.checkDuplicateWeatherCodeMappings();
                    this.item.MetarWeatherCodeMappings = metarWeatherTypeService.sort(this.item.MetarWeatherCodeMappings, 'WeatherCode');
                });
            });
        }
    }

    save() {
        this.item.$promise.then(_ => {
            this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
        });
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['MetarWeatherCodeMappingProfile'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    addCodeMapping() {
        this.item.MetarWeatherCodeMappings = this.item.MetarWeatherCodeMappings || [];
        this.item.MetarWeatherCodeMappings.unshift({
            WeatherCode: "WxCode" + (this.item.MetarWeatherCodeMappings.length + 1),
            MaxTemp: 999,
        });
        this.mappingsGrid.gridApi.applyTransaction({ addIndex: 0, add: this.mappingsGrid.rowData.slice(0, 1) })
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    };

    removeMetarMessageMapping(mapping) {
        if (confirm(this.translateService.translate('Are you sure?'))) {
            this.item.MetarWeatherCodeMappings.splice(this.item.MetarWeatherCodeMappings.indexOf(mapping), 1);
            this.mappingsGrid.updateFilteredRows();
            this.ngForm.form.updateValueAndValidity();
            this.ngForm.form.markAsDirty();
            this.checkDuplicateWeatherCodeMappings();
        }
    };

    exportProfile() {
        var query = {
            Id: this.item.Id,
            MetarCodeMappingColumnDefs: this.columnDefs,
        };

        this.api.MetarWeatherCodeMappingProfileExport
            .exportPost(query)
            .then(response => {
                this.fileDownload.download(response.body, (header) => response.headers.get(header));
            });
    };

    exportJson() {
        var json = JSON.stringify(this.item, null, 4);
        var blob = new Blob([json], { type: 'application/json' });
        this.fileDownload.download(blob, 'item.json');
    };

    importJson() {
        var fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.style.width = '0';
        fileInput.style.height = '0';
        fileInput.style.position = 'absolute';
        document.body.appendChild(fileInput)

        fileInput.addEventListener('change', () => {
            var file = fileInput.files[0];
            var reader = new FileReader();
            reader.onload = e => {
                var json = e.target.result;
                var item = JSON.parse(<string>json);
                this.importItem(item);
                document.body.removeChild(fileInput);
            };
            reader.readAsText(file);
        });

        fileInput.click();
    };

    importItem(item) {
        Object.assign(this.item, item);

        delete this.item.Id;

        if (this.item.MetarWeatherCodeMappings) {
            this.item.MetarWeatherCodeMappings.forEach(related => {
                delete related.MetarWeatherCodeMappingProfileId;
            });
        }
    }

    checkDuplicateWeatherCodeMappings() {
        this.item.MetarWeatherCodeMappings = this.item.MetarWeatherCodeMappings || [];
        this.item.MetarWeatherCodeMappings.forEach(row => {
            var duplicateWeatherCodeMapping = this.item.MetarWeatherCodeMappings.find(weatherCodeMapping => {
                return row !== weatherCodeMapping
                    && (row.WeatherCode && row.WeatherCode.toLowerCase() + " " + row.MaxTemp === weatherCodeMapping.WeatherCode.toLowerCase() + " " + weatherCodeMapping.MaxTemp);
            });

            var valid = typeof duplicateWeatherCodeMapping === 'undefined';
            row._valid = valid;

            var key = row.WeatherCode.toLowerCase() + " " + row.MaxTemp;

            if (!valid) {
                if (!this.duplicateWeatherCodeMappings.has(key)) {
                    this.duplicateWeatherCodeMappings.set(key, this.notification.show(this.translateService.translate("Duplicate weather code mapping (#" + (row.Id || 'new') + ", " + (duplicateWeatherCodeMapping.Id || 'new') + ": " + duplicateWeatherCodeMapping.WeatherCode + " " + duplicateWeatherCodeMapping.MaxTemp + ")"), { type: "error", sticky: true }));

                    if (this.areWeatherCodeMappingsValid) {
                        this.areWeatherCodeMappingsValid = false;
                    }
                }
            } else if (this.duplicateWeatherCodeMappings.has(key)) {
                this.duplicateWeatherCodeMappings.get(key).remove(); // remove notification
                this.duplicateWeatherCodeMappings.delete(key);

                if (this.duplicateWeatherCodeMappings.size === 0 && !this.areWeatherCodeMappingsValid) {
                    this.areWeatherCodeMappingsValid = true;
                }
            }
        });
    };
}
