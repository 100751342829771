import { Component } from '@angular/core';
import { AuthenticationService, SwxModule } from 'swx.front-end-lib';
import {ApiService} from "../shared/Api.service";
import { HasPermissionPipe } from '../shared/HasPermission.pipe';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';

@Component({
    selector: '[menu]',
    templateUrl: 'Menu.component.html',
    standalone: true,
    imports: [NgIf, RouterLinkActive, RouterLink, SwxModule, HasPermissionPipe]
})
export class MenuComponent {
    currentUser;
    environment;

    constructor(
        private api: ApiService,
        private authentication: AuthenticationService,
    ) {
        this.api.Environment.queryObject().$promise.then(e => {
            this.environment = e;
        });
        
        this.authentication.getCurrentUser()
            .then(user => this.currentUser = user);

        this.authentication.userChange.subscribe(user => {
            this.currentUser = user;
        });
    }
}
