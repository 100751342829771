import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { CellRendererFactories, ChangesService, SwxModule, GridModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { ConfigGridComponent } from "swx.front-end-lib";
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'LWEMessageMappingProfileDetail.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, GridModule, HasPermissionPipe]
})
export class LWEMessageMappingProfileDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', {static: true}) ngForm;
    @ViewChild('mappingsGrid', { static: true }) mappingsGrid: ConfigGridComponent;
    @ViewChild('categoriesGrid', { static: true }) categoriesGrid: ConfigGridComponent;
    tab;
    weatherTypes = this.api.ClientWeatherType.query();
    fluidTypeFlags = this.api.FluidTypeFlags.query();
    equipmentTypes = this.api.EquipmentType.query();
    lweMessageMappingHotTypes = this.api.LWEMessageMappingHotType.query();
    hotConditions = this.api.HotCondition.query();
    mappingsColumnDefs = [
        { colId: "Id", field: "Id", headerName: "#", width: 50, pinned: 'left', filterType: "integer" },
        { colId: "FluidType", field: "FluidType", headerName: "Fluid types", width: 230, cellClass: "select", cellTemplate: `
            <multiselect-enum [enum]="parent.fluidTypeFlags" width="220" required [(ngModel)]="params.data.FluidType" [name]="'LWEMessageMapping[' + params.node.id + '].FluidType'"></multiselect-enum>`, pinned: 'left' },
        { colId: "WeatherType", field: "WeatherType", headerName: "Weather category", width: 150, cellClass: "select", cellTemplate: `
            <select [(ngModel)]="params.data.WeatherType" [name]="'LWEMessageMapping[' + params.node.id + '].WeatherType'" required style="width: 140px;">
                <option *ngFor="let option of parent.weatherTypes|keys" [ngValue]="option">
                    {{option + ' (' + parent.weatherTypes[option] + ')'}}
                </option>
            </select>`, pinned: 'left' },
        { colId: "EquipmentTypeNames", field: "EquipmentTypeNames", headerName: "Equipment types", width: 230, cellClass: "select", cellTemplate: `
            <multiselect width="220" [none]="'All'" [(ngModel)]="params.data.EquipmentTypeIds" [name]="'LWEMessageMapping[' + params.node.id + '].EquipmentTypeIds'">
                <multiselect-option *ngFor="let equipmentType of parent.equipmentTypes|orderBy:['IATACode', 'ICAOCode']" [value]="equipmentType.Id" [name]="equipmentType.IATACode + '/' + (equipmentType.ICAOCode || ('N/A'|translate))"></multiselect-option>
            </multiselect>`, pinned: 'left' },
        { colId: "MaxTemp", field: "MaxTemp", headerName: "Temp <= (°C)", width: 85, cellTemplate: `<input required type="number" step="any" [(ngModel)]="params.data.MaxTemp" [name]="'LWEMessageMapping[' + params.node.id + '].MaxTemp'" style="width: 70px;" />`, pinned: 'left' },
        { colId: "MaxLWE", field: "MaxLWE", headerName: "LWE < (g/dm²/h)", width: 85, cellTemplate: `<input required type="number" step="any" [(ngModel)]="params.data.MaxLWE" [name]="'LWEMessageMapping[' + params.node.id + '].MaxLWE'" style="width: 70px;" />`, pinned: 'left' },
        { colId: "HotType", field: "HotType", headerName: "HOT type", width: 300, cellClass: "select", cellTemplate: `
            <select [(ngModel)]="params.data.HotType" [name]="'LWEMessageMapping[' + params.node.id + '].HotType'" required style="width: 140px;">
                <option *ngFor="let option of parent.lweMessageMappingHotTypes|keys" [ngValue]="option">
                    {{parent.lweMessageMappingHotTypes[option]}}
                </option>
            </select>` },
        { colId: "MetarIcePelletsOverrideLWE", field: "MetarIcePelletsOverrideLWE", headerName: "METAR ice pellets override LWE?", width: 100, cellClass: "select", cellTemplate: `<input type="checkbox" *ngIf="params.data.HotType != 'METARHOT'" [(ngModel)]="params.data.MetarIcePelletsOverrideLWE" [name]="'LWEMessageMapping[' + params.node.id + '].MetarIcePelletsOverrideLWE'" />` },
        { colId: "MaxHOT", field: "MaxHOT", headerName: "Max HOT (minutes)", width: 85, cellTemplate: `<input *ngIf="params.data.HotType != 'METARHOT'" [required]="params.data.HotType != 'METARHOT'" type="number" step="1" min="0" max="10000" [(ngModel)]="params.data.MaxHOT" [name]="'LWEMessageMapping[' + params.node.id + '].MaxHOT'" style="width: 70px;" />` },
        { colId: "Message", field: "Message", headerName: "Message for ACARS, web portal and API responses", width: 300, cellClass: "select", cellTemplate: `<input *ngIf="params.data.HotType != 'METARHOT'" type="text" pattern="^[0-9a-zA-Z•\\t\\- ':\.,\\\\/@\+]+$" [(ngModel)]="params.data.Message" [name]="'LWEMessageMapping[' + params.node.id + '].Message'" style="width: 99%;" />` },
        { colId: "ShortMobileMessage", field: "ShortMobileMessage", headerName: "Short message for mobile app only", width: 300, cellClass: "select", cellTemplate: `<input *ngIf="params.data.HotType != 'METARHOT'" type="text" pattern="^[0-9a-zA-Z•\\t\\- ':\.,\\\\/@\+]+$" [(ngModel)]="params.data.ShortMobileMessage" [name]="'LWEMessageMapping[' + params.node.id + '].ShortMobileMessage'" style="width: 99%;" />` },
        { colId: "LongMobileMessage", field: "LongMobileMessage", headerName: "Long message for mobile app only", width: 350, cellClass: "textarea", cellTemplate: `<textarea *ngIf="params.data.HotType != 'METARHOT'" style="width: 99%; height: 52px;" [(ngModel)]="params.data.LongMobileMessage" [name]="'LWEMessageMapping[' + params.node.id + '].LongMobileMessage'"></textarea>` },
        { colId: "ShowHotAndMessage", field: "ShowHotAndMessage", headerName: "Show HOT and message?", width: 100, cellClass: "select", cellTemplate: `<input *ngIf="params.data.HotType != 'METARHOT'" type="checkbox" [(ngModel)]="params.data.ShowHotAndMessage" [name]="'LWEMessageMapping[' + params.node.id + '].ShowHotAndMessage'" />` },
        { colId: "IsRestrictiveCondition", field: "IsRestrictiveCondition", headerName: "Is restrictive condition?", width: 100, cellClass: "select", cellTemplate: `<input type="checkbox" [(ngModel)]="params.data.IsRestrictiveCondition" [name]="'LWEMessageMapping[' + params.node.id + '].IsRestrictiveCondition'" />` },
        { colId: "HotConditionName", field: "HotConditionName", headerName: "HOT condition for dispatch portal", width: 200, cellClass: "select", cellTemplate: `
            <select *ngIf="params.data.HotType != 'METARHOT'" [(ngModel)]="params.data.HotConditionId" [name]="'LWEMessageMapping[' + params.node.id + '].HotConditionId'" style="width: 98%;">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let option of parent.hotConditions" [ngValue]="option.Id">
                    {{option.Name}}
                </option>
            </select>` },
        { colId: "Actions", field: "Id", headerName: "", width: 80, pinned: 'right', cellClass: "actions", cellTemplate: `<a *ngIf="parent.hasPermissionService.hasPermission('ConfigEditLWEMessageMappings')" (click)="parent.removeMapping(params.data)" class="mdi mdi-delete" title="{{'Remove'|translate}}"></a>` },
    ];
    categoriesColumnDefs = [
        { colId: "Id", field: "Id", headerName: "#", width: 50, pinned: 'left', filterType: "integer" },
        { colId: "WeatherType", field: "WeatherType", headerName: "Weather category as determined by the LWE station", width: 150, cellClass: "select", cellTemplate: `
            <select [(ngModel)]="params.data.WeatherType" [name]="'WeartherCategoryProperty[' + params.node.id + '].WeatherType'" required style="width: 140px;">
                <option *ngFor="let option of parent.weatherTypes|keys" [ngValue]="option">
                    {{option + ' (' + parent.weatherTypes[option] + ')'}}
                </option>
            </select>`, pinned: 'left' },
        { colId: "DisplayName", field: "DisplayName", headerName: "Label in web portal", width: 300, cellClass: "select", cellTemplate: `<input type="text" pattern="^[0-9a-zA-Z•\\t\\- ':\.,\\\\/@\+]+$" [(ngModel)]="params.data.DisplayName" [name]="'WeartherCategoryProperty[' + params.node.id + '].DisplayName'" style="width: 99%;" />` },
        { colId: "ACARSCode", field: "ACARSCode", headerName: "Label in ACARS and mobile app", width: 300, cellClass: "select", cellTemplate: `<input type="text" pattern="^[0-9a-zA-Z•\\t\\- ':\.,\\\\/@\+]+$" [(ngModel)]="params.data.ACARSCode" [name]="'WeartherCategoryProperty[' + params.node.id + '].ACARSCode'" style="width: 99%;" />` },
        { colId: "Actions", field: "Id", headerName: "", width: 80, pinned: 'right', cellClass: "select", cellTemplate: `<a *ngIf="parent.hasPermissionService.hasPermission('ConfigEditLWEMessageMappings')" (click)="parent.removeCategory(params.data)" class="mdi mdi-delete" title="{{'Remove'|translate}}"></a>` },
    ];
    exceptionsData = [
        {'Field': 'SnowLWETooHighError', 'Name': 'Snow LWE too high'},
        {'Field': 'SLDLWETooHighError', 'Name': 'SLD/FZDZ/FZRA LWE too high'},
        {'Field': 'FZFGLWETooHighError', 'Name': 'FZFG LWE too high'},
        {'Field': 'SnowTemperatureTooLowAndWindSpeedTooHighError', 'Name': 'Snow temperature too low and wind speed too high'},
        {'Field': 'TWASnowLWETooHighError', 'Name': 'TWA intermediate snow LWE too high'},
        {'Field': 'TWASLDLWETooHighError', 'Name': 'TWA intermediate SLD LWE too high'},
        {'Field': 'TWAFZFGLWETooHighError', 'Name': 'TWA intermediate FZFG LWE too high'},
        {'Field': 'TWASnowTemperatureTooLowAndWindSpeedTooHighError', 'Name': 'TWA intermediate snow temperature too low and wind speed too high'},
    ];
    exceptionsColumnDefs = [
        {
            colId: "Name",
            field: "Name",
            headerName: "Name",
            width: 300,
            pinned: 'left'
        },
        {
            colId: "Message",
            field: "Field",
            headerName: "Message for ACARS, web portal and API responses",
            width: 300,
            cellTemplate: `<input type="text" [(ngModel)]="parent.item[params.data.Field].Message" [name]="'parent.item.' + params.data.Field" style="width: 99%;" pattern="^[0-9a-zA-Z•\\t\\- ':\.,\\\\/@\+]+$" />`, // to avoid conflict with uppercase, use of lowercase in pattern
        },
        {
            colId: "ShortMobileMessage",
            field: "Field",
            headerName: "Short message for mobile app only",
            width: 300,
            cellTemplate: `<input type="text" [(ngModel)]="parent.item[params.data.Field].ShortMobileMessage" [name]="'parent.item.ShortMobile' + params.data.Field" style="width: 99%;" pattern="^[0-9a-zA-Z•\\t\\- ':\.,\\\\/@\+]+$" />`, // to avoid conflict with uppercase, use of lowercase in pattern
        },
        {
            colId: "LongMobileMessage",
            field: "Field",
            headerName: "Long message for mobile app only",
            width: 350,
            cellClass: "textarea",
            cellTemplate: `<textarea style="width: 99%; height: 52px;" [(ngModel)]="parent.item[params.data.Field].LongMobileMessage" [name]="'parent.item.LongMobile' + params.data.Field"></textarea>`,
        },
        {
            colId: "IsRestrictiveCondition",
            field: "Field",
            headerName: "Is restrictive condition?",
            width: 200,
            cellRenderer: params => this.cellRenderers.input('checkbox', {
                checked: this.item['IsRestrictiveCondition' + params.data.Field],
                onclick: () => {
                    this.item['IsRestrictiveCondition' + params.data.Field] = !this.item[params.data.Field].IsRestrictiveCondition;
                    this.ngForm.form.updateValueAndValidity();
                    this.ngForm.form.markAsDirty();
                },
            }),
        },
        {
            colId: "HotConditionId",
            field: "Field",
            headerName: "HOT condition for dispatch portal",
            width: 200,
            cellClass: "select",
            cellTemplate: `
                <select style="width: 99%;" [(ngModel)]="parent.item['HotConditionId' + params.data.Field]" [name]="'parent.item.HotConditionId' + params.data.Field">
                    <option [ngValue]="null">{{'None'|translate}}</option>
                    <option *ngFor="let option of parent.hotConditions" [ngValue]="option.Id">
                        {{option.Name}}
                    </option>
                </select>`,
        },
        {
            colId: "Options",
            field: "Field",
            headerName: "",
            width: 1150,
            cellTemplate: `
<div class="field inline" *ngIf="params.data.Field == 'SnowLWETooHighError'">
    <span class="caption">{{'Use METAR HOT if available'|translate}}</span>
    <input type="checkbox" [(ngModel)]="parent.item.UseMetarHotWhenSnowLWETooHigh" name="parent.item.UseMetarHotWhenSnowLWETooHigh" />
</div>
<div class="field inline" *ngIf="params.data.Field == 'SnowLWETooHighError' && parent.item.UseMetarHotWhenSnowLWETooHigh">
    <span class="caption">{{'LWE limit (g/dm²/h)'|translate}}</span>
    <br /><input type="number" [(ngModel)]="parent.item.UseMetarHotWhenSnowLWETooHighLimit" name="parent.item.UseMetarHotWhenSnowLWETooHighLimit" style="width: 60px;" min="0" step="any" />
</div>
<div class="field inline" *ngIf="params.data.Field == 'SnowLWETooHighError' && parent.item.UseMetarHotWhenSnowLWETooHigh">
    <span class="caption">{{'Extra ACARS/portal/API message'|translate}}</span>
    <br /><input type="text" [(ngModel)]="parent.item.UseMetarHotWhenSnowLWETooHighExtraMessage" name="parent.item.UseMetarHotWhenSnowLWETooHighExtraMessage" style="width: 300px;" pattern="^[0-9a-zA-Z•\\t\\- ':\\.,\\\\\\/@\\+]+$" />
</div>
<div class="field inline" *ngIf="params.data.Field == 'SnowLWETooHighError' && parent.item.UseMetarHotWhenSnowLWETooHigh">
    <span class="caption">{{'Extra mobile short message'|translate}}</span>
    <br /><input type="text" [(ngModel)]="parent.item.UseMetarHotWhenSnowLWETooHighExtraShortMobileMessage" name="parent.item.UseMetarHotWhenSnowLWETooHighExtraShortMobileMessage" style="width: 300px;" pattern="^[0-9a-zA-Z•\\t\\- ':\\.,\\\\\\/@\\+]+$" />
</div>
<div class="field inline" *ngIf="params.data.Field == 'SnowLWETooHighError' && parent.item.UseMetarHotWhenSnowLWETooHigh">
    <textarea [(ngModel)]="parent.item.UseMetarHotWhenSnowLWETooHighExtraLongMobileMessage" name="parent.item.UseMetarHotWhenSnowLWETooHighExtraLongMobileMessage" placeholder="Extra mobile long message" style="width: 250px; height: 52px;"></textarea>
</div>

<div class="field inline" *ngIf="params.data.Field == 'SLDLWETooHighError'">
    <span class="caption">{{'Use METAR HOT if available'|translate}}</span>
    <input type="checkbox" [(ngModel)]="parent.item.UseMetarHotWhenSLDLWETooHigh" name="parent.item.UseMetarHotWhenSLDLWETooHigh" />
</div>
<div class="field inline" *ngIf="params.data.Field == 'SLDLWETooHighError' && parent.item.UseMetarHotWhenSLDLWETooHigh">
    <span class="caption">{{'Extra ACARS/portal/API message'|translate}}</span>
    <br /><input type="text" [(ngModel)]="parent.item.UseMetarHotWhenSLDLWETooHighExtraMessage" name="parent.item.UseMetarHotWhenSLDLWETooHighExtraMessage" style="width: 300px;" pattern="^[0-9a-zA-Z•\\t\\- ':\\.,\\\\\\/@\\+]+$" />
</div>
<div class="field inline" *ngIf="params.data.Field == 'SLDLWETooHighError' && parent.item.UseMetarHotWhenSLDLWETooHigh">
    <span class="caption">{{'Extra mobile short message'|translate}}</span>
    <br /><input type="text" [(ngModel)]="parent.item.UseMetarHotWhenSLDLWETooHighExtraShortMobileMessage" name="parent.item.UseMetarHotWhenSLDLWETooHighExtraShortMobileMessage" style="width: 300px;" pattern="^[0-9a-zA-Z•\\t\\- ':\\.,\\\\\\/@\\+]+$" />
</div>
<div class="field inline" *ngIf="params.data.Field == 'SLDLWETooHighError' && parent.item.UseMetarHotWhenSLDLWETooHigh">
    <textarea [(ngModel)]="parent.item.UseMetarHotWhenSLDLWETooHighExtraLongMobileMessage" name="parent.item.UseMetarHotWhenSLDLWETooHighExtraLongMobileMessage" placeholder="Extra mobile long message" style="width: 250px; height: 52px;"></textarea>
</div>

<div class="field inline" *ngIf="params.data.Field == 'FZFGLWETooHighError'">
    <span class="caption">{{'Use METAR HOT if available'|translate}}</span>
    <input type="checkbox" [(ngModel)]="parent.item.UseMetarHotWhenFZFGLWETooHigh" name="parent.item.UseMetarHotWhenFZFGLWETooHigh" />
</div>
<div class="field inline" *ngIf="params.data.Field == 'FZFGLWETooHighError' && parent.item.UseMetarHotWhenFZFGLWETooHigh">
    <span class="caption">{{'Extra ACARS/portal/API message'|translate}}</span>
    <br /><input type="text" [(ngModel)]="parent.item.UseMetarHotWhenFZFGLWETooHighExtraMessage" name="parent.item.UseMetarHotWhenFZFGLWETooHighExtraMessage" style="width: 300px;" pattern="^[0-9a-zA-Z•\\t\\- ':\\.,\\\\\\/@\\+]+$" />
</div>
<div class="field inline" *ngIf="params.data.Field == 'FZFGLWETooHighError' && parent.item.UseMetarHotWhenFZFGLWETooHigh">
    <span class="caption">{{'Extra mobile short message'|translate}}</span>
    <br /><input type="text" [(ngModel)]="parent.item.UseMetarHotWhenFZFGLWETooHighExtraShortMobileMessage" name="parent.item.UseMetarHotWhenFZFGLWETooHighExtraShortMobileMessage" style="width: 300px;" pattern="^[0-9a-zA-Z•\\t\\- ':\\.,\\\\\\/@\\+]+$" />
</div>
<div class="field inline" *ngIf="params.data.Field == 'FZFGLWETooHighError' && parent.item.UseMetarHotWhenFZFGLWETooHigh">
    <textarea [(ngModel)]="parent.item.UseMetarHotWhenFZFGLWETooHighExtraLongMobileMessage" name="parent.item.UseMetarHotWhenFZFGLWETooHighExtraLongMobileMessage" placeholder="Extra mobile long message" style="width: 250px; height: 52px; margin-top: -3px;"></textarea>
</div>

<div class="field inline" *ngIf="params.data.Field == 'SnowTemperatureTooLowAndWindSpeedTooHighError'">
    <span class="caption">{{'Use METAR HOT if available'|translate}}</span>
    <input type="checkbox" [(ngModel)]="parent.item.UseMetarHotWhenSNTemperatureTooLowAndWindSpeedTooHigh" name="parent.item.UseMetarHotWhenSNTemperatureTooLowAndWindSpeedTooHigh" />
</div>
<div class="field inline" *ngIf="params.data.Field == 'SnowTemperatureTooLowAndWindSpeedTooHighError' && parent.item.UseMetarHotWhenSNTemperatureTooLowAndWindSpeedTooHigh">
    <span class="caption">{{'Extra ACARS/portal/API message'|translate}}</span>
    <br /><input type="text" [(ngModel)]="parent.item.UseMetarHotWhenSNTemperatureTooLowAndWindSpeedTooHighExtraMessage" name="parent.item.UseMetarHotWhenSNTemperatureTooLowAndWindSpeedTooHighExtraMessage" style="width: 300px;" pattern="^[0-9a-zA-Z•\\t\\- ':\\.,\\\\\\/@\\+]+$" />
</div>
<div class="field inline" *ngIf="params.data.Field == 'SnowTemperatureTooLowAndWindSpeedTooHighError' && parent.item.UseMetarHotWhenSNTemperatureTooLowAndWindSpeedTooHigh">
    <span class="caption">{{'Extra mobile short message'|translate}}</span>
    <br /><input type="text" [(ngModel)]="parent.item.UseMetarHotWhenSNTemperatureTooLowAndWindSpeedTooHighExtraShortMobileMessage" name="parent.item.UseMetarHotWhenSNTemperatureTooLowAndWindSpeedTooHighExtraShortMobileMessage" style="width: 300px;" pattern="^[0-9a-zA-Z•\\t\\- ':\\.,\\\\\\/@\\+]+$" />
</div>
<div class="field inline" *ngIf="params.data.Field == 'SnowTemperatureTooLowAndWindSpeedTooHighError' && parent.item.UseMetarHotWhenSNTemperatureTooLowAndWindSpeedTooHigh">
    <textarea [(ngModel)]="parent.item.UseMetarHotWhenSNTemperatureTooLowAndWindSpeedTooHighExtraLongMobileMessage" name="parent.item.UseMetarHotWhenSNTemperatureTooLowAndWindSpeedTooHighExtraLongMobileMessage" placeholder="Extra mobile long message" style="width: 250px; height: 52px; margin-top: -3px;"></textarea>
</div>
`,
        },
    ];
    
    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        private cellRenderers: CellRendererFactories,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.LWEMessageMappingProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.WeatherCategoryProperties) {
                    this.item.WeatherCategoryProperties.forEach(related => {
                        delete related.LWEMessageMappingProfileId;
                    });
                }

                if (this.item.LWEMessageMappings) {
                    this.item.LWEMessageMappings.forEach(related => {
                        delete related.LWEMessageMappingProfileId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.LWEMessageMappingProfile.create({
                Active: true,
            });

            this.exceptionsData.forEach(r => {
                this.item[r.Field] = {
                    IsRestrictiveCondition: false,
                };
            });
        } else {
            this.item = this.api.LWEMessageMappingProfile.get({ id: id });
        }

        Promise.all([this.item.$promise, this.equipmentTypes.$promise]).then(_ => {
            this.hotConditions.$promise.then(() => {
                this.item.LWEMessageMappings = this.item.LWEMessageMappings || [];

                this.item.LWEMessageMappings.forEach(mm => {
                    mm.EquipmentTypeIds = (mm.LWEMessageMappingEquipmentTypes || [])
                        .map(mmeq => mmeq.EquipmentTypeId);

                    mm.EquipmentTypeNames = (mm.LWEMessageMappingEquipmentTypes || [])
                        .map(mmeq => this.equipmentTypes.find(mwt => mwt === mmeq.EquipmentTypeId))
                        .join(', ');
                    
                    if (mm.HotConditionId) {
                        mm.HotConditionName = this.hotConditions.find(hc => hc.Id === mm.HotConditionId).Name;
                    }
                });
            });
        });
    }

    save() {
        this.item.$promise.then(_ => {
            this.item.LWEMessageMappings.forEach(mm => {
                mm.LWEMessageMappingEquipmentTypes = (mm.EquipmentTypeIds || [])
                    .map(eq => ({
                        LWEMessageMappingId: mm.Id,
                        EquipmentTypeId: eq
                    }));
            });
            
            this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
        });
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['LWEMessageMappingProfile'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    addCategory() {
        this.item.WeatherCategoryProperties = this.item.WeatherCategoryProperties || [];
        this.item.WeatherCategoryProperties.unshift({});
        this.categoriesGrid.gridApi.applyTransaction({ addIndex: 0, add: this.categoriesGrid.rowData.slice(0, 1) })
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    };

    removeCategory(category) {
        this.item.WeatherCategoryProperties.splice(this.item.WeatherCategoryProperties.indexOf(category), 1);
        this.categoriesGrid.updateFilteredRows();
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    };

    addMapping() {
        this.item.LWEMessageMappings = this.item.LWEMessageMappings || [];
        this.item.LWEMessageMappings.unshift({});
        this.mappingsGrid.gridApi.applyTransaction({ addIndex: 0, add: this.mappingsGrid.rowData.slice(0, 1) })
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    };

    removeMapping(mapping) {
        this.item.LWEMessageMappings.splice(this.item.LWEMessageMappings.indexOf(mapping), 1);
        this.mappingsGrid.updateFilteredRows();
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    };
}
