import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService, SwxModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { Options, QueryParams, ApiFactoryService } from "swx.front-end-lib";
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'MetarSettings.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, NgFor, HasPermissionPipe]
})
export class MetarSettingsComponent {
    settings: any;
    returnPath;
    @ViewChild('ngForm', {static: true}) ngForm;
    sources = this.api.MetarSource.query();
    tab;
    metarSources = this.api.MetarSource.query();

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private apiFactory: ApiFactoryService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = '/';
        
        this.settings = this.api.MetarSetting.queryObject();

        Object.defineProperty(this.settings, "$save", {
            enumerable: false,
            writable: true,
            value: (options?: Options, params?: QueryParams): Promise<Response> => {
                if (options == null || !options.silent) this.apiFactory.busyIndicator.setBusy(true);

                return this.apiFactory.http.post(this.apiFactory.environment.apiUrl + '/MetarSetting',
                    this.settings,
                    {
                        headers: (options && options.anonymous) ? null : this.apiFactory.authentication.getHeaders(),
                        params: params as any
                    }).toPromise()
                    .then(response => {
                        if (options == null || !options.silent) this.apiFactory.busyIndicator.setBusy(false);
                        return response;
                    }) as any;
            },
        });
    }

    save() {
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.settings, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['Setting'],
            SubjectName: 'Metar'
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };
}
