import { ModuleWithProviders, NgModule, Compiler, COMPILER_OPTIONS, CompilerFactory } from "@angular/core";
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
import { EnvironmentConfiguration } from "./EnvironmentConfiguration.model";
import { EllipsisPipe } from './pipes/Ellipsis.pipe';
import { DumpPipe } from './pipes/Dump.pipe';
import { ApplySortPipe } from './pipes/ApplySort.pipe';
import { FilterPipe } from './pipes/Filter.pipe';
import { FindByIdPipe } from './pipes/FindById.pipe';
import { IncludeInactivePipe } from './pipes/IncludeInactive.pipe';
import { KeysPipe } from './pipes/Keys.pipe';
import { ValuesPipe } from './pipes/Values.pipe';
import { TranslatePipe } from './pipes/Translate.pipe';
import { OrderByPipe } from './pipes/OrderBy.pipe';
import { UtcDateTimeFormatPipe } from './pipes/UtcDateTimeFormat.pipe';
import { FileComponent } from "./file/File.component";
import { FileAsDataComponent } from "./fileAsData/FileAsData.component";
import { FileAsDataUriComponent } from "./fileAsDataUri/FileAsDataUri.component";
import { MultiselectComponent } from "./multiselect/Multiselect.component";
import { MultiselectOptionComponent } from "./multiselect/MultiselectOption.component";
import { PageTitleComponent } from "./PageTitle.service";
import { CommonModule } from "@angular/common";
import { BusyIndicatorComponent } from "./busyIndicator/busyIndicator.component";
import { PasswordMatchDirective } from "./passwordMatch/PasswordMatch.directive";
import { AutocompleteComponent } from "./autocomplete/Autocomplete.component";
import { DateDirective, DateTimeDirective, TimeComponent } from "./date/Date.directive";
import { PortletComponent, PortletsComponent } from "./portlets/Portlets.component";
import { ColorPickerDirective } from './colorPicker/ColorPicker.directive';
import { SelectMenuComponent, SelectMenuOptionComponent } from './selectMenu/SelectMenu.component';
import { SortableComponent, SortableItemComponent } from './sortable/Sortable.component';
import {CellTemplateService} from "./grid/CellTemplate.service";
import {MultiselectEnumComponent} from "./multiselect/MultiselectEnum.component";

export function createCompiler(compilerFactory: CompilerFactory) {
    return compilerFactory.createCompiler();
}

@NgModule({
    imports: [
        CommonModule,
        EllipsisPipe,
        DumpPipe,
        ApplySortPipe,
        FilterPipe,
        FindByIdPipe,
        IncludeInactivePipe,
        KeysPipe,
        ValuesPipe,
        TranslatePipe,
        OrderByPipe,
        UtcDateTimeFormatPipe,
        FileComponent,
        FileAsDataComponent,
        FileAsDataUriComponent,
        MultiselectComponent,
        MultiselectOptionComponent,
        MultiselectEnumComponent,
        PageTitleComponent,
        BusyIndicatorComponent,
        PasswordMatchDirective,
        AutocompleteComponent,
        DateDirective,
        DateTimeDirective,
        TimeComponent,
        PortletsComponent,
        PortletComponent,
        ColorPickerDirective,
        SelectMenuComponent,
        SelectMenuOptionComponent,
        SortableComponent,
        SortableItemComponent,
    ],
    exports: [
        EllipsisPipe,
        DumpPipe,
        ApplySortPipe,
        FilterPipe,
        FindByIdPipe,
        IncludeInactivePipe,
        KeysPipe,
        ValuesPipe,
        TranslatePipe,
        OrderByPipe,
        UtcDateTimeFormatPipe,
        FileComponent,
        FileAsDataComponent,
        FileAsDataUriComponent,
        MultiselectComponent,
        MultiselectOptionComponent,
        MultiselectEnumComponent,
        PageTitleComponent,
        BusyIndicatorComponent,
        PasswordMatchDirective,
        AutocompleteComponent,
        DateDirective,
        DateTimeDirective,
        TimeComponent,
        PortletsComponent,
        PortletComponent,
        ColorPickerDirective,
        SelectMenuComponent,
        SelectMenuOptionComponent,
        SortableComponent,
        SortableItemComponent,
    ],
    providers: [
        { provide: COMPILER_OPTIONS, useValue: {}, multi: true },
        { provide: CompilerFactory, useClass: JitCompilerFactory, deps: [COMPILER_OPTIONS] },
        { provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory] },
        CellTemplateService,
    ],
})
export class SwxModule {
    static forRoot(
        environment: EnvironmentConfiguration,
    ): ModuleWithProviders<SwxModule> {
        return {
            ngModule: SwxModule,
            providers: [
                {
                    provide: EnvironmentConfiguration,
                    useValue: environment
                },
            ],
        }
    }
}
