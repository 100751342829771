import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import moment from 'moment';
import { RootScope } from '../shared/RootScope.service';
import { ApiService } from '../shared/Api.service';
import { IResourceItem, IResourceItemList, GridComponent, TranslateService, ConfirmService, SwxModule, GridModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'HeatFlapsAlertProfileList.component.html',
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        GridModule,
        NgIf,
        RouterLink,
        HasPermissionPipe,
    ],
})
export class HeatFlapsAlertProfileListComponent implements OnInit {
    items: IResourceItemList<any>;
    searchQuery;
    query: any;
    includeInactive = false;
    @ViewChild('grid', { static: true }) grid: GridComponent;
    columnDefs = [
        { colId: "Id", field: "Id", headerName: "#", width: 60, pinned: "left", filterType: "integer" },
        { colId: "Name", field: "Name", headerName: "Name", width: 200, pinned: "left", cellRenderer: c => {
                if (c.data == null) return '';
                var container = document.createElement('div');
                container.innerHTML = '<a href="/heatFlapsAlertProfiles/' + c.data.Id + '" onclick="event.preventDefault();">' + c.value + '</a>';
                container.querySelector('a').addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href')));
                return container;
            } },
        { colId: "Active", field: "Active", headerName: "Active?", width: 70, valueFormatter: c => c.value == null ? '' : c.value ? 'Yes' : 'No' },
        { colId: "ClientName", field: "ClientName", headerName: "Client", width: 200 },
        { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 120, valueFormatter: c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateTimeFormat), filterType: "date" },
        { colId: "CreatedByUserName", field: "CreatedByUserName", headerName: "Created by", width: 120 },
        { colId: "LastModified", field: "LastModified", headerName: "Modified date", width: 120, valueFormatter: c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateTimeFormat), filterType: "date" },
        { colId: "LastModifiedByUserName", field: "LastModifiedByUserName", headerName: "Modified by", width: 120 },
        { colId: "_spacer", "flex": 1, excelIgnore: true },
        { colId: "Actions", field: "Id", headerName: "", width: 80, cellClass: "actions", cellRenderer: c => {
                if (c.data == null) return '';
                var container = document.createElement('div');
                container.innerHTML = '<a href="/heatFlapsAlertProfiles/' + c.data.Id + '" class="mdi mdi-pencil" title="' + this.translateService.translate('Edit') + '" onclick="event.preventDefault();"></a>'
                    + (this.hasPermission.hasPermission('ConfigEditHeatFlapsAlertProfiles') ? '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>' : '')
                    + (this.hasPermission.hasPermission('ConfigEditHeatFlapsAlertProfiles') ? '<a href="/heatFlapsAlertProfiles/new?copy=' + c.data.Id + '" class="mdi mdi-content-copy" title="' + this.translateService.translate('Copy') + '" onclick="event.preventDefault();"></a>' : '');
                Array.from(container.querySelectorAll('a[href]')).forEach(n => n.addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href'))));
                Array.from(container.querySelectorAll('.mdi-delete')).forEach(n => n.addEventListener('click', () => this.delete(c.data)));
                return container;
            }, pinned: "right", "searchable": false, excelIgnore: true },
    ];

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private $root: RootScope,
        public api: ApiService,
        private translateService: TranslateService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private confirmService: ConfirmService,
        private hasPermission: HasPermissionService,
    ) {
    }

    ngOnInit() {
        if (window.localStorage['heatFlapsAlertProfileQuery']) {
            this.query = JSON.parse(window.localStorage['heatFlapsAlertProfileQuery']);
            this.refresh();
        } else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.searchQuery = '';
        this.query = {
            Filters: []
        };
        this.refresh();
    };

    refresh() {
        window.localStorage['heatFlapsAlertProfileQuery'] = JSON.stringify(this.query);
        this.grid.refresh();
    }

    delete(item: IResourceItem) {
        this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.ticketTrackingSystem.trackAndDelete(this.viewContainerRef, item, () => this.refresh());
        }).catch(() => {});
    }
}
