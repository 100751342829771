import {Component, ViewChild, ViewContainerRef} from "@angular/core";
import { ConfirmService, GridComponent, IAgGridColumnDef, IResourceItem, IResourceItemList, TranslateService, ValueFormatters, yesNo, SwxModule, GridModule } from "swx.front-end-lib";
import { Router, RouterLink } from "@angular/router";
import {RootScope} from "../shared/RootScope.service";
import {ApiService} from "../shared/Api.service";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";
import { HasPermissionService, HasPermissionPipe } from "../shared/HasPermission.pipe";
import { NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './EquipmentTypeList.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, RouterLink, GridModule, HasPermissionPipe]
})
export class EquipmentTypeListComponent {
    items: IResourceItemList<any>;
    searchQuery;
    query: any;
    includeInactive = false;
    @ViewChild('grid', { static: true }) grid: GridComponent;

    columnDefs: IAgGridColumnDef[] = [
        { colId: "Id", field: "Id", headerName: "#", width: 60, pinned: "left", filterType: "integer" },
        { colId: "IATACode", field: "IATACode", headerName: "IATA code", width: 80, pinned: 'left', "cellTemplate": "<a href=\"/equipmentTypes/{{params.data?.Id}}\">{{params.data?.IATACode}}</a>" },
        { colId: "ICAOCode", field: "ICAOCode", headerName: "ICAO code", width: 80, pinned: 'left', "cellTemplate": "<a href=\"/equipmentTypes/{{params.data?.Id}}\">{{params.data?.ICAOCode || 'N/A'}}</a>" },
        { 
            colId: "Active", 
            field: "Active", 
            headerName: "Active?", 
            width: 70, 
            cellRenderer: yesNo, 
        },
        { 
            colId: "DontUseICAOCodeForLookup", 
            field: "DontUseICAOCodeForLookup", 
            headerName: "Ignore ICAO code for lookup?", 
            width: 70, 
            filterType: "boolean", 
            cellRenderer: yesNo,
        },
        { colId: "ManufacturerAndModelDescription", field: "ManufacturerAndModelDescription", headerName: "Manufacturer and model", width: 250 },
        { colId: "WingMaterialType", field: "WingMaterialType", headerName: "Wing surface type", width: 120 },
        { 
            colId: "UseAdjustedHot", 
            field: "UseAdjustedHot", 
            headerName: "Use adjusted HOT?", 
            width: 70, 
            filterType: "boolean",
            cellRenderer: yesNo,
        },
        { colId: "BodyType", field: "BodyType", headerName: "Body type", width: 80 },
        { colId: "NumberOfEngines", field: "NumberOfEngines", headerName: "Number of engines", width: 100, filterType: 'integer' },
        { colId: "FuelBurnLitresPerMinute", field: "FuelBurnLitresPerMinute", headerName: "Fuel burn rate (kg/min/engine)", width: 100, filterType: 'integer' },
        { colId: "FluidQuantityWingsLitres", field: "FluidQuantityWingsLitres", headerName: "Anti-icing fluid quantity wings (L)", width: 100, filterType: 'integer' },
        { colId: "FluidQuantityTailLitres", field: "FluidQuantityTailLitres", headerName: "Anti-icing fluid quantity tail (L)", width: 100, filterType: 'integer' },
        {colId: 'CreatedDate', field: 'CreatedDate', headerName: 'Created date', width: 150, valueFormatter: this.formatters.utcDateTimeFormatter()},
        {colId: 'CreatedByUserName', field: 'CreatedByUserName', headerName: 'Created by', width: 100},
        {colId: 'LastModified', field: 'LastModified', headerName: 'Modified date', width: 150, valueFormatter: this.formatters.utcDateTimeFormatter()},
        {colId: 'LastModifiedByUserName', field: 'LastModifiedByUserName', headerName: 'Modified by', width: 100},
        { colId: "Actions", field: "Id", headerName: "", width: 80, cellClass: "actions", cellRenderer: c => {
                if (c.data == null) return '';
                var container = document.createElement('div');
                container.innerHTML = '<a href="/equipmentTypes/' + c.data.Id + '" class="mdi mdi-pencil" title="' + this.translateService.translate('Edit') + '" onclick="event.preventDefault();"></a>'
                    + (this.hasPermission.hasPermission('ConfigEditEquipmentTypes') ? '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>' : '')
                    + (this.hasPermission.hasPermission('ConfigEditEquipmentTypes') ? '<a href="/equipmentTypes/new?copy=' + c.data.Id + '" class="mdi mdi-content-copy" title="' + this.translateService.translate('Copy') + '" onclick="event.preventDefault();"></a>' : '');
                Array.from(container.querySelectorAll('a[href]')).forEach(n => n.addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href'))));
                Array.from(container.querySelectorAll('.mdi-delete')).forEach(n => n.addEventListener('click', () => this.delete(c.data)));
                return container;
            }, pinned: "right", "searchable": false, excelIgnore: true },
    ];

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private $root: RootScope,
        public api: ApiService,
        private translateService: TranslateService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private confirmService: ConfirmService,
        private hasPermission: HasPermissionService,
        private formatters: ValueFormatters,
    ) {
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.grid.refresh();
    }

    delete(item: IResourceItem) {
        this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.ticketTrackingSystem.trackAndDelete(this.viewContainerRef, item, () => this.refresh());
        }).catch(() => {});
    }
}
