import {Component, Injectable, OnInit, ViewChild} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import { DialogService, FileDownloadService, IAgGridColGroupDef, StorageService, ValueFormatters, SwxModule, GridModule } from "swx.front-end-lib";
import {StationDataRawCellRendererComponent} from "../stationDataGen2/StationDataRawCellRenderer.component";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './StationDataGen3.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, GridModule]
})
export class StationDataGen3Component implements OnInit {
    storeKey = 'stationDataGen3Query'
    @ViewChild('grid') grid;
    query: any;
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    stations = this.api.Station.query();
    resource = this.api.StationReadingGen3;
    exportResourcePost = this.api.StationReadingGen3Export;
    columnDefs: IAgGridColGroupDef[];
    components = {
        'stationDataRawCellRenderer': StationDataRawCellRendererComponent,
    }
    constructor(private api: ApiService,
                private dialog: DialogService,
                private fileDownload: FileDownloadService,
                private storage: StorageService,
                private formatters: ValueFormatters,
                private stationReadingGen3ColumnService: StationReadingGen3ColumnService,
                ) {
    }

    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey)

        if (storedQuery) {
            this.query = storedQuery
        } else {
            this.resetQuery();
        }
        
        this.columnDefs = this.stationReadingGen3ColumnService.getColumns()
    }

    resetQuery() {
        this.query = {
            Filters: []
        }
    }

    refresh() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }
    
    downloadRawData() {
        this.api.StationReadingGen3RawData.export(this.query).then(response => {
            this.fileDownload.download(response.body, (header) => response.headers.get(header))
        })
    }


    downloadCsvData() {
        this.api.StationReadingGen3CsvData.export(this.query).then(response => {
            this.fileDownload.download(response.body, (header) => response.headers.get(header))
        });
    }
}

@Injectable({ providedIn: 'root' })
export class StationReadingGen3ColumnService {
    sensorReadingVaisalaWMT700Status = this.api.SensorReadingVaisalaWMT700Status.query();
    constructor(private formatters: ValueFormatters, private api: ApiService) {
    }
    getColumns(): IAgGridColGroupDef[] {
        return [
            {
                headerName: 'Sensor reading', 
                children: [
                    { colId: "StationReading.ReadingDateDate", field: "StationReading.ReadingDate", headerName: "Date", width: 90, pinned: 'left', filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.ReadingDateTime", field: "StationReading.ReadingDate", headerName: "Time", width: 80, excelIgnore: true, pinned: 'left', searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.ReportedReadingDateTime", field: "StationReading.ReportedReadingDate", headerName: "Reported time", width: 80, pinned: 'left', searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationName", field: "StationName", headerName: "Station", width: 80, pinned: 'left' },
                    { colId: "StationReading.TelegramNumber", field: "StationReading.TelegramNumber", headerName: "Telegram number", width: 80, filterType: "integer", pinned: 'left' },
                    { colId: "Raw", field: "StationReading.RawData", headerName: "Raw", width: 40, pinned: 'left', cellRenderer: 'stationDataRawCellRenderer' },
                    { colId: "StationReading.Id", field: "StationReading.Id", headerName: "#", width: 80, filterType: "integer", pinned: 'left' },
                ]
            },
            {
                headerName: 'Sensor reading',
                children: [
                    { colId: "StationReading.QueueSize", field: "StationReading.QueueSize", headerName: "Queue size", width: 50, filterType: "integer" },
                    { colId: "StationReading.PowerErrorCount", field: "StationReading.PowerErrorCount", headerName: "Power error count", width: 60, filterType: "integer" },
                    { colId: "StationReading.VpnIp", field: "StationReading.VpnIp", headerName: "VPN IP", width: 120, },
                    { colId: "StationReading.SerialMaintenanceMode", field: "StationReading.SerialMaintenanceMode", headerName: "Serial maintenance mode?", width: 70, filterType: "boolean" },
                    { colId: "StationReading.StationProblemNames", field: "StationReading.StationProblemNames", headerName: "Station alarms", width: 350, sortable: false, "searchable": false },
                    { colId: "StationReading.Alarm", field: "StationReading.Alarm", headerName: "Alarm", width: 60 },
                ]
            },
            {
                headerName: 'Thies LPM',
                children: [
                    { colId: "StationReading.ThiesLPM.ReadingStartDate", field: "StationReading.ThiesLPM.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.ThiesLPM.ReadingStartTime", field: "StationReading.ThiesLPM.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.ThiesLPM.ReadingEndDate", field: "StationReading.ThiesLPM.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.ThiesLPM.ReadingEndTime", field: "StationReading.ThiesLPM.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.ThiesLPM.ConnectionIP", field: "StationReading.ThiesLPM.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.ThiesLPM.SensorFailed", field: "StationReading.ThiesLPM.SensorFailed", headerName: "Sensor failed?", width: 80 },
                    { colId: "StationReading.ThiesLPM.WMOCode", field: "StationReading.ThiesLPM.WMOCode", headerName: "WMO", width: 60, filterType: "integer" },
                    { colId: "StationReading.ThiesLPM.MetarWeatherCode", field: "StationReading.ThiesLPM.MetarWeatherCode", headerName: "METAR code", width: 60 },
                    { colId: "StationReading.ThiesLPM.Intensity", field: "StationReading.ThiesLPM.Intensity", headerName: "Intensity (mm/h)", width: 75, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.IntensityLiquid", field: "StationReading.ThiesLPM.IntensityLiquid", headerName: "Intensity liquid (mm/h)", width: 75, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.IntensitySolid", field: "StationReading.ThiesLPM.IntensitySolid", headerName: "Intensity solid (mm/h)", width: 75, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.Visibility", field: "StationReading.ThiesLPM.Visibility", headerName: "Visibility (m)", width: 80, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.PrecipitationAmount", field: "StationReading.ThiesLPM.PrecipitationAmount", headerName: "Precipitation amount (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.RadarReflectivity", field: "StationReading.ThiesLPM.RadarReflectivity", headerName: "Radar reflectivity (dBZ)", width: 90, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.MeasuringQuality", field: "StationReading.ThiesLPM.MeasuringQuality", headerName: "Measuring quality (%)", width: 90, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.MaximumDiameterHail", field: "StationReading.ThiesLPM.MaximumDiameterHail", headerName: "Maximum diameter hail (mm)", width: 105, filterType: "float" },
                ]
            },
            {
                headerName: 'Campbell PWS100',
                children: [
                    { colId: "StationReading.CampbellPWS100.ReadingStartDate", field: "StationReading.CampbellPWS100.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.CampbellPWS100.ReadingStartTime", field: "StationReading.CampbellPWS100.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.CampbellPWS100.ReadingEndDate", field: "StationReading.CampbellPWS100.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.CampbellPWS100.ReadingEndTime", field: "StationReading.CampbellPWS100.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.CampbellPWS100.ConnectionIP", field: "StationReading.CampbellPWS100.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.CampbellPWS100.ConnectionIP", field: "StationReading.CampbellPWS100.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.CampbellPWS100.FaultStatus", field: "StationReading.CampbellPWS100.FaultStatus", headerName: "Fault status", width: 100 },
                    { colId: "StationReading.CampbellPWS100.WarmingUp", field: "StationReading.CampbellPWS100.WarmingUp", headerName: "Warming up?", width: 100 },
                    { colId: "StationReading.CampbellPWS100.WMOCode", field: "StationReading.CampbellPWS100.WMOCode", headerName: "WMO", width: 100, filterType: "integer" },
                    { colId: "StationReading.CampbellPWS100.MetarWeatherCode", field: "StationReading.CampbellPWS100.MetarWeatherCode", headerName: "METAR code", width: 100 },
                    { colId: "StationReading.CampbellPWS100.Intensity", field: "StationReading.CampbellPWS100.Intensity", headerName: "Intensity (mm/h)", width: 100, filterType: "float" },
                    { colId: "StationReading.CampbellPWS100.Visibility", field: "StationReading.CampbellPWS100.Visibility", headerName: "Visibility (m)", width: 100, filterType: "float" },
                    { colId: "StationReading.CampbellPWS100.RelativeHumidity", field: "StationReading.CampbellPWS100.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 100, filterType: "float" },
                    { colId: "StationReading.CampbellPWS100.Temperature", field: "StationReading.CampbellPWS100.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.CampbellPWS100.WetBulb", field: "StationReading.CampbellPWS100.WetBulb", headerName: "Wet bulb (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.CampbellPWS100.UpperDetectorTemperature", field: "StationReading.CampbellPWS100.UpperDetectorTemperature", headerName: "Upper detector temp (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.CampbellPWS100.LowerDetectorTemperature", field: "StationReading.CampbellPWS100.LowerDetectorTemperature", headerName: "Lower detector temp (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.CampbellPWS100.LaserHoodTemperature", field: "StationReading.CampbellPWS100.LaserHoodTemperature", headerName: "Laser hood temp (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.CampbellPWS100.LaserTemperature", field: "StationReading.CampbellPWS100.LaserTemperature", headerName: "Laser temp (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.CampbellPWS100.LaserDriveCurrent", field: "StationReading.CampbellPWS100.LaserDriveCurrent", headerName: "Laser drive current (mA)", width: 100, filterType: "float" },
                ]              
            },
            {
                headerName: 'Thies 2D Compact',
                children: [
                    { colId: "StationReading.Thies2DCompact.ReadingStartDate", field: "StationReading.Thies2DCompact.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.Thies2DCompact.ReadingStartTime", field: "StationReading.Thies2DCompact.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false , valueFormatter: this.formatters.utcTimeLongFormatter()},
                    { colId: "StationReading.Thies2DCompact.ReadingEndDate", field: "StationReading.Thies2DCompact.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.Thies2DCompact.ReadingEndTime", field: "StationReading.Thies2DCompact.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter()},
                    { colId: "StationReading.Thies2DCompact.ConnectionIP", field: "StationReading.Thies2DCompact.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.Thies2DCompact.SensorFailed", field: "StationReading.Thies2DCompact.SensorFailed", headerName: "Sensor failed?", width: 100, filterType: "integer" },
                    { colId: "StationReading.Thies2DCompact.WindSpeed", field: "StationReading.Thies2DCompact.WindSpeed", headerName: "Wind speed (m/s)", width: 100, filterType: "float" },
                    { colId: "StationReading.Thies2DCompact.WindDirection", field: "StationReading.Thies2DCompact.WindDirection", headerName: "Wind direction (°)", width: 100, filterType: "float" },
                    { colId: "StationReading.Thies2DCompact.Temperature", field: "StationReading.Thies2DCompact.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'Thies temp probe',
                children: [
                    { colId: "StationReading.ThiesTempProbe.RelativeHumidity", field: "StationReading.ThiesTempProbe.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesTempProbe.Temperature", field: "StationReading.ThiesTempProbe.Temperature", headerName: "Temperature (°C)", width: 105, filterType: "float" },
                ]
            },
            {
                headerName: 'Digital temp probe',
                children: [
                    { colId: "StationReading.DigitalTempProbe.ReadingStartDate", field: "StationReading.DigitalTempProbe.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.DigitalTempProbe.ReadingStartTime", field: "StationReading.DigitalTempProbe.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.DigitalTempProbe.ReadingEndDate", field: "StationReading.DigitalTempProbe.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.DigitalTempProbe.ReadingEndTime", field: "StationReading.DigitalTempProbe.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.DigitalTempProbe.ConnectionIP", field: "StationReading.DigitalTempProbe.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.DigitalTempProbe.RelativeHumidity", field: "StationReading.DigitalTempProbe.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 100, filterType: "float" },
                    { colId: "StationReading.DigitalTempProbe.Temperature", field: "StationReading.DigitalTempProbe.Temperature", headerName: "Temperature (°C)", width: 105, filterType: "float" },
                ]
            },
            {
                headerName: 'Active frost',
                children: [
                    { colId: "StationReading.ActiveFrost.Temperature1", field: "StationReading.ActiveFrost.Temperature1", headerName: "Temperature 1 (°C)", width: 105, filterType: "float" },
                    { colId: "StationReading.ActiveFrost.Temperature2", field: "StationReading.ActiveFrost.Temperature2", headerName: "Temperature 2 (°C)", width: 105, filterType: "float" },
                    { colId: "StationReading.ActiveFrost.Temperature3", field: "StationReading.ActiveFrost.Temperature3", headerName: "Temperature 3 (°C)", width: 105, filterType: "float" },
                    { colId: "StationReading.ActiveFrost.Temperature4", field: "StationReading.ActiveFrost.Temperature4", headerName: "Temperature 4 (°C)", width: 105, filterType: "float" },
                    { colId: "StationReading.ActiveFrost.ConeSurfaceTemperature", field: "StationReading.ActiveFrost.ConeSurfaceTemperature", headerName: "Active Frost sensor temperature (°C)", width: 105, filterType: "float" },
                ]
            },
            {
                headerName: 'OSI OWI 400',
                children: [
                    { colId: "StationReading.OSIOWI400.ReadingStartDate", field: "StationReading.OSIOWI400.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.OSIOWI400.ReadingStartTime", field: "StationReading.OSIOWI400.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.OSIOWI400.ReadingEndDate", field: "StationReading.OSIOWI400.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.OSIOWI400.ReadingEndTime", field: "StationReading.OSIOWI400.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.OSIOWI400.ConnectionIP", field: "StationReading.OSIOWI400.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.OSIOWI400.WMOCode", field: "StationReading.OSIOWI400.WMOCode", headerName: "WMO", width: 100, filterType: "integer" },
                    { colId: "StationReading.OSIOWI400.PrecipitationRate", field: "StationReading.OSIOWI400.PrecipitationRate", headerName: "Precipitation rate (mm/h)", width: 100, filterType: "float" },
                    { colId: "StationReading.OSIOWI400.Temperature", field: "StationReading.OSIOWI400.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.OSIOWI400.Visibility", field: "StationReading.OSIOWI400.Visibility", headerName: "Visibility (km)", width: 100, filterType: "float" },
                    { colId: "StationReading.OSIOWI400.Status", field: "StationReading.OSIOWI400.Status", headerName: "Status", width: 80 },
                    { colId: "StationReading.OSIOWI400.ParticleRawData", field: "StationReading.OSIOWI400.ParticleRawData", headerName: "Particle raw data", width: 100 },
                    { colId: "StationReading.OSIOWI400.AccousticRawData", field: "StationReading.OSIOWI400.AccousticRawData", headerName: "Accoustic raw data", width: 100 },
                ]
            },
            {
                headerName: 'Biral SWS-200/250',
                children: [
                    { colId: "StationReading.BiralSWS200.ReadingStartDate", field: "StationReading.BiralSWS200.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.BiralSWS200.ReadingStartTime", field: "StationReading.BiralSWS200.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.BiralSWS200.ReadingEndDate", field: "StationReading.BiralSWS200.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.BiralSWS200.ReadingEndTime", field: "StationReading.BiralSWS200.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.BiralSWS200.ConnectionIP", field: "StationReading.BiralSWS200.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.BiralSWS200.Status", field: "StationReading.BiralSWS200.Status", headerName: "Status", width: 60 },
                    { colId: "StationReading.BiralSWS200.WMOCode", field: "StationReading.BiralSWS200.WMOCode", headerName: "WMO", width: 100, filterType: "integer" },
                    { colId: "StationReading.BiralSWS200.PastWeatherType1", field: "StationReading.BiralSWS200.PastWeatherType1", headerName: "Past weather type 1", width: 100 },
                    { colId: "StationReading.BiralSWS200.PastWeatherType2", field: "StationReading.BiralSWS200.PastWeatherType2", headerName: "Past weather type 2", width: 100 },
                    { colId: "StationReading.BiralSWS200.Obstruction", field: "StationReading.BiralSWS200.Obstruction", headerName: "Obstruction", width: 100 },
                    { colId: "StationReading.BiralSWS200.MetarWeatherCode", field: "StationReading.BiralSWS200.MetarWeatherCode", headerName: "METAR weather code", width: 100 },
                    { colId: "StationReading.BiralSWS200.Intensity", field: "StationReading.BiralSWS200.Intensity", headerName: "Intensity (mm/h)", width: 100, filterType: "integer" },
                    { colId: "StationReading.BiralSWS200.Visibility", field: "StationReading.BiralSWS200.Visibility", headerName: "Visibility (m)", width: 100, filterType: "integer" },
                    { colId: "StationReading.BiralSWS200.OpticalRange", field: "StationReading.BiralSWS200.OpticalRange", headerName: "Optical range (m)", width: 100, filterType: "integer" },
                    { colId: "StationReading.BiralSWS200.TotalExco", field: "StationReading.BiralSWS200.TotalExco", headerName: "Total EXCO (km-1)", width: 100, filterType: "integer" },
                    { colId: "StationReading.BiralSWS200.TransmissometerExco", field: "StationReading.BiralSWS200.TransmissometerExco", headerName: "Transmissometer EXCO (km-1)", width: 100, filterType: "integer" },
                    { colId: "StationReading.BiralSWS200.BackScatterExco", field: "StationReading.BiralSWS200.BackScatterExco", headerName: "Back scatter EXCO (km-1)", width: 100, filterType: "integer" },
                    { colId: "StationReading.BiralSWS200.Temperature", field: "StationReading.BiralSWS200.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.BiralSWS200.ALSSignal", field: "StationReading.BiralSWS200.ALSSignal", headerName: "ALS signal (cd/m²)", width: 100, filterType: "integer" },
                    { colId: "StationReading.BiralSWS200.ALSSelfTest", field: "StationReading.BiralSWS200.ALSSelfTest", headerName: "ALS self-test", width: 100, filterType: "integer" },
                    { colId: "StationReading.BiralSWS200.NumberOfParticles", field: "StationReading.BiralSWS200.NumberOfParticles", headerName: "Number of particles", width: 100, filterType: "integer" },
                    { colId: "StationReading.BiralSWS200.AveragingPeriod", field: "StationReading.BiralSWS200.AveragingPeriod", headerName: "Averaging period (s)", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'Biral FRD500',
                children: [
                    { colId: "StationReading.BiralFRD500.ReadingStartDate", field: "StationReading.BiralFRD500.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.BiralFRD500.ReadingStartTime", field: "StationReading.BiralFRD500.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.BiralFRD500.ReadingEndDate", field: "StationReading.BiralFRD500.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.BiralFRD500.ReadingEndTime", field: "StationReading.BiralFRD500.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.BiralFRD500.ConnectionIP", field: "StationReading.BiralFRD500.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.BiralFRD500.SystemStatus", field: "StationReading.BiralFRD500.SystemStatus", headerName: "System status", width: 100 },
                    { colId: "StationReading.BiralFRD500.ActiveAntenna", field: "StationReading.BiralFRD500.ActiveAntenna", headerName: "System status", width: 60, filterType: "integer" },
                    { colId: "StationReading.BiralFRD500.AirTemp", field: "StationReading.BiralFRD500.AirTemp", headerName: "Air temp (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.BiralFRD500.EnclosureTemp", field: "StationReading.BiralFRD500.EnclosureTemp", headerName: "Enclosure temp (°C)", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'Biral FRD500 Antenna 1',
                children: [
                    { colId: "StationReading.BiralFRD500.Antenna1Valid", field: "StationReading.BiralFRD500.Antenna1Valid", headerName: "Valid", width: 60, filterType: "integer" },
                    { colId: "StationReading.BiralFRD500.Antenna1Status", field: "StationReading.BiralFRD500.Antenna1Status", headerName: "Status", width: 100 },
                    { colId: "StationReading.BiralFRD500.Antenna1State", field: "StationReading.BiralFRD500.Antenna1State", headerName: "State", width: 100 },
                    { colId: "StationReading.BiralFRD500.Antenna1ReportType", field: "StationReading.BiralFRD500.Antenna1ReportType", headerName: "Report type", width: 100 },
                    { colId: "StationReading.BiralFRD500.Antenna1Temp", field: "StationReading.BiralFRD500.Antenna1Temp", headerName: "Temp (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.BiralFRD500.Antenna1AccumulationRate", field: "StationReading.BiralFRD500.Antenna1AccumulationRate", headerName: "Accumulation rate (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.BiralFRD500.Antenna1Thickness", field: "StationReading.BiralFRD500.Antenna1Thickness", headerName: "Thickness (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.BiralFRD500.Antenna11MinuteAccumulation", field: "StationReading.BiralFRD500.Antenna11MinuteAccumulation", headerName: "1 minute accumulation (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.BiralFRD500.Antenna115MinuteAccumulation", field: "StationReading.BiralFRD500.Antenna115MinuteAccumulation", headerName: "15 minute accumulation (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.BiralFRD500.Antenna160MinuteAccumulation", field: "StationReading.BiralFRD500.Antenna160MinuteAccumulation", headerName: "60 minute accumulation (mm)", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'Biral FRD500 Antenna 2',
                children: [
                    { colId: "StationReading.BiralFRD500.Antenna2Valid", field: "StationReading.BiralFRD500.Antenna2Valid", headerName: "Valid", width: 60, filterType: "integer" },
                    { colId: "StationReading.BiralFRD500.Antenna2Status", field: "StationReading.BiralFRD500.Antenna2Status", headerName: "Status", width: 100 },
                    { colId: "StationReading.BiralFRD500.Antenna2State", field: "StationReading.BiralFRD500.Antenna2State", headerName: "State", width: 100 },
                    { colId: "StationReading.BiralFRD500.Antenna2ReportType", field: "StationReading.BiralFRD500.Antenna2ReportType", headerName: "Report type", width: 100 },
                    { colId: "StationReading.BiralFRD500.Antenna2Temp", field: "StationReading.BiralFRD500.Antenna2Temp", headerName: "Temp (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.BiralFRD500.Antenna2AccumulationRate", field: "StationReading.BiralFRD500.Antenna2AccumulationRate", headerName: "Accumulation rate (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.BiralFRD500.Antenna2Thickness", field: "StationReading.BiralFRD500.Antenna2Thickness", headerName: "Thickness (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.BiralFRD500.Antenna21MinuteAccumulation", field: "StationReading.BiralFRD500.Antenna21MinuteAccumulation", headerName: "1 minute accumulation (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.BiralFRD500.Antenna215MinuteAccumulation", field: "StationReading.BiralFRD500.Antenna215MinuteAccumulation", headerName: "15 minute accumulation (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.BiralFRD500.Antenna260MinuteAccumulation", field: "StationReading.BiralFRD500.Antenna260MinuteAccumulation", headerName: "60 minute accumulation (mm)", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'Biral VPF730',
                children: [
                    { colId: "StationReading.BiralVPF730.ReadingStartDate", field: "StationReading.BiralVPF730.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.BiralVPF730.ReadingStartTime", field: "StationReading.BiralVPF730.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.BiralVPF730.ReadingEndDate", field: "StationReading.BiralVPF730.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.BiralVPF730.ReadingEndTime", field: "StationReading.BiralVPF730.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.BiralVPF730.ConnectionIP", field: "StationReading.BiralVPF730.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.BiralVPF730.Status", field: "StationReading.BiralVPF730.Status", headerName: "Status", width: 60 },
                    { colId: "StationReading.BiralVPF730.PrecipitationType", field: "StationReading.BiralVPF730.PrecipitationType", headerName: "Precipitation type", width: 100 },
                    { colId: "StationReading.BiralVPF730.AveragingPeriod", field: "StationReading.BiralVPF730.AveragingPeriod", headerName: "Averaging period (s)", width: 100, filterType: "float" },
                    { colId: "StationReading.BiralVPF730.PrecipitationAmount", field: "StationReading.BiralVPF730.PrecipitationAmount", headerName: "Precipitation amount (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.BiralVPF730.Intensity", field: "StationReading.BiralVPF730.Intensity", headerName: "Intensity (mm/h)", width: 100, filterType: "float" },
                    { colId: "StationReading.BiralVPF730.Visibility", field: "StationReading.BiralVPF730.Visibility", headerName: "Visibility (m)", width: 100, filterType: "float" },
                    { colId: "StationReading.BiralVPF730.Temperature", field: "StationReading.BiralVPF730.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.BiralVPF730.Obstruction", field: "StationReading.BiralVPF730.Obstruction", headerName: "Obstruction", width: 100 },
                    { colId: "StationReading.BiralVPF730.ParticleCount", field: "StationReading.BiralVPF730.ParticleCount", headerName: "Particle count", width: 100, filterType: "integer" },
                    { colId: "StationReading.BiralVPF730.PrecipitationMatrix", field: "StationReading.BiralVPF730.PrecipitationMatrix", headerName: "Particle matrix", width: 100 },
                ]
            },
            {
                headerName: 'Campbell CS125',
                children: [
                    { colId: "StationReading.CampbellCS125.FaultStatus", field: "StationReading.CampbellCS125.FaultStatus", headerName: "Fault status", width: 100 },
                    { colId: "StationReading.CampbellCS125.WMOCode", field: "StationReading.CampbellCS125.WMOCode", headerName: "WMO", width: 100, filterType: "integer" },
                    { colId: "StationReading.CampbellCS125.MetarWeatherCode", field: "StationReading.CampbellCS125.MetarWeatherCode", headerName: "METAR code", width: 100 },
                    { colId: "StationReading.CampbellCS125.Intensity", field: "StationReading.CampbellCS125.Intensity", headerName: "Intensity (mm/h)", width: 100, filterType: "float" },
                    { colId: "StationReading.CampbellCS125.Visibility", field: "StationReading.CampbellCS125.Visibility", headerName: "Visibility (m)", width: 100, filterType: "float" },
                    { colId: "StationReading.CampbellCS125.RelativeHumidity", field: "StationReading.CampbellCS125.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 100, filterType: "float" },
                    { colId: "StationReading.CampbellCS125.Temperature", field: "StationReading.CampbellCS125.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.CampbellCS125.ParticleCount", field: "StationReading.CampbellCS125.ParticleCount", headerName: "Particle count", width: 100, filterType: "integer" },
                ]
            },
            {
                headerName: 'OTT Parsivel 2',
                children: [
                    { colId: "StationReading.OTTParsivel2.ReadingStartDate", field: "StationReading.OTTParsivel2.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.OTTParsivel2.ReadingStartTime", field: "StationReading.OTTParsivel2.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.OTTParsivel2.ReadingEndDate", field: "StationReading.OTTParsivel2.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date" , valueFormatter: this.formatters.utcDateFormatter()},
                    { colId: "StationReading.OTTParsivel2.ReadingEndTime", field: "StationReading.OTTParsivel2.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.OTTParsivel2.ConnectionIP", field: "StationReading.OTTParsivel2.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.OTTParsivel2.SensorStatus", field: "StationReading.OTTParsivel2.SensorStatus", headerName: "Sensor status", width: 100 },
                    { colId: "StationReading.OTTParsivel2.WMOCode", field: "StationReading.OTTParsivel2.WMOCode", headerName: "WMO", width: 100, filterType: "integer" },
                    { colId: "StationReading.OTTParsivel2.RainIntensity", field: "StationReading.OTTParsivel2.RainIntensity", headerName: "Rain intensity (mm/h)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2.MORVisibility", field: "StationReading.OTTParsivel2.MORVisibility", headerName: "MOR visibility (m)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2.InternalTemperature", field: "StationReading.OTTParsivel2.InternalTemperature", headerName: "Internal temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2.ParticleCount", field: "StationReading.OTTParsivel2.ParticleCount", headerName: "Particle count (validated)", width: 100, filterType: "integer" },
                    { colId: "StationReading.OTTParsivel2.RainAmount", field: "StationReading.OTTParsivel2.RainAmount", headerName: "Rain amount (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2.RadarReflectivity", field: "StationReading.OTTParsivel2.RadarReflectivity", headerName: "Radar reflectivity", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2.KineticEnergy", field: "StationReading.OTTParsivel2.KineticEnergy", headerName: "Kinetic energy (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2.SignalAmplitude", field: "StationReading.OTTParsivel2.SignalAmplitude", headerName: "Signal amplitude (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2.Error", field: "StationReading.OTTParsivel2.Error", headerName: "Error", width: 100 },
                    { colId: "StationReading.OTTParsivel2.MetarWeatherCode", field: "StationReading.OTTParsivel2.MetarWeatherCode", headerName: "METAR code", width: 100 },
                    { colId: "StationReading.OTTParsivel2.SnowDepthIntensity", field: "StationReading.OTTParsivel2.SnowDepthIntensity", headerName: "Snow depth intensity (mm/h)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2.ParticleCountAll", field: "StationReading.OTTParsivel2.ParticleCountAll", headerName: "Particle count (all)", width: 100, filterType: "integer" },
                    { colId: "StationReading.OTTParsivel2.SampleInterval", field: "StationReading.OTTParsivel2.SampleInterval", headerName: "Sample interval (s)", width: 100, filterType: "integer" },
                    { colId: "StationReading.OTTParsivel2.TemperaturePCB", field: "StationReading.OTTParsivel2.TemperaturePCB", headerName: "Temperature PCB (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2.TemperatureRightSensorHead", field: "StationReading.OTTParsivel2.TemperatureRightSensorHead", headerName: "Temperature right sensor head (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2.TemperatureLeftSensorHead", field: "StationReading.OTTParsivel2.TemperatureLeftSensorHead", headerName: "Temperature left sensor head (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2.PowerSupplyVoltage", field: "StationReading.OTTParsivel2.PowerSupplyVoltage", headerName: "Power supply voltage (V)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2.SensorHeadHeatingCurrent", field: "StationReading.OTTParsivel2.SensorHeadHeatingCurrent", headerName: "Sensor head heating current (A)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2.FirmwareIOPVersion", field: "StationReading.OTTParsivel2.FirmwareIOPVersion", headerName: "Firmware IOP version", width: 100 },
                    { colId: "StationReading.OTTParsivel2.FirmwareDSPVersion", field: "StationReading.OTTParsivel2.FirmwareDSPVersion", headerName: "Firmware DSP version", width: 100 },
                    { colId: "StationReading.OTTParsivel2.MeasuringStartDateTimeDate", field: "StationReading.OTTParsivel2.MeasuringStartDateTime", headerName: "Measuring date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.OTTParsivel2.MeasuringStartDateTimeTime", field: "StationReading.OTTParsivel2.MeasuringStartDateTime", headerName: "Measuring time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.OTTParsivel2.SensorDateTimeDate", field: "StationReading.OTTParsivel2.SensorDateTime", headerName: "Sensor date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.OTTParsivel2.SensorDateTimeTime", field: "StationReading.OTTParsivel2.SensorDateTime", headerName: "Sensor time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.OTTParsivel2.DebugFieldN", field: "StationReading.OTTParsivel2.DebugFieldN", headerName: "Field N (d)", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.OTTParsivel2.DebugFieldN", field: "StationReading.OTTParsivel2.DebugFieldN", headerName: "Field v (d)", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.OTTParsivel2.DebugRawData", field: "StationReading.OTTParsivel2.DebugRawData", headerName: "Raw data", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.OTTParsivel2.Debug94", field: "StationReading.OTTParsivel2.Debug94", headerName: "Debug %94", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.OTTParsivel2.Debug95", field: "StationReading.OTTParsivel2.Debug95", headerName: "Debug %95", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.OTTParsivel2.Debug96", field: "StationReading.OTTParsivel2.Debug96", headerName: "Debug %96", width: 100, sortable: false, searchable: false },
                ]
            },
            {
                headerName: 'Thies 3D',
                children: [
                    { colId: "StationReading.Thies3D.ReadingStartDate", field: "StationReading.Thies3D.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.Thies3D.ReadingStartTime", field: "StationReading.Thies3D.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.Thies3D.ReadingEndDate", field: "StationReading.Thies3D.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.Thies3D.ReadingEndTime", field: "StationReading.Thies3D.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.Thies3D.ConnectionIP", field: "StationReading.Thies3D.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.Thies3D.WMOCode", field: "StationReading.Thies3D.WMOCode", headerName: "WMO", width: 60, filterType: "integer" },
                    { colId: "StationReading.Thies3D.Intensity", field: "StationReading.Thies3D.Intensity", headerName: "Intensity (mm/h)", width: 75, filterType: "float" },
                    { colId: "StationReading.Thies3D.CorrectedIntensity", field: "StationReading.Thies3D.CorrectedIntensity", headerName: "Corrected intensity (mm/h)", width: 75, filterType: "float" },
                    { colId: "StationReading.Thies3D.PrecipitationAmount", field: "StationReading.Thies3D.PrecipitationAmount", headerName: "Precipitation amount (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.Thies3D.SensorDateTimeDate", field: "StationReading.Thies3D.SensorDateTime", headerName: "Sensor date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.Thies3D.SensorDateTimeTime", field: "StationReading.Thies3D.SensorDateTime", headerName: "Sensor time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.Thies3D.Visibility", field: "StationReading.Thies3D.Visibility", headerName: "Visibility (m)", width: 80, filterType: "float" },
                    { colId: "StationReading.Thies3D.RadarReflectivity", field: "StationReading.Thies3D.RadarReflectivity", headerName: "Radar reflectivity (dBZ)", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.MeasuringQuality", field: "StationReading.Thies3D.MeasuringQuality", headerName: "Measuring quality (%)", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.MaximumDiameterHail", field: "StationReading.Thies3D.MaximumDiameterHail", headerName: "Maximum diameter hail (mm)", width: 105, filterType: "float" },
                    { colId: "StationReading.Thies3D.CamMultiplicationFactor", field: "StationReading.Thies3D.CamMultiplicationFactor", headerName: "CAM multiplication factor (%)", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.FalsePositiveInformation", field: "StationReading.Thies3D.FalsePositiveInformation", headerName: "False positive information", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.FalsePositiveCount", field: "StationReading.Thies3D.FalsePositiveCount", headerName: "False positive count", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.ExtraSynopInformation", field: "StationReading.Thies3D.ExtraSynopInformation", headerName: "Extra SYNOP information", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.WaterParticleCount", field: "StationReading.Thies3D.WaterParticleCount", headerName: "Water particle count", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.InteriorTemperature", field: "StationReading.Thies3D.InteriorTemperature", headerName: "Interior temperature (°C)", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.AmbientTemperature", field: "StationReading.Thies3D.AmbientTemperature", headerName: "Ambient temperature (°C)", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.ValidParticleCount", field: "StationReading.Thies3D.ValidParticleCount", headerName: "Valid particle count", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.NotMatchedParticleCount", field: "StationReading.Thies3D.NotMatchedParticleCount", headerName: "Not matched particle count", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.CircularityAbove0_785", field: "StationReading.Thies3D.CircularityAbove0_785", headerName: "Circularity above 0.785", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.CircularityBetween0_7And0_785", field: "StationReading.Thies3D.CircularityBetween0_7And0_785", headerName: "Circularity between 0.7 and 0.785", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.CircularityBetween0_5And0_7", field: "StationReading.Thies3D.CircularityBetween0_5And0_7", headerName: "Circularity between 0.5 and 0.7", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.CircularityBetween0_0And0_5", field: "StationReading.Thies3D.CircularityBetween0_0And0_5", headerName: "Circularity between 0.0 and 0.5", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.DepthClass1", field: "StationReading.Thies3D.DepthClass1", headerName: "Depth class 1", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.DepthClass2", field: "StationReading.Thies3D.DepthClass2", headerName: "Depth class 2", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.DepthClass3", field: "StationReading.Thies3D.DepthClass3", headerName: "Depth class 3", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.DepthClass4", field: "StationReading.Thies3D.DepthClass4", headerName: "Depth class 4", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.DepthClass5", field: "StationReading.Thies3D.DepthClass5", headerName: "Depth class 5", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.DepthClass6", field: "StationReading.Thies3D.DepthClass6", headerName: "Depth class 6", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.DepthClass7", field: "StationReading.Thies3D.DepthClass7", headerName: "Depth class 7", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.DepthClass8", field: "StationReading.Thies3D.DepthClass8", headerName: "Depth class 8", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.DepthClass9", field: "StationReading.Thies3D.DepthClass9", headerName: "Depth class 9", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.DepthClass10", field: "StationReading.Thies3D.DepthClass10", headerName: "Depth class 10", width: 90, filterType: "float" },
                    { colId: "StationReading.Thies3D.DDH0", field: "StationReading.Thies3D.DDH0", headerName: "DDH0", width: 200 },
                    { colId: "StationReading.Thies3D.DEH0", field: "StationReading.Thies3D.DEH0", headerName: "DEH0", width: 200 },
                    { colId: "StationReading.Thies3D.DWH0", field: "StationReading.Thies3D.DWH0", headerName: "DWH0", width: 100 },
                    { colId: "StationReading.Thies3D.DSD0", field: "StationReading.Thies3D.DSD0", headerName: "DSD0", width: 200 },
                    { colId: "StationReading.Thies3D.IMG0", field: "StationReading.Thies3D.IMG0", headerName: "IMG0", width: 200 },
                    { colId: "StationReading.Thies3D.DDF0", field: "StationReading.Thies3D.DDF0", headerName: "DDF0", width: 200 },
                ]
            },
            {
                headerName: 'Thies LPM debug',
                children: [
                    { colId: "StationReading.ThiesLPM.DebugData.StatusLaser", field: "StationReading.ThiesLPM.DebugData.StatusLaser", headerName: "Status", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPM.DebugData.NumberOfParticlesTotal", field: "StationReading.ThiesLPM.DebugData.NumberOfParticlesTotal", headerName: "# of particles", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPM.DebugData.NumberOfSlowParticlesTotal", field: "StationReading.ThiesLPM.DebugData.NumberOfSlowParticlesTotal", headerName: "# of slow particles", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPM.DebugData.NumberOfFastParticlesTotal", field: "StationReading.ThiesLPM.DebugData.NumberOfFastParticlesTotal", headerName: "# of fast particles", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPM.DebugData.NumberOfSmallParticlesTotal", field: "StationReading.ThiesLPM.DebugData.NumberOfSmallParticlesTotal", headerName: "# of small particles", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPM.DebugData.NumberOfNoHydrometeorParticlesTotal", field: "StationReading.ThiesLPM.DebugData.NumberOfNoHydrometeorParticlesTotal", headerName: "# of no hydrometeor particles", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPM.DebugData.NumberOfNoHydrometeorParticlesTotalVolume", field: "StationReading.ThiesLPM.DebugData.NumberOfNoHydrometeorParticlesTotalVolume", headerName: "Volume of no hydrometeor particles", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPM.DebugData.NumberOfUnknownParticlesTotal", field: "StationReading.ThiesLPM.DebugData.NumberOfUnknownParticlesTotal", headerName: "# of no unknown particles", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPM.DebugData.NumberOfUnknownParticlesTotalVolume", field: "StationReading.ThiesLPM.DebugData.NumberOfUnknownParticlesTotalVolume", headerName: "Volume of unknown particles", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPM.DebugData.ParticleCountsByClass", field: "StationReading.ThiesLPM.DebugData.ParticleCountsByClass", headerName: "Particle counts by class", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPM.DebugData.ParticleCountsByClassAndSpeed", field: "StationReading.ThiesLPM.DebugData.ParticleCountsByClassAndSpeed", headerName: "Particle counts by class and speed", width: 100, sortable: false, searchable: false },
                ]
            },
            {
                headerName: 'Campbell PWS100 debug',
                children: [
                    { colId: "StationReading.CampbellPWS100.DebugData.PrecipitationAccumulation", field: "StationReading.CampbellPWS100.DebugData.PrecipitationAccumulation", headerName: "Precipitation accumulation (mm)", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.AverageVelocity", field: "StationReading.CampbellPWS100.DebugData.AverageVelocity", headerName: "Average velocity (m/s)", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.AverageSize", field: "StationReading.CampbellPWS100.DebugData.AverageSize", headerName: "Average particle size (mm)", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleCountDZ", field: "StationReading.CampbellPWS100.DebugData.ParticleCountDZ", headerName: "# of particles DZ", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleCountFZDZ", field: "StationReading.CampbellPWS100.DebugData.ParticleCountFZDZ", headerName: "# of particles FZDZ", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleCountRA", field: "StationReading.CampbellPWS100.DebugData.ParticleCountRA", headerName: "# of particles RA", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleCountFZRA", field: "StationReading.CampbellPWS100.DebugData.ParticleCountFZRA", headerName: "# of particles FZRA", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleCountSG", field: "StationReading.CampbellPWS100.DebugData.ParticleCountSG", headerName: "# of particles SG", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleCountSN", field: "StationReading.CampbellPWS100.DebugData.ParticleCountSN", headerName: "# of particles SN", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleCountPL", field: "StationReading.CampbellPWS100.DebugData.ParticleCountPL", headerName: "# of particles PL", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleCountGR", field: "StationReading.CampbellPWS100.DebugData.ParticleCountGR", headerName: "# of particles GR", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleCountGS", field: "StationReading.CampbellPWS100.DebugData.ParticleCountGS", headerName: "# of particles GS", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleCountER", field: "StationReading.CampbellPWS100.DebugData.ParticleCountER", headerName: "# of particles ER", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleCountUP", field: "StationReading.CampbellPWS100.DebugData.ParticleCountUP", headerName: "# of particles UP", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticlesPerSecondMax", field: "StationReading.CampbellPWS100.DebugData.ParticlesPerSecondMax", headerName: "Particles per secondMax", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticlesCountNotProcessed", field: "StationReading.CampbellPWS100.DebugData.ParticlesCountNotProcessed", headerName: "ParticlesCountNotProcessed", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.DropSizes", field: "StationReading.CampbellPWS100.DebugData.DropSizes", headerName: "Drop sizes", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.VisibilityRangeAverage10Min", field: "StationReading.CampbellPWS100.DebugData.VisibilityRangeAverage10Min", headerName: "Visibility range ave. 10 min, (m)", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentDZ", field: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentDZ", headerName: "% of particles DZ", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentFZDZ", field: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentFZDZ", headerName: "% of particles FZDZ", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentRA", field: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentRA", headerName: "% of particles RA", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentFZRA", field: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentFZRA", headerName: "% of particles FZRA", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentSG", field: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentSG", headerName: "% of particles SG", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentSN", field: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentSN", headerName: "% of particles SN", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentPL", field: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentPL", headerName: "% of particles PL", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentGR", field: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentGR", headerName: "% of particles GR", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentGS", field: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentGS", headerName: "% of particles GS", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentER", field: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentER", headerName: "% of particles ER", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentUP", field: "StationReading.CampbellPWS100.DebugData.ParticleDistributionPercentUP", headerName: "% of particles UP", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.RawParticleData", field: "StationReading.CampbellPWS100.DebugData.RawParticleData", headerName: "Raw particle data", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.CampbellPWS100.DebugData.DirtyWindowUpperVoltage", field: "StationReading.CampbellPWS100.DebugData.DirtyWindowUpperVoltage", headerName: "Dirty window upper boltage (mV)", width: 100, filterType: "float" },
                    { colId: "StationReading.CampbellPWS100.DebugData.DirtyWindowLowerVoltage", field: "StationReading.CampbellPWS100.DebugData.DirtyWindowLowerVoltage", headerName: "Dirty window lower boltage (mV)", width: 100, filterType: "float" },
                    { colId: "StationReading.CampbellPWS100.DebugData.DirtyWindowLaserVoltage", field: "StationReading.CampbellPWS100.DebugData.DirtyWindowLaserVoltage", headerName: "Dirty window laser boltage (mV)", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'Vaisala FD71P',
                children: [
                    { colId: "StationReading.VaisalaFD71P.ReadingStartDate", field: "StationReading.VaisalaFD71P.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.VaisalaFD71P.ReadingStartTime", field: "StationReading.VaisalaFD71P.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.VaisalaFD71P.ReadingEndDate", field: "StationReading.VaisalaFD71P.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.VaisalaFD71P.ReadingEndTime", field: "StationReading.VaisalaFD71P.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.VaisalaFD71P.ConnectionIP", field: "StationReading.VaisalaFD71P.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.VaisalaFD71P.SensorDateTimeDate", field: "StationReading.VaisalaFD71P.SensorDateTime", headerName: "Sensor date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.VaisalaFD71P.SensorDateTimeTime", field: "StationReading.VaisalaFD71P.SensorDateTime", headerName: "Sensor time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.VaisalaFD71P.HardwareAlert", field: "StationReading.VaisalaFD71P.HardwareAlert", headerName: "Hardware alert", width: 80, filterType: "integer" },
                    { colId: "StationReading.VaisalaFD71P.MaintenanceAlert", field: "StationReading.VaisalaFD71P.MaintenanceAlert", headerName: "Maintenance alert", width: 100, filterType: "integer" },
                    { colId: "StationReading.VaisalaFD71P.WMOCode", field: "StationReading.VaisalaFD71P.WMOCode", headerName: "WMO", width: 100, filterType: "integer" },
                    { colId: "StationReading.VaisalaFD71P.WMOCode15Minute", field: "StationReading.VaisalaFD71P.WMOCode15Minute", headerName: "WMO 15 min.", width: 100, filterType: "integer" },
                    { colId: "StationReading.VaisalaFD71P.WMOCode1Hour", field: "StationReading.VaisalaFD71P.WMOCode1Hour", headerName: "WMO 1 hour", width: 100, filterType: "integer" },
                    { colId: "StationReading.VaisalaFD71P.NWSWeatherCode", field: "StationReading.VaisalaFD71P.NWSWeatherCode", headerName: "NSW code", width: 100 },
                    { colId: "StationReading.VaisalaFD71P.MetarWeatherCode", field: "StationReading.VaisalaFD71P.MetarWeatherCode", headerName: "METAR code", width: 100 },
                    { colId: "StationReading.VaisalaFD71P.Intensity", field: "StationReading.VaisalaFD71P.Intensity", headerName: "Intensity (mm/h)", width: 100, filterType: "float" },
                    { colId: "StationReading.VaisalaFD71P.PrecipitationAccumulation", field: "StationReading.VaisalaFD71P.PrecipitationAccumulation", headerName: "Precipitation accumulation (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.VaisalaFD71P.SnowAccumulation", field: "StationReading.VaisalaFD71P.SnowAccumulation", headerName: "Snow accumulation (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.VaisalaFD71P.Temperature", field: "StationReading.VaisalaFD71P.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.VaisalaFD71P.DewPoint", field: "StationReading.VaisalaFD71P.DewPoint", headerName: "Dew point (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.VaisalaFD71P.RelativeHumidity", field: "StationReading.VaisalaFD71P.RelativeHumidity", headerName: "Relative humidity (%)", width: 100, filterType: "float" },
                    { colId: "StationReading.VaisalaFD71P.MORVisibility", field: "StationReading.VaisalaFD71P.MORVisibility", headerName: "MOR visibility (m)", width: 100, filterType: "float" },
                    { colId: "StationReading.VaisalaFD71P.MORVisibility10Minute", field: "StationReading.VaisalaFD71P.MORVisibility10Minute", headerName: "MOR visibility 10 min. (m)", width: 100, filterType: "float" },
                    { colId: "StationReading.VaisalaFD71P.ParticleData", field: "StationReading.VaisalaFD71P.ParticleData", headerName: "Particle data", width: 100 },
                    { colId: "StationReading.VaisalaFD71P.ParticleData2", field: "StationReading.VaisalaFD71P.ParticleData2", headerName: "Particle data 2", width: 100 },
                ]
            },
            {
                headerName: 'Campbell 3D WS',
                children: [
                    { colId: "StationReading.Campbell3DWS.ReadingStartDate", field: "StationReading.Campbell3DWS.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.Campbell3DWS.ReadingStartTime", field: "StationReading.Campbell3DWS.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.Campbell3DWS.ReadingEndDate", field: "StationReading.Campbell3DWS.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.Campbell3DWS.ReadingEndTime", field: "StationReading.Campbell3DWS.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.Campbell3DWS.ConnectionIP", field: "StationReading.Campbell3DWS.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.Campbell3DWS.SensorDateTimeDate", field: "StationReading.Campbell3DWS.SensorDateTime", headerName: "Sensor date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.Campbell3DWS.SensorDateTimeTime", field: "StationReading.Campbell3DWS.SensorDateTime", headerName: "Sensor time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.Campbell3DWS.RecordNumber", field: "StationReading.Campbell3DWS.RecordNumber", headerName: "Record number", width: 100, filterType: "integer" },
                    { colId: "StationReading.Campbell3DWS.SonicTemperatureAvg", field: "StationReading.Campbell3DWS.SonicTemperatureAvg", headerName: "Sonic temperature avg. (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.Campbell3DWS.SonicTemperatureAvg", field: "StationReading.Campbell3DWS.SonicTemperatureStd", headerName: "Sonic temperature std. (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.Campbell3DWS.WindSpeedXAvg", field: "StationReading.Campbell3DWS.WindSpeedXAvg", headerName: "Wind speed X avg. (m/s)", width: 100, filterType: "float" },
                    { colId: "StationReading.Campbell3DWS.WindSpeedXStd", field: "StationReading.Campbell3DWS.WindSpeedXStd", headerName: "Wind speed X std. (m/s)", width: 100, filterType: "float" },
                    { colId: "StationReading.Campbell3DWS.WindSpeedYAvg", field: "StationReading.Campbell3DWS.WindSpeedYAvg", headerName: "Wind speed Y avg. (m/s)", width: 100, filterType: "float" },
                    { colId: "StationReading.Campbell3DWS.WindSpeedYStd", field: "StationReading.Campbell3DWS.WindSpeedYStd", headerName: "Wind speed Y std. (m/s)", width: 100, filterType: "float" },
                    { colId: "StationReading.Campbell3DWS.WindSpeedZAvg", field: "StationReading.Campbell3DWS.WindSpeedZAvg", headerName: "Wind speed Z avg. (m/s)", width: 100, filterType: "float" },
                    { colId: "StationReading.Campbell3DWS.WindSpeedZStd", field: "StationReading.Campbell3DWS.WindSpeedZStd", headerName: "Wind speed Z std. (m/s)", width: 100, filterType: "float" },
                    { colId: "StationReading.Campbell3DWS.WindDirectionCompass", field: "StationReading.Campbell3DWS.WindDirectionCompass", headerName: "Wind direction compass (°)", width: 100, filterType: "float" },
                    { colId: "StationReading.Campbell3DWS.WindDirectionCsat3", field: "StationReading.Campbell3DWS.WindDirectionCsat3", headerName: "Wind direction CSAT3 (°)", width: 100, filterType: "float" },
                    { colId: "StationReading.Campbell3DWS.WindSpeedAverage", field: "StationReading.Campbell3DWS.WindSpeedAverage", headerName: "Wind speed (°)", width: 100, filterType: "float" },
                    { colId: "StationReading.Campbell3DWS.ResultantWindSpeedAverage", field: "StationReading.Campbell3DWS.ResultantWindSpeedAverage", headerName: "Resultant wind speed (°)", width: 100, filterType: "float" },
                    { colId: "StationReading.Campbell3DWS.WindDirectionStd", field: "StationReading.Campbell3DWS.WindDirectionStd", headerName: "Wind direction std. (°)", width: 100, filterType: "float" },
                    { colId: "StationReading.Campbell3DWS.NumberOfSamples", field: "StationReading.Campbell3DWS.NumberOfSamples", headerName: "Number of samples", width: 100, filterType: "integer" },
                    { colId: "StationReading.Campbell3DWS.CsatWarnings", field: "StationReading.Campbell3DWS.CsatWarnings", headerName: "CSAT warnings", width: 100, filterType: "integer" },
                    { colId: "StationReading.Campbell3DWS.DeltaTemperatureFlagCount", field: "StationReading.Campbell3DWS.DeltaTemperatureFlagCount", headerName: "Delta temperature flag count", width: 100, filterType: "integer" },
                    { colId: "StationReading.Campbell3DWS.PoorSignalLockFlagCount", field: "StationReading.Campbell3DWS.PoorSignalLockFlagCount", headerName: "Poor signal lock flag count", width: 100, filterType: "integer" },
                    { colId: "StationReading.Campbell3DWS.AmplitudeHighFlagCount", field: "StationReading.Campbell3DWS.AmplitudeHighFlagCount", headerName: "Amplitude high flag count", width: 100, filterType: "integer" },
                    { colId: "StationReading.Campbell3DWS.AmplitudeLowFlagCount", field: "StationReading.Campbell3DWS.AmplitudeLowFlagCount", headerName: "Amplitude low flag count", width: 100, filterType: "integer" },
                ]
            },
            {
                headerName: 'Thies WP',
                children: [
                    { colId: "StationReading.ThiesWP.ReadingStartDate", field: "StationReading.ThiesWP.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.ThiesWP.ReadingStartTime", field: "StationReading.ThiesWP.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.ThiesWP.ReadingEndDate", field: "StationReading.ThiesWP.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.ThiesWP.ReadingEndTime", field: "StationReading.ThiesWP.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.ThiesWP.ConnectionIP", field: "StationReading.ThiesWP.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.ThiesWP.SensorFailed", field: "StationReading.ThiesWP.SensorFailed", headerName: "Sensor failed?", width: 100, filterType: "integer" },
                    { colId: "StationReading.ThiesWP.WindSpeed", field: "StationReading.ThiesWP.WindSpeed", headerName: "Wind speed (m/s)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesWP.WindDirection", field: "StationReading.ThiesWP.WindDirection", headerName: "Wind direction (°)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesWP.WindGustSpeed", field: "StationReading.ThiesWP.WindGustSpeed", headerName: "Wind gust speed (m/s)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesWP.WindGustDirection", field: "StationReading.ThiesWP.WindGustDirection", headerName: "Wind gust direction (°)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesWP.Temperature", field: "StationReading.ThiesWP.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'Thies WP 1 sec',
                children: [
                    { colId: "StationReading.ThiesWP1Sec.ReadingStartDate", field: "StationReading.ThiesWP1Sec.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.ThiesWP1Sec.ReadingStartTime", field: "StationReading.ThiesWP1Sec.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.ThiesWP1Sec.ReadingEndDate", field: "StationReading.ThiesWP1Sec.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.ThiesWP1Sec.ReadingEndTime", field: "StationReading.ThiesWP1Sec.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.ThiesWP1Sec.ConnectionIP", field: "StationReading.ThiesWP1Sec.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.ThiesWP1Sec.Readings", field: "StationReading.ThiesWP1Sec.Readings", headerName: "Readings", width: 200, filterType: "integer" },
                    { colId: "StationReading.ThiesWP1Sec.LastTimestamp", field: "StationReading.ThiesWP1Sec.LastTimestamp", headerName: "Last timestamp", width: 80 },
                ]
            },
            {
                headerName: 'Thies LPM extra',
                children: [
                    { colId: "StationReading.ThiesLPM.InteriorTemperature", field: "StationReading.ThiesLPM.InteriorTemperature", headerName: "Interior temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.LaserDriveTemperature", field: "StationReading.ThiesLPM.LaserDriveTemperature", headerName: "Laser drive temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.LaserCurrent", field: "StationReading.ThiesLPM.LaserCurrent", headerName: "Laser current (0.01 mA)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.ControlVoltage", field: "StationReading.ThiesLPM.ControlVoltage", headerName: "Control voltage (mV)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.OpticalControlOutput", field: "StationReading.ThiesLPM.OpticalControlOutput", headerName: "Optical control output (mV)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.SensorSupplyVoltage", field: "StationReading.ThiesLPM.SensorSupplyVoltage", headerName: "Sensor supply voltage (0.1 V)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.PanHeatingLaserHead", field: "StationReading.ThiesLPM.PanHeatingLaserHead", headerName: "Pan heating laser head (mA)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.PanHeatingReceiverHead", field: "StationReading.ThiesLPM.PanHeatingReceiverHead", headerName: "Pan heating receiver head", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.AmbientTemperature", field: "StationReading.ThiesLPM.AmbientTemperature", headerName: "Ambient temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.HeatingSupplyVoltage", field: "StationReading.ThiesLPM.HeatingSupplyVoltage", headerName: "Heating supply voltage (0.1 V)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.HeatingHousingCurrent", field: "StationReading.ThiesLPM.HeatingHousingCurrent", headerName: "Heating housing current (mA)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.HeatingHeadsCurrent", field: "StationReading.ThiesLPM.HeatingHeadsCurrent", headerName: "Heating heads current (mA)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPM.HeatingCarriersCurrent", field: "StationReading.ThiesLPM.HeatingCarriersCurrent", headerName: "Heating carriers current (mA)", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'RUT modem A',
                children: [
                    { colId: "StationReading.RUTModemDataA.ReadingStartDate", field: "StationReading.RUTModemDataA.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.RUTModemDataA.ReadingStartTime", field: "StationReading.RUTModemDataA.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.RUTModemDataA.ReadingEndDate", field: "StationReading.RUTModemDataA.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.RUTModemDataA.ReadingEndTime", field: "StationReading.RUTModemDataA.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.RUTModemDataA.ConnectionIP", field: "StationReading.RUTModemDataA.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.RUTModemDataA.RSSI", field: "StationReading.RUTModemDataA.RSSI", headerName: "RSSI", width: 80, filterType: "float" },
                    { colId: "StationReading.RUTModemDataA.RSRP", field: "StationReading.RUTModemDataA.RSRP", headerName: "RSRP", width: 80, filterType: "float" },
                    { colId: "StationReading.RUTModemDataA.RSRQ", field: "StationReading.RUTModemDataA.RSRQ", headerName: "RSRQ", width: 80, filterType: "float" },
                    { colId: "StationReading.RUTModemDataA.SINR", field: "StationReading.RUTModemDataA.SINR", headerName: "SINR", width: 80, filterType: "float" },
                ]
            },
            {
                headerName: 'RUT modem B',
                children: [
                    { colId: "StationReading.RUTModemDataB.ReadingStartDate", field: "StationReading.RUTModemDataB.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.RUTModemDataB.ReadingStartTime", field: "StationReading.RUTModemDataB.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.RUTModemDataB.ReadingEndDate", field: "StationReading.RUTModemDataB.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.RUTModemDataB.ReadingEndTime", field: "StationReading.RUTModemDataB.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.RUTModemDataB.ConnectionIP", field: "StationReading.RUTModemDataB.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.RUTModemDataB.RSSI", field: "StationReading.RUTModemDataB.RSSI", headerName: "RSSI", width: 80, filterType: "float" },
                    { colId: "StationReading.RUTModemDataB.RSRP", field: "StationReading.RUTModemDataB.RSRP", headerName: "RSRP", width: 80, filterType: "float" },
                    { colId: "StationReading.RUTModemDataB.RSRQ", field: "StationReading.RUTModemDataB.RSRQ", headerName: "RSRQ", width: 80, filterType: "float" },
                    { colId: "StationReading.RUTModemDataB.SINR", field: "StationReading.RUTModemDataB.SINR", headerName: "SINR", width: 80, filterType: "float" },
                ]
            },
            {
                headerName: 'Lufft VS20k',
                children: [
                    { colId: "StationReading.LufftVS20k.ReadingStartDate", field: "StationReading.LufftVS20k.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.LufftVS20k.ReadingStartTime", field: "StationReading.LufftVS20k.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.LufftVS20k.ReadingEndDate", field: "StationReading.LufftVS20k.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.LufftVS20k.ReadingEndTime", field: "StationReading.LufftVS20k.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.LufftVS20k.ConnectionIP", field: "StationReading.LufftVS20k.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.LufftVS20k.RawData", field: "StationReading.LufftVS20k.RawData", headerName: "Raw data", width: 300 },
                  
                ]
            },
            {
                headerName: 'Goodrich LP1',
                children: [
                    { colId: "StationReading.GoodrichLP1.ReadingStartDate", field: "StationReading.GoodrichLP1.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.GoodrichLP1.ReadingStartTime", field: "StationReading.GoodrichLP1.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.GoodrichLP1.ReadingEndDate", field: "StationReading.GoodrichLP1.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.GoodrichLP1.ReadingEndTime", field: "StationReading.GoodrichLP1.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.GoodrichLP1.ConnectionIP", field: "StationReading.GoodrichLP1.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.GoodrichLP1.RawData", field: "StationReading.GoodrichLP1.RawData", headerName: "Raw data", width: 300 },
                ]
            },
            {
                headerName: 'Vaisala WMT700',
                children: [
                    { colId: "StationReading.VaisalaWMT700.ReadingStartDate", field: "StationReading.VaisalaWMT700.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.VaisalaWMT700.ReadingStartTime", field: "StationReading.VaisalaWMT700.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.VaisalaWMT700.ReadingEndDate", field: "StationReading.VaisalaWMT700.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.VaisalaWMT700.ReadingEndTime", field: "StationReading.VaisalaWMT700.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.VaisalaWMT700.ConnectionIP", field: "StationReading.VaisalaWMT700.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.VaisalaWMT700.Status", field: "StationReading.VaisalaWMT700.Status", headerName: "Status", width: 300, valueFormatter: (c) => {
                        if (c.value == null) return '';
                        var flags = this.sensorReadingVaisalaWMT700Status;

                        if (c.value === '') return 'OK';

                        var numericValue = parseInt(c.value);
                        if (!isNaN(numericValue)) {
                            var keys = Object.keys(flags);
                            if (numericValue === 0) return keys[0];

                            var described = [];

                            for (var i = 1; i < keys.length; i++) {
                                var flagValue = 1 << (i - 1);
                                if ((numericValue & flagValue) === flagValue) {
                                    described.push(keys[i]);
                                }
                            }

                            return described.join(', ');
                        }

                        return c.value;
                    }, excelField: "StationReading.VaisalaWMT700.StatusFlags" },
                    { colId: "StationReading.VaisalaWMT700.WindSpeed", field: "StationReading.VaisalaWMT700.WindSpeed", headerName: "Wind speed (m/s)", width: 100, filterType: "float" },
                    { colId: "StationReading.VaisalaWMT700.WindDirection", field: "StationReading.VaisalaWMT700.WindDirection", headerName: "Wind direction (°)", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'Vaisala PWD22',
                children: [
                    { colId: "StationReading.VaisalaFD12P.ReadingStartDate", field: "StationReading.VaisalaFD12P.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.VaisalaFD12P.ReadingStartTime", field: "StationReading.VaisalaFD12P.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.VaisalaFD12P.ReadingEndDate", field: "StationReading.VaisalaFD12P.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.VaisalaFD12P.ReadingEndTime", field: "StationReading.VaisalaFD12P.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.VaisalaFD12P.ConnectionIP", field: "StationReading.VaisalaFD12P.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.VaisalaFD12P.Alarm1", field: "StationReading.VaisalaFD12P.Alarm1", headerName: "Alarm 1", width: 80, filterType: "integer" },
                    { colId: "StationReading.VaisalaFD12P.Alarm2", field: "StationReading.VaisalaFD12P.Alarm2", headerName: "Alarm 2", width: 80, filterType: "integer" },
                    { colId: "StationReading.VaisalaFD12P.WMOCode", field: "StationReading.VaisalaFD12P.WMOCode", headerName: "WMO", width: 100, filterType: "integer" },
                    { colId: "StationReading.VaisalaFD12P.WMOCode15Minute", field: "StationReading.VaisalaFD12P.WMOCode15Minute", headerName: "WMO 15 min.", width: 100, filterType: "integer" },
                    { colId: "StationReading.VaisalaFD12P.NWSWeatherCode", field: "StationReading.VaisalaFD12P.NWSWeatherCode", headerName: "NSW code", width: 100 },
                    { colId: "StationReading.VaisalaFD12P.MetarWeatherCode", field: "StationReading.VaisalaFD12P.MetarWeatherCode", headerName: "METAR code", width: 100 },
                    { colId: "StationReading.VaisalaFD12P.Intensity", field: "StationReading.VaisalaFD12P.Intensity", headerName: "Intensity (mm/h)", width: 100, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.CumulativeWater", field: "StationReading.VaisalaFD12P.CumulativeWater", headerName: "Water precipitation sum (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.CumulativeSnow", field: "StationReading.VaisalaFD12P.CumulativeSnow", headerName: "Snow precipitation sum (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.Temperature", field: "StationReading.VaisalaFD12P.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.Visibility", field: "StationReading.VaisalaFD12P.Visibility", headerName: "Visibility (m)", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'Vaisala PWD22 status',
                children: [
                    { colId: "StationReading.VaisalaFD12P.Status.SIGNAL", field: "StationReading.VaisalaFD12P.Status.SIGNAL", headerName: "SIGNAL", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.Status.OFFSET", field: "StationReading.VaisalaFD12P.Status.OFFSET", headerName: "OFFSET", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.Status.DRIFT", field: "StationReading.VaisalaFD12P.Status.DRIFT", headerName: "DRIFT", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.Status.REC_BACKSCATTER", field: "StationReading.VaisalaFD12P.Status.REC_BACKSCATTER", headerName: "REC. BACKSCATTER", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.Status.TR_BACKSCATTER", field: "StationReading.VaisalaFD12P.Status.TR_BACKSCATTER", headerName: "TR. BACKSCATTER", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.Status.LEDI", field: "StationReading.VaisalaFD12P.Status.LEDI", headerName: "LEDI", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.Status.AMBL", field: "StationReading.VaisalaFD12P.Status.AMBL", headerName: "AMBL", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.Status.VBB", field: "StationReading.VaisalaFD12P.Status.VBB", headerName: "VBB", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.Status.P12", field: "StationReading.VaisalaFD12P.Status.P12", headerName: "P12", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.Status.M12", field: "StationReading.VaisalaFD12P.Status.M12", headerName: "M12", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.Status.TS", field: "StationReading.VaisalaFD12P.Status.TS", headerName: "TS", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.Status.TB", field: "StationReading.VaisalaFD12P.Status.TB", headerName: "TB", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.Status.TDRD", field: "StationReading.VaisalaFD12P.Status.TDRD", headerName: "TDRD", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.Status.DRD", field: "StationReading.VaisalaFD12P.Status.DRD", headerName: "DRD", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.Status.DRY", field: "StationReading.VaisalaFD12P.Status.DRY", headerName: "DRY", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaFD12P.Status.HOOD_HEATERS", field: "StationReading.VaisalaFD12P.Status.HOOD_HEATERS", headerName: "HOOD HEATERS", width: 70 },
                    { colId: "StationReading.VaisalaFD12P.Status.HARDWARE", field: "StationReading.VaisalaFD12P.Status.HARDWARE", headerName: "HARDWARE", width: 70 },
                ]
            },
            {
                headerName: 'OTT Pluvio2',
                children: [
                    { colId: "StationReading.OTTPluvio2.ReadingStartDate", field: "StationReading.OTTPluvio2.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.OTTPluvio2.ReadingStartTime", field: "StationReading.OTTPluvio2.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.OTTPluvio2.ReadingEndDate", field: "StationReading.OTTPluvio2.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.OTTPluvio2.ReadingEndTime", field: "StationReading.OTTPluvio2.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.OTTPluvio2.ConnectionIP", field: "StationReading.OTTPluvio2.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.OTTPluvio2.PrecipitationIntensity", field: "StationReading.OTTPluvio2.PrecipitationIntensity", headerName: "Precipitation intensity (mm/h)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2.AccumulatedPrecipitationUnfiltered", field: "StationReading.OTTPluvio2.AccumulatedPrecipitationUnfiltered", headerName: "Accumulated precipitation unfiltered (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2.AccumulatedPrecipitationFiltered", field: "StationReading.OTTPluvio2.AccumulatedPrecipitationFiltered", headerName: "Accumulated precipitation filtered (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2.AccumulatedPrecipitationTotal", field: "StationReading.OTTPluvio2.AccumulatedPrecipitationTotal", headerName: "Accumulated precipitation total (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2.BucketUnfiltered", field: "StationReading.OTTPluvio2.BucketUnfiltered", headerName: "Bucket unfiltered (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2.BucketFiltered", field: "StationReading.OTTPluvio2.BucketFiltered", headerName: "Bucket filtered (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2.LoadCellTemperature", field: "StationReading.OTTPluvio2.LoadCellTemperature", headerName: "Load cell temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2.HeaterStatus", field: "StationReading.OTTPluvio2.HeaterStatus", headerName: "Heater status", width: 250 },
                    { colId: "StationReading.OTTPluvio2.SensorStatus", field: "StationReading.OTTPluvio2.PStatus", headerName: "Sensor status", width: 250 },
                    { colId: "StationReading.OTTPluvio2.ElectronicsUnitTemperature", field: "StationReading.OTTPluvio2.ElectronicsUnitTemperature", headerName: "Electronics unit temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2.SupplyVoltage", field: "StationReading.OTTPluvio2.SupplyVoltage", headerName: "Supply voltage (V)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2.OrificeRingRimTemperature", field: "StationReading.OTTPluvio2.OrificeRingRimTemperature", headerName: "Orifice ring rim temperature (°C)", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'OTT Pluvio2 DFAR',
                children: [
                    { colId: "StationReading.OTTPluvio2DFAR.ReadingStartDate", field: "StationReading.OTTPluvio2DFAR.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.OTTPluvio2DFAR.ReadingStartTime", field: "StationReading.OTTPluvio2DFAR.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.OTTPluvio2DFAR.ReadingEndDate", field: "StationReading.OTTPluvio2DFAR.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.OTTPluvio2DFAR.ReadingEndTime", field: "StationReading.OTTPluvio2DFAR.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.OTTPluvio2DFAR.ConnectionIP", field: "StationReading.OTTPluvio2DFAR.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.OTTPluvio2DFAR.PrecipitationIntensity", field: "StationReading.OTTPluvio2DFAR.PrecipitationIntensity", headerName: "Precipitation intensity (mm/h)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2DFAR.AccumulatedPrecipitationUnfiltered", field: "StationReading.OTTPluvio2DFAR.AccumulatedPrecipitationUnfiltered", headerName: "Accumulated precipitation unfiltered (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2DFAR.AccumulatedPrecipitationFiltered", field: "StationReading.OTTPluvio2DFAR.AccumulatedPrecipitationFiltered", headerName: "Accumulated precipitation filtered (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2DFAR.AccumulatedPrecipitationTotal", field: "StationReading.OTTPluvio2DFAR.AccumulatedPrecipitationTotal", headerName: "Accumulated precipitation total (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2DFAR.BucketUnfiltered", field: "StationReading.OTTPluvio2DFAR.BucketUnfiltered", headerName: "Bucket unfiltered (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2DFAR.BucketFiltered", field: "StationReading.OTTPluvio2DFAR.BucketFiltered", headerName: "Bucket filtered (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2DFAR.LoadCellTemperature", field: "StationReading.OTTPluvio2DFAR.LoadCellTemperature", headerName: "Load cell temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2DFAR.HeaterStatus", field: "StationReading.OTTPluvio2DFAR.HeaterStatus", headerName: "Heater status", width: 250 },
                    { colId: "StationReading.OTTPluvio2DFAR.SensorStatus", field: "StationReading.OTTPluvio2DFAR.PStatus", headerName: "Sensor status", width: 250 },
                    { colId: "StationReading.OTTPluvio2DFAR.ElectronicsUnitTemperature", field: "StationReading.OTTPluvio2DFAR.ElectronicsUnitTemperature", headerName: "Electronics unit temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2DFAR.SupplyVoltage", field: "StationReading.OTTPluvio2DFAR.SupplyVoltage", headerName: "Supply voltage (V)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTPluvio2DFAR.OrificeRingRimTemperature", field: "StationReading.OTTPluvio2DFAR.OrificeRingRimTemperature", headerName: "Orifice ring rim temperature (°C)", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'Geonor T200 DA',
                children: [
                    { colId: "StationReading.GeonorT200DA.ReadingStartDate", field: "StationReading.GeonorT200DA.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.GeonorT200DA.ReadingStartTime", field: "StationReading.GeonorT200DA.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.GeonorT200DA.ReadingEndDate", field: "StationReading.GeonorT200DA.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.GeonorT200DA.ReadingEndTime", field: "StationReading.GeonorT200DA.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.GeonorT200DA.ConnectionIP", field: "StationReading.GeonorT200DA.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.GeonorT200DA.Frequency1", field: "StationReading.GeonorT200DA.Frequency1", headerName: "Frequency 1 (Hz)", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DA.Frequency2", field: "StationReading.GeonorT200DA.Frequency2", headerName: "Frequency 2 (Hz)", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DA.Frequency3", field: "StationReading.GeonorT200DA.Frequency3", headerName: "Frequency 3 (Hz)", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DA.AccumulatedPrecipitationTotal1", field: "StationReading.GeonorT200DA.AccumulatedPrecipitationTotal1", headerName: "Accumulated precipitation total 1 (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DA.AccumulatedPrecipitationTotal2", field: "StationReading.GeonorT200DA.AccumulatedPrecipitationTotal2", headerName: "Accumulated precipitation total 2 (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DA.AccumulatedPrecipitationTotal3", field: "StationReading.GeonorT200DA.AccumulatedPrecipitationTotal3", headerName: "Accumulated precipitation total 3 (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DA.TempUpperOrificeSection", field: "StationReading.GeonorT200DA.TempUpperOrificeSection", headerName: "Temp upper orifice section (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DA.LastHeaterStatusChangeDate", field: "StationReading.GeonorT200DA.LastHeaterStatusChange", headerName: "Last heater status change date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.GeonorT200DA.LastHeaterStatusChangeTime", field: "StationReading.GeonorT200DA.LastHeaterStatusChange", headerName: "Last jeater status change time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.GeonorT200DA.HeaterStatus", field: "StationReading.GeonorT200DA.HeaterStatus", headerName: "Heater status (Hz)", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DA.Frequency1RawData", field: "StationReading.GeonorT200DA.Frequency1RawData", headerName: "Frequency 1 raw data", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DA.Frequency2RawData", field: "StationReading.GeonorT200DA.Frequency2RawData", headerName: "Frequency 2 raw data", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DA.Frequency3RawData", field: "StationReading.GeonorT200DA.Frequency3RawData", headerName: "Frequency 3 raw data", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DA.AccumulatedPrecipitation1RawData", field: "StationReading.GeonorT200DA.AccumulatedPrecipitation1RawData", headerName: "Accumulated precipitation1 raw data", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DA.AccumulatedPrecipitation2RawData", field: "StationReading.GeonorT200DA.AccumulatedPrecipitation2RawData", headerName: "Accumulated precipitation2 raw data", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DA.AccumulatedPrecipitation3RawData", field: "StationReading.GeonorT200DA.AccumulatedPrecipitation3RawData", headerName: "Accumulated precipitation3 raw data", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'Geonor T200 DFAR',
                children: [
                    { colId: "StationReading.GeonorT200DFAR.ReadingStartDate", field: "StationReading.GeonorT200DFAR.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.GeonorT200DFAR.ReadingStartTime", field: "StationReading.GeonorT200DFAR.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.GeonorT200DFAR.ReadingEndDate", field: "StationReading.GeonorT200DFAR.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.GeonorT200DFAR.ReadingEndTime", field: "StationReading.GeonorT200DFAR.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.GeonorT200DFAR.ConnectionIP", field: "StationReading.GeonorT200DFAR.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.GeonorT200DFAR.Frequency1", field: "StationReading.GeonorT200DFAR.Frequency1", headerName: "Frequency 1 (Hz)", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DFAR.Frequency2", field: "StationReading.GeonorT200DFAR.Frequency2", headerName: "Frequency 2 (Hz)", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DFAR.Frequency3", field: "StationReading.GeonorT200DFAR.Frequency3", headerName: "Frequency 3 (Hz)", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DFAR.AccumulatedPrecipitationTotal1", field: "StationReading.GeonorT200DFAR.AccumulatedPrecipitationTotal1", headerName: "Accumulated precipitation total 1 (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DFAR.AccumulatedPrecipitationTotal2", field: "StationReading.GeonorT200DFAR.AccumulatedPrecipitationTotal2", headerName: "Accumulated precipitation total 2 (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DFAR.AccumulatedPrecipitationTotal3", field: "StationReading.GeonorT200DFAR.AccumulatedPrecipitationTotal3", headerName: "Accumulated precipitation total 3 (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DFAR.TempUpperOrificeSection", field: "StationReading.GeonorT200DFAR.TempUpperOrificeSection", headerName: "Temp upper orifice section (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DFAR.LastHeaterStatusChangeDate", field: "StationReading.GeonorT200DFAR.LastHeaterStatusChange", headerName: "Last heater status change date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.GeonorT200DFAR.LastHeaterStatusChangeTime", field: "StationReading.GeonorT200DFAR.LastHeaterStatusChange", headerName: "Last jeater status change time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.GeonorT200DFAR.HeaterStatus", field: "StationReading.GeonorT200DFAR.HeaterStatus", headerName: "Heater status (Hz)", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DFAR.Frequency1RawData", field: "StationReading.GeonorT200DFAR.Frequency1RawData", headerName: "Frequency 1 raw data", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DFAR.Frequency2RawData", field: "StationReading.GeonorT200DFAR.Frequency2RawData", headerName: "Frequency 2 raw data", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DFAR.Frequency3RawData", field: "StationReading.GeonorT200DFAR.Frequency3RawData", headerName: "Frequency 3 raw data", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DFAR.AccumulatedPrecipitation1RawData", field: "StationReading.GeonorT200DFAR.AccumulatedPrecipitation1RawData", headerName: "Accumulated precipitation1 raw data", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DFAR.AccumulatedPrecipitation2RawData", field: "StationReading.GeonorT200DFAR.AccumulatedPrecipitation2RawData", headerName: "Accumulated precipitation2 raw data", width: 100, filterType: "float" },
                    { colId: "StationReading.GeonorT200DFAR.AccumulatedPrecipitation3RawData", field: "StationReading.GeonorT200DFAR.AccumulatedPrecipitation3RawData", headerName: "Accumulated precipitation3 raw data", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'Pond Hot Plate',
                children: [
                    { colId: "StationReading.PondHotPlate.ReadingStartDate", field: "StationReading.PondHotPlate.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date" },
                    { colId: "StationReading.PondHotPlate.ReadingStartTime", field: "StationReading.PondHotPlate.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.PondHotPlate.ReadingEndDate", field: "StationReading.PondHotPlate.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.PondHotPlate.ReadingEndTime", field: "StationReading.PondHotPlate.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.PondHotPlate.ConnectionIP", field: "StationReading.PondHotPlate.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.PondHotPlate.PrecipitationRate", field: "StationReading.PondHotPlate.PrecipitationRate", headerName: "Precipitation rate (mm/h)", width: 100, filterType: "float" },
                    { colId: "StationReading.PondHotPlate.Accumulation24Hour", field: "StationReading.PondHotPlate.Accumulation24Hour", headerName: "Accumulation 24 hour (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.PondHotPlate.AmbientTemperature", field: "StationReading.PondHotPlate.AmbientTemperature", headerName: "Ambient temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.PondHotPlate.BarometricPressure1Min", field: "StationReading.PondHotPlate.BarometricPressure1Min", headerName: "Barometric pressure 1 min. (mbar)", width: 100, filterType: "float" },
                    { colId: "StationReading.PondHotPlate.RelativeHumidity1Min", field: "StationReading.PondHotPlate.RelativeHumidity1Min", headerName: "Relative humidity 1 min. (%)", width: 100, filterType: "float" },
                    { colId: "StationReading.PondHotPlate.WindSpeed", field: "StationReading.PondHotPlate.WindSpeed", headerName: "Wind speed (m/s)", width: 100, filterType: "float" },
                    { colId: "StationReading.PondHotPlate.SystemStatus", field: "StationReading.PondHotPlate.SystemStatus", headerName: "System status", width: 100, filterType: "float" },
                    { colId: "StationReading.PondHotPlate.SensorPlatePower1Min", field: "StationReading.PondHotPlate.SensorPlatePower1Min", headerName: "Sensor plate power 1 min. (W)", width: 100, filterType: "float" },
                    { colId: "StationReading.PondHotPlate.ReferencePlatePower1Min", field: "StationReading.PondHotPlate.ReferencePlatePower1Min", headerName: "Reference plate power 1 min. (W)", width: 100, filterType: "float" },
                    { colId: "StationReading.PondHotPlate.AlarmCode", field: "StationReading.PondHotPlate.AlarmCode", headerName: "Alarm code", width: 100, filterType: "float" },
                    { colId: "StationReading.PondHotPlate.SensorPlateTemp", field: "StationReading.PondHotPlate.SensorPlateTemp", headerName: "Sensor plate temp (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.PondHotPlate.ReferencePlateTemp", field: "StationReading.PondHotPlate.ReferencePlateTemp", headerName: "Reference plate temp (°C)", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'Thies WP secondary',
                children: [
                    { colId: "StationReading.ThiesWPSecondary.ReadingStartDate", field: "StationReading.ThiesWPSecondary.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.ThiesWPSecondary.ReadingStartTime", field: "StationReading.ThiesWPSecondary.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.ThiesWPSecondary.ReadingEndDate", field: "StationReading.ThiesWPSecondary.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.ThiesWPSecondary.ReadingEndTime", field: "StationReading.ThiesWPSecondary.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.ThiesWPSecondary.ConnectionIP", field: "StationReading.ThiesWPSecondary.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.ThiesWPSecondary.SensorFailed", field: "StationReading.ThiesWPSecondary.SensorFailed", headerName: "Sensor failed?", width: 100, filterType: "integer" },
                    { colId: "StationReading.ThiesWPSecondary.WindSpeed", field: "StationReading.ThiesWPSecondary.WindSpeed", headerName: "Wind speed (m/s)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesWPSecondary.WindDirection", field: "StationReading.ThiesWPSecondary.WindDirection", headerName: "Wind direction (°)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesWPSecondary.Temperature", field: "StationReading.ThiesWPSecondary.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'Thies LPM secondary',
                children: [
                    { colId: "StationReading.ThiesLPMSecondary.ReadingStartDate", field: "StationReading.ThiesLPMSecondary.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.ThiesLPMSecondary.ReadingStartTime", field: "StationReading.ThiesLPMSecondary.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.ThiesLPMSecondary.ReadingEndDate", field: "StationReading.ThiesLPMSecondary.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.ThiesLPMSecondary.ReadingEndTime", field: "StationReading.ThiesLPMSecondary.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.ThiesLPMSecondary.ConnectionIP", field: "StationReading.ThiesLPMSecondary.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.ThiesLPMSecondary.SensorFailed", field: "StationReading.ThiesLPMSecondary.SensorFailed", headerName: "Sensor failed?", width: 80 },
                    { colId: "StationReading.ThiesLPMSecondary.WMOCode", field: "StationReading.ThiesLPMSecondary.WMOCode", headerName: "WMO", width: 60, filterType: "integer" },
                    { colId: "StationReading.ThiesLPMSecondary.MetarWeatherCode", field: "StationReading.ThiesLPMSecondary.MetarWeatherCode", headerName: "METAR code", width: 60 },
                    { colId: "StationReading.ThiesLPMSecondary.Intensity", field: "StationReading.ThiesLPMSecondary.Intensity", headerName: "Intensity (mm/h)", width: 75, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.IntensityLiquid", field: "StationReading.ThiesLPMSecondary.IntensityLiquid", headerName: "Intensity liquid (mm/h)", width: 75, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.IntensitySolid", field: "StationReading.ThiesLPMSecondary.IntensitySolid", headerName: "Intensity solid (mm/h)", width: 75, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.Visibility", field: "StationReading.ThiesLPMSecondary.Visibility", headerName: "Visibility (m)", width: 80, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.PrecipitationAmount", field: "StationReading.ThiesLPMSecondary.PrecipitationAmount", headerName: "Precipitation amount (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.RadarReflectivity", field: "StationReading.ThiesLPMSecondary.RadarReflectivity", headerName: "Radar reflectivity (dBZ)", width: 90, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.MeasuringQuality", field: "StationReading.ThiesLPMSecondary.MeasuringQuality", headerName: "Measuring quality (%)", width: 90, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.MaximumDiameterHail", field: "StationReading.ThiesLPMSecondary.MaximumDiameterHail", headerName: "Maximum diameter hail (mm)", width: 105, filterType: "float" },

                    { colId: "StationReading.ThiesLPMSecondary.DebugData.StatusLaser", field: "StationReading.ThiesLPMSecondary.DebugData.StatusLaser", headerName: "Status", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPMSecondary.DebugData.NumberOfParticlesTotal", field: "StationReading.ThiesLPMSecondary.DebugData.NumberOfParticlesTotal", headerName: "# of particles", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPMSecondary.DebugData.NumberOfSlowParticlesTotal", field: "StationReading.ThiesLPMSecondary.DebugData.NumberOfSlowParticlesTotal", headerName: "# of slow particles", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPMSecondary.DebugData.NumberOfFastParticlesTotal", field: "StationReading.ThiesLPMSecondary.DebugData.NumberOfFastParticlesTotal", headerName: "# of fast particles", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPMSecondary.DebugData.NumberOfSmallParticlesTotal", field: "StationReading.ThiesLPMSecondary.DebugData.NumberOfSmallParticlesTotal", headerName: "# of small particles", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPMSecondary.DebugData.NumberOfNoHydrometeorParticlesTotal", field: "StationReading.ThiesLPMSecondary.DebugData.NumberOfNoHydrometeorParticlesTotal", headerName: "# of no hydrometeor particles", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPMSecondary.DebugData.NumberOfNoHydrometeorParticlesTotalVolume", field: "StationReading.ThiesLPMSecondary.DebugData.NumberOfNoHydrometeorParticlesTotalVolume", headerName: "Volume of no hydrometeor particles", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPMSecondary.DebugData.NumberOfUnknownParticlesTotal", field: "StationReading.ThiesLPMSecondary.DebugData.NumberOfUnknownParticlesTotal", headerName: "# of no unknown particles", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPMSecondary.DebugData.NumberOfUnknownParticlesTotalVolume", field: "StationReading.ThiesLPMSecondary.DebugData.NumberOfUnknownParticlesTotalVolume", headerName: "Volume of unknown particles", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPMSecondary.DebugData.ParticleCountsByClass", field: "StationReading.ThiesLPMSecondary.DebugData.ParticleCountsByClass", headerName: "Particle counts by class", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.ThiesLPMSecondary.DebugData.ParticleCountsByClassAndSpeed", field: "StationReading.ThiesLPMSecondary.DebugData.ParticleCountsByClassAndSpeed", headerName: "Particle counts by class and speed", width: 100, sortable: false, searchable: false },
                ]
            },
            {
                headerName: 'Thies LPM secondary extra',
                children: [
                    { colId: "StationReading.ThiesLPMSecondary.InteriorTemperature", field: "StationReading.ThiesLPMSecondary.InteriorTemperature", headerName: "Interior temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.LaserDriveTemperature", field: "StationReading.ThiesLPMSecondary.LaserDriveTemperature", headerName: "Laser drive temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.LaserCurrent", field: "StationReading.ThiesLPMSecondary.LaserCurrent", headerName: "Laser current (0.01 mA)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.ControlVoltage", field: "StationReading.ThiesLPMSecondary.ControlVoltage", headerName: "Control voltage (mV)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.OpticalControlOutput", field: "StationReading.ThiesLPMSecondary.OpticalControlOutput", headerName: "Optical control output (mV)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.SensorSupplyVoltage", field: "StationReading.ThiesLPMSecondary.SensorSupplyVoltage", headerName: "Sensor supply voltage (0.1 V)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.PanHeatingLaserHead", field: "StationReading.ThiesLPMSecondary.PanHeatingLaserHead", headerName: "Pan heating laser head (mA)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.PanHeatingReceiverHead", field: "StationReading.ThiesLPMSecondary.PanHeatingReceiverHead", headerName: "Pan heating receiver head (mA)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.AmbientTemperature", field: "StationReading.ThiesLPMSecondary.AmbientTemperature", headerName: "Ambient temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.HeatingSupplyVoltage", field: "StationReading.ThiesLPMSecondary.HeatingSupplyVoltage", headerName: "Heating supply voltage (0.1 V)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.HeatingHousingCurrent", field: "StationReading.ThiesLPMSecondary.HeatingHousingCurrent", headerName: "Heating housing current (mA)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.HeatingHeadsCurrent", field: "StationReading.ThiesLPMSecondary.HeatingHeadsCurrent", headerName: "Heating heads current (mA)", width: 100, filterType: "float" },
                    { colId: "StationReading.ThiesLPMSecondary.HeatingCarriersCurrent", field: "StationReading.ThiesLPMSecondary.HeatingCarriersCurrent", headerName: "Heating carriers current (mA)", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'OTT Parsivel 2 secondary',
                children: [
                    { colId: "StationReading.OTTParsivel2Secondary.ReadingStartDate", field: "StationReading.OTTParsivel2Secondary.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.OTTParsivel2Secondary.ReadingStartTime", field: "StationReading.OTTParsivel2Secondary.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.OTTParsivel2Secondary.ReadingEndDate", field: "StationReading.OTTParsivel2Secondary.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.OTTParsivel2Secondary.ReadingEndTime", field: "StationReading.OTTParsivel2Secondary.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.OTTParsivel2Secondary.ConnectionIP", field: "StationReading.OTTParsivel2Secondary.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.OTTParsivel2Secondary.SensorStatus", field: "StationReading.OTTParsivel2Secondary.SensorStatus", headerName: "Sensor status", width: 100 },
                    { colId: "StationReading.OTTParsivel2Secondary.WMOCode", field: "StationReading.OTTParsivel2Secondary.WMOCode", headerName: "WMO", width: 100, filterType: "integer" },
                    { colId: "StationReading.OTTParsivel2Secondary.RainIntensity", field: "StationReading.OTTParsivel2Secondary.RainIntensity", headerName: "Rain intensity (mm/h)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2Secondary.MORVisibility", field: "StationReading.OTTParsivel2Secondary.MORVisibility", headerName: "MOR visibility (m)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2Secondary.InternalTemperature", field: "StationReading.OTTParsivel2Secondary.InternalTemperature", headerName: "Internal temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2Secondary.ParticleCount", field: "StationReading.OTTParsivel2Secondary.ParticleCount", headerName: "Particle count (validated)", width: 100, filterType: "integer" },
                    { colId: "StationReading.OTTParsivel2Secondary.RainAmount", field: "StationReading.OTTParsivel2Secondary.RainAmount", headerName: "Rain amount (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2Secondary.RadarReflectivity", field: "StationReading.OTTParsivel2Secondary.RadarReflectivity", headerName: "Radar reflectivity", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2Secondary.KineticEnergy", field: "StationReading.OTTParsivel2Secondary.KineticEnergy", headerName: "Kinetic energy (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2Secondary.SignalAmplitude", field: "StationReading.OTTParsivel2Secondary.SignalAmplitude", headerName: "Signal amplitude (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2Secondary.Error", field: "StationReading.OTTParsivel2Secondary.Error", headerName: "Error", width: 100 },
                    { colId: "StationReading.OTTParsivel2Secondary.MetarWeatherCode", field: "StationReading.OTTParsivel2Secondary.MetarWeatherCode", headerName: "METAR code", width: 100 },
                    { colId: "StationReading.OTTParsivel2Secondary.SnowDepthIntensity", field: "StationReading.OTTParsivel2Secondary.SnowDepthIntensity", headerName: "Snow depth intensity (mm/h)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2Secondary.ParticleCountAll", field: "StationReading.OTTParsivel2Secondary.ParticleCountAll", headerName: "Particle count (all)", width: 100, filterType: "integer" },
                    { colId: "StationReading.OTTParsivel2Secondary.SampleInterval", field: "StationReading.OTTParsivel2Secondary.SampleInterval", headerName: "Sample interval (s)", width: 100, filterType: "integer" },
                    { colId: "StationReading.OTTParsivel2Secondary.TemperaturePCB", field: "StationReading.OTTParsivel2Secondary.TemperaturePCB", headerName: "Temperature PCB (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2Secondary.TemperatureRightSensorHead", field: "StationReading.OTTParsivel2Secondary.TemperatureRightSensorHead", headerName: "Temperature right sensor head (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2Secondary.TemperatureLeftSensorHead", field: "StationReading.OTTParsivel2Secondary.TemperatureLeftSensorHead", headerName: "Temperature left sensor head (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2Secondary.PowerSupplyVoltage", field: "StationReading.OTTParsivel2Secondary.PowerSupplyVoltage", headerName: "Power supply voltage (V)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2Secondary.SensorHeadHeatingCurrent", field: "StationReading.OTTParsivel2Secondary.SensorHeadHeatingCurrent", headerName: "Sensor head heating current (A)", width: 100, filterType: "float" },
                    { colId: "StationReading.OTTParsivel2Secondary.FirmwareIOPVersion", field: "StationReading.OTTParsivel2Secondary.FirmwareIOPVersion", headerName: "Firmware IOP version", width: 100 },
                    { colId: "StationReading.OTTParsivel2Secondary.FirmwareDSPVersion", field: "StationReading.OTTParsivel2Secondary.FirmwareDSPVersion", headerName: "Firmware DSP version", width: 100 },
                    { colId: "StationReading.OTTParsivel2Secondary.MeasuringStartDateTimeDate", field: "StationReading.OTTParsivel2Secondary.MeasuringStartDateTime", headerName: "Measuring date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.OTTParsivel2Secondary.MeasuringStartDateTimeTime", field: "StationReading.OTTParsivel2Secondary.MeasuringStartDateTime", headerName: "Measuring time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.OTTParsivel2Secondary.SensorDateTimeDate", field: "StationReading.OTTParsivel2Secondary.SensorDateTime", headerName: "Sensor date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.OTTParsivel2Secondary.SensorDateTimeTime", field: "StationReading.OTTParsivel2Secondary.SensorDateTime", headerName: "Sensor time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.OTTParsivel2Secondary.DebugFieldN", field: "StationReading.OTTParsivel2Secondary.DebugFieldN", headerName: "Field N (d)", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.OTTParsivel2Secondary.DebugFieldN", field: "StationReading.OTTParsivel2Secondary.DebugFieldN", headerName: "Field v (d)", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.OTTParsivel2Secondary.DebugRawData", field: "StationReading.OTTParsivel2Secondary.DebugRawData", headerName: "Raw data", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.OTTParsivel2Secondary.Debug94", field: "StationReading.OTTParsivel2Secondary.Debug94", headerName: "Debug %94", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.OTTParsivel2Secondary.Debug95", field: "StationReading.OTTParsivel2Secondary.Debug95", headerName: "Debug %95", width: 100, sortable: false, searchable: false },
                    { colId: "StationReading.OTTParsivel2Secondary.Debug96", field: "StationReading.OTTParsivel2Secondary.Debug96", headerName: "Debug %96", width: 100, sortable: false, searchable: false },
                ]
            },
            {
                headerName: 'Vaisala PWD22 secondary',
                children: [
                    { colId: "StationReading.VaisalaPWD22Secondary.ReadingStartDate", field: "StationReading.VaisalaPWD22Secondary.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.VaisalaPWD22Secondary.ReadingStartTime", field: "StationReading.VaisalaPWD22Secondary.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.VaisalaPWD22Secondary.ReadingEndDate", field: "StationReading.VaisalaPWD22Secondary.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.VaisalaPWD22Secondary.ReadingEndTime", field: "StationReading.VaisalaPWD22Secondary.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.VaisalaPWD22Secondary.ConnectionIP", field: "StationReading.VaisalaPWD22Secondary.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.VaisalaPWD22Secondary.Alarm1", field: "StationReading.VaisalaPWD22Secondary.Alarm1", headerName: "Alarm 1", width: 80, filterType: "integer" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Alarm2", field: "StationReading.VaisalaPWD22Secondary.Alarm2", headerName: "Alarm 2", width: 80, filterType: "integer" },
                    { colId: "StationReading.VaisalaPWD22Secondary.WMOCode", field: "StationReading.VaisalaPWD22Secondary.WMOCode", headerName: "WMO", width: 100, filterType: "integer" },
                    { colId: "StationReading.VaisalaPWD22Secondary.WMOCode15Minute", field: "StationReading.VaisalaPWD22Secondary.WMOCode15Minute", headerName: "WMO 15 min.", width: 100, filterType: "integer" },
                    { colId: "StationReading.VaisalaPWD22Secondary.NWSWeatherCode", field: "StationReading.VaisalaPWD22Secondary.NWSWeatherCode", headerName: "NSW code", width: 100 },
                    { colId: "StationReading.VaisalaPWD22Secondary.MetarWeatherCode", field: "StationReading.VaisalaPWD22Secondary.MetarWeatherCode", headerName: "METAR code", width: 100 },
                    { colId: "StationReading.VaisalaPWD22Secondary.Intensity", field: "StationReading.VaisalaPWD22Secondary.Intensity", headerName: "Intensity (mm/h)", width: 100, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.CumulativeWater", field: "StationReading.VaisalaPWD22Secondary.CumulativeWater", headerName: "Water precipitation sum (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.CumulativeSnow", field: "StationReading.VaisalaPWD22Secondary.CumulativeSnow", headerName: "Snow precipitation sum (mm)", width: 100, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Temperature", field: "StationReading.VaisalaPWD22Secondary.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Visibility", field: "StationReading.VaisalaPWD22Secondary.Visibility", headerName: "Visibility (m)", width: 100, filterType: "float" },
                ]
            },
            {
                headerName: 'Vaisala PWD22 secondary status',
                children: [
                    { colId: "StationReading.VaisalaPWD22Secondary.Status.SIGNAL", field: "StationReading.VaisalaPWD22Secondary.Status.SIGNAL", headerName: "SIGNAL", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Status.OFFSET", field: "StationReading.VaisalaPWD22Secondary.Status.OFFSET", headerName: "OFFSET", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Status.DRIFT", field: "StationReading.VaisalaPWD22Secondary.Status.DRIFT", headerName: "DRIFT", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Status.REC_BACKSCATTER", field: "StationReading.VaisalaPWD22Secondary.Status.REC_BACKSCATTER", headerName: "REC. BACKSCATTER", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Status.TR_BACKSCATTER", field: "StationReading.VaisalaPWD22Secondary.Status.TR_BACKSCATTER", headerName: "TR. BACKSCATTER", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Status.LEDI", field: "StationReading.VaisalaPWD22Secondary.Status.LEDI", headerName: "LEDI", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Status.AMBL", field: "StationReading.VaisalaPWD22Secondary.Status.AMBL", headerName: "AMBL", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Status.VBB", field: "StationReading.VaisalaPWD22Secondary.Status.VBB", headerName: "VBB", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Status.P12", field: "StationReading.VaisalaPWD22Secondary.Status.P12", headerName: "P12", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Status.M12", field: "StationReading.VaisalaPWD22Secondary.Status.M12", headerName: "M12", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Status.TS", field: "StationReading.VaisalaPWD22Secondary.Status.TS", headerName: "TS", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Status.TB", field: "StationReading.VaisalaPWD22Secondary.Status.TB", headerName: "TB", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Status.TDRD", field: "StationReading.VaisalaPWD22Secondary.Status.TDRD", headerName: "TDRD", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Status.DRD", field: "StationReading.VaisalaPWD22Secondary.Status.DRD", headerName: "DRD", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Status.DRY", field: "StationReading.VaisalaPWD22Secondary.Status.DRY", headerName: "DRY", width: 70, filterType: "float" },
                    { colId: "StationReading.VaisalaPWD22Secondary.Status.HOOD_HEATERS", field: "StationReading.VaisalaPWD22Secondary.Status.HOOD_HEATERS", headerName: "HOOD HEATERS", width: 70 },
                    { colId: "StationReading.VaisalaPWD22Secondary.Status.HARDWARE", field: "StationReading.VaisalaPWD22Secondary.Status.HARDWARE", headerName: "HARDWARE", width: 70 },
                ]
            },
            {
                headerName: 'Lufft WS100',
                children: [
                    { colId: "StationReading.LufftWS100.ReadingStartDate", field: "StationReading.LufftWS100.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.LufftWS100.ReadingStartTime", field: "StationReading.LufftWS100.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.LufftWS100.ReadingEndDate", field: "StationReading.LufftWS100.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.LufftWS100.ReadingEndTime", field: "StationReading.LufftWS100.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.LufftWS100.ConnectionIP", field: "StationReading.LufftWS100.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.LufftWS100.Temperature", field: "StationReading.LufftWS100.Temperature", headerName: "Temperature (°C)", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.DewPoint", field: "StationReading.LufftWS100.DewPoint", headerName: "Dew point (°C)", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.WindChill", field: "StationReading.LufftWS100.WindChill", headerName: "Wind chill (°C)", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.RelativeHumidity", field: "StationReading.LufftWS100.RelativeHumidity", headerName: "Relative humidity (%)", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.RelativeAirPressure", field: "StationReading.LufftWS100.RelativeAirPressure", headerName: "Relative air pressure (hPa)", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.WindSpeed", field: "StationReading.LufftWS100.WindSpeed", headerName: "Wind speed (m/s)", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.WindDirection", field: "StationReading.LufftWS100.WindDirection", headerName: "Wind direction (°)", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationQuantity", field: "StationReading.LufftWS100.PrecipitationQuantity", headerName: "Precipitation quantity (mm)", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationType", field: "StationReading.LufftWS100.PrecipitationType", headerName: "Precipitation type", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationIntensity", field: "StationReading.LufftWS100.PrecipitationIntensity", headerName: "Precipitation intensity (mm/h)", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationTotalParticles", field: "StationReading.LufftWS100.PrecipitationTotalParticles", headerName: "Precipitation total particles", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationTotalDrops", field: "StationReading.LufftWS100.PrecipitationTotalDrops", headerName: "Precipitation total drops", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationDrizzleParticles", field: "StationReading.LufftWS100.PrecipitationDrizzleParticles", headerName: "Precipitation drizzle particles", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationSnowParticles", field: "StationReading.LufftWS100.PrecipitationSnowParticles", headerName: "Precipitation snow particles", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationHailParticles", field: "StationReading.LufftWS100.PrecipitationHailParticles", headerName: "Precipitation hail particles", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationDropClass0", field: "StationReading.LufftWS100.PrecipitationDropClass0", headerName: "Precipitation drop class 0", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationDropClass1", field: "StationReading.LufftWS100.PrecipitationDropClass1", headerName: "Precipitation drop class 1", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationDropClass2", field: "StationReading.LufftWS100.PrecipitationDropClass2", headerName: "Precipitation drop class 2", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationDropClass3", field: "StationReading.LufftWS100.PrecipitationDropClass3", headerName: "Precipitation drop class 3", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationDropClass4", field: "StationReading.LufftWS100.PrecipitationDropClass4", headerName: "Precipitation drop class 4", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationDropClass5", field: "StationReading.LufftWS100.PrecipitationDropClass5", headerName: "Precipitation drop class 5", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationDropClass6", field: "StationReading.LufftWS100.PrecipitationDropClass6", headerName: "Precipitation drop class 6", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationDropClass7", field: "StationReading.LufftWS100.PrecipitationDropClass7", headerName: "Precipitation drop class 7", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationDropClass8", field: "StationReading.LufftWS100.PrecipitationDropClass8", headerName: "Precipitation drop class 8", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationDropClass9", field: "StationReading.LufftWS100.PrecipitationDropClass9", headerName: "Precipitation drop class 9", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationDropClass10", field: "StationReading.LufftWS100.PrecipitationDropClass10", headerName: "Precipitation drop class A", width: 90, filterType: "float" },
                    { colId: "StationReading.LufftWS100.PrecipitationDropClass11", field: "StationReading.LufftWS100.PrecipitationDropClass11", headerName: "Precipitation drop class B", width: 90, filterType: "float" },
                ]
            },
            {
                headerName: 'MiniOFS',
                children: [
                    { colId: "StationReading.MiniOFS.ReadingStartDate", field: "StationReading.MiniOFS.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.MiniOFS.ReadingStartTime", field: "StationReading.MiniOFS.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.MiniOFS.ReadingEndDate", field: "StationReading.MiniOFS.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.MiniOFS.ReadingEndTime", field: "StationReading.MiniOFS.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.MiniOFS.ConnectionIP", field: "StationReading.MiniOFS.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.MiniOFS.AmbientSolarLightLevel", field: "StationReading.MiniOFS.AmbientSolarLightLevel", headerName: "Ambient solar light level", width: 120, filterType: "float" },
                    { colId: "StationReading.MiniOFS.ExtinctionCoefficient", field: "StationReading.MiniOFS.ExtinctionCoefficient", headerName: "Extinction coefficient", width: 120, filterType: "float" },
                    { colId: "StationReading.MiniOFS.Visibility", field: "StationReading.MiniOFS.Visibility", headerName: "Visibility (m)", width: 120, filterType: "float" },
                ]
            },
            {
                headerName: 'Power relay module',
                children: [
                    { colId: "StationReading.PowerRelayModule.Port3_1", field: "StationReading.PowerRelayModule.Port3_1", headerName: "P3.1", width: 40, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                    { colId: "StationReading.PowerRelayModule.Port3_2", field: "StationReading.PowerRelayModule.Port3_2", headerName: "P3.2", width: 40, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                    { colId: "StationReading.PowerRelayModule.Port3_3", field: "StationReading.PowerRelayModule.Port3_3", headerName: "P3.3", width: 40, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                    { colId: "StationReading.PowerRelayModule.Port3_4", field: "StationReading.PowerRelayModule.Port3_4", headerName: "P3.4", width: 40, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                    { colId: "StationReading.PowerRelayModule.Port3_5", field: "StationReading.PowerRelayModule.Port3_5", headerName: "P3.5", width: 40, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                    { colId: "StationReading.PowerRelayModule.Port3_6", field: "StationReading.PowerRelayModule.Port3_6", headerName: "P3.6", width: 40, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                    { colId: "StationReading.PowerRelayModule.Port3_7", field: "StationReading.PowerRelayModule.Port3_7", headerName: "P3.7", width: 40, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                    { colId: "StationReading.PowerRelayModule.Port3_8", field: "StationReading.PowerRelayModule.Port3_8", headerName: "P3.8", width: 40, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },

                    { colId: "StationReading.PowerRelayModule.Port5_1", field: "StationReading.PowerRelayModule.Port5_1", headerName: "P5.1 on", width: 65, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                    { colId: "StationReading.PowerRelayModule.Port5_2", field: "StationReading.PowerRelayModule.Port5_2", headerName: "P5.2 on", width: 65, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                    { colId: "StationReading.PowerRelayModule.Port5_3", field: "StationReading.PowerRelayModule.Port5_3", headerName: "P5.3 on", width: 65, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                    { colId: "StationReading.PowerRelayModule.Port5_4", field: "StationReading.PowerRelayModule.Port5_4", headerName: "P5.4 on", width: 65, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                    { colId: "StationReading.PowerRelayModule.Port5_5", field: "StationReading.PowerRelayModule.Port5_5", headerName: "P5.5 on", width: 65, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                    { colId: "StationReading.PowerRelayModule.Port5_6", field: "StationReading.PowerRelayModule.Port5_6", headerName: "P5.6 on", width: 65, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                    { colId: "StationReading.PowerRelayModule.Port5_7", field: "StationReading.PowerRelayModule.Port5_7", headerName: "P5.7 on", width: 65, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                    { colId: "StationReading.PowerRelayModule.Port5_8", field: "StationReading.PowerRelayModule.Port5_8", headerName: "P5.8 on", width: 65, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },

                    { colId: "StationReading.PowerRelayModule.Port6_1", field: "StationReading.PowerRelayModule.Port6_1", headerName: "ADC.1 (V)", width: 65, filterType: "float" },
                    { colId: "StationReading.PowerRelayModule.Port6_2", field: "StationReading.PowerRelayModule.Port6_2", headerName: "ADC.2 (V)", width: 65, filterType: "float" },
                    { colId: "StationReading.PowerRelayModule.Port6_3", field: "StationReading.PowerRelayModule.Port6_3", headerName: "ADC.3 (V)", width: 65, filterType: "float" },
                    { colId: "StationReading.PowerRelayModule.Port6_4", field: "StationReading.PowerRelayModule.Port6_4", headerName: "ADC.4 (V)", width: 65, filterType: "float" },
                    { colId: "StationReading.PowerRelayModule.Port6_5", field: "StationReading.PowerRelayModule.Port6_5", headerName: "ADC.5 (V)", width: 65, filterType: "float" },
                    { colId: "StationReading.PowerRelayModule.Port6_6", field: "StationReading.PowerRelayModule.Port6_6", headerName: "ADC.6 (V)", width: 65, filterType: "float" },
                    { colId: "StationReading.PowerRelayModule.Port6_7", field: "StationReading.PowerRelayModule.Port6_7", headerName: "ADC.7 (V)", width: 65, filterType: "float" },
                    { colId: "StationReading.PowerRelayModule.Port6_8", field: "StationReading.PowerRelayModule.Port6_8", headerName: "ADC.8 (V)", width: 65, filterType: "float" },

                    { colId: "StationReading.PowerRelayModule.ACOK", field: "StationReading.PowerRelayModule.ACOK", headerName: "AC OK", width: 50, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                    { colId: "StationReading.PowerRelayModule.DCOK", field: "StationReading.PowerRelayModule.DCOK", headerName: "DC OK", width: 50, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                    { colId: "StationReading.PowerRelayModule.BattLow", field: "StationReading.PowerRelayModule.BattLow", headerName: "Batt Low", width: 70, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                ]
            },
            {
                headerName: 'Thies FZRA',
                children: [
                    { colId: "StationReading.ThiesFZRA.ReadingStartDate", field: "StationReading.ThiesFZRA.ReadingStart", headerName: "Reading start date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.ThiesFZRA.ReadingStartTime", field: "StationReading.ThiesFZRA.ReadingStart", headerName: "Reading start time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.ThiesFZRA.ReadingEndDate", field: "StationReading.ThiesFZRA.ReadingEnd", headerName: "Reading end date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                    { colId: "StationReading.ThiesFZRA.ReadingEndTime", field: "StationReading.ThiesFZRA.ReadingEnd", headerName: "Reading end time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                    { colId: "StationReading.ThiesFZRA.ConnectionIP", field: "StationReading.ThiesFZRA.ConnectionIP", headerName: "IP", width: 115 },
                    { colId: "StationReading.ThiesFZRA.StatusFreezingPrecipitation", field: "StationReading.ThiesFZRA.StatusFreezingPrecipitation", headerName: "Status freezing precipitation", width: 80 },
                    { colId: "StationReading.ThiesFZRA.QualityIndexA", field: "StationReading.ThiesFZRA.QualityIndexA", headerName: "Quality index A", width: 80 },
                    { colId: "StationReading.ThiesFZRA.QualityIndexB", field: "StationReading.ThiesFZRA.QualityIndexB", headerName: "Quality index B", width: 80 },
                    { colId: "StationReading.ThiesFZRA.QualityIndexC", field: "StationReading.ThiesFZRA.QualityIndexC", headerName: "Quality index C", width: 80 },
                    { colId: "StationReading.ThiesFZRA.QualityIndexD", field: "StationReading.ThiesFZRA.QualityIndexD", headerName: "Quality index D", width: 80 },
                    { colId: "StationReading.ThiesFZRA.QualityIndexE", field: "StationReading.ThiesFZRA.QualityIndexE", headerName: "Quality index E", width: 80 },
                    { colId: "StationReading.ThiesFZRA.QualityIndexF", field: "StationReading.ThiesFZRA.QualityIndexF", headerName: "Quality index F", width: 80 },
                    { colId: "StationReading.ThiesFZRA.ElectricalStatus", field: "StationReading.ThiesFZRA.ElectricalStatus", headerName: "Electrical status", width: 80 },
                    { colId: "StationReading.ThiesFZRA.StatusHeating", field: "StationReading.ThiesFZRA.StatusHeating", headerName: "Status heating", width: 80 },
                    { colId: "StationReading.ThiesFZRA.ErrorCode1", field: "StationReading.ThiesFZRA.ErrorCode1", headerName: "Error code 1", width: 80 },
                    { colId: "StationReading.ThiesFZRA.ErrorCode2", field: "StationReading.ThiesFZRA.ErrorCode2", headerName: "Error code 2", width: 80 },
                ]
            },
        ]
    }
}
