import { Component, OnInit } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { StorageService, SwxModule } from "swx.front-end-lib";
import { UntypedFormGroup, FormsModule } from "@angular/forms";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import moment from "moment";
import { NgIf, NgFor, JsonPipe, KeyValuePipe } from "@angular/common";

interface ClientApiRequest {
    clientApiUrl: string;
    fluidId: any;
    hotResponseId: any;
    startTime: any;
    equipmentTypeCode: any;
    count: number;
    interval: number;
    wingMaterialTypes: any;
    callSign: string;
    tailNumber: string;
    airportCode: any;
    version: string;
    publicHash: any;
    privateHash: any;
    clientCode: any;
}

@Component({
    templateUrl: 'ClientApi.component.html',
    standalone: true,
    imports: [NgIf, SwxModule, FormsModule, NgFor, JsonPipe, KeyValuePipe]
})
export class ClientApiComponent implements OnInit {
    private readonly storageKey = 'debugClientApiRequest';
    vm;
    request: ClientApiRequest;
    datePickerOptions;
    result: any;

    constructor(private api: ApiService, private storage: StorageService, private http: HttpClient) {
    }

    ngOnInit() {
        this.request = this.storage.load(this.storageKey) || {
            clientApiUrl: 'https://api.surewx.com/',
            version: 'hotv1',
            count: 1,
            interval: 1,
            equipmentTypeCode: '',
            tailNumber: '',
            callSign: '',
            wingMaterialTypes: ''
        };
        this.request.startTime = null;

        this.api.WingMaterialType.query().$promise.then(wingMaterialType => {
            let maxDateTime = new Date();
            maxDateTime.setDate(maxDateTime.getDate() + 1);
            this.vm = {
                wingMaterialTypes: wingMaterialType,
                datePickerOptions: {
                    showTime: true,
                    timezone: 0,
                    showTimezone: true,
                    maxDateTime: maxDateTime,
                    dateFormat: 'yy-mm-dd',
                    timeFormat: 'HH:mm:ss',
                    useUtc: true,
                }
            }
        })
    }

    isWingMaterialTypeEnabled(option) {
        var selected = (this.request.wingMaterialTypes || '').split(', ');
        return selected.indexOf(option) > -1;
    };

    toggleWingMaterialType(option, form: UntypedFormGroup) {
        let selected = this.request.wingMaterialTypes ? this.request.wingMaterialTypes.split(', ') : [];
        const index = selected.indexOf(option);
        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push(option);
        }
        this.request.wingMaterialTypes = selected.join(', ');
        form.markAsDirty();
    };

    send(version: string) {
        if (version === 'hotv1') {
            this.sendV1(this.request).then(result => this.result = result);
        }
        else if (version === 'hotv1.5') {
            this.sendV15(this.request).then(result => this.result = result);
        }
        else if (version === 'hotv2') {
            this.sendV2(this.request).then(result => this.result = result);
        }
        else if (version === 'mobilehotv2') {
            this.sendMobileHot2(this.request).then(result => this.result = result);
        }
        else if (version === 'mobilehotv3') {
            this.sendMobileHotV3(this.request).then(result => this.result = result);
        }
        else if (version === 'deicingreport') {
            this.sendDeicingReport(this.request).then(result => this.result = result);
        }
        else if (version === 'weather') {
            this.sendWeather(this.request).then(result => this.result = result);
        }
    }

    async sendV1(request: ClientApiRequest): Promise<any> {
        const content = JSON.stringify({'dummy':'dummy'});
        const signature = await this.HmacSHA256(content, request.privateHash);
        const url = `${request.clientApiUrl}/v1/hot/${request.clientCode}/${request.airportCode}`;
        return this.http.post(url, content, {
            headers: new HttpHeaders()
                .set('X-Hash', signature)
                .set('X-Public', request.publicHash)
                .set('Content-Type', 'text/plain')
        }).toPromise().then((result:any) => {
            if (typeof result === 'string' && result.indexOf('<pre>') === 0) {
                result = result.substring(5, result.length - 7);
            }

            return result;
        });
    }

    async sendV15(request: ClientApiRequest) {
        let url = `${request.clientCode}/${request.airportCode}`;
        if (request.equipmentTypeCode) url = `${url}/${request.equipmentTypeCode}`;
        if (request.tailNumber) url = `${url}/${request.tailNumber}`;

        const signature = await this.HmacSHA256(url, request.privateHash);
        url = `${request.clientApiUrl}/v1/hot/${url}`;

        return this.http.get(url, {
            headers: new HttpHeaders()
                .set('X-Hash', signature)
                .set('X-Public', request.publicHash),
            params: new HttpParams()
                .set('count', request.count)
                .set('interval', request.interval)
        }).toPromise().then(result => {
            return result;
        });
    }

    async sendV2(request: ClientApiRequest) {
        let url = `${request.clientCode}/${request.airportCode}`;
        if (request.equipmentTypeCode) url = `${url}/${request.equipmentTypeCode}`;
        if (request.tailNumber) url = `${url}/${request.tailNumber}`;

        const signature = await this.HmacSHA256(url, request.privateHash);
        const realUrl = `${request.clientApiUrl}/v2/hot`;

        return this.http.get(realUrl, {
            headers: new HttpHeaders()
                .set('X-Hash', signature)
                .set('X-Public', request.publicHash),
            params: new HttpParams()
                .set('clientCode', request.clientCode)
                .set('airportCode', request.airportCode)
                .set('equipmentTypeCode', request.equipmentTypeCode || '')
                .set('tailNumber', request.tailNumber || '')
                .set('callSign', request.callSign || '')
                .set('wingMaterialTypes', request.wingMaterialTypes || '')
                .set('count', request.count)
                .set('interval', request.interval)
        }).toPromise().then(result => {
            return result;
        });
    }

    async sendMobileHot2(request: ClientApiRequest) {
        let url = `${request.clientCode}/${request.airportCode}`;
        if (request.equipmentTypeCode) url = `${url}/${request.equipmentTypeCode}`;
        if (request.tailNumber) url = `${url}/${request.tailNumber}`;

        const signature = await this.HmacSHA256(url, request.privateHash);
        url = `${request.clientApiUrl}/mobile/hot`;

        return this.http.post(url, {
            clientCode: request.clientCode,
            airportCode: request.airportCode,
            equipmentTypeCode: request.equipmentTypeCode,
            tailNumber: request.tailNumber,
            callSign: request.callSign,
            wingMaterialTypes: request.wingMaterialTypes,
            count: request.count,
            interval: request.interval,
        }, {
            headers: new HttpHeaders()
                .set('X-Hash', signature)
                .set('X-Public', request.publicHash)
        }).toPromise().then(result => {
            return result;
        });
    }

    async sendMobileHotV3(request: ClientApiRequest) {
        let url = `${request.clientCode}/${request.airportCode}`;
        if (request.equipmentTypeCode) url = `${url}/${request.equipmentTypeCode}`;
        if (request.tailNumber) url = `${url}/${request.tailNumber}`;

        const signature = await this.HmacSHA256(url, request.privateHash);

        return this.http.post(`${request.clientApiUrl}/v3/mobilehot`, {
            clientCode: request.clientCode,
            airportCode: request.airportCode,
            equipmentTypeCode: request.equipmentTypeCode,
            tailNumber: request.tailNumber,
            callSign: request.callSign
        }, {
            headers: new HttpHeaders()
                .set('X-Hash', signature)
                .set('X-Public', request.publicHash)
        }).toPromise().then(result => {
            return result;
        });
    }

    async sendDeicingReport(request: ClientApiRequest) {
        const signature = await this.HmacSHA256(request.clientCode, request.privateHash);

        return this.http.post(`${request.clientApiUrl}/deicingReport`, {
            clientCode: request.clientCode,
            info: {
                StartTime: moment.utc(request.startTime).toDate().toISOString(),
                HotResponseId: request.hotResponseId,
                FluidId: request.fluidId,
            }
        }, {
            headers: new HttpHeaders()
                .set('X-Hash', signature)
                .set('X-Public', request.publicHash)
        }).toPromise().then(result => {
            return result;
        });
    }

    async sendWeather(request: ClientApiRequest) {
        const authHash = btoa(`${request.clientCode}:${request.privateHash}`);
        return this.http.get(`${request.clientApiUrl}/weather/${request.airportCode}`, {
            headers: new HttpHeaders().set('Authorization', `Basic ${authHash}`)
        }).toPromise().then(result => {
            return result;
        });
    }

    async HmacSHA256(plainText: string, secret: string) {
        let enc = new TextEncoder();
        let algorithm = { name: 'HMAC', hash: 'SHA-256' };
        let key = await crypto.subtle.importKey('raw', enc.encode(secret), algorithm, false, ['sign', 'verify']);
        let signature = await crypto.subtle.sign(algorithm.name, key, enc.encode(plainText));
        let digest = btoa(String.fromCharCode(...new Uint8Array(signature)));

        return digest;
    }
}

