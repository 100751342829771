import {AfterContentInit, Component, contentChild} from "@angular/core";
import {DialogRef} from "@angular/cdk/dialog";
import {DialogHeaderComponent} from "./dialogHeader/DialogHeader.component";
import {DialogContentComponent} from "./dialogContent/DialogContent.component";

@Component({
    standalone: true,
    selector: "swx-dialog",
    templateUrl: "./Dialog.component.html",
    styleUrl: "./Dialog.component.css"
})
export class DialogComponent implements AfterContentInit  {
    content = contentChild.required(DialogContentComponent)
    header = contentChild(DialogHeaderComponent)
    
    constructor(private dialogRef: DialogRef) {
        
    }
    
    ngAfterContentInit() {
        this.content()
        this.header()?.close.subscribe(() => this.close())
    }

    close() {
        this.dialogRef.close()
    }
}
