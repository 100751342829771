import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import { DialogService, IAgGridColumnDef, NotificationService, StorageService, TranslatePipe, ValueFormatters, SwxModule, GridModule } from "swx.front-end-lib";
import {StationDataRawDataDialogComponent} from "../stationDataGen2/StationDataRawDataDialog.component";

import moment from 'moment';
import {RootScope} from "../shared/RootScope.service";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";
import {param} from "jquery";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './EngineCoverAlert.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, GridModule]
})
export class EngineCoverAlertComponent implements OnInit {
    @ViewChild('grid') grid;
    storeKey = 'engineCoverAlertQuery';
    query: any;
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    resource = this.api.EngineCoverAlert;
    exportResourcePost = this.api.EngineCoverAlertExport;
    engineCoverAlertType = this.api.EngineCoverAlertType.query();

    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private storage: StorageService,
                private formatters: ValueFormatters,
                private dialog: DialogService,
                private rootScope: RootScope,
                private ticketTrackingSystem: TicketTrackingSystemService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
    }

    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery) {
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    }

    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }


    columnDefs: IAgGridColumnDef[] = [
        { colId: "Id", field: "Id", headerName: "#", width: 90, pinned: 'left' },
        { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 100, pinned: 'left', valueFormatter: this.formatters.utcDateFormatter() },
        { colId: "CreatedTime", field: "CreatedDate", headerName: "Created time", width: 70, excelIgnore: true, pinned: 'left', valueFormatter: this.formatters.utcTimeFormatter() },
        { colId: "Type", field: "Type", headerName: "Type", width: 100, pinned: 'left',
            cellClassRules: {
                'red-alert': params => params.value === 'RedEntry',
                'orange-alert': params => params.value === 'OrangeEntry' || params.value === 'ForcedOrangeEntry',
            },
            valueFormatter: (params) => params.value && typeof this.engineCoverAlertType[params.value] !== 'undefined' ? this.engineCoverAlertType[params.value] : params.value,
            filterType: "enum", source: "EngineCoverAlertType" },
        { colId: "ClientName", field: "ClientName", headerName: "Client", width: 140, pinned: 'left' },
        { colId: "AirportICAOCode", field: "AirportICAOCode", headerName: "Airport", width: 80, pinned: 'left' },
        { colId: "StationName", field: "StationName", headerName: "Station", width: 100 },
        { colId: "LastUpdateDate", field: "LastUpdate", headerName: "Last update date", width: 100, valueFormatter: this.formatters.utcDateFormatter() },
        { colId: "LastUpdateTime", field: "LastUpdate", headerName: "Last update time", width: 70, excelIgnore: true, valueFormatter: this.formatters.utcTimeFormatter() },
        { colId: "CurrentWindSpeed", field: "CurrentWindSpeed", headerName: "Current wind speed (m/s)",
            cellClassRules: {
                'red-alert': params => params.data?.Type === 'RedEntry',
            },
            width: 100, filterType: "float" },
        { colId: "CurrentLwe", field: "CurrentLwe", headerName: "Current LWE (g/dm²/h)",
            cellClassRules: {
                'red-alert': params => params.data?.Type === 'RedEntry',
            },
            width: 100, filterType: "float" },
        { colId: "CurrentTemperature", field: "CurrentTemperature", headerName: "Current temperature (°C)",width: 120 },
        { colId: "CurrentWeatherType", field: "CurrentWeatherType", headerName: "Current weather type", width: 120, searchable: false },
        { colId: "NoaaRapWindSpeed", field: "NoaaRapWindSpeed", headerName: "NOAA RAP 240 minute forecast average wind speed (m/s)",
            cellClassRules: {
                'orange-alert': params => params.data?.Type === 'OrangeEntry',
            },
            width: 120, filterType: "float" },
        { colId: "LweForecastExpectation", field: "LweForecastExpectation", headerName: "Nowcast LWE12 expectation (%)",
            cellClassRules: {
                'orange-alert': params => params.data?.Type === 'OrangeEntry',
            },
            width: 100, filterType: "float" },
        { colId: "Body", field: "Body", headerName: "Email", cellTemplate: "<a (click)='parent.showEmail(params.data)' class='mdi mdi-eye'></a>", width: 50 },
        { colId: "Emails", field: "Emails", headerName: "Recipients", minWidth: 300, searchable: false, valueFormatter: (params) => this.formatRecipients(params.value) },
    ];
    
    showEmail(data) {
        var bodyIndex = data.Body?.lastIndexOf("<body>");
        const body = bodyIndex > -1 ? (data.Body?.substring(bodyIndex + 6, data.Body.lastIndexOf("</body>"))) : data.Body;

        const recipients = "<table class=\"table\">"
            + "<tr><th>Recipient</th><th>Status</th><th>Status updated on</th></tr>"
            + data.Emails.map(e => "<tr><td>" + e.Name + " &lt;" + e.Email + "&gt;</td><td style=\"" + (e.AmazonSESStatus === 'Bounce' ? 'background-color: #ffaaaa;' : '') + "\">" + (e.AmazonSESStatus || '') + "</td><td>" + (e.AmazonSESStatusUpdateDate ? moment.utc(e.AmazonSESStatusUpdateDate).format('YYYY-MM-DD HH:mm') : "") + "</td></tr>").join('')
            + "</table><br />";

        this.dialog.show(this.viewContainerRef,StationDataRawDataDialogComponent, {
            html: recipients + body,
        }, {
            title: data.Subject,
            width: 1030,
            height: 680,
            modal: true,
        })
    }

    formatRecipients(emails) {
        return emails == null ? '' : emails.map(e => e.Name + " <" + e.Email + ">").join(', ');
    }


    manualExitClick() {
        if (typeof this.query.ClientId === 'undefined'
            || this.query.ClientId.length !== 1
            || (typeof this.query.AirportId === 'undefined')
            || this.query.AirportId.length !== 1) {
            alert("Please select one client and one airport");
            return;
        }
        
        if (this.rootScope.currentUser.TicketTrackingRequired) {
            this.ticketTrackingSystem.getComment(this.viewContainerRef)
                .then(result => {
                    this.manualExit({...result, ...this.query});
                });
        } else {
            this.manualExit(this.query);
        }
    }
    
    manualExit(query) {
        this.api.EngineCoverManualReset.post(query).then(result => {
                if (result.Message) {
                    alert(this.translate.transform(result.Message));
                } else {
                    this.notification.show(this.translate.transform('Engine cover alert monitoring has been reset for this client-airport. If the last email was an entry, then an exit email has been sent.'));
                }
            });
    }
} 
