import {Component} from "@angular/core";

@Component({
    selector: 'swx-dialog-content',
    standalone: true,
    templateUrl: "./DialogContent.component.html",
    styleUrl: "./DialogContent.component.css"
})
export class DialogContentComponent {

}
