import {Component} from "@angular/core";
import { NoaaWpcAlertEmailComponent } from "./NoaaWpcAlertEmail.component";
import { NoaaWpcAlertListComponent } from "./NoaaWpcAlertList.component";
import { SwxModule } from "swx.front-end-lib";

@Component({
    selector: 'swx-noaa-wpc-alert',
    templateUrl: './NoaaWpcAlert.component.html',
    standalone: true,
    imports: [SwxModule, NoaaWpcAlertListComponent, NoaaWpcAlertEmailComponent]
})
export class NoaaWpcAlertComponent {
    query: any = {};
    
    onQueryChange(query) {
        this.query = {...query};
    }
} 
