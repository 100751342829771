import {Component, output} from "@angular/core";

@Component({
    selector: 'swx-dialog-footer',
    standalone: true,
    templateUrl: "./DialogFooter.component.html",
    styleUrl: "./DialogFooter.component.css"
})
export class DialogFooterComponent {
    
}
