import { Component } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { NgIf, NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SwxModule } from "swx.front-end-lib";

@Component({
    templateUrl: 'XmlRequest.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, NgFor]
})
export class XmlRequestComponent {
    query = { message: ''};
    result: any;

    constructor(private api: ApiService) {
    }

    send() {
        this.api.XmlRequest.post(this.query).then(result => {
            this.result = result;
        });
    }
}

