import {Component, Input} from "@angular/core";
import {RootScope} from "../shared/RootScope.service";
import {ApiService} from "../shared/Api.service";
import { SwxModule } from "swx.front-end-lib";
import { NgFor } from "@angular/common";

@Component({
    templateUrl: 'WeatherOverrideDeicingReportsDialog.component.html',
    standalone: true,
    imports: [NgFor, SwxModule]
})
export class WeatherOverrideDeicingReportsDialogComponent {
    dateTimeFormat = this.root.dateTimeFormat
    metarWeatherTypes = this.api.MetarWeatherType.query();

    constructor(
        private root: RootScope,
        private api: ApiService,
    ) {
    }
    
    @Input() deicingReports;
}
