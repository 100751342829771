import {Component, output} from "@angular/core";

@Component({
    selector: 'swx-dialog-header',
    standalone: true,
    templateUrl: "./DialogHeader.component.html",
    styleUrl: "./DialogHeader.component.css"
})
export class DialogHeaderComponent {
    close = output();
    
    onClose() {
        this.close.emit();
    }
}
