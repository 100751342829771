import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { ChangesService, ConfigGridComponent, FileDownloadService, IAgGridColGroupDef, OrderByPipe, SwxModule, GridModule } from "swx.front-end-lib";
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'WeatherTypeMappingProfileDetail.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, GridModule, HasPermissionPipe]
})
export class WeatherTypeMappingProfileDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', {static: true}) ngForm;
    @ViewChild('mappingsGrid', { static: true }) mappingsGrid: ConfigGridComponent;
    @ViewChild('countsGrid', { static: true }) countsGrid: ConfigGridComponent;
    @ViewChild('combinationsGrid', { static: true }) combinationsGrid: ConfigGridComponent;
    tab;
    weatherTypes = this.api.WeatherType.query();
    weatherTypeNgs = this.api.WeatherTypeNg.query();
    weatherTypeNgFlags = this.api.WeatherTypeNgFlags.query();
    mappingsColumnDefs: IAgGridColGroupDef[] = [{
        children: [
            { colId: "WMOCode", field: "WMOCode", headerName: "WMO Code", width: 70, cellTemplate: `<input type="number" step="1" min="0" max="99" [(ngModel)]="params.data.WMOCode" [name]="'WeatherTypeMapping[' + params.node.id + '].WMOCode'" style="width: 60px;" required />` },
            { colId: "MinTemp", field: "MinTemp", headerName: "Min temp (°C)", width: 70, cellTemplate: `<input type="number" step="any" [(ngModel)]="params.data.MinTemp" [name]="'WeatherTypeMapping[' + params.node.id + '].MinTemp'" style="width: 60px;" required />` },
            { colId: "WeatherType", field: "WeatherType", headerName: "Weather type", width: 200, cellClass: "select", cellTemplate: `
                <select style="width: 190px;" [(ngModel)]="params.data.WeatherType" [name]="'WeatherTypeMapping[' + params.node.id + '].WeatherType'" required>
                    <option *ngFor="let option of parent.weatherTypes|keys" [ngValue]="option">
                        {{parent.weatherTypes[option]}}
                    </option>
                </select>` },
            { colId: "WeatherTypeNg", field: "WeatherTypeNg", headerName: "Weather type NG", width: 200, cellClass: "select", cellTemplate: `
                <select style="width: 190px;" [(ngModel)]="params.data.WeatherTypeNg" [name]="'WeatherTypeMapping[' + params.node.id + '].WeatherTypeNg'" required>
                    <option *ngFor="let option of parent.weatherTypeNgs|keys" [ngValue]="option">
                        {{parent.weatherTypeNgs[option]}}
                    </option>
                </select>` },
            { colId: "Actions", field: "Id", headerName: "", width: 80, pinned: 'right', excelIgnore: true, cellClass: "actions", cellTemplate: `<a *ngIf="parent.hasPermissionService.hasPermission('ConfigDeleteWMOCodeMappings')" (click)="parent.removeMapping(params.data)" class="mdi mdi-delete" title="{{'Remove'|translate}}"></a>` },
        ],
    }];
    countsColumnDefs: IAgGridColGroupDef[] = [{
        children: [
            { colId: "WeatherTypeNg", field: "WeatherTypeNg", headerName: "Weather type NG", width: 200, cellClass: "select", cellTemplate: `
                <select style="width: 190px;" [(ngModel)]="params.data.WeatherTypeNg" [name]="'WeatherTypeNgCount[' + params.node.id + '].WeatherTypeNg'" required>
                    <option *ngFor="let option of parent.weatherTypeNgs|keys" [ngValue]="option">
                        {{parent.weatherTypeNgs[option]}}
                    </option>
                </select>` },
            { colId: "MinimumCount", field: "MinimumCount", headerName: "Minimum count", width: 70, cellTemplate: `<input type="number" step="1" min="0" [(ngModel)]="params.data.MinimumCount" [name]="'WeatherTypeNgCount[' + params.node.id + '].MinimumCount'" style="width: 60px;"/>` },
            { colId: "Actions", field: "Id", headerName: "", width: 80, pinned: 'right', excelIgnore: true, cellTemplate: `<a *ngIf="parent.hasPermissionService.hasPermission('ConfigDeleteWMOCodeMappings')" (click)="parent.removeCount(params.data)" class="mdi mdi-delete" title="{{'Remove'|translate}}"></a>` },
        ],
    }];
    combinationsColumnDefs: IAgGridColGroupDef[] = [{
        children: [
            { colId: "WeatherTypes", field: "WeatherTypes", headerName: "Weather type NG combination", width: 310, cellClass: "select", cellTemplate: `
                <multiselect-enum [enum]="parent.weatherTypeNgFlags" width="300" required [(ngModel)]="params.data.WeatherTypes" [name]="'WeatherTypeNgCombination[' + params.node.id + '].WeatherTypes'"></multiselect-enum>` },
            { colId: "MinTemp", field: "MinTemp", headerName: "Min temp (°C)", width: 70, cellTemplate: `<input type="number" step="any" [(ngModel)]="params.data.MinTemp" [name]="'WeatherTypeNgCombination[' + params.node.id + '].MinTemp'" style="width: 60px;" required />` },
            { colId: "FinalWeatherType", field: "FinalWeatherType", headerName: "Final instant weather", width: 200, cellClass: "select", cellTemplate: `
                <select style="width: 190px;" [(ngModel)]="params.data.FinalWeatherType" [name]="'WeatherTypeNgCombination[' + params.node.id + '].FinalWeatherType'" required>
                    <option *ngFor="let option of parent.weatherTypeNgs|keys" [ngValue]="option">
                        {{parent.weatherTypeNgs[option]}}
                    </option>
                </select>` },
            { colId: "Actions", field: "Id", headerName: "", width: 80, pinned: 'right', excelIgnore: true, cellTemplate: `<a *ngIf="parent.hasPermissionService.hasPermission('ConfigDeleteWMOCodeMappings')" (click)="parent.removeCombination(params.data)" class="mdi mdi-delete" title="{{'Remove'|translate}}"></a>` },
        ],
    }];

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        private fileDownloadService: FileDownloadService,
        public orderByPipe: OrderByPipe,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = api.WeatherTypeMappingProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.WeatherTypeMappings) {
                    this.item.WeatherTypeMappings.forEach(related => {
                        delete related.WeatherTypeMappingProfileId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.WeatherTypeMappingProfile.create({
                Active: true,
            });
        } else {
            this.item = api.WeatherTypeMappingProfile.get({ id: id });
        }

        if (this.item.$promise) {
            this.item.$promise.then(item => {
                item.WeatherTypeMappings = this.orderByPipe.transform(item.WeatherTypeMappings, ['WMOCode', 'MinTemp']);
                item.WeatherTypeNgCounts = this.orderByPipe.transform(item.WeatherTypeNgCounts, ['WeatherTypeNg']);
                item.WeatherTypeNgCombination = this.orderByPipe.transform(item.WeatherTypeNgCombination, ['WeatherTypes', 'MinTemp']);
            });
        }
    }

    save() {
        this.item.$promise.then(() => {
            this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
        });
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['WMOCodeMappingProfile'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    exportProfile () {
        var query = {
            Id: this.item.Id,
            MappingsColumnDefs: this.mappingsColumnDefs,
            CountsColumnDefs: this.countsColumnDefs,
            CombinationsColumnDefs: this.combinationsColumnDefs,
        };

        this.api.WeatherTypeMappingProfileExport
            .exportPost(query)
            .then((response) => this.fileDownloadService.download(response.body, (header) => response.headers.get(header)));
    }

    addMapping () {
        this.item.WeatherTypeMappings = this.item.WeatherTypeMappings || [];
        this.item.WeatherTypeMappings.unshift({});
        this.mappingsGrid.gridApi.applyTransaction({ addIndex: 0, add: this.mappingsGrid.rowData.slice(0, 1) })
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removeMapping (mapping) {
        this.item.WeatherTypeMappings.splice(this.item.WeatherTypeMappings.indexOf(mapping), 1);
        this.mappingsGrid.updateFilteredRows();
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    addCount () {
        this.item.WeatherTypeNgCounts = this.item.WeatherTypeNgCounts || [];
        this.item.WeatherTypeNgCounts.unshift({});
        this.countsGrid.gridApi.applyTransaction({ addIndex: 0, add: this.countsGrid.rowData.slice(0, 1) })
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removeCount (count) {
        this.item.WeatherTypeNgCounts.splice(this.item.WeatherTypeNgCounts.indexOf(count), 1);
        this.countsGrid.updateFilteredRows();
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    addCombination () {
        this.item.WeatherTypeNgCombination = this.item.WeatherTypeNgCombination || [];
        this.item.WeatherTypeNgCombination.unshift({});
        this.combinationsGrid.gridApi.applyTransaction({ addIndex: 0, add: this.combinationsGrid.rowData.slice(0, 1) })
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removeCombination (combination) {
        this.item.WeatherTypeNgCombination.splice(this.item.WeatherTypeNgCombination.indexOf(combination), 1);
        this.combinationsGrid.updateFilteredRows();
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }
}
