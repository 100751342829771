import {Component, Inject} from "@angular/core";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {
    DialogComponent,
    DialogContentComponent,
    DialogFooterComponent,
    DialogHeaderComponent,
    SwxModule
} from "swx.front-end-lib";

@Component({
    standalone: true,
    templateUrl: 'ReportDeicingHotErrorsDialog.component.html',
    styleUrl: "./ReportDeicingHotErrorsDialog.component.css",
    imports: [DialogComponent, DialogHeaderComponent, SwxModule, DialogFooterComponent, DialogContentComponent]
})
export class ReportDeicingHotErrorsDialogComponent {
    constructor(@Inject(DIALOG_DATA) public hot: any,
                private dialogRef: DialogRef) {
    }
    
    close() {
        this.dialogRef.close();
    }
}
