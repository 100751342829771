import { AfterViewInit, Component } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { NgFor, NgIf, JsonPipe } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SwxModule } from "swx.front-end-lib";

@Component({
    templateUrl: './ManualStationEntry.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, NgIf, JsonPipe]
})
export class ManualStationEntryCompoment implements AfterViewInit {
    stations: any;
    stationId: any;
    rawData: any;
    result: any;

    constructor(private api: ApiService) {
    }

    ngAfterViewInit() {
        this.api.Station.query().$promise.then(result => {
            this.stations = result;
        })
    }

    decode() {
        this.api.StationDecode.queryObject({
            stationId: this.stationId,
            rawData: this.rawData,
        }).$promise.then(result => {
            this.result = result;
        });
    }
}
