import {Component} from "@angular/core";
import { BarrelIcingEmailsComponent } from "./BarrelIcingEmails.component";
import { BarrelIcingDataComponent } from "./BarrelIcingData.component";
import { SwxModule } from "swx.front-end-lib";

@Component({
    templateUrl: './BarrelIcing.component.html',
    standalone: true,
    imports: [SwxModule, BarrelIcingDataComponent, BarrelIcingEmailsComponent]
})
export class BarrelIcingComponent {
    query: any = {};
    constructor() {
    }

    onAirplaneRefresh(query) {
        this.query = {...query};
    }
} 
