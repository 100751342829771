import {Component, Input} from "@angular/core";
import { FileDownloadService, SwxModule } from "swx.front-end-lib";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './RawDataDialog.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule]
})
export class RawDataDialogComponent {
    @Input() hot;
    
    constructor(private download: FileDownloadService) {
    }
    
    onDownload(data: string, fileName: string) {
        this.download.download(new Blob([data]), fileName)
    }
}
