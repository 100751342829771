import {Component, OnInit, ViewChild} from "@angular/core";
import { IAgGridColGroupDef, StorageService, ValueFormatters, SwxModule, GridModule } from "swx.front-end-lib";
import {ApiService} from "../shared/Api.service";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './Flight.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, GridModule]
})
export class FlightComponent implements OnInit {

    storeKey = 'flightQuery'
    @ViewChild('grid') grid;
    query: any;
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };

    airlines = this.api.DeicingAirline.query();
    airports = this.api.Airport.query();
    resource = this.api.Flight;
    exportResourcePost = this.api.FlightExport;

    constructor(private storage: StorageService,
                private api: ApiService,
                private formatters: ValueFormatters) {
    }

    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery){
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    };

    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }


    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: '',
            children: [
                { colId: "Flight.Id", field: "Flight.Id", headerName: "#", width: 80, filterType: "integer", pinned: 'left' },
                { colId: "Flight.CreatedDate", field: "Flight.CreatedDate", headerName: "Date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left', filterType: "date" },
                { colId: "Flight.CreatedTime", field: "Flight.CreatedDate", headerName: "Time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, pinned: 'left', "searchable": false },
                { colId: "AirlineName", field: "AirlineName", headerName: "Airline", width: 120 },
                { colId: "OriginStation", field: "OriginStation", headerName: "Origin", width: 100 },
                { colId: "DestinationStation", field: "DestinationStation", headerName: "Destination", width: 100 },
                { colId: "Flight.RegistrationNumber", field: "Flight.RegistrationNumber", headerName: "Given aircraft identifier", width: 80 },
                { colId: "FlightIdentifier", field: "FlightIdentifier", headerName: "Flight identifier / call sign", width: 85 },
                { colId: "Flight.EquipmentTypeIdentifier", field: "Flight.EquipmentTypeIdentifier", headerName: "Given equipment type code", width: 90 },
                { colId: "Flight.HotRequestDate", field: "Flight.HotRequestTime", headerName: "HOT request date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
                { colId: "Flight.HotRequestTime", field: "Flight.HotRequestTime", headerName: "HOT request time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
                { colId: "Flight.AcarsInitDate", field: "Flight.AcarsInitTime", headerName: "ACARS init date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
                { colId: "Flight.AcarsInitTime", field: "Flight.AcarsInitTime", headerName: "ACARS init time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
                { colId: "Flight.OffBlockDate", field: "Flight.OffBlockTime", headerName: "Out of gate (off block) date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
                { colId: "Flight.OffBlockTime", field: "Flight.OffBlockTime", headerName: "Out of gate (off block) time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
                { colId: "Flight.WheelsUpDate", field: "Flight.WheelsUpTime", headerName: "Off ground (wheels up) date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
                { colId: "Flight.WheelsUpTime", field: "Flight.WheelsUpTime", headerName: "Off ground (wheels up) time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
                { colId: "Flight.TouchdownDate", field: "Flight.TouchdownTime", headerName: "On the ground (touchdown) date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
                { colId: "Flight.TouchdownTime", field: "Flight.TouchdownTime", headerName: "On the ground (touchdown) time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
                { colId: "Flight.OnBlockDate", field: "Flight.OnBlockTime", headerName: "In the gate (on block) date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
                { colId: "Flight.OnBlockTime", field: "Flight.OnBlockTime", headerName: "In the gate (on block) time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
            ]
        },
        {
            headerName: 'Deicing record',
            children: [
                { colId: "Flight.DeicingStartDate", field: "Flight.DeicingStartTime", headerName: "Deicing start date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "Flight.DeicingStartTime", field: "Flight.DeicingStartTime", headerName: "Deicing start time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), "searchable": false },
            ]
        },
        {
            headerName: 'Deicing timestamps',
            children: [
                { colId: "Flight.HoldoverStartDate", field: "Flight.HoldoverStartTime", headerName: "Holdover/Anti-icing start date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "Flight.HoldoverStartTime", field: "Flight.HoldoverStartTime", headerName: "Holdover/Anti-icing start time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), "searchable": false },
            ]
        },
        {
            headerName: 'Deicing record',
            children: [
                { colId: "Flight.DeicingCompleteDate", field: "Flight.DeicingCompleteTime", headerName: "De/Anti-ice finish date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "Flight.DeicingCompleteTime", field: "Flight.DeicingCompleteTime", headerName: "De/Anti-ice finish time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), "searchable": false },
                { colId: "Flight.CancelledDate", field: "Flight.CancelledTime", headerName: "Deicing cancelled date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "Flight.CancelledTime", field: "Flight.CancelledTime", headerName: "Deicing cancelled time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), "searchable": false },
                { colId: "Flight.InitialCallDate", field: "Flight.InitialCallTime", headerName: "Initial call date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "Flight.InitialCallTime", field: "Flight.InitialCallTime", headerName: "Initial call time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), "searchable": false },
                { colId: "Flight.TaxiInDate", field: "Flight.TaxiInTime", headerName: "Taxi in date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "Flight.TaxiInTime", field: "Flight.TaxiInTime", headerName: "Taxi in time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), "searchable": false },
                { colId: "Flight.BayAssignedInDate", field: "Flight.BayAssignedInTime", headerName: "Bay assigned in date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "Flight.BayAssignedInTime", field: "Flight.BayAssignedInTime", headerName: "Bay assigned in time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), "searchable": false },
                { colId: "Flight.ParkInInDate", field: "Flight.ParkInInTime", headerName: "Park in date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "Flight.ParkInInTime", field: "Flight.ParkInInTime", headerName: "Park in time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), "searchable": false },
                { colId: "Flight.IcemanAcceptInDate", field: "Flight.IcemanAcceptInTime", headerName: "Iceman accept in date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "Flight.IcemanAcceptInTime", field: "Flight.IcemanAcceptInTime", headerName: "Iceman accept in time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), "searchable": false },
                { colId: "Flight.IcehouseAcceptInDate", field: "Flight.IcehouseAcceptInTime", headerName: "Icehouse accept in date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "Flight.IcehouseAcceptInTime", field: "Flight.IcehouseAcceptInTime", headerName: "Icehouse accept in time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), "searchable": false },
                { colId: "Flight.IcehouseAcceptOutDate", field: "Flight.IcehouseAcceptOutTime", headerName: "Icehouse accept out date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "Flight.IcehouseAcceptOutTime", field: "Flight.IcehouseAcceptOutTime", headerName: "Icehouse accept out time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), "searchable": false },
                { colId: "Flight.IcemanOutDate", field: "Flight.IcemanOutTime", headerName: "Iceman accept out date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "Flight.IcemanOutTime", field: "Flight.IcemanOutTime", headerName: "Iceman accept out time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), "searchable": false },
                { colId: "Flight.CTMCallDate", field: "Flight.CTMCallTime", headerName: "CTM call date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "Flight.CTMCallTime", field: "Flight.CTMCallTime", headerName: "CTM call time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), "searchable": false },
                { colId: "Flight.BayOutDate", field: "Flight.BayOutTime", headerName: "Bay out date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "Flight.BayOutTime", field: "Flight.BayOutTime", headerName: "Bay out time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), "searchable": false },
                { colId: "Flight.TaxiOutDate", field: "Flight.TaxiOutTime", headerName: "Taxi out date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "Flight.TaxiOutTime", field: "Flight.TaxiOutTime", headerName: "Taxi out time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), "searchable": false },
            ]
        },
        {
            headerName: '',
            children: [
                { colId: "OffBlockTaxiTime", field: "OffBlockTaxiTime", headerName: "Off block taxi time (minutes)", width: 80, filterType: "integer" },
                { colId: "HoldoverTaxiTime", field: "HoldoverTaxiTime", headerName: "Holdover taxi time (actual) (minutes)", width: 80, filterType: "integer" },
            ]
        }
    ];
} 
