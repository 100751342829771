import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'sftpServerServiceProviderNotEnabled',
    standalone: true,
})
export class SFTPServerServiceProviderNotEnabledPipe implements PipeTransform {
    transform(serviceProviders, sftpServerConfigurationDeicingServiceProviders) {
        if (sftpServerConfigurationDeicingServiceProviders == null) return serviceProviders;
        return serviceProviders.filter(sp => !sftpServerConfigurationDeicingServiceProviders.some(csp => csp.DeicingServiceProviderId === sp.Id));
    };
}

