import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { FiltersComponent, IAgGridColGroupDef, SortableComponent, ValueFormatters, SwxModule } from "swx.front-end-lib";
import {ApiService} from "../shared/Api.service";
import { ControlContainer, NgModelGroup, FormsModule } from "@angular/forms";
import {StationMonitoringConditionListComponent} from "../stationMonitoringProfile/StationMonitoringConditionList.component";
import {Field, FieldGroup} from "swx.front-end-lib/lib/grid/Query.model";
import { HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'flap-contamination-alert-condition-list',
    templateUrl: 'FlapContaminationAlertConditionList.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
    standalone: true,
    imports: [SwxModule, NgFor, FormsModule, NgIf, HasPermissionPipe]
})
export class FlapContaminationAlertConditionListComponent implements OnInit {
    @Input() conditions;
    @Input() parent;
    @Input() parentList: FlapContaminationAlertConditionListComponent;
    @Input() ngForm;
    @ViewChild('sortable', { static: true }) sortable: SortableComponent;
    availableFields: Map<string, Field>;
    fieldGroups: FieldGroup[];
    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: "Airport",
            children: [
                { field: "Airport.ICAOCode", headerName: "Airport ICAO code", },
                { field: "Airport.City", headerName: "Airport city", },
                { field: "Airport.Country", headerName: "Airport country", },
                { field: "Airport.Region", headerName: "Airport region", },
                { field: "Airport.Latitude", headerName: "Airport latitude", filterType: "float" },
                { field: "Airport.Longitudfe", headerName: "Airport longitude", filterType: "float" },
            ]
        },
        /*
        {
            headerName: "Flight",
            children: [
                { field: "FlapContaminationAlertAirplane.RegistrationNumber", headerName: "Registration number", },
                { field: "FlapContaminationAlertAirplane.EquipmentTypeIdentifier", headerName: "Equipment type code", },
            ]
        },
        */
        {
            headerName: "Weather profile",
            children: [
                { field: "WeatherProfile.LWE", headerName: "LWE (g/dm²/h)", filterType: "float", },
                { field: "WeatherProfile.WeatherType", headerName: "Weather type", filterType: "enum", source: "ClientWeatherType" },
                { field: "WeatherProfile.Temperature", headerName: "Temperature (°C)", filterType: "float", },
                { field: "WeatherProfile.RelativeHumidity", headerName: "Relative humidity (RH%)", filterType: "float" },
                { field: "WeatherProfile.DewPoint", headerName: "Dew point (°C)", filterType: "float" },
                { field: "WeatherProfile.ConeSurfaceTemperature", headerName: "Active Frost temp (°C)", filterType: "float" },
                { field: "WeatherProfile.FrostPoint", headerName: "Frost point (°C)", filterType: "float" },
                { field: "WeatherProfile.WindSpeed", headerName: "Wind speed (m/s)", filterType: "float", },
                { field: "WeatherProfile.Visibility", headerName: "Visibility (m)", filterType: "float", },
            ]
        },
        {
            headerName: 'Metar reading',
            children: [
                { field: "MetarReadingAgeMinutes", headerName: "Age (minutes)", filterType: "float" },
                { field: "MetarReading.WxString", headerName: "Weather string", },
                { field: "MetarReadingFinalWeatherType", headerName: "Final weather Type", filterType: "enum", source: "MetarWeatherType" },
                { field: "MetarReading.TempC", headerName: "Temperature (°C)", filterType: "float" },
                { field: "MetarReading.DewPointC", headerName: "Dew point (°C)", filterType: "float" },
                { field: "MetarReading.FrostPointC", headerName: "Frost point (°C)", filterType: "float" },
                { field: "MetarReading.RelativeHumidity", headerName: "Relative humidity (RH%)", filterType: "float" },
                { field: "MetarReading.IsClearSky", headerName: "Clear sky?", filterType: "integer" },
                { field: "MetarReading.IsActiveFrost", headerName: "Active frost?", filterType: "integer" },
                { field: "MetarReadingVisibilityM", headerName: "Visibility (m)", filterType: "float" },
                { field: "MetarReadingWindSpeedMS", headerName: "Wind speed (m/s)", filterType: "float" },
            ],
        },
    ];
    
    constructor(
        private formatters: ValueFormatters,
        private api: ApiService,
    ) {
    }

    ngOnInit() {
        this.availableFields = FiltersComponent.mapColumnDefs(this.api, this.columnDefs);
        this.fieldGroups = FiltersComponent.getFieldGroups(this.columnDefs);
    }

    addCondition(parent, type: string) {
        var maxOrder = Math.max.apply(null, this.conditions.filter(c => c.ParentConditionId == this.parent?.Id)
            .map(h => h.Order));
        this.conditions.push({
            Id: StationMonitoringConditionListComponent.uuidv4(),
            ParentConditionId: this.parent?.Id,
            Order: Math.max(1, maxOrder + 1),
            ConditionType: type,
            MinimumDurationMinutes: 1,
        });
        this.ngForm.form.markAsDirty();
    }

    childConditions() {
        return this.conditions?.filter(c => c.ParentConditionId == this.parent?.Id) ?? [];
    }

    removeCondition(parent, condition) {
        this.conditions.splice(this.conditions.indexOf(condition), 1);
        this.ngForm.form.markAsDirty();
    }
}
