<form #ngForm="ngForm" (submit)="save()">
    <page-title>{{('Fluid'|translate) + ': ' + (item.Name || 'new')}}</page-title>
    <div id="fluidEditPage" class="noMargins">
        <div class="toolbar">
            <a class="tab" (click)="switchTab('basicInfo')" [ngClass]="{active : tab === 'basicInfo', invalid: !ngForm.form.controls.basicInfoForm?.valid }">{{'Basic info'}}</a>
            <a class="tab" (click)="switchTab('fluidVersions')" [ngClass]="{active : tab == 'fluidVersions', invalid: !ngForm.form.controls.fluidVersionsForm?.valid}">{{'Versions'}}</a>
            <a class="tab" (click)="switchTab('hotSpecs')" [ngClass]="{active : tab == 'hotSpecs', invalid: !ngForm.form.controls.hotSpecsForm?.valid}">{{'HOT specs'}}</a>
            <a class="tab" (click)="switchTab('metarSpecs')" [ngClass]="{active : tab == 'metarSpecs', invalid: !ngForm.form.controls.metarSpecsForm?.valid}">{{'METAR Specs'}}</a>
            <a class="tab" (click)="switchTab('degreeSpecificMetarSpecs')" [ngClass]="{active : tab == 'degreeSpecificMetarSpecs', invalid: !ngForm.form.controls.degreeSpecificMetarSpecsForm?.valid}">{{'DS METAR Specs'}}</a>
            <input type="submit" [disabled]="!ngForm.form.valid || !ngForm.form.dirty" value="{{'Save'|translate}}" />
            <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
            <input type="button" *ngIf="'DataChanges'|hasPermission" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
            <select *ngIf="('ConfigEditFluids'|hasPermission) && tab == 'fluidVersions'" [(ngModel)]="fluidSeasonId" name="fluidSeasonId">
                <option *ngFor="let fluidSeason of fluidSeasonsNotEnabled()" [ngValue]="fluidSeason.Id">
                    {{fluidSeason.Name}}
                </option>
            </select>
            <button *ngIf="('ConfigEditFluids'|hasPermission) && tab == 'fluidVersions' && fluidSeasonId" type="button" class="green" (click)="addFluidVersion(fluidSeasonId); fluidSeasonId = null;">{{'Add version'|translate}}</button>
            <button type="button" (click)="exportJson()"><span class="mdi mdi-download"></span>{{'Export JSON'|translate}}</button>
            <button type="button" (click)="importJson()"><span class="mdi mdi-upload"></span>{{'Import JSON'|translate}}</button>
        </div>

        <fieldset class="contentMargins form" [disabled]="!('ConfigEditFluids'|hasPermission)" [hidden]="tab !== 'basicInfo'" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'Name'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Name" maxlength="100" pattern="^[0-9a-zA-Z•\t\- ':\.,\\\/@\+]+$" name="Name" />
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="Active"/>
            </label>
            <label>
                <span class="caption">{{'Fluid type'|translate}}</span>
                <select [(ngModel)]="item.Type" name="Type" required>
                    <option *ngFor="let fluidType of fluidTypes|keyvalue" [value]="fluidType.key">
                        {{fluidType.value}}
                    </option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Dilution'|translate}}</span>
                <input *ngIf="item.Type =='Type1'" type="text" [required]="item.Type =='Type1'" [(ngModel)]="item.Dilution" maxlength="50" name="Dilution" />
                <select *ngIf="item.Type !='Type1'" [(ngModel)]="item.Dilution" [required]="item.Type !='Type1'" name="selectedDilution">
                    <option *ngFor="let dilutionFormat of dilutionFormats" [value]="dilutionFormat">
                        {{dilutionFormat}}
                    </option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Category'|translate}}</span>
                <select [(ngModel)]="item.Category" name="Category">
                    <option [ngValue]="null">None</option>
                    <option *ngFor="let category of ['PG', 'EG']" [ngValue]="category">
                        {{category}}
                    </option>
                </select>
            </label>
            <div class="field">
                <span class="caption">{{'Wing Surface Types'|translate}}</span>
                <div>
                    <label *ngFor="let wingMaterialType of wingMaterialTypes|keyvalue">
                        <input type="checkbox" [checked]="isWingMaterialTypeEnabled(wingMaterialType.key)" (click)="toggleWingMaterialType(wingMaterialType.key)" />{{wingMaterialType.value}}
                    </label>
                </div>
            </div>
            <label>
                <span class="caption">{{'Deicing fluid'|translate}}</span>
                <select [(ngModel)]="item.DeicingFluidId" name="DeicingFluidId">
                    <option [ngValue]="null">{{'None'|translate}}</option>
                    <option *ngFor="let deicingFluid of deicingFluids|orderBy:'Name'" [value]="deicingFluid.Id">
                        {{deicingFluid.Name}}
                    </option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Fluid profile'|translate}}</span>
                <select required [(ngModel)]="item.FluidProfileId" name="FluidProfileId">
                    <option *ngFor="let fluidProfile of fluidProfiles|orderBy:'Name'" [value]="fluidProfile.Id">
                        {{fluidProfile.Name}}
                    </option>
                </select>
            </label>
        </fieldset>

        <fieldset class="form" [disabled]="!('ConfigEditFluids'|hasPermission)" [hidden]="tab !== 'fluidVersions'" ngModelGroup="fluidVersionsForm">
            <table class="table">
                <tr>
                    <th>{{'#'|translate}}</th>
                    <th>{{'Order'|translate}}</th>
                    <th>{{'Season'|translate}}</th>
                    <th>{{'Active?'|translate}}</th>
                    <th>{{'Cautions from regulatory agency fluid table'|translate}}</th>
                    <th>{{'Ice pellet cautions from regulatory agency fluid table'|translate}}</th>
                    <th>{{'LWE HOT adjustment % for flaps/slats'|translate}}<br />{{'&lt;= (°C)'|translate}}</th>
                    <th></th>
                </tr>
                <tr *ngFor="let fluidVersion of item.FluidVersions; index as i">
                    <td>
                        {{fluidVersion.Id}}
                    </td>
                    <td>
                        <input required type="number" step="1" min="0" [(ngModel)]="fluidVersion.Order" style="width: 60px;" name="fluidVersion.Order{{i}}" />
                    </td>
                    <td>
                        {{(fluidSeasons|findById:fluidVersion.FluidSeasonId).Name}}
                    </td>
                    <td>
                        <input type="checkbox" [(ngModel)]="fluidVersion.Active" name="fluidVersion.Active{{i}}" />
                    </td>
                    <td>
                        <textarea [(ngModel)]="fluidVersion.Cautions" rows="2" cols="30" name="fluidVersion.Cautions{{i}}"></textarea>
                    </td>
                    <td>
                        <textarea [(ngModel)]="fluidVersion.IcePelletCautions" rows="2" cols="30" name="fluidVersion.IcePelletCautions{{i}}"></textarea>
                    </td>
                    <td>
                        <input type="number" required [(ngModel)]="fluidVersion.HOTAdjustmentPercentage" name="fluidVersion.HOTAdjustmentPercentage{{i}}" min="0" step="any" style="width: 50px;" />
                    </td>
                    <td>
                        <a *ngIf="'ConfigEditFluids'|hasPermission" (click)="removeFluidVersion(fluidVersion)" class="mdi mdi-delete"></a>
                    </td>
                </tr>
            </table>
        </fieldset>

        <div [hidden]="tab !== 'hotSpecs'" ngModelGroup="hotSpecsForm">
            <div class="toolbar">
                <a class="tab" *ngFor="let fluidVersion of (item.FluidVersions || [])|filter:{Active:true}|orderBy:'Order'" (click)="switchFluidVersion(fluidVersion)" [ngClass]="{active : fluidVersionTab === fluidVersion, invalid: fluidVersion.hotSpecsForm && !fluidVersion.hotSpecsForm.valid }">{{(fluidSeasons|findById:fluidVersion.FluidSeasonId).Name}}</a>
                <button *ngIf="('ConfigEditFluids'|hasPermission) && fluidVersionTab != null" type="button" class="green" (click)="addFluidHotSpec(fluidVersionTab)">{{'Add spec'|translate}}</button>
            </div>
                <div *ngFor="let fluidVersion of (item.FluidVersions || [])|filter:{Active:true}, ">
                    <fieldset *ngIf="fluidVersionTab === fluidVersion" class="form" [disabled]="!('ConfigEditFluids'|hasPermission)" ngModelGroup="fluidVersion.hotSpecsForm">
                        <table class="table">
                            <tr>
                                <th>{{'#'|translate}}</th>
                                <th>{{'Weather type'|translate}}</th>
                                <th>{{'Temp'|translate}}<br />{{'&gt; (°C)'|translate}}</th>
                                <th>{{'Temp'|translate}}<br />{{'&lt;= (°C)'|translate}}</th>
                                <th width="100">{{'LWE'|translate}}<br />{{'&lt; (g/dm²/h)'|translate}}</th>
                                <th width="100">{{'LUPR (g/dm²/h)'|translate}}</th>
                                <th>I</th>
                                <th>A</th>
                                <th>B</th>
                                <th></th>
                            </tr>
                            <tr *ngFor="let spec of fluidVersion.FluidHotSpecs; index as i">
                                <td>{{spec.Id}}</td>
                                <td>
                                    <select [(ngModel)]="spec.WeatherType" required style="width: 150px;" name="WeatherType-{{i}}">
                                        <option *ngFor="let weatherType of weatherTypes|keyvalue" [value]="weatherType.key">
                                            {{weatherType.value}}
                                        </option>
                                    </select>
                                </td>
                                <td><input required type="number" step="any" [(ngModel)]="spec.MinTemp" max="{{spec.MaxTemp - 0.01}}" style="width: 60px;" name="MaxTemp-{{i}}" /></td>
                                <td><input required type="number" step="any" [(ngModel)]="spec.MaxTemp" min="{{spec.MinTemp + 0.01}}" style="width: 60px;" name="MinTemp-{{i}}"/></td>
                                <td><input required type="number" step="any" [(ngModel)]="spec.MaxIntensity" style="width: 80px;" name="MaxIntensity-{{i}}"/></td>
                                <td><input *ngIf="!spec.Message" [required]="!spec.Message" type="number" step="any" [(ngModel)]="spec.IntensityCutoff" name="IntensityCutoff-{{i}}" style="width: 80px;" /></td>
                                <td><input *ngIf="!spec.Message" [required]="!spec.Message" type="number" step="any" [(ngModel)]="spec.I" name="I-{{i}}" style="width: 60px;" /></td>
                                <td><input *ngIf="!spec.Message" [required]="!spec.Message" type="number" step="any" [(ngModel)]="spec.A" name="A-{{i}}" style="width: 60px;" /></td>
                                <td><input *ngIf="!spec.Message" [required]="!spec.Message" type="number" step="any" [(ngModel)]="spec.B" name="B-{{i}}" style="width: 60px;" /></td>
                                <td>
                                    <a *ngIf="'ConfigEditFluids'|hasPermission" (click)="removeFluidHotSpec(fluidVersion, spec)" class="mdi mdi-delete"></a>
                                </td>
                            </tr>
                        </table>
                    </fieldset>
                </div>
        </div>

        <div [hidden]="tab !== 'metarSpecs'" ngModelGroup="metarSpecsForm">
            <div class="toolbar">
                <a class="tab" *ngFor="let fluidVersion of (item.FluidVersions||[])|filter:{Active:true}|orderBy:'Order'" (click)="switchFluidVersion(fluidVersion)" [ngClass]="{active : fluidVersionTab === fluidVersion, invalid: fluidVersion.metarSpecsForm && !fluidVersion.metarSpecsForm.valid }">{{(fluidSeasons|findById:fluidVersion.FluidSeasonId).Name}}</a>
                <button *ngIf="('ConfigEditFluids'|hasPermission) && fluidVersionTab != null" type="button" class="green" (click)="addFluidMetarSpec(fluidVersionTab)">{{'Add spec'|translate}}</button>
            </div>
            <div *ngFor="let fluidVersion of (item.FluidVersions||[])|filter:{Active:true}">
                <div *ngIf="fluidVersionTab === fluidVersion">
                    <fieldset class="form" [disabled]="!('ConfigEditFluids'|hasPermission)" ngModelGroup="fluidVersion.$metarSpecsForm">
                        <table class="table">
                            <tr>
                                <th>{{'#'|translate}}</th>
                                <th>{{'Weather type'|translate}}</th>
                                <th>{{'Temp &gt; (°C)'|translate}}</th>
                                <th>{{'Temp &lt;= (°C)'|translate}}</th>
                                <th width="100">{{'Min HOT (minutes)'|translate}}</th>
                                <th width="100">{{'Max HOT (minutes)'|translate}}</th>
                                <th width="100">{{'Adjusted min HOT (minutes)'|translate}}</th>
                                <th width="100">{{'Adjusted max HOT (minutes)'|translate}}</th>
                                <th>{{'Notes from regulatory agency fluid table'|translate}}</th>
                                <th></th>
                            </tr>
                            <tr *ngFor="let spec of fluidVersion.FluidMetarSpecs; index as i">
                                <td>{{spec.Id}}</td>
                                <td>
                                    <select [(ngModel)]="spec.MetarWeatherType" style="width: 120px;" name="metarSpec-MetarWeatherType-{{i}}">
                                        <option *ngFor="let metarWeatherType of metarWeatherTypes|keyvalue" [value]="metarWeatherType.key">
                                            {{metarWeatherType.value}}
                                        </option>        
                                    </select>
                                </td>
                                <td><input required type="number" step="any" [(ngModel)]="spec.MinTemp" name="metarSpec-MinTemp-{{i}}" max="{{spec.MaxTemp - 0.01}}" style="width: 100px;" /></td>
                                <td><input required type="number" step="any" [(ngModel)]="spec.MaxTemp" name="metarSpec-MaxTemp-{{i}}" min="{{spec.MinTemp + 0.01}}" style="width: 100px;" /></td>
                                <td><input required type="number" step="any" [(ngModel)]="spec.MinHot" name="metarSpec-MinHot-{{i}}" style="width: 100px;" /></td>
                                <td><input required type="number" step="any" [(ngModel)]="spec.MaxHot" name="metarSpec-MaxHot-{{i}}" style="width: 100px;" /></td>
                                <td><input required type="number" step="any" [(ngModel)]="spec.AdjustedMinHot" name="metarSpec-AdjustedMinHot-{{i}}" style="width: 100px;" /></td>
                                <td><input required type="number" step="any" [(ngModel)]="spec.AdjustedMaxHot" name="metarSpec-AdjustedMaxHot-{{i}}" style="width: 100px;" /></td>
                                <td style="text-align: center;">
                                    <a *ngIf="!isEditionMode" (click)="isEditionMode = !isEditionMode" title="" [ngClass]="{'mdi': true, 'mdi-plus-box':(spec.Notes == null || spec.Notes==''), 'mdi-pencil':(spec.Notes != null)}"></a>
                                    <a *ngIf="isEditionMode" (click)="isEditionMode = !isEditionMode" title="" class="mdi mdi-eye-off"></a>
                                    <div *ngIf="isEditionMode"><textarea [(ngModel)]="spec.Notes" name="metarSpec-Notes-{{i}}" rows="3" cols="40"></textarea></div>
                                </td>
                                <td>
                                    <a *ngIf="'ConfigEditFluids'|hasPermission" (click)="removeFluidMetarSpec(fluidVersion, spec)" class="mdi mdi-delete"></a>
                                </td>
                            </tr>
                        </table>
                    </fieldset>
                </div>
            </div>
        </div>

        <div [hidden]="tab !== 'degreeSpecificMetarSpecs'" ngModelGroup="degreeSpecificMetarSpecsForm">
            <div class="toolbar">
                <a class="tab" *ngFor="let fluidVersion of (item.FluidVersions||[])|filter:{Active:true}|orderBy:'Order'" (click)="switchFluidVersion(fluidVersion)" [ngClass]="{active : fluidVersionTab === fluidVersion, invalid: fluidVersion.degreeSpecificMetarSpecsForm && !fluidVersion.degreeSpecificMetarSpecsForm.valid }">{{(fluidSeasons|findById:fluidVersion.FluidSeasonId).Name}}</a>
                <button *ngIf="('ConfigEditFluids'|hasPermission) && fluidVersionTab != null" type="button" class="green" (click)="addFluidDegreeSpecificMetarSpec(fluidVersionTab)">{{'Add spec'|translate}}</button>
            </div>
                <div *ngFor="let fluidVersion of (item.FluidVersions||[])|filter:{Active:true}">
                    <div *ngIf="fluidVersionTab === fluidVersion">
                        <fieldset class="form" [disabled]="!('ConfigEditFluids'|hasPermission)" ngModelGroup="fluidVersion.$degreeSpecificMetarSpecsForm">
                        <table class="table">
                            <tr>
                                <th>{{'#'|translate}}</th>
                                <th>{{'Weather type'|translate}}</th>
                                <th>{{'Temp &gt; (°C)'|translate}}</th>
                                <th>{{'Temp &lt;= (°C)'|translate}}</th>
                                <th width="100">{{'Min HOT (minutes)'|translate}}</th>
                                <th width="100">{{'Max HOT (minutes)'|translate}}</th>
                                <th width="100">{{'Adjusted min HOT (minutes)'|translate}}</th>
                                <th width="100">{{'Adjusted max HOT (minutes)'|translate}}</th>
                                <th>{{'Notes from regulatory agency fluid table'|translate}}</th>
                                <th></th>
                            </tr>
                            <tr *ngFor="let spec of fluidVersion.FluidDegreeSpecificMetarSpecs; index as i">
                                <td>{{spec.Id}}</td>
                                <td>
                                    <select [(ngModel)]="spec.MetarWeatherType" name="dsMetarSpec.MetarWeatherType-{{i}}" required style="width: 120px;">
                                        <option *ngFor="let metarWeatherType of metarWeatherTypes|keyvalue" [value]="metarWeatherType.key">
                                            {{metarWeatherType.value}}
                                        </option>
                                    </select>
                                </td>
                                <td><input required type="number" step="any" [(ngModel)]="spec.MinTemp" name="dsMetarSpec-MinTemp-{{i}}" max="{{spec.MaxTemp - 0.01}}" style="width: 100px;" /></td>
                                <td><input required type="number" step="any" [(ngModel)]="spec.MaxTemp" name="dsMetarSpec-MaxTemp-{{i}}" min="{{spec.MinTemp + 0.01}}" style="width: 100px;" /></td>
                                <td><input required type="number" step="any" [(ngModel)]="spec.MinHot" name="dsMetarSpec-MinHot-{{i}}" style="width: 100px;" /></td>
                                <td><input required type="number" step="any" [(ngModel)]="spec.MaxHot" name="dsMetarSpec-MaxHot-{{i}}" style="width: 100px;" /></td>
                                <td><input required type="number" step="any" [(ngModel)]="spec.AdjustedMinHot" name="dsMetarSpec-AdjustedMinHot-{{i}}" style="width: 100px;" /></td>
                                <td><input required type="number" step="any" [(ngModel)]="spec.AdjustedMaxHot" name="dsMetarSpec-AdjustedMaxHot-{{i}}" style="width: 100px;" /></td>
                                <td style="text-align: center;">
                                    <a *ngIf="!isEditionMode" (click)="isEditionMode = !isEditionMode" title="" [ngClass]="{'mdi': true, 'mdi-plus-box':(spec.Notes == null || spec.Notes==''), 'mdi-pencil':(spec.Notes != null)}"></a>
                                    <a *ngIf="isEditionMode" (click)="isEditionMode = !isEditionMode" title="" class="mdi mdi-eye-off"></a>
                                    <div *ngIf="isEditionMode"><textarea [(ngModel)]="spec.Notes" name="dsMetarSpec-Notes-{{i}}" rows="3" cols="40"></textarea></div>
                                </td>
                                <td>
                                    <a *ngIf="'ConfigEditFluids'|hasPermission" (click)="removeFluidDegreeSpecificMetarSpec(fluidVersion, spec)" class="mdi mdi-delete"></a>
                                </td>
                            </tr>
                        </table>
                    </fieldset>
                    </div>
                    
                </div>
        </div>
    </div>
</form>
