<swx-dialog>
    <swx-dialog-header>
        Errors
    </swx-dialog-header>
    <swx-dialog-content>
        <table class="table">
            <tr>
                <th>{{'HOT response errors'|translate}}</th>
            </tr>
            <tr>
                <td>{{hot.HotResponse?.Error || '&nbsp;'}}</td>
            </tr>
            <tr>
                <th>{{'ACARS request errors'|translate}}</th>
            </tr>
            <tr>
                <td>{{hot.AcarsRequest?.Errors || '&nbsp;'}}</td>
            </tr>
            <tr>
                <th>{{'HOT calculation errors'|translate}}</th>
            </tr>
            <tr>
                <td>{{hot.HotResponseHot?.Errors || '&nbsp;'}}</td>
            </tr>
            <tr>
                <th>{{'Weather profile errors'|translate}}</th>
            </tr>
            <tr>
                <td>{{hot.WeatherProfile?.Errors || '&nbsp;'}}</td>
            </tr>
        </table>
    </swx-dialog-content>
</swx-dialog>



