import {Component, OnChanges, Input, forwardRef} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule } from '@angular/forms';
import {Field, FieldGroup, Filter, IAgGridColGroupDef, IAgGridColumnDef} from "./Query.model";
import { ExportApi } from "../ApiFactory.service";
import {ColumnPreferencesService} from "./ColumnPreferences.service";
import { FilterComponent } from './Filter.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'filters',
    templateUrl: 'Filters.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FiltersComponent),
            multi: true
        }
    ],
    standalone: true,
    imports: [NgFor, FilterComponent, FormsModule, NgIf]
})
export class FiltersComponent implements ControlValueAccessor, OnChanges {
    @Input() columnDefs: (IAgGridColumnDef | IAgGridColGroupDef)[];
    filters: Filter[] = [];
    groups: FieldGroup[];
    availableField: Field = null;
    availableFields: Map<string, Field>;
    onchange: (_: any) => void = () => { };
    ontouched: (_: any) => void = () => { };

    constructor(
        private api: ExportApi,
    ) {
    }

    ngOnChanges(changes) {
        this.availableFields = FiltersComponent.mapColumnDefs(this.api, this.columnDefs);
        this.groups = FiltersComponent.getFieldGroups(this.columnDefs);
    }

    writeValue(value): void {
        this.filters = value ? value : [];
    }

    registerOnChange(fn): void {
        this.onchange = fn;
    }

    registerOnTouched(fn): void {
        this.ontouched = fn;
    }

    public static getFieldGroups(columnDefs: IAgGridColumnDef[] | IAgGridColGroupDef[]) : FieldGroup[] {
        return ColumnPreferencesService.normalizeColumnDefs(columnDefs)
            .map(g => ({
                Label: g.headerName,
                Fields: g.children
                    .filter(c => (typeof c.searchable === 'undefined' || c.searchable) && c.field != null)
                    .map(c => c.field),
            }));
    }

    public static mapColumnDefs(api: ExportApi, columnDefs: IAgGridColumnDef[] | IAgGridColGroupDef[]): Map<string, Field> {
        let availableFields = new Map<string, Field>();
        
        ColumnPreferencesService.normalizeColumnDefs(columnDefs)
            .map(g => {
                g.children
                    .filter(c => (typeof c.searchable === 'undefined' || c.searchable) && c.field != null)
                    .forEach(columnDef => {
                    var operators;
                    var options = {};
                    switch (columnDef.filterType) {
                        case 'boolean':
                            operators = {
                                Equals: '=',
                                NotEquals: '!=',
                                Empty: 'Empty',
                                NotEmpty: 'Not empty'
                            };
                            break;
                        case 'enum':
                            operators = {
                                Equals: 'Any of',
                                NotEquals: 'Not any of',
                                Empty: 'Empty',
                                NotEmpty: 'Not empty',
                            };
                            if (typeof api[columnDef.source] === 'undefined') {
                                console.log(columnDef.source);
                            } else {
                                options = api[columnDef.source].query();
                            }
                            break;
                        case 'date':
                        case 'integer':
                        case 'float':
                            operators = {
                                Equals: '=',
                                NotEquals: '!=',
                                GreaterThan: '>',
                                LessThan: '<',
                                Empty: 'Empty',
                                NotEmpty: 'Not empty',
                            };
                            break;
                        default:
                            operators = {
                                Equals: '=',
                                NotEquals: '!=',
                                Contains: 'Contains',
                                NotContains: 'Doesn\'t contain',
                                Empty: 'Empty',
                                NotEmpty: 'Not empty',
                            };
                            break;
                    }
                    
                    availableFields.set(columnDef.field, {
                        Field: columnDef.field,
                        Label: columnDef.headerName,
                        GroupLabel: g.headerName,
                        Type: columnDef.filterType,
                        Operators: operators,
                        Options: options,
                        MultiselectWidth: columnDef.multiselectWidth,
                    });
                });
            });

        return availableFields;
    }

    addFilter(availableField) {
        this.filters = this.filters || [];
        this.filters.push({
            Field: availableField.Field,
            Type: availableField.Type,
            Operator: "Equals",
        });
        this.availableField = null;
        this.onchange(this.filters);
        this.ontouched(this.filters);
    }

    removeFilter(filter) {
        this.filters.splice(this.filters.indexOf(filter), 1);
        this.onchange(this.filters);
        this.ontouched(this.filters);
    }
}
