import {Component, Input} from "@angular/core";
import {RawDataLinkCellRenderer} from "../hotData/RawDataLinkCellRenderer";
import { GridModule } from "swx.front-end-lib";

@Component({
    template: `<div style="height: 350px; display: flex;"><config-grid #grid [columnDefs]="columnDefs" [rowData]="data" [components]="components" [headerHeight]="96"></config-grid></div>`,
    standalone: true,
    imports: [GridModule]
})
export class RequestDetailDialogComponent {
    @Input() columnDefs;
    @Input() data;
    
    components; 
    
    constructor() {
        this.components = {
            rawDataLinkCellRenderer: RawDataLinkCellRenderer
        }
    }
}
