/// <reference path="../../../node_modules/@types/jqueryui/index.d.ts" />

import { Component, Input, OnInit } from "@angular/core";
import { SwxModule } from "swx.front-end-lib";
import { NgFor, NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: "MDMUploadDialog.component.html",
    styles: [
        `
            .ui-form-container {
                margin: 10px 10px 20px 10px;
            }
            .ui-dialog-buttonpane {
                padding-top: 20px;
            }
            .ui-radio-label {
                padding-right: 10px;
            }
        `,
    ],
    standalone: true,
    imports: [
        FormsModule,
        NgFor,
        NgIf,
        SwxModule,
    ],
})
export class MDMUploadDialogComponent implements OnInit {
    @Input("row") row;
    @Input("api") api;
    @Input("notification") notification;
    @Input("refresh") refresh;
    @Input("isRelease") isRelease;
    groups: any[];
    container: JQuery;
    Object = Object;
    form = {
        smartGroups: {},
        pushMode: "auto",
    };

    constructor() {}

    ngOnInit() {
        this.groups = this.api.WorkspaceOneGroup.query();
    }
    
    numGroupsSelected() {
        return Object.keys(this.form.smartGroups).filter(o => this.form.smartGroups[o]).length;
    }

    submitForm() {
        this.api.WorkspaceOne.post({
            appName: this.row.data.MobileAppName,
            appReleaseId: this.isRelease ? this.row.data.Id : this.row.data.MobileAppReleaseId,
            groupIds: Object.keys(this.form.smartGroups).filter(
                (k) => this.form.smartGroups[k]
            ),
            pushMode: this.form.pushMode ? this.form.pushMode : "auto",
        }).then((e) => {
            this.container["dialog"]("close");
            if (e.errors.length) {
                this.notification.show('Error: ' + e.errors.join('<br />'), { type: 'error', sticky: true })
            } else {
                this.notification.show("Successfully uploaded");
            }
            this.refresh();
        });
    }
}
