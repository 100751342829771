import {Component, OnInit, ViewChild} from "@angular/core";
import {FileDownloadService, GridModule, IAgGridColGroupDef, StorageService, SwxModule} from "swx.front-end-lib";
import {ApiService} from "../shared/Api.service";
import {ICellRendererParams} from "ag-grid-community";
import {FormsModule} from "@angular/forms";
import {NgFor} from "@angular/common";

@Component({
    templateUrl: './StationPerformance.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, GridModule]
})
export class StationPerformanceComponent implements OnInit {
    @ViewChild('grid') grid;
    storeKey = 'stationPerformanceQuery';
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    query: any;

    stations = this.api.Station.query();
    resource = this.api.StationPerformance;
    exportResourcePost = this.api.StationPerformanceExport;
    stationLoggerTypes = {
        1: 'DataTaker',
        2: 'SureLogger',
        3: 'Serial over TCP',
        4: 'SureLogger 2',
    };
    stationTypes = {
        2: 'Gen 1/2',
        3: 'Gen 3',
        5: 'Gen V',
    };

    constructor(private storage: StorageService, 
                private api: ApiService,
                private fileDownloadService: FileDownloadService) {
    }
    
    ngOnInit() {
       const storedQuery = this.storage.load(this.storeKey);
       if (storedQuery){
           this.query = storedQuery;
       }
       else {
           this.resetQuery();
       }
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    };
    
    apply() {
        this.storage.save(this.storeKey, this.query);

        this.grid.gridApi.showLoadingOverlay();
        this.resource
            .query(this.query)
            .$promise
            .then(response => {
                this.grid.gridApi.setRowData(response);
                this.grid.gridApi.hideOverlay();
            });
    }

    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: "Station",
            children: [
                { colId: "StationId", field: "StationId", headerName: "#", width: 80, filterType: "integer", pinned: 'left', },
                { colId: "StationName", field: "StationName", headerName: "Station", width: 100, pinned: 'left', },
                { colId: "StationType", field: "StationType", headerName: "Type", width: 50, },
                { colId: "StationLoggerType", field: "StationLoggerType", headerName: "Logger", width: 120, valueFormatter: c => c.value ? this.stationLoggerTypes[c.value] : "" },
            ],
        },
        {
            headerName: "Data",
            children: [
                { colId: "TotalMinutes", field: "TotalMinutes", headerName: "Potential data set (minutes)", width: 80, filterType: "integer", },
                { colId: "DataMinutes", field: "DataMinutes", headerName: "Actual data set (minutes)", width: 80, filterType: "integer", },
                { colId: "DataPercent", field: "DataPercent", headerName: "Actual data set (%)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v > 99.5), },
                { colId: "HavePowerErrorCount", field: "HavePowerErrorCount", headerName: "Power error count", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v < 2), },
                { colId: "MaxQueueSize", field: "MaxQueueSize", headerName: "Max. queue size", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v == 0), },
                { colId: "QueuedMinutes", field: "QueuedMinutes", headerName: "Queued data (minutes)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v == 0), },
                { colId: "LongGapCount", field: "LongGapCount", headerName: "Long gap count (>= 3 minutes)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v == 0), },
                { colId: "LongestGapMinutes", field: "LongestGapMinutes", headerName: "Max. gap (minutes)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v == 0), },
            ],
        },
        {
            headerName: "Thies LPM",
            children: [
                { colId: "ThiesLPM_WMOCode_Minutes", field: "ThiesLPM_WMOCode_Minutes", headerName: "WMO (minutes)", width: 80, filterType: "integer", },
                { colId: "ThiesLPM_WMOCode_Percent", field: "ThiesLPM_WMOCode_Percent", headerName: "WMO (%)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v > 99.5), },
            ],
        },
        {
            headerName: "Campbell PWS100",
            children: [
                { colId: "CampbellPWS100_WMOCodeOrOff_Minutes", field: "CampbellPWS100_WMOCodeOrOff_Minutes", headerName: "WMO (minutes)", width: 80, filterType: "integer", },
                { colId: "CampbellPWS100_WMOCodeOrOff_Percent", field: "CampbellPWS100_WMOCodeOrOff_Percent", headerName: "WMO (%)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v > 99.5), },
                { colId: "CampbellPWS100_Laseroff_Minutes", field: "CampbellPWS100_Laseroff_Minutes", headerName: "Laser off (minutes)", width: 80, filterType: "integer", },
                { colId: "CampbellPWS100_OK_Minutes", field: "CampbellPWS100_OK_Minutes", headerName: "Status OK (minutes)", width: 80, filterType: "integer", },
                { colId: "CampbellPWS100_Temperaturee_Minutes", field: "CampbellPWS100_Temperaturee_Minutes", headerName: "Temperature (minutes)", width: 80, filterType: "integer", },
                { colId: "CampbellPWS100_Temperaturee_Percent", field: "CampbellPWS100_Temperaturee_Percent", headerName: "Temperature (%)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v > 99.5), },
            ],
        },
        {
            headerName: "Thies 2D Compact",
            children: [
                { colId: "Thies2DCompact_WindSpeed_Percent", field: "Thies2DCompact_WindSpeed_Percent", headerName: "Wind speed (minutes)", width: 80, filterType: "integer", },
                { colId: "Thies2DCompact_WindSpeed_Percent", field: "Thies2DCompact_WindSpeed_Percent", headerName: "Wind speed (%)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v > 99.5), },
            ],
        },
        {
            headerName: "Digital temp probe",
            children: [
                { colId: "DigitalTempProbe_Temperature_Minutes", field: "DigitalTempProbe_Temperature_Minutes", headerName: "Temperature (minutes)", width: 80, filterType: "integer", },
                { colId: "DigitalTempProbe_Temperature_Percent", field: "DigitalTempProbe_Temperature_Percent", headerName: "Temperature (%)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v > 99.5), },
                { colId: "DigitalTempProbe_Temperature_Minimum", field: "DigitalTempProbe_Temperature_Minimum", headerName: "Min. temperature (°C)", width: 80, filterType: "integer", },
            ],
        },
        {
            headerName: "OTT Parsivel2",
            children: [
                { colId: "OTTParsivel2_WMOCode_Minutes", field: "OTTParsivel2_WMOCode_Minutes", headerName: "WMO (minutes)", width: 80, filterType: "integer", },
                { colId: "OTTParsivel2_WMOCode_Percent", field: "OTTParsivel2_WMOCode_Percent", headerName: "WMO (%)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v > 99.5), },
            ],
        },
        {
            headerName: "Vaisala FD71P",
            children: [
                { colId: "VaisalaFD71P_WMOCode_Minutes", field: "VaisalaFD71P_WMOCode_Minutes", headerName: "WMO (minutes)", width: 80, filterType: "integer", },
                { colId: "VaisalaFD71P_WMOCode_Percent", field: "VaisalaFD71P_WMOCode_Percent", headerName: "WMO (%)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v > 99.5), },
            ],
        },
        {
            headerName: "Thies LPM Extra",
            children: [
                { colId: "ThiesLPM_AmbientTemperature_Minutes", field: "ThiesLPM_AmbientTemperature_Minutes", headerName: "Ambient temperature (minutes)", width: 80, filterType: "integer", },
                { colId: "ThiesLPM_AmbientTemperature_Percent", field: "ThiesLPM_AmbientTemperature_Percent", headerName: "Ambient temperature (%)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v > 99.5), },
            ],
        },
        {
            headerName: "OTT Pluvio2",
            children: [
                { colId: "OTTPluvio2_BucketUnfiltered_Minutes", field: "OTTPluvio2_BucketUnfiltered_Minutes", headerName: "Bucket unfiltered (minutes)", width: 80, filterType: "integer", },
                { colId: "OTTPluvio2_BucketUnfiltered_Percent", field: "OTTPluvio2_BucketUnfiltered_Percent", headerName: "Bucket unfiltered (%)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v > 99.5), },
            ],
        },
        {
            headerName: "OTT Pluvio2 DFAR",
            children: [
                { colId: "OTTPluvio2DFAR_BucketUnfiltered_Minutes", field: "OTTPluvio2DFAR_BucketUnfiltered_Minutes", headerName: "Bucket unfiltered (minutes)", width: 80, filterType: "integer", },
                { colId: "OTTPluvio2DFAR_BucketUnfiltered_Percent", field: "OTTPluvio2DFAR_BucketUnfiltered_Percent", headerName: "Bucket unfiltered (%)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v > 99.5), },
            ],
        },
        {
            headerName: "Pond Hot Plate",
            children: [
                { colId: "PondHotPlate_PrecipitationRate_Minutes", field: "PondHotPlate_PrecipitationRate_Minutes", headerName: "Precipitation rate (minutes)", width: 80, filterType: "integer", },
                { colId: "PondHotPlate_PrecipitationRate_Percent", field: "PondHotPlate_PrecipitationRate_Percent", headerName: "Precipitation rate (%)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v > 99.5), },
            ],
        },
        {
            headerName: "MiniOFS",
            children: [
                { colId: "MiniOFS_Visibility_Minutes", field: "MiniOFS_Visibility_Minutes", headerName: "Visibility (minutes)", width: 80, filterType: "integer", },
                { colId: "MiniOFS_Visibility_Percent", field: "MiniOFS_Visibility_Percent", headerName: "Visibility (%)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v > 99.5), },
            ],
        },
        {
            headerName: "Vaisala PWD22",
            children: [
                { colId: "VaisalaFD12P_WMOCode_Minutes", field: "VaisalaFD12P_WMOCode_Minutes", headerName: "WMO (minutes)", width: 80, filterType: "integer", },
                { colId: "VaisalaFD12P_WMOCode_Percent", field: "VaisalaFD12P_WMOCode_Percent", headerName: "WMO (%)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v > 99.5), },
            ],
        },
        {
            headerName: "Vaisala WMT700",
            children: [
                { colId: "VaisalaWMT700_WindSpeed_Minutes", field: "VaisalaWMT700_WindSpeed_Minutes", headerName: "Wind speed (minutes)", width: 80, filterType: "integer", },
                { colId: "VaisalaWMT700_WindSpeed_Percent", field: "VaisalaWMT700_WindSpeed_Percent", headerName: "Wind speed (%)", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v > 99.5), },
            ],
        },
        {
            headerName: "Alarms",
            children: [
                { colId: "WeatherProfileErrorCount", field: "WeatherProfileErrorCount", headerName: "Weather profile error count", width: 80, filterType: "integer", cellRenderer: c => this.redGreenCellRenderer(c, v => v == 0), },
            ],
        },
    ];

    redGreenCellRenderer(c : ICellRendererParams, isGreen) : HTMLElement {
        var container = document.createElement('div');
        if (c.value != null) {
            container.innerHTML = c.value;
            if (isGreen(c.value)) {
                container.style.color = '#00AA00';
            } else {
                container.style.color = '#BB0000';
            }
        }
        return container;
    }
    
    export() {
        const query = Object.assign({
            Output: 'Excel',
            ColumnDefs: this.columnDefs,
        }, this.query);

        this.api.StationPerformanceExport.exportPost(query)
            .then((response) => this.fileDownloadService.download(response.body, (header) => response.headers.get(header)));
    }
} 
