import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'airportsNotEnabled',
    pure: false,
    standalone: true,
})
export class AirportsNotEnabledPipe implements PipeTransform {
    transform(airports, clientAirports): any {
        airports = airports || [];
        clientAirports = clientAirports || [];
        return airports.filter(a => clientAirports.every(ca => ca.AirportId !== a.Id));
    }
}
