<form #ngForm="ngForm" (submit)="save()" class="form">
    <page-title>{{('Sensor select matrix'|translate) + ': ' + (item.Name || 'new')}}</page-title>
    <div class="noMargins">
        <div class="toolbar">
            <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
            <a class="tab" *ngIf="stationType != 'Simulation'" (click)="switchTab('weatherType')" [class.active]="tab == 'weatherType'" [class.invalid]="!ngForm.form.controls.weatherTypeForm?.valid">{{'Weather type'|translate}}</a>
            <a class="tab" *ngIf="stationType != 'Simulation'" (click)="switchTab('intensity')" [class.active]="tab == 'intensity'" [class.invalid]="!ngForm.form.controls.intensityForm?.valid">{{'Intensity'|translate}}</a>
            <a class="tab" *ngIf="stationType != 'Simulation'" (click)="switchTab('sensors')" [class.active]="tab == 'sensors'" [class.invalid]="!ngForm.form.controls.sensorsForm?.valid">{{'Other sensors'|translate}}</a>
            <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" class="green" value="{{'Save'|translate}}" />
            <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
            <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        </div>
    
        <fieldset class="contentMargins" [disabled]="!('ConfigEditStations'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'Name'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
            </label>
            <label>
                <span class="caption">{{'Description'|translate}}</span>
                <input [(ngModel)]="item.Description" name="item.Description" />
            </label>
            <ng-container *ngIf="stationType == 'Simulation'">
                <label>
                    <span class="caption">{{'Weather category'|translate}}</span>
                    <select [(ngModel)]="item.WeatherCategory" name="item.WeatherCategory">
                        <option [ngValue]="null">{{'Not available'}}</option>
                        <option *ngFor="let weatherCategory of weatherCategories|keys" [ngValue]="weatherCategory">{{weatherCategories[weatherCategory] + ' (' + weatherCategory + ')'}}</option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'LWE (g/dm²/h)'|translate}}</span>
                    <input type="number" [(ngModel)]="item.LWE" step="any" name="item.LWE" min="0" />
                </label>
                <label>
                    <span class="caption">{{'Temperature (°C)'|translate}}</span>
                    <input type="number" [(ngModel)]="item.Temperature" step="any" name="item.Temperature" />
                </label>
                <label>
                    <span class="caption">{{'RelativeHumidity (RH%)'|translate}}</span>
                    <input type="number" [(ngModel)]="item.RelativeHumidity" step="any" name="item.RelativeHumidity" min="0" max="100" />
                </label>
                <label>
                    <span class="caption">{{'Active Frost temp (°C)'|translate}}</span>
                    <input type="number" [(ngModel)]="item.ConeSurfaceTemperature" step="any" name="item.ConeSurfaceTemperature" />
                </label>
                <label>
                    <span class="caption">{{'Wind speed (m/s)'|translate}}</span>
                    <input type="number" [(ngModel)]="item.WindSpeed" step="any" name="item.WindSpeed" min="0" />
                </label>
                <label>
                    <span class="caption">{{'Wind direction (°)'|translate}}</span>
                    <input type="number" [(ngModel)]="item.WindDirection" step="any" name="item.WindDirection" min="0" max="360" />
                </label>
                <label>
                    <span class="caption">{{'Visibility (m)'|translate}}</span>
                    <input type="number" [(ngModel)]="item.Visibility" step="any" name="item.Visibility" min="0" />
                </label>
            </ng-container>
            <ng-container *ngIf="stationType != 'Simulation'">
                <label>
                    <span class="caption">{{'Generate weather profiles with updated temperature every minute'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.GenerateWeatherProfileEveryMinute" name="item.GenerateWeatherProfileEveryMinute" />
                </label>
                <label>
                    <span class="caption">{{'Assume LWE is 0 when weather category is clear'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.AssumeLWEZeroWhenWeatherIsClear" name="item.AssumeLWEZeroWhenWeatherIsClear" />
                </label>
                <label>
                    <span class="caption">{{'Minimum LWE'|translate}}</span>
                    <input type="number" step="any" [(ngModel)]="item.MinimumLWE" name="item.MinimumLWE" required />
                </label>
            </ng-container>
            <ng-container *ngIf="stationType == 'Gen2'">
                <label>
                    <span class="caption">{{'LWE equation profile'|translate}}</span>
                    <select required [(ngModel)]="item.LWEEquationProfileId" name="item.LWEEquationProfileId">
                        <option *ngFor="let profile of lweEquationProfiles|orderBy:['Name']" [ngValue]="profile.Id">
                            {{profile.Name}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'Most recent weather type count profile'|translate}}</span>
                    <select [(ngModel)]="item.WeatherTypeCountProfileId" name="item.WeatherTypeCountProfileId">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let profile of weatherTypeCountProfiles|orderBy:['Name']" [ngValue]="profile.Id">
                            {{profile.Name}}
                        </option>
                    </select>
                </label>
                <label *ngIf="item.WeatherTypeCountProfileId">
                    <span class="caption">{{'Weather type NG to category mapping profile'|translate}}</span>
                    <select [required]="item.WeatherTypeCountProfileId" [(ngModel)]="item.WTNgToCategoryProfileId" name="item.WTNgToCategoryProfileId">
                        <option *ngFor="let profile of weatherTypeNgToCategoryMappingProfiles|orderBy:['Name']" [ngValue]="profile.Id">
                            {{profile.Name}}
                        </option>
                    </select>
                </label>
                <label *ngIf="item.WeatherTypeCountProfileId">
                    <span class="caption">{{'Instant weather type algorithm'|translate}}</span>
                    <select [required]="item.WeatherTypeCountProfileId" [(ngModel)]="item.InstantWeatherTypeAlgorithm" name="item.InstantWeatherTypeAlgorithm">
                        <option *ngFor="let option of instantWeatherTypeAlgorithms|keys" [ngValue]="option">
                            {{instantWeatherTypeAlgorithms[option]}}
                        </option>
                    </select>
                </label>
                <label *ngIf="item.WeatherTypeCountProfileId && item.InstantWeatherTypeAlgorithm == 'InstantWeatherTypeAlgorithm20202021'">
                    <span class="caption">{{'Instant weather type rule profile'|translate}}</span>
                    <select [required]="item.WeatherTypeCountProfileId && item.InstantWeatherTypeAlgorithm == 'InstantWeatherTypeAlgorithm20202021'" [(ngModel)]="item.InstantWeatherTypeRuleProfileId" name="item.InstantWeatherTypeRuleProfileId">
                        <option *ngFor="let profile of instantWeatherTypeRuleProfiles|orderBy:['Name']" [ngValue]="profile.Id">
                            {{profile.Name}}
                        </option>
                    </select>
                </label>
            </ng-container>
            <ng-container *ngIf="stationType != 'Simulation' && item.OverrideWeatherCategoryConfiguration != null">
                <h3>{{'Override weather category to snow'|translate}}</h3>
                <label>
                    <span class="caption">{{'Enabled'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.OverrideWeatherCategoryConfiguration.Enabled" name="item.OverrideWeatherCategoryConfiguration.Enabled" />
                </label>
                <label *ngIf="item.OverrideWeatherCategoryConfiguration.Enabled">
                    <span class="caption">{{'Temp &gt; (°C)'|translate}}</span>
                    <input [required]="item.OverrideWeatherCategoryConfiguration.Enabled" type="number" step="any" [(ngModel)]="item.OverrideWeatherCategoryConfiguration.TempLow" name="item.OverrideWeatherCategoryConfiguration.TempLow" max="{{item.OverrideWeatherCategoryConfiguration.TempHigh - 0.01}}" style="width: 100px;" />
                </label>
                <label *ngIf="item.OverrideWeatherCategoryConfiguration.Enabled">
                    <span class="caption">{{'Temp &lt; (°C)'|translate}}</span>
                    <input [required]="item.OverrideWeatherCategoryConfiguration.Enabled" type="number" step="any" [(ngModel)]="item.OverrideWeatherCategoryConfiguration.TempHigh" name="item.OverrideWeatherCategoryConfiguration.TempHigh" min="{{item.OverrideWeatherCategoryConfiguration.TempLow + 0.01}}" style="width: 100px;" />
                </label>
            </ng-container>
        </fieldset>

        <fieldset class="contentMargins" *ngIf="stationType != 'Simulation'" [disabled]="!('ConfigEditStations'|hasPermission)" [hidden]="tab != 'weatherType'" ngModelGroup="weatherTypeForm">
            <h2 *ngIf="stationType != 'Gen2'" style="margin-top: 0;">{{'Combinations'|translate}}&nbsp;&nbsp;<input type="button" class="green" *ngIf="('ConfigAddStations'|hasPermission)" (click)="addSensorSelectMatrixWeatherTypeSensorMapping()" value="{{'Add'|translate}}" /></h2>
            <table *ngIf="stationType != 'Gen2'" class="table">
                <tr>
                    <th>{{'#'|translate}}</th>
                    <th>{{'Order'|translate}}</th>
                    <th>{{'Reference'|translate}}</th>
                    <th>{{'Sensors'|translate}}</th>
                    <th>{{'Remote endpoint'|translate}}</th>
                    <th>{{'Most recent weather type counts'|translate}}</th>
                    <th>{{'Instant weather type algorithm'|translate}}</th>
                    <th>{{'Instant weather type rules'|translate}}</th>
                    <th>{{'Weather type NG to category mappings'|translate}}</th>
                    <th>{{'Use Gen 1/2 algorithm'|translate}}</th>
                    <th>{{'WMO code mapping'|translate}}</th>
                    <th></th>
                </tr>
                <tr *ngFor="let sensorSelectMatrixWeatherTypeSensorMapping of item.SensorSelectMatrixWeatherTypeSensorMappings; let i = index">
                    <td>{{sensorSelectMatrixWeatherTypeSensorMapping.Id || ('new'|translate)}}</td>
                    <td><input type="number" required step="1" min="0" style="width: 60px;" [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.Order" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].Order'" /></td>
                    <td>
                        <input type="text" style="width: 100px;" [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.Reference" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].Reference'" />
                    </td>
                    <td>
                        <multiselect [all]="false" [format]="'string'" required [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.RequiredWeatherSensors" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].RequiredWeatherSensors'">
                            <multiselect-option *ngFor="let option of weatherTypeSensorTypes|keys" [value]="option" [name]="weatherTypeSensorTypes[option]"></multiselect-option>
                        </multiselect>
                    </td>
                    <td>
                        <select style="width: 200px;" [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.WeatherCategoryEndpointId" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].WeatherCategoryEndpointId'">
                            <option [ngValue]="null">{{'None'|translate}}</option>
                            <option *ngFor="let profile of remoteEndpoints|filter:{Type:'WeatherCategory'}|orderBy:['Name']" [ngValue]="profile.Id">
                                {{profile.Name}}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.WeatherTypeCountProfileId" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].WeatherTypeCountProfileId'">
                            <option [ngValue]="null">{{'None'|translate}}</option>
                            <option *ngFor="let profile of weatherTypeCountProfiles|orderBy:['Name']" [ngValue]="profile.Id">
                                {{profile.Name}}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select *ngIf="sensorSelectMatrixWeatherTypeSensorMapping.WeatherTypeCountProfileId" [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.InstantWeatherTypeAlgorithm" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].InstantWeatherTypeAlgorithm'">
                            <option [ngValue]="null">{{'None'|translate}}</option>
                            <option *ngFor="let option of instantWeatherTypeAlgorithms|keys" [ngValue]="option">
                                {{instantWeatherTypeAlgorithms[option]}}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select *ngIf="sensorSelectMatrixWeatherTypeSensorMapping.WeatherTypeCountProfileId && sensorSelectMatrixWeatherTypeSensorMapping.InstantWeatherTypeAlgorithm == 'InstantWeatherTypeAlgorithm20202021'" required [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.InstantWeatherTypeRuleProfileId" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].InstantWeatherTypeRuleProfileId'" [selectedIndex]="-1">
                            <option *ngFor="let weatherTypeMappingProfile of instantWeatherTypeRuleProfiles|orderBy:'Name'" [ngValue]="weatherTypeMappingProfile.Id">
                                {{weatherTypeMappingProfile.Name}}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select *ngIf="sensorSelectMatrixWeatherTypeSensorMapping.WeatherTypeCountProfileId" required [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.WTNgToCategoryProfileId" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].WTNgToCategoryProfileId'">
                            <option *ngFor="let profile of weatherTypeNgToCategoryMappingProfiles|orderBy:['Name']" [ngValue]="profile.Id">
                                {{profile.Name}}
                            </option>
                        </select>
                    </td>
                    <td>
                        <input type="checkbox" [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.UseGen2Algorithm" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].UseGen2Algorithm'" />
                    </td>
                    <td>
                        <div *ngIf="sensorSelectMatrixWeatherTypeSensorMapping.RequiredWeatherSensors?.replaceAll(' ', '') .split(',').includes('ThiesLPM')">
                            <h4 style="margin: 6px 0 2px 0">{{'Thies LPM'|translate}}</h4>
                            <select required [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.ThiesLPMWTMProfileId" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].ThiesLPMWTMProfileId'" [selectedIndex]="-1">
                                <option *ngFor="let weatherTypeMappingProfile of weatherTypeMappingProfiles|orderBy:'Name'" [ngValue]="weatherTypeMappingProfile.Id">
                                    {{weatherTypeMappingProfile.Name}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="sensorSelectMatrixWeatherTypeSensorMapping.RequiredWeatherSensors?.replaceAll(' ', '').split(',').includes('ThiesLPMSecondary')">
                            <h4 style="margin: 6px 0 2px 0">{{'Thies LPM Secondary'|translate}}</h4>
                            <select required [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.ThiesLPMSecondaryWTMProfileId" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].ThiesLPMSecondaryWTMProfileId'" [selectedIndex]="-1">
                                <option *ngFor="let weatherTypeMappingProfile of weatherTypeMappingProfiles|orderBy:'Name'" [ngValue]="weatherTypeMappingProfile.Id">
                                    {{weatherTypeMappingProfile.Name}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="sensorSelectMatrixWeatherTypeSensorMapping.RequiredWeatherSensors?.replaceAll(' ', '').split(',').includes('CampbellPWS100')">
                            <h4 style="margin: 6px 0 2px 0">{{'Campbell PWS100'|translate}}</h4>
                            <select required [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.CampbellPWS100WTMProfileId" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].CampbellPWS100WTMProfileId'" [selectedIndex]="-1">
                                <option *ngFor="let weatherTypeMappingProfile of weatherTypeMappingProfiles|orderBy:'Name'" [ngValue]="weatherTypeMappingProfile.Id">
                                    {{weatherTypeMappingProfile.Name}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="sensorSelectMatrixWeatherTypeSensorMapping.RequiredWeatherSensors?.replaceAll(' ', '').split(',').includes('OTTParsivel2')">
                            <h4 style="margin: 6px 0 2px 0">{{'OTT Parsivel2'|translate}}</h4>
                            <select required [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.OTTParsivel2WTMProfileId" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].OTTParsivel2WTMProfileId'" [selectedIndex]="-1">
                                <option *ngFor="let weatherTypeMappingProfile of weatherTypeMappingProfiles|orderBy:'Name'" [ngValue]="weatherTypeMappingProfile.Id">
                                    {{weatherTypeMappingProfile.Name}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="sensorSelectMatrixWeatherTypeSensorMapping.RequiredWeatherSensors?.replaceAll(' ', '').split(',').includes('OTTParsivel2Secondary')">
                            <h4 style="margin: 6px 0 2px 0">{{'OTT Parsivel2 Secondary'|translate}}</h4>
                            <select *ngIf="sensorSelectMatrixWeatherTypeSensorMapping.RequiredWeatherSensors?.replaceAll(' ', '').split(',').includes('OTTParsivel2Secondary')" required [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.OTTParsivel2SecondaryWTMProfileId" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].OTTParsivel2SecondaryWTMProfileId'" [selectedIndex]="-1">
                                <option *ngFor="let weatherTypeMappingProfile of weatherTypeMappingProfiles|orderBy:'Name'" [ngValue]="weatherTypeMappingProfile.Id">
                                    {{weatherTypeMappingProfile.Name}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="sensorSelectMatrixWeatherTypeSensorMapping.RequiredWeatherSensors?.replaceAll(' ', '').split(',').includes('VaisalaPWD22')">
                            <h4 style="margin: 6px 0 2px 0">{{'Vaisala PWD22'|translate}}</h4>
                            <select required [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.VaisalaPWD22WTMProfileId" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].VaisalaPWD22WTMProfileId'" [selectedIndex]="-1">
                                <option *ngFor="let weatherTypeMappingProfile of weatherTypeMappingProfiles|orderBy:'Name'" [ngValue]="weatherTypeMappingProfile.Id">
                                    {{weatherTypeMappingProfile.Name}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="sensorSelectMatrixWeatherTypeSensorMapping.RequiredWeatherSensors?.replaceAll(' ', '').split(',').includes('VaisalaPWD22Secondary')">
                            <h4 style="margin: 6px 0 2px 0">{{'Vaisala PWD22 Secondary'|translate}}</h4>
                            <select required [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.VaisalaPWD22SecondaryWTMProfileId" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].VaisalaPWD22SecondaryWTMProfileId'" [selectedIndex]="-1">
                                <option *ngFor="let weatherTypeMappingProfile of weatherTypeMappingProfiles|orderBy:'Name'" [ngValue]="weatherTypeMappingProfile.Id">
                                    {{weatherTypeMappingProfile.Name}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="sensorSelectMatrixWeatherTypeSensorMapping.RequiredWeatherSensors?.replaceAll(' ', '').split(',').includes('VaisalaFD71P')">
                            <h4 style="margin: 6px 0 2px 0">{{'Vaisala FD71P'|translate}}</h4>
                            <select required [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.VaisalaFD71PWTMProfileId" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].VaisalaFD71PWTMProfileId'" [selectedIndex]="-1">
                                <option *ngFor="let weatherTypeMappingProfile of weatherTypeMappingProfiles|orderBy:'Name'" [ngValue]="weatherTypeMappingProfile.Id">
                                    {{weatherTypeMappingProfile.Name}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="sensorSelectMatrixWeatherTypeSensorMapping.RequiredWeatherSensors?.replaceAll(' ', '').split(',').includes('MiniOFS')">
                            <h4 style="margin: 6px 0 2px 0">{{'MiniOFS'|translate}}</h4>
                            <select required [(ngModel)]="sensorSelectMatrixWeatherTypeSensorMapping.MiniOFSWTMProfileId" [name]="'sensorSelectMatrixWeatherTypeSensorMapping[' + i + '].MiniOFSWTMProfileId'" [selectedIndex]="-1">
                                <option *ngFor="let weatherTypeMappingProfile of weatherTypeMappingProfiles|orderBy:'Name'" [ngValue]="weatherTypeMappingProfile.Id">
                                    {{weatherTypeMappingProfile.Name}}
                                </option>
                            </select>
                        </div>
                    </td>
                    <td>
                        <a *ngIf="'ConfigEditStations'|hasPermission" (click)="removeSensorSelectMatrixWeatherTypeSensorMapping(sensorSelectMatrixWeatherTypeSensorMapping)" class="mdi mdi-delete"></a>
                    </td>
                </tr>
            </table>

            <h2>{{'Sensors'|translate}}</h2>
            <table class="table" *ngIf="stationType != 'Simulation' && (item.$promise|async) != null">
                <tr>
                    <th>{{'Sensor'|translate}}</th>
                    <th>{{'Enabled'|translate}}</th>
                    <th width="80">{{'# of 1 min. samples'|translate}}</th>
                    <th width="80">{{'Minimum samples'|translate}}</th>
                    <th width="120">
                        <span>{{'WMO code mapping profile'|translate}}</span>
                    </th>
                </tr>
                <tr *ngIf="stationType == 'Gen2'" [sensor-selection-configuration]="item.WMOVaisalaFD12P" property="item.WMOVaisalaFD12P" type="WMO" [group]="groups.WMO" [name]="'Vaisala FD12P'|translate" [item]="item" [stationType]="stationType" [weatherTypeMappingProfiles]="weatherTypeMappingProfiles" weatherTypeMappingProfileProperty="FD12PWTMProfileId"></tr>
                <tr *ngIf="stationType == 'Gen2'" [sensor-selection-configuration]="item.WMOVaisalaFD12P15Minute" property="item.WMOVaisalaFD12P15Minute" type="WMO" [group]="groups.WMO" [name]="'Vaisala FD12P 15 min.'|translate" [item]="item" [stationType]="stationType" [weatherTypeMappingProfiles]="weatherTypeMappingProfiles" weatherTypeMappingProfileProperty="FD12P15MinuteWTMProfileId"></tr>
                <tr [sensor-selection-configuration]="item.WMOThiesLPM" property="item.WMOThiesLPM" type="WMO" [group]="groups.WMO" [name]="'Thies LPM'|translate" [item]="item" [stationType]="stationType" [weatherTypeMappingProfiles]="weatherTypeMappingProfiles" weatherTypeMappingProfileProperty="ThiesLPMWTMProfileId"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.WMOThiesLPMSecondary" property="item.WMOThiesLPMSecondary" type="WMO" [group]="groups.WMO" [name]="'Thies LPM Secondary'|translate" [item]="item" [stationType]="stationType" [weatherTypeMappingProfiles]="weatherTypeMappingProfiles" weatherTypeMappingProfileProperty="ThiesLPMSecondaryWTMProfileId"></tr>
                <tr [sensor-selection-configuration]="item.WMOCampbellPWS100" property="item.WMOCampbellPWS100" type="WMO" [group]="groups.WMO" [name]="'Campbell PWS100'|translate" [item]="item" [stationType]="stationType" [weatherTypeMappingProfiles]="weatherTypeMappingProfiles" weatherTypeMappingProfileProperty="CampbellPWS100WTMProfileId"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.WMOOTTParsivel2" property="item.WMOOTTParsivel2" type="WMO" [group]="groups.WMO" [name]="'OTT Parsivel 2'|translate" [item]="item" [stationType]="stationType" [weatherTypeMappingProfiles]="weatherTypeMappingProfiles" weatherTypeMappingProfileProperty="OTTParsivel2WTMProfileId"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.WMOOTTParsivel2Secondary" property="item.WMOOTTParsivel2Secondary" type="WMO" [group]="groups.WMO" [name]="'OTT Parsivel 2 Secondary'|translate" [item]="item" [stationType]="stationType" [weatherTypeMappingProfiles]="weatherTypeMappingProfiles" weatherTypeMappingProfileProperty="OTTParsivel2SecondaryWTMProfileId"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.WMOVaisalaPWD22" property="item.WMOVaisalaPWD22" type="WMO" [group]="groups.WMO" [name]="'Vaisala PWD22'|translate" [item]="item" [stationType]="stationType" [weatherTypeMappingProfiles]="weatherTypeMappingProfiles" weatherTypeMappingProfileProperty="VaisalaPWD22WTMProfileId"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.WMOVaisalaPWD22Secondary" property="item.WMOVaisalaPWD22Secondary" type="WMO" [group]="groups.WMO" [name]="'Vaisala PWD22 Secondary'|translate" [item]="item" [stationType]="stationType" [weatherTypeMappingProfiles]="weatherTypeMappingProfiles" weatherTypeMappingProfileProperty="VaisalaPWD22SecondaryWTMProfileId"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.WMOVaisalaFD71P" property="item.WMOVaisalaFD71P" type="WMO" [group]="groups.WMO" [name]="'Vaisala FD71P'|translate" [item]="item" [stationType]="stationType" [weatherTypeMappingProfiles]="weatherTypeMappingProfiles" weatherTypeMappingProfileProperty="VaisalaFD71PWTMProfileId"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.WMOMiniOFS" property="item.WMOMiniOFS" type="WMO" [group]="groups.WMO" [name]="'MiniOFS'|translate" [item]="item" [stationType]="stationType" [weatherTypeMappingProfiles]="weatherTypeMappingProfiles" weatherTypeMappingProfileProperty="MiniOFSWTMProfileId" style="border-bottom: 1px solid #d4d4d4;"></tr>
                <tr *ngIf="stationType != 'Gen2' && item.WMOMiniOFS.Enabled" style="border-bottom: 1px solid #d4d4d4;">
                    <td colspan="10">
                        <h3>{{'MiniOFS Visibility to WMO code mappings'|translate}}</h3>
                        <table class="table">
                            <tr>
                                <th style="width: 90px;">{{'Max. visibility (m)'|translate}}</th>
                                <th style="width: 90px;">{{'WMO code'|translate}}</th>
                                <th></th>
                            </tr>
                            <tr *ngFor="let miniOFSVisibilityWmoCodeMapping of item.MiniOFSVisibilityWmoCodeMappings; let i = index">
                                <td>
                                    <input type="number" step="1" required min="0" [(ngModel)]="miniOFSVisibilityWmoCodeMapping.MaxVisibility" [name]="'miniOFSVisibilityWmoCodeMapping[' + i + '].MaxVisibility'" style="width: 100%;"/>
                                </td>
                                <td>
                                    <input type="number" step="1" required min="0" max="100" [(ngModel)]="miniOFSVisibilityWmoCodeMapping.WMOCode" [name]="'miniOFSVisibilityWmoCodeMapping[' + i + '].WMOCode'" style="width: 100%;"/>
                                </td>
                                <td>
                                    <a *ngIf="'ConfigEditStations'|hasPermission" (click)="removeMiniOFSVisibilityWmoCodeMapping(miniOFSVisibilityWmoCodeMapping)" class="mdi mdi-delete"></a>
                                </td>
                            </tr>
                        </table>
                        <br/>
                        <input type="button" class="green" *ngIf="'ConfigEditStations'|hasPermission" (click)="addMiniOFSVisibilityWmoCodeMapping()" value="{{'Add mapping'|translate}}"/>
                    </td>
                </tr>
                <tr *ngIf="stationType != 'Gen2' && (item.WMOThiesLPM.Enabled || item.WMOThiesLPMSecondary.Enabled)" style="border-bottom: 1px solid #d4d4d4;">
                    <td colspan="10">
                        <h3>{{'Thies LPM WMO code remapping profile'|translate}}</h3>
                        <select style="width: 200px;" [(ngModel)]="item.ThiesLPMWMOCodeMappingProfileId" [name]="'item.ThiesLPMWMOCodeMappingProfileId'">
                            <option [ngValue]="null">{{'None'|translate}}</option>
                            <option *ngFor="let profile of thiesLPMWMOCodeMappingProfiles" [ngValue]="profile.Id">
                                {{profile.Name}}
                            </option>
                        </select>
                    </td>
                </tr>
            </table>
        </fieldset>
        
        <fieldset class="contentMargins"  *ngIf="stationType != 'Simulation'" [disabled]="!('ConfigEditStations'|hasPermission)" [hidden]="tab != 'intensity'" ngModelGroup="intensityForm">
            <h2 *ngIf="stationType != 'Gen2'" style="margin-top: 0;">{{'Combinations'|translate}}&nbsp;&nbsp;<input type="button" class="green" *ngIf="('ConfigAddStations'|hasPermission)" (click)="addSensorSelectMatrixIntensitySensorMapping()" value="{{'Add'|translate}}" /></h2>
            <table *ngIf="stationType != 'Gen2'" class="table">
                <tr>
                    <th>{{'#'|translate}}</th>
                    <th>{{'Order'|translate}}</th>
                    <th>{{'Reference'|translate}}</th>
                    <th>{{'Sensors'|translate}}</th>
                    <th>{{'Snow endpoint'|translate}}</th>
                    <th>{{'SLD endpoint'|translate}}</th>
                    <th>{{'Remote snow LWE equations'|translate}}</th>
                    <th>{{'Remote SLD LWE equations'|translate}}</th>
                    <th>{{'Local LWE equations'|translate}}</th>
                    <th width="150">{{'Use OTT Pluvio2 NCAR LWE for snow'|translate}}</th>
                    <th width="150">{{'Use OTT Pluvio2 NCAR LWE for SLD'|translate}}</th>
                    <th>{{'Remote endpoint Thies LPM factor'|translate}}</th>
                    <th>{{'Remote endpoint Thies LPM offset'|translate}}</th>
                    <th></th>
                </tr>
                <tr *ngFor="let sensorSelectMatrixIntensitySensorMapping of item.SensorSelectMatrixIntensitySensorMappings; let i = index">
                    <td>{{sensorSelectMatrixIntensitySensorMapping.Id || ('new'|translate)}}</td>
                    <td><input type="number" required step="1" min="0" style="width: 60px;" [(ngModel)]="sensorSelectMatrixIntensitySensorMapping.Order" [name]="'sensorSelectMatrixIntensitySensorMapping[' + i + '].Order'" /></td>
                    <td>
                        <input type="text" style="width: 100px;" [(ngModel)]="sensorSelectMatrixIntensitySensorMapping.Reference" [name]="'sensorSelectMatrixIntensitySensorMapping[' + i + '].Reference'" />
                    </td>
                    <td>
                        <multiselect [all]="false" [format]="'string'" required [(ngModel)]="sensorSelectMatrixIntensitySensorMapping.RequiredWeatherSensors" [name]="'sensorSelectMatrixIntensitySensorMapping[' + i + '].RequiredWeatherSensors'">
                            <multiselect-option *ngFor="let option of intensitySensorTypes|keys" [value]="option" [name]="intensitySensorTypes[option]"></multiselect-option>
                        </multiselect>
                    </td>
                    <td>
                        <select style="width: 200px;" [(ngModel)]="sensorSelectMatrixIntensitySensorMapping.SnowLWEEndpointId" [name]="'sensorSelectMatrixIntensitySensorMapping[' + i + '].SnowLWEEndpointId'">
                            <option [ngValue]="null">{{'None'|translate}}</option>
                            <option *ngFor="let profile of remoteEndpoints|filter:{Type:'LWE'}|orderBy:['Name']" [ngValue]="profile.Id">
                                {{profile.Name}}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select style="width: 200px;" [(ngModel)]="sensorSelectMatrixIntensitySensorMapping.SLDLWEEndpointId" [name]="'sensorSelectMatrixIntensitySensorMapping[' + i + '].SLDLWEEndpointId'">
                            <option [ngValue]="null">{{'None'|translate}}</option>
                            <option *ngFor="let profile of remoteEndpoints|filter:{Type:'LWE'}|orderBy:['Name']" [ngValue]="profile.Id">
                                {{profile.Name}}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select *ngIf="sensorSelectMatrixIntensitySensorMapping.SnowLWEEndpointId" required [(ngModel)]="sensorSelectMatrixIntensitySensorMapping.RemoteSnowLWEEquationProfileId" [name]="'sensorSelectMatrixIntensitySensorMapping[' + i + '].RemoteSnowLWEEquationProfileId'" [selectedIndex]="-1">
                            <option *ngFor="let profile of lweEquationProfiles|orderBy:['Name']" [ngValue]="profile.Id">
                                {{profile.Name}}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select *ngIf="sensorSelectMatrixIntensitySensorMapping.SLDLWEEndpointId" required [(ngModel)]="sensorSelectMatrixIntensitySensorMapping.RemoteSLDLWEEquationProfileId" [name]="'sensorSelectMatrixIntensitySensorMapping[' + i + '].RemoteSLDLWEEquationProfileId'" [selectedIndex]="-1">
                            <option *ngFor="let profile of lweEquationProfiles|orderBy:['Name']" [ngValue]="profile.Id">
                                {{profile.Name}}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select [(ngModel)]="sensorSelectMatrixIntensitySensorMapping.LocalLWEEquationProfileId" [name]="'sensorSelectMatrixIntensitySensorMapping[' + i + '].LocalLWEEquationProfileId'">
                            <option [ngValue]="null">{{'None'|translate}}</option>
                            <option *ngFor="let profile of lweEquationProfiles|orderBy:['Name']" [ngValue]="profile.Id">
                                {{profile.Name}}
                            </option>
                        </select>
                    </td>
                    <td>
                        <input type="checkbox" [(ngModel)]="sensorSelectMatrixIntensitySensorMapping.SnowUseOTTPluvio2NCAR" [name]="'sensorSelectMatrixIntensitySensorMapping[' + i + '].SnowUseOTTPluvio2NCAR'" />
                    </td>
                    <td>
                        <input type="checkbox" [(ngModel)]="sensorSelectMatrixIntensitySensorMapping.SLDUseOTTPluvio2NCAR" [name]="'sensorSelectMatrixIntensitySensorMapping[' + i + '].SLDUseOTTPluvio2NCAR'" />
                    </td>
                    <td>
                        <input type="number" step="any" style="width: 70px;" [(ngModel)]="sensorSelectMatrixIntensitySensorMapping.ThiesLPMFactor" [name]="'sensorSelectMatrixIntensitySensorMapping[' + i + '].ThiesLPMFactor'" *ngIf="(sensorSelectMatrixIntensitySensorMapping.SnowLWEEndpointId != null || sensorSelectMatrixIntensitySensorMapping.SLDLWEEndpointId) && sensorSelectMatrixIntensitySensorMapping.RequiredWeatherSensors?.indexOf('ThiesLPM') > -1" />
                    </td>
                    <td>
                        <input type="number" step="any" style="width: 70px;" [(ngModel)]="sensorSelectMatrixIntensitySensorMapping.ThiesLPMOffset" [name]="'sensorSelectMatrixIntensitySensorMapping[' + i + '].ThiesLPMOffset'" *ngIf="(sensorSelectMatrixIntensitySensorMapping.SnowLWEEndpointId != null || sensorSelectMatrixIntensitySensorMapping.SLDLWEEndpointId) && sensorSelectMatrixIntensitySensorMapping.RequiredWeatherSensors?.indexOf('ThiesLPM') > -1" />
                    </td>
                    <td>
                        <a *ngIf="'ConfigEditStations'|hasPermission" (click)="removeSensorSelectMatrixIntensitySensorMapping(sensorSelectMatrixIntensitySensorMapping)" class="mdi mdi-delete"></a>
                    </td>
                </tr>
            </table>
            <h2>{{'Sensors'|translate}}</h2>
            <table class="table" *ngIf="(item.$promise|async) != null">
                <tr>
                    <th>{{'Sensor'|translate}}</th>
                    <th>{{'Enabled'|translate}}</th>
                    <th width="80">{{'# of 1 min. samples'|translate}}</th>
                    <th width="80">{{'Minimum samples'|translate}}</th>
                    <th width="120">
                        <span *ngIf="stationType != 'Gen2'">{{'Snow spike correction?'|translate}}</span>
                    </th>
                </tr>
                <tr *ngIf="stationType == 'Gen2'" style="border-bottom: 1px solid #d4d4d4;">
                    <td>{{'Sartorius'|translate}}</td>
                    <td><input type="checkbox" [(ngModel)]="item.IntensitySartorius.Enabled" name="item.IntensitySartorius.Enabled" /></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.IntensityThiesLPM" property="item.IntensityThiesLPM" type="Intensity" [group]="groups.Intensity" [name]="'Thies LPM'|translate" [item]="item" [stationType]="stationType"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.IntensityThiesLPMSecondary" property="item.IntensityThiesLPMSecondary" type="Intensity" [group]="groups.Intensity" [name]="'Thies LPM Secondary'|translate" [item]="item" [stationType]="stationType"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.IntensityCampbellPWS100" property="item.IntensityCampbellPWS100" type="Intensity" [group]="groups.Intensity" [name]="'Campbell PWS100'|translate" [item]="item" [stationType]="stationType"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.IntensityOTTParsivel2" property="item.IntensityOTTParsivel2" type="Intensity" [group]="groups.Intensity" [name]="'OTT Parsivel 2'|translate" [item]="item" [stationType]="stationType"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.IntensityOTTParsivel2Secondary" property="item.IntensityOTTParsivel2Secondary" type="Intensity" [group]="groups.Intensity" [name]="'OTT Parsivel 2 Secondary'|translate" [item]="item" [stationType]="stationType"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.IntensityVaisalaPWD22" property="item.IntensityVaisalaPWD22" type="Intensity" [group]="groups.Intensity" [name]="'Vaisala PWD22'|translate" [item]="item" [stationType]="stationType"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.IntensityVaisalaPWD22Secondary" property="item.IntensityVaisalaPWD22Secondary" type="Intensity" [group]="groups.Intensity" [name]="'Vaisala PWD22 Secondary'|translate" [item]="item" [stationType]="stationType"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.IntensityVaisalaFD71P" property="item.IntensityVaisalaFD71P" type="Intensity" [group]="groups.Intensity" [name]="'Vaisala FD71P'|translate" [item]="item" [stationType]="stationType"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.IntensityPondHotPlate" property="item.IntensityPondHotPlate" type="Intensity" [group]="groups.Intensity" [name]="'Pond Hot Plate'|translate" [item]="item" [stationType]="stationType"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.IntensityOTTPluvio2" property="item.IntensityOTTPluvio2" type="Intensity" [group]="groups.Intensity" [name]="'OTT Pluvio2'|translate" [item]="item" [stationType]="stationType"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.IntensityOTTPluvio2DFAR" property="item.IntensityOTTPluvio2DFAR" type="Intensity" [group]="groups.Intensity" [name]="'OTT Pluvio2 DFAR'|translate" [item]="item" [stationType]="stationType"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.IntensityGeonorT200DA" property="item.IntensityGeonorT200DA" type="Intensity" [group]="groups.Intensity" [name]="'Geonor T200 DA'|translate" [item]="item" [stationType]="stationType"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.IntensityGeonorT200DFAR" property="item.IntensityGeonorT200DFAR" type="Intensity" [group]="groups.Intensity" [name]="'Geonor T200 DFAR'|translate" [item]="item" [stationType]="stationType"></tr>

                <tr *ngIf="stationType != 'Gen2'">
                    <td>{{'OTT Pluvio2 (NCAR)'|translate}}</td>
                    <td><input type="checkbox" [(ngModel)]="item.NCARIntensityOTTPluvio2.Enabled" name="item.NCARIntensityOTTPluvio2.Enabled" /></td>
                    <td><input *ngIf="item.NCARIntensityOTTPluvio2.Enabled" [required]="item.NCARIntensityOTTPluvio2.Enabled" type="number" step="1" min="1" [(ngModel)]="item.NCARIntensityOTTPluvio2.SampleCount" name="item.NCARIntensityOTTPluvio2.SampleCount" style="width: 50px;" /></td>
                    <td><input *ngIf="item.NCARIntensityOTTPluvio2.Enabled" [required]="item.NCARIntensityOTTPluvio2.Enabled" type="number" step="1" min="1" max="{{item.NCARIntensityOTTPluvio2.SampleCount}}" [(ngModel)]="item.NCARIntensityOTTPluvio2.MinimumSampleCount" name="item.NCARIntensityOTTPluvio2.MinimumSampleCount" style="width: 50px;" /></td>
                    <td></td>
                </tr>
                <tr *ngIf="stationType != 'Gen2' && item.NCARIntensityOTTPluvio2.Enabled">
                    <td colspan="5">
                        <table class="table">
                            <tr><th>{{'Setting'|translate}}</th><th>{{'Value'|translate}}</th><th>{{'Unit'|translate}}</th><th>{{'Default'|translate}}</th><th style="width: 600px;">{{'Description'|translate}}</th></tr>
                            <tr><th>{{'Regression duration'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2.Enabled" type="number" step="1" min="1" [(ngModel)]="item.NCARIntensityOTTPluvio2.RegressionDurationCount" name="item.NCARIntensityOTTPluvio2.RegressionDurationCount" style="width: 50px;" /></td><td>{{'# samples'|translate}}</td><td>10</td><td>{{'This is the period over which the regression is performed. This should always be less than or equal to the filtering duration.'|translate}}</td></tr>
                            <tr><th>{{'Min. valid rate'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityOTTPluvio2.MinValidRateMMH" name="item.NCARIntensityOTTPluvio2.MinValidRateMMH" style="width: 50px;" /></td><td>{{'mm/h'|translate}}</td><td>0.15</td><td>{{'If the computed rate is less than this value, the rate is set to 0.'|translate}}</td></tr>
                            <tr><th>{{'Max. valid rate'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityOTTPluvio2.MaxValidRateMMH" name="item.NCARIntensityOTTPluvio2.MaxValidRateMMH" style="width: 50px;" /></td><td>{{'mm/h'|translate}}</td><td>250</td><td>{{'Maximum allowable Precip Rate (mm/h) Clamp max value to this number'|translate}}</td></tr>
                            <tr><th>{{'Apply automatic noise removal'|translate}}</th><td><input type="checkbox" [(ngModel)]="item.NCARIntensityOTTPluvio2.ApplyAutoNoiseRemoval" name="item.NCARIntensityOTTPluvio2.ApplyAutoNoiseRemoval" /></td><td>{{'Yes/no'|translate}}</td><td>Yes</td><td>{{'If set, a forward and backward pass are made through the data, removing the variation due to sensor noise.'|translate}}</td></tr>
                            <tr><th>{{'Minimum accumulation during regression period'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2.Enabled" type="number" step="any" [(ngModel)]="item.NCARIntensityOTTPluvio2.MinAccumulationInRegressionPeriodMM" name="item.NCARIntensityOTTPluvio2.MinAccumulationInRegressionPeriodMM" style="width: 50px;" /></td><td>{{'mm'|translate}}</td><td>0.03</td><td>{{'If the computed accumulation is less than this value, the rate is set to 0.'|translate}}</td></tr>
                            <tr><th>{{'Max gauge noise value'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityOTTPluvio2.MaxValidNoiseValue" name="item.NCARIntensityOTTPluvio2.MaxValidNoiseValue" style="width: 50px;" /></td><td>{{'mm'|translate}}</td><td>0.5</td><td>{{'If the gauge noise exceeds this value, the rate is marked bad.'|translate}}</td></tr>
                            <tr><th>{{'Temp. at which precip is considered all rain'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2.Enabled" type="number" step="any" [(ngModel)]="item.NCARIntensityOTTPluvio2.RainMinTemp" name="item.NCARIntensityOTTPluvio2.RainMinTemp" style="width: 50px;" /></td><td>{{'C'|translate}}</td><td>5</td><td>{{'This is used for computing the gauge catch efficiency. Efficiency is assumed to vary linearly between snow and rain temperatures.'|translate}}</td></tr>
                            <tr><th>{{'Temp. at which precip is considered all snow'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2.Enabled" type="number" step="any" [(ngModel)]="item.NCARIntensityOTTPluvio2.SnowMinTemp" name="item.NCARIntensityOTTPluvio2.SnowMinTemp" style="width: 50px;" /></td><td>{{'C'|translate}}</td><td>0</td><td>{{'This is used for computing the gauge catch efficiency. Efficiency is assumed to vary linearly between snow and rain temperatures.'|translate}}</td></tr>
                            <tr><th>{{'Catch bias'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityOTTPluvio2.CatchBias" name="item.NCARIntensityOTTPluvio2.CatchBias" style="width: 50px;" /></td><td>{{'%'|translate}}</td><td>100</td><td>{{'The gauge catch efficiency is assumed to be inversely linearly related to wind speed. The catch bias is the catch efficiency, in %, at 0 wind speed. This is the intercept of the linear fit at speed = 0. The catch slope is the rate at which the catch efficiency decreases with wind speed, in % per m/s. It is the slope of the catch efficiency line. Catch efficiency is computed as (catch bias + (wind speed * catch slope)). The catch efficiency is applicable to snow, and is used for any temperatures below temp_snow. The efficiency for rain (temp above temp_rain) is assumed to be 100%. The efficiency for mixed precipitation (between temp_rain and temp_snow) is assumed to vary linearly with temperature. catch_min is the minimum allowable value for catch efficiency. If the value drops below this, it is set to the minimum value.'|translate}}</td></tr>
                            <tr><th>{{'Catch slope'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2.Enabled" type="number" step="any" [(ngModel)]="item.NCARIntensityOTTPluvio2.CatchSlope" name="item.NCARIntensityOTTPluvio2.CatchSlope" style="width: 50px;" /></td><td></td><td>-7.1</td><td></td></tr>
                            <tr><th>{{'Catch min.'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityOTTPluvio2.CatchMin" name="item.NCARIntensityOTTPluvio2.CatchMin" style="width: 50px;" /></td><td>{{'%'|translate}}</td><td>25</td><td></td></tr>
                        </table>
                    </td>
                </tr>

                <tr *ngIf="stationType != 'Gen2'">
                    <td>{{'OTT Pluvio2 DFAR (NCAR)'|translate}}</td>
                    <td><input type="checkbox" [(ngModel)]="item.NCARIntensityOTTPluvio2DFAR.Enabled" name="item.NCARIntensityOTTPluvio2DFAR.Enabled" /></td>
                    <td><input *ngIf="item.NCARIntensityOTTPluvio2DFAR.Enabled" [required]="item.NCARIntensityOTTPluvio2DFAR.Enabled" type="number" step="1" min="1" [(ngModel)]="item.NCARIntensityOTTPluvio2DFAR.SampleCount" name="item.NCARIntensityOTTPluvio2DFAR.SampleCount" style="width: 50px;" /></td>
                    <td><input *ngIf="item.NCARIntensityOTTPluvio2DFAR.Enabled" [required]="item.NCARIntensityOTTPluvio2DFAR.Enabled" type="number" step="1" min="1" max="{{item.NCARIntensityOTTPluvio2DFAR.SampleCount}}" [(ngModel)]="item.NCARIntensityOTTPluvio2DFAR.MinimumSampleCount" name="item.NCARIntensityOTTPluvio2DFAR.MinimumSampleCount" style="width: 50px;" /></td>
                    <td></td>
                </tr>
                <tr *ngIf="stationType != 'Gen2' && item.NCARIntensityOTTPluvio2DFAR.Enabled" style="border-bottom: 1px solid #d4d4d4;">
                    <td colspan="5">
                        <table class="table">
                            <tr><th>{{'Setting'|translate}}</th><th>{{'Value'|translate}}</th><th>{{'Unit'|translate}}</th><th>{{'Default'|translate}}</th><th style="width: 600px;">{{'Description'|translate}}</th></tr>
                            <tr><th>{{'Regression duration'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2DFAR.Enabled" type="number" step="1" min="1" [(ngModel)]="item.NCARIntensityOTTPluvio2DFAR.RegressionDurationCount" name="item.NCARIntensityOTTPluvio2DFAR.RegressionDurationCount" style="width: 50px;" /></td><td>{{'# samples'|translate}}</td><td>10</td><td>{{'This is the period over which the regression is performed. This should always be less than or equal to the filtering duration.'|translate}}</td></tr>
                            <tr><th>{{'Min. valid rate'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2DFAR.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityOTTPluvio2DFAR.MinValidRateMMH" name="item.NCARIntensityOTTPluvio2DFAR.MinValidRateMMH" style="width: 50px;" /></td><td>{{'mm/h'|translate}}</td><td>0.15</td><td>{{'If the computed rate is less than this value, the rate is set to 0.'|translate}}</td></tr>
                            <tr><th>{{'Max. valid rate'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2DFAR.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityOTTPluvio2DFAR.MaxValidRateMMH" name="item.NCARIntensityOTTPluvio2DFAR.MaxValidRateMMH" style="width: 50px;" /></td><td>{{'mm/h'|translate}}</td><td>250</td><td>{{'Maximum allowable Precip Rate (mm/h) Clamp max value to this number'|translate}}</td></tr>
                            <tr><th>{{'Apply automatic noise removal'|translate}}</th><td><input type="checkbox" [(ngModel)]="item.NCARIntensityOTTPluvio2DFAR.ApplyAutoNoiseRemoval" name="item.NCARIntensityOTTPluvio2DFAR.ApplyAutoNoiseRemoval" /></td><td>{{'Yes/no'|translate}}</td><td>Yes</td><td>{{'If set, a forward and backward pass are made through the data, removing the variation due to sensor noise.'|translate}}</td></tr>
                            <tr><th>{{'Minimum accumulation during regression period'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2DFAR.Enabled" type="number" step="any" [(ngModel)]="item.NCARIntensityOTTPluvio2DFAR.MinAccumulationInRegressionPeriodMM" name="item.NCARIntensityOTTPluvio2DFAR.MinAccumulationInRegressionPeriodMM" style="width: 50px;" /></td><td>{{'mm'|translate}}</td><td>0.03</td><td>{{'If the computed accumulation is less than this value, the rate is set to 0.'|translate}}</td></tr>
                            <tr><th>{{'Max gauge noise value'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2DFAR.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityOTTPluvio2DFAR.MaxValidNoiseValue" name="item.NCARIntensityOTTPluvio2DFAR.MaxValidNoiseValue" style="width: 50px;" /></td><td>{{'mm'|translate}}</td><td>0.5</td><td>{{'If the gauge noise exceeds this value, the rate is marked bad.'|translate}}</td></tr>
                            <tr><th>{{'Temp. at which precip is considered all rain'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2DFAR.Enabled" type="number" step="any" [(ngModel)]="item.NCARIntensityOTTPluvio2DFAR.RainMinTemp" name="item.NCARIntensityOTTPluvio2DFAR.RainMinTemp" style="width: 50px;" /></td><td>{{'C'|translate}}</td><td>5</td><td>{{'This is used for computing the gauge catch efficiency. Efficiency is assumed to vary linearly between snow and rain temperatures.'|translate}}</td></tr>
                            <tr><th>{{'Temp. at which precip is considered all snow'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2DFAR.Enabled" type="number" step="any" [(ngModel)]="item.NCARIntensityOTTPluvio2DFAR.SnowMinTemp" name="item.NCARIntensityOTTPluvio2DFAR.SnowMinTemp" style="width: 50px;" /></td><td>{{'C'|translate}}</td><td>0</td><td>{{'This is used for computing the gauge catch efficiency. Efficiency is assumed to vary linearly between snow and rain temperatures.'|translate}}</td></tr>
                            <tr><th>{{'Catch bias'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2DFAR.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityOTTPluvio2DFAR.CatchBias" name="item.NCARIntensityOTTPluvio2DFAR.CatchBias" style="width: 50px;" /></td><td>{{'%'|translate}}</td><td>100</td><td>{{'The gauge catch efficiency is assumed to be inversely linearly related to wind speed. The catch bias is the catch efficiency, in %, at 0 wind speed. This is the intercept of the linear fit at speed = 0. The catch slope is the rate at which the catch efficiency decreases with wind speed, in % per m/s. It is the slope of the catch efficiency line. Catch efficiency is computed as (catch bias + (wind speed * catch slope)). The catch efficiency is applicable to snow, and is used for any temperatures below temp_snow. The efficiency for rain (temp above temp_rain) is assumed to be 100%. The efficiency for mixed precipitation (between temp_rain and temp_snow) is assumed to vary linearly with temperature. catch_min is the minimum allowable value for catch efficiency. If the value drops below this, it is set to the minimum value.'|translate}}</td></tr>
                            <tr><th>{{'Catch slope'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2DFAR.Enabled" type="number" step="any" [(ngModel)]="item.NCARIntensityOTTPluvio2DFAR.CatchSlope" name="item.NCARIntensityOTTPluvio2DFAR.CatchSlope" style="width: 50px;" /></td><td></td><td>-7.1</td><td></td></tr>
                            <tr><th>{{'Catch min.'|translate}}</th><td><input [required]="item.NCARIntensityOTTPluvio2DFAR.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityOTTPluvio2DFAR.CatchMin" name="item.NCARIntensityOTTPluvio2DFAR.CatchMin" style="width: 50px;" /></td><td>{{'%'|translate}}</td><td>25</td><td></td></tr>
                        </table>
                    </td>
                </tr>

                <tr *ngIf="stationType != 'Gen2'">
                    <td>{{'Geonor T200 DA (NCAR)'|translate}}</td>
                    <td><input type="checkbox" [(ngModel)]="item.NCARIntensityGeonorT200DA.Enabled" name="item.NCARIntensityGeonorT200DA.Enabled" /></td>
                    <td><input *ngIf="item.NCARIntensityGeonorT200DA.Enabled" [required]="item.NCARIntensityGeonorT200DA.Enabled" type="number" step="1" min="1" [(ngModel)]="item.NCARIntensityGeonorT200DA.SampleCount" name="item.NCARIntensityGeonorT200DA.SampleCount" style="width: 50px;" /></td>
                    <td><input *ngIf="item.NCARIntensityGeonorT200DA.Enabled" [required]="item.NCARIntensityGeonorT200DA.Enabled" type="number" step="1" min="1" max="{{item.NCARIntensityGeonorT200DA.SampleCount}}" [(ngModel)]="item.NCARIntensityGeonorT200DA.MinimumSampleCount" name="item.NCARIntensityGeonorT200DA.MinimumSampleCount" style="width: 50px;" /></td>
                    <td></td>
                </tr>
                <tr *ngIf="stationType != 'Gen2' && item.NCARIntensityGeonorT200DA.Enabled">
                    <td colspan="5">
                        <table class="table">
                            <tr><th>{{'Setting'|translate}}</th><th>{{'Value'|translate}}</th><th>{{'Unit'|translate}}</th><th>{{'Default'|translate}}</th><th style="width: 600px;">{{'Description'|translate}}</th></tr>
                            <tr><th>{{'Regression duration'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DA.Enabled" type="number" step="1" min="1" [(ngModel)]="item.NCARIntensityGeonorT200DA.RegressionDurationCount" name="item.NCARIntensityGeonorT200DA.RegressionDurationCount" style="width: 50px;" /></td><td>{{'# samples'|translate}}</td><td>10</td><td>{{'This is the period over which the regression is performed. This should always be less than or equal to the filtering duration.'|translate}}</td></tr>
                            <tr><th>{{'Min. valid rate'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DA.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityGeonorT200DA.MinValidRateMMH" name="item.NCARIntensityGeonorT200DA.MinValidRateMMH" style="width: 50px;" /></td><td>{{'mm/h'|translate}}</td><td>0.15</td><td>{{'If the computed rate is less than this value, the rate is set to 0.'|translate}}</td></tr>
                            <tr><th>{{'Max. valid rate'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DA.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityGeonorT200DA.MaxValidRateMMH" name="item.NCARIntensityGeonorT200DA.MaxValidRateMMH" style="width: 50px;" /></td><td>{{'mm/h'|translate}}</td><td>250</td><td>{{'Maximum allowable Precip Rate (mm/h) Clamp max value to this number'|translate}}</td></tr>
                            <tr><th>{{'Apply automatic noise removal'|translate}}</th><td><input type="checkbox" [(ngModel)]="item.NCARIntensityGeonorT200DA.ApplyAutoNoiseRemoval" name="item.NCARIntensityGeonorT200DA.ApplyAutoNoiseRemoval" /></td><td>{{'Yes/no'|translate}}</td><td>Yes</td><td>{{'If set, a forward and backward pass are made through the data, removing the variation due to sensor noise.'|translate}}</td></tr>
                            <tr><th>{{'Minimum accumulation during regression period'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DA.Enabled" type="number" step="any" [(ngModel)]="item.NCARIntensityGeonorT200DA.MinAccumulationInRegressionPeriodMM" name="item.NCARIntensityGeonorT200DA.MinAccumulationInRegressionPeriodMM" style="width: 50px;" /></td><td>{{'mm'|translate}}</td><td>0.03</td><td>{{'If the computed accumulation is less than this value, the rate is set to 0.'|translate}}</td></tr>
                            <tr><th>{{'Max gauge noise value'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DA.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityGeonorT200DA.MaxValidNoiseValue" name="item.NCARIntensityGeonorT200DA.MaxValidNoiseValue" style="width: 50px;" /></td><td>{{'mm'|translate}}</td><td>0.5</td><td>{{'If the gauge noise exceeds this value, the rate is marked bad.'|translate}}</td></tr>
                            <tr><th>{{'Temp. at which precip is considered all rain'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DA.Enabled" type="number" step="any" [(ngModel)]="item.NCARIntensityGeonorT200DA.RainMinTemp" name="item.NCARIntensityGeonorT200DA.RainMinTemp" style="width: 50px;" /></td><td>{{'C'|translate}}</td><td>5</td><td>{{'This is used for computing the gauge catch efficiency. Efficiency is assumed to vary linearly between snow and rain temperatures.'|translate}}</td></tr>
                            <tr><th>{{'Temp. at which precip is considered all snow'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DA.Enabled" type="number" step="any" [(ngModel)]="item.NCARIntensityGeonorT200DA.SnowMinTemp" name="item.NCARIntensityGeonorT200DA.SnowMinTemp" style="width: 50px;" /></td><td>{{'C'|translate}}</td><td>0</td><td>{{'This is used for computing the gauge catch efficiency. Efficiency is assumed to vary linearly between snow and rain temperatures.'|translate}}</td></tr>
                            <tr><th>{{'Catch bias'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DA.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityGeonorT200DA.CatchBias" name="item.NCARIntensityGeonorT200DA.CatchBias" style="width: 50px;" /></td><td>{{'%'|translate}}</td><td>100</td><td>{{'The gauge catch efficiency is assumed to be inversely linearly related to wind speed. The catch bias is the catch efficiency, in %, at 0 wind speed. This is the intercept of the linear fit at speed = 0. The catch slope is the rate at which the catch efficiency decreases with wind speed, in % per m/s. It is the slope of the catch efficiency line. Catch efficiency is computed as (catch bias + (wind speed * catch slope)). The catch efficiency is applicable to snow, and is used for any temperatures below temp_snow. The efficiency for rain (temp above temp_rain) is assumed to be 100%. The efficiency for mixed precipitation (between temp_rain and temp_snow) is assumed to vary linearly with temperature. catch_min is the minimum allowable value for catch efficiency. If the value drops below this, it is set to the minimum value.'|translate}}</td></tr>
                            <tr><th>{{'Catch slope'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DA.Enabled" type="number" step="any" [(ngModel)]="item.NCARIntensityGeonorT200DA.CatchSlope" name="item.NCARIntensityGeonorT200DA.CatchSlope" style="width: 50px;" /></td><td></td><td>-7.1</td><td></td></tr>
                            <tr><th>{{'Catch min.'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DA.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityGeonorT200DA.CatchMin" name="item.NCARIntensityGeonorT200DA.CatchMin" style="width: 50px;" /></td><td>{{'%'|translate}}</td><td>25</td><td></td></tr>
                        </table>
                    </td>
                </tr>

                <tr *ngIf="stationType != 'Gen2'">
                    <td>{{'Geonor T200 DFAR (NCAR)'|translate}}</td>
                    <td><input type="checkbox" [(ngModel)]="item.NCARIntensityGeonorT200DFAR.Enabled" name="item.NCARIntensityGeonorT200DFAR.Enabled" /></td>
                    <td><input *ngIf="item.NCARIntensityGeonorT200DFAR.Enabled" [required]="item.NCARIntensityGeonorT200DFAR.Enabled" type="number" step="1" min="1" [(ngModel)]="item.NCARIntensityGeonorT200DFAR.SampleCount" name="item.NCARIntensityGeonorT200DFAR.SampleCount" style="width: 50px;" /></td>
                    <td><input *ngIf="item.NCARIntensityGeonorT200DFAR.Enabled" [required]="item.NCARIntensityGeonorT200DFAR.Enabled" type="number" step="1" min="1" max="{{item.NCARIntensityGeonorT200DFAR.SampleCount}}" [(ngModel)]="item.NCARIntensityGeonorT200DFAR.MinimumSampleCount" name="item.NCARIntensityGeonorT200DFAR.MinimumSampleCount" style="width: 50px;" /></td>
                    <td></td>
                </tr>
                <tr *ngIf="stationType != 'Gen2' && item.NCARIntensityGeonorT200DFAR.Enabled" style="border-bottom: 1px solid #d4d4d4;">
                    <td colspan="5">
                        <table class="table">
                            <tr><th>{{'Setting'|translate}}</th><th>{{'Value'|translate}}</th><th>{{'Unit'|translate}}</th><th>{{'Default'|translate}}</th><th style="width: 600px;">{{'Description'|translate}}</th></tr>
                            <tr><th>{{'Regression duration'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DFAR.Enabled" type="number" step="1" min="1" [(ngModel)]="item.NCARIntensityGeonorT200DFAR.RegressionDurationCount" name="item.NCARIntensityGeonorT200DFAR.RegressionDurationCount" style="width: 50px;" /></td><td>{{'# samples'|translate}}</td><td>10</td><td>{{'This is the period over which the regression is performed. This should always be less than or equal to the filtering duration.'|translate}}</td></tr>
                            <tr><th>{{'Min. valid rate'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DFAR.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityGeonorT200DFAR.MinValidRateMMH" name="item.NCARIntensityGeonorT200DFAR.MinValidRateMMH" style="width: 50px;" /></td><td>{{'mm/h'|translate}}</td><td>0.15</td><td>{{'If the computed rate is less than this value, the rate is set to 0.'|translate}}</td></tr>
                            <tr><th>{{'Max. valid rate'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DFAR.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityGeonorT200DFAR.MaxValidRateMMH" name="item.NCARIntensityGeonorT200DFAR.MaxValidRateMMH" style="width: 50px;" /></td><td>{{'mm/h'|translate}}</td><td>250</td><td>{{'Maximum allowable Precip Rate (mm/h) Clamp max value to this number'|translate}}</td></tr>
                            <tr><th>{{'Apply automatic noise removal'|translate}}</th><td><input type="checkbox" [(ngModel)]="item.NCARIntensityGeonorT200DFAR.ApplyAutoNoiseRemoval" name="item.NCARIntensityGeonorT200DFAR.ApplyAutoNoiseRemoval" /></td><td>{{'Yes/no'|translate}}</td><td>Yes</td><td>{{'If set, a forward and backward pass are made through the data, removing the variation due to sensor noise.'|translate}}</td></tr>
                            <tr><th>{{'Minimum accumulation during regression period'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DFAR.Enabled" type="number" step="any" [(ngModel)]="item.NCARIntensityGeonorT200DFAR.MinAccumulationInRegressionPeriodMM" name="item.NCARIntensityGeonorT200DFAR.MinAccumulationInRegressionPeriodMM" style="width: 50px;" /></td><td>{{'mm'|translate}}</td><td>0.03</td><td>{{'If the computed accumulation is less than this value, the rate is set to 0.'|translate}}</td></tr>
                            <tr><th>{{'Max gauge noise value'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DFAR.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityGeonorT200DFAR.MaxValidNoiseValue" name="item.NCARIntensityGeonorT200DFAR.MaxValidNoiseValue" style="width: 50px;" /></td><td>{{'mm'|translate}}</td><td>0.5</td><td>{{'If the gauge noise exceeds this value, the rate is marked bad.'|translate}}</td></tr>
                            <tr><th>{{'Temp. at which precip is considered all rain'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DFAR.Enabled" type="number" step="any" [(ngModel)]="item.NCARIntensityGeonorT200DFAR.RainMinTemp" name="item.NCARIntensityGeonorT200DFAR.RainMinTemp" style="width: 50px;" /></td><td>{{'C'|translate}}</td><td>5</td><td>{{'This is used for computing the gauge catch efficiency. Efficiency is assumed to vary linearly between snow and rain temperatures.'|translate}}</td></tr>
                            <tr><th>{{'Temp. at which precip is considered all snow'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DFAR.Enabled" type="number" step="any" [(ngModel)]="item.NCARIntensityGeonorT200DFAR.SnowMinTemp" name="item.NCARIntensityGeonorT200DFAR.SnowMinTemp" style="width: 50px;" /></td><td>{{'C'|translate}}</td><td>0</td><td>{{'This is used for computing the gauge catch efficiency. Efficiency is assumed to vary linearly between snow and rain temperatures.'|translate}}</td></tr>
                            <tr><th>{{'Catch bias'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DFAR.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityGeonorT200DFAR.CatchBias" name="item.NCARIntensityGeonorT200DFAR.CatchBias" style="width: 50px;" /></td><td>{{'%'|translate}}</td><td>100</td><td>{{'The gauge catch efficiency is assumed to be inversely linearly related to wind speed. The catch bias is the catch efficiency, in %, at 0 wind speed. This is the intercept of the linear fit at speed = 0. The catch slope is the rate at which the catch efficiency decreases with wind speed, in % per m/s. It is the slope of the catch efficiency line. Catch efficiency is computed as (catch bias + (wind speed * catch slope)). The catch efficiency is applicable to snow, and is used for any temperatures below temp_snow. The efficiency for rain (temp above temp_rain) is assumed to be 100%. The efficiency for mixed precipitation (between temp_rain and temp_snow) is assumed to vary linearly with temperature. catch_min is the minimum allowable value for catch efficiency. If the value drops below this, it is set to the minimum value.'|translate}}</td></tr>
                            <tr><th>{{'Catch slope'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DFAR.Enabled" type="number" step="any" [(ngModel)]="item.NCARIntensityGeonorT200DFAR.CatchSlope" name="item.NCARIntensityGeonorT200DFAR.CatchSlope" style="width: 50px;" /></td><td></td><td>-7.1</td><td></td></tr>
                            <tr><th>{{'Catch min.'|translate}}</th><td><input [required]="item.NCARIntensityGeonorT200DFAR.Enabled" type="number" step="any" min="0" [(ngModel)]="item.NCARIntensityGeonorT200DFAR.CatchMin" name="item.NCARIntensityGeonorT200DFAR.CatchMin" style="width: 50px;" /></td><td>{{'%'|translate}}</td><td>25</td><td></td></tr>
                        </table>
                    </td>
                </tr>

                <tr><td style="border-style: none; background-color: #ffffff;">&nbsp;</td></tr>
                <tr>
                    <th>{{'Long average intensity'}}</th>
                    <td colspan="10" style="background-color: #f0f0f0;">
                        <label><span class="caption">{{'Snow minimum LWE for long average comparison:'|translate}}</span> <input type="number" step="any" min="0" [(ngModel)]="item.SnowMinimumLWEForLongAverageComparison" name="item.SnowMinimumLWEForLongAverageComparison" style="width: 60px;" /></label>
                        <label><span class="caption">{{'SLD/FZDZ/FZRA minimum LWE for long average comparison:'|translate}}</span> <input type="number" step="any" min="0" [(ngModel)]="item.SLDMinimumLWEForLongAverageComparison" name="item.SLDMinimumLWEForLongAverageComparison" style="width: 60px;" /></label>
                    </td>
                </tr>
                <tr>
                    <th>{{'Sensor'|translate}}</th>
                    <th>{{'Enabled'|translate}}</th>
                    <th width="80">{{'# of 1 min. samples'|translate}}</th>
                    <th width="80">{{'Minimum samples'|translate}}</th>
                    <th width="120">
                        <span *ngIf="stationType != 'Gen2'">{{'Snow spike correction?'|translate}}</span>
                    </th>
                </tr>
                <tr *ngIf="stationType == 'Gen2' && item.IntensitySartorius?.Enabled" style="border-bottom: 1px solid #d4d4d4;">
                    <td>{{'Sartorius'|translate}}</td>
                    <td><input type="checkbox" [(ngModel)]="item.LongAverageIntensitySartorius.Enabled" name="item.LongAverageIntensitySartorius.Enabled" /></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <ng-container *ngIf="stationType != 'Gen2'">
                    <tr *ngIf="stationType != 'Gen2' && item.IntensityThiesLPM.Enabled" [sensor-selection-configuration]="item.LongAverageIntensityThiesLPM" property="item.LongAverageIntensityThiesLPM" type="Intensity" [group]="groups.Intensity" [name]="'Thies LPM'|translate" [item]="item" [stationType]="stationType"></tr>
                    <tr *ngIf="stationType != 'Gen2' && item.IntensityCampbellPWS100.Enabled" [sensor-selection-configuration]="item.LongAverageIntensityCampbellPWS100" property="item.LongAverageIntensityCampbellPWS100" type="Intensity" [group]="groups.Intensity" [name]="'Campbell PWS100'|translate" [item]="item" [stationType]="stationType" style="border-bottom: 1px solid #d4d4d4;"></tr>
                </ng-container>
            </table>
        </fieldset>

        <fieldset class="contentMargins" *ngIf="stationType != 'Simulation'" [disabled]="!('ConfigEditStations'|hasPermission)" [hidden]="tab != 'sensors'" ngModelGroup="sensorsForm">
            <table class="table" *ngIf="(item.$promise|async) != null">
                <tr *ngIf="item.WindSensorCombination != null">
                    <th>{{'Wind speed'}}</th>
                    <td colspan="10" style="background-color: #f0f0f0;">
                        <label><span class="caption">{{'Sensor combination aggregation:'|translate}}</span>
                            <select required [(ngModel)]="item.WindSensorCombination.Aggregation" name="item.WindSensorCombination.Aggregation">
                                <option *ngFor="let option of sensorCombinationAggregations|keys" [ngValue]="option">
                                    {{sensorCombinationAggregations[option]}}
                                </option>
                            </select>
                        </label>
                        <label><span class="caption">{{'Sensor count:'|translate}}</span> <input required type="number" step="1" min="1" [(ngModel)]="item.WindSensorCombination.SampleCount" name="item.WindSensorCombination.SampleCount" style="width: 50px;"/></label>
                        <label><span class="caption">{{'Minimum sensor count:'|translate}}</span> <input required type="number" step="1" min="1" max="item.WindSensorCombination.SampleCount" [(ngModel)]="item.WindSensorCombination.MinimumSampleCount" name="item.WindSensorCombination.MinimumSampleCount" style="width: 50px;"/></label>
                    </td>
                </tr>
                <tr sensor-selection-configuration-header></tr>
                <tr *ngIf="stationType == 'Gen2'" [sensor-selection-configuration]="item.WindVaisalaWS425" property="item.WindVaisalaWS425" [group]="groups.Wind" [name]="'Vaisala WS425'|translate"></tr>
                <tr [sensor-selection-configuration]="item.WindThies2DCompact" property="item.WindThies2DCompact" [group]="groups.Wind" [name]="'Thies 2D Compact'|translate"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.WindVaisalaWMT700" property="item.WindVaisalaWMT700" [group]="groups.Wind" [name]="'Vaisala WMT700'|translate"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.WindThiesWP" property="item.WindThiesWP" [group]="groups.Wind" [name]="'Thies WP'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>

                <!-- Wind Gust Speed -->
                <ng-container *ngIf="stationType != 'Gen2'">
                    <tr><td style="border-style: none; background-color: #ffffff;">&nbsp;</td></tr>
                    <tr *ngIf="item.WindGustSpeedSensorCombination != null">
                        <th>{{'Wind Gust Speed'}}</th>
                        <td colspan="10" style="background-color: #f0f0f0;">
                            <label><span class="caption">{{'Sensor combination aggregation:'|translate}}</span>
                                <select required [(ngModel)]="item.WindGustSpeedSensorCombination.Aggregation" name="item.WindGustSpeedSensorCombination.Aggregation">
                                    <option *ngFor="let option of sensorCombinationAggregations|keys" [ngValue]="option">
                                        {{sensorCombinationAggregations[option]}}
                                    </option>
                                </select>
                            </label>
                            <label><span class="caption">{{'Sensor count:'|translate}}</span> <input required type="number" step="1" min="1" [(ngModel)]="item.WindGustSpeedSensorCombination.SampleCount" name="item.WindGustSpeedSensorCombination.SampleCount" style="width: 50px;"/></label>
                            <label><span class="caption">{{'Minimum sensor count:'|translate}}</span> <input required type="number" step="1" min="1" max="item.WindGustSpeedSensorCombination.SampleCount" [(ngModel)]="item.WindGustSpeedSensorCombination.MinimumSampleCount" name="item.WindGustSpeedSensorCombination.MinimumSampleCount" style="width: 50px;"/></label>
                        </td>
                    </tr>
                    <tr sensor-selection-configuration-header></tr>
                    <tr [sensor-selection-configuration]="item.WindGustSpeedThiesWP" property="item.WindGustSpeedThiesWP" [group]="groups.WindGustSpeed" [name]="'Thies WP'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>
                </ng-container>

                <!-- Wind Direction -->
                <tr><td style="border-style: none; background-color: #ffffff;">&nbsp;</td></tr>
                <tr *ngIf="item.WindDirectionSensorCombination != null">
                    <th>{{'Wind Direction'}}</th>
                    <td colspan="10" style="background-color: #f0f0f0;">
                        <label><span class="caption">{{'Sensor combination aggregation:'|translate}}</span>
                            <select required [(ngModel)]="item.WindDirectionSensorCombination.Aggregation" name="item.WindDirectionSensorCombination.Aggregation">
                                <option *ngFor="let option of sensorCombinationAggregations|keys" [ngValue]="option">
                                    {{sensorCombinationAggregations[option]}}
                                </option>
                            </select>
                        </label>
                        <label><span class="caption">{{'Sensor count:'|translate}}</span> <input required type="number" step="1" min="1" [(ngModel)]="item.WindDirectionSensorCombination.SampleCount" name="item.WindDirectionSensorCombination.SampleCount" style="width: 50px;"/></label>
                        <label><span class="caption">{{'Minimum sensor count:'|translate}}</span> <input required type="number" step="1" min="1" max="item.WindDirectionSensorCombination.SampleCount" [(ngModel)]="item.WindDirectionSensorCombination.MinimumSampleCount" name="item.WindDirectionSensorCombination.MinimumSampleCount" style="width: 50px;"/></label>
                    </td>
                </tr>
                <tr sensor-selection-configuration-header></tr>
                <tr *ngIf="stationType == 'Gen2'" [sensor-selection-configuration]="item.WindDirectionVaisalaWS425" property="item.WindDirectionVaisalaWS425" [group]="groups.WindDirection" [name]="'Vaisala WS425'|translate"></tr>
                <tr [sensor-selection-configuration]="item.WindDirectionThies2DCompact" property="item.WindDirectionThies2DCompact" [group]="groups.WindDirection" [name]="'Thies 2D Compact'|translate"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.WindDirectionVaisalaWMT700" property="item.WindDirectionVaisalaWMT700" [group]="groups.WindDirection" [name]="'Vaisala WMT700'|translate"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.WindDirectionThiesWP" property="item.WindDirectionThiesWP" [group]="groups.WindDirection" [name]="'Thies WP'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>

                <!-- Wind Gust Direction -->
                <ng-container *ngIf="stationType != 'Gen2'">
                    <tr><td style="border-style: none; background-color: #ffffff;">&nbsp;</td></tr>
                    <tr *ngIf="item.WindGustDirectionSensorCombination != null">
                        <th>{{'Wind Gust Direction'}}</th>
                        <td colspan="10" style="background-color: #f0f0f0;">
                            <label><span class="caption">{{'Sensor combination aggregation:'|translate}}</span>
                                <select required [(ngModel)]="item.WindGustDirectionSensorCombination.Aggregation" name="item.WindGustDirectionSensorCombination.Aggregation">
                                    <option *ngFor="let option of sensorCombinationAggregations|keys" [ngValue]="option">
                                        {{sensorCombinationAggregations[option]}}
                                    </option>
                                </select>
                            </label>
                            <label><span class="caption">{{'Sensor count:'|translate}}</span> <input required type="number" step="1" min="1" [(ngModel)]="item.WindGustDirectionSensorCombination.SampleCount" name="item.WindGustDirectionSensorCombination.SampleCount" style="width: 50px;"/></label>
                            <label><span class="caption">{{'Minimum sensor count:'|translate}}</span> <input required type="number" step="1" min="1" max="item.WindGustDirectionSensorCombination.SampleCount" [(ngModel)]="item.WindGustDirectionSensorCombination.MinimumSampleCount" name="item.WindGustDirectionSensorCombination.MinimumSampleCount" style="width: 50px;"/></label>
                        </td>
                    </tr>
                    <tr sensor-selection-configuration-header></tr>
                    <tr [sensor-selection-configuration]="item.WindGustDirectionThiesWP" property="item.WindGustDirectionThiesWP" [group]="groups.WindGustDirection" [name]="'Thies WP'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>
                </ng-container>

                <!-- Visibility -->
                <tr><td style="border-style: none; background-color: #ffffff;">&nbsp;</td></tr>
                <tr *ngIf="item.VisibilitySensorCombination != null">
                    <th>{{'Visibility'}}</th>
                    <td colspan="10" style="background-color: #f0f0f0;">
                        <label><span class="caption">{{'Sensor combination aggregation:'|translate}}</span>
                            <select required [(ngModel)]="item.VisibilitySensorCombination.Aggregation" name="item.VisibilitySensorCombination.Aggregation">
                                <option *ngFor="let option of sensorCombinationAggregations|keys" [ngValue]="option">
                                    {{sensorCombinationAggregations[option]}}
                                </option>
                            </select>
                        </label>
                        <label><span class="caption">{{'Sensor count:'|translate}}</span> <input required type="number" step="1" min="1" [(ngModel)]="item.VisibilitySensorCombination.SampleCount" name="item.VisibilitySensorCombination.SampleCount" style="width: 50px;"/></label>
                        <label><span class="caption">{{'Minimum sensor count:'|translate}}</span> <input required type="number" step="1" min="1" max="item.VisibilitySensorCombination.SampleCount" [(ngModel)]="item.VisibilitySensorCombination.MinimumSampleCount" name="item.VisibilitySensorCombination.MinimumSampleCount" style="width: 50px;"/></label>
                    </td>
                </tr>
                <tr sensor-selection-configuration-header></tr>
                <tr *ngIf="stationType == 'Gen2'" [sensor-selection-configuration]="item.VisibilityVaisalaFD12P" property="item.VisibilityVaisalaFD12P" [group]="groups.Visibility" [name]="'Vaisala FD12P'|translate"></tr>
                <tr *ngIf="stationType == 'Gen2'" [sensor-selection-configuration]="item.VisibilityBiralSWS200" property="item.VisibilityBiralSWS200" [group]="groups.Visibility" [name]="'Biral SWS200'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.VisibilityOTTParsivel2" property="item.VisibilityOTTParsivel2" [group]="groups.Visibility" [name]="'OTT Parsivel 2'|translate"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.VisibilityVaisalaFD71P" property="item.VisibilityVaisalaFD71P" [group]="groups.Visibility" [name]="'Vaisala FD71P'|translate"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.VisibilityCampbellPWS100" property="item.VisibilityCampbellPWS100" [group]="groups.Visibility" [name]="'Campbell PWS100'|translate"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.VisibilityVaisalaPWD22" property="item.VisibilityVaisalaPWD22" [group]="groups.Visibility" [name]="'Vaisala PWD22'|translate"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.VisibilityMiniOFS" property="item.VisibilityMiniOFS" [group]="groups.Visibility" [name]="'MiniOFS'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>

                <tr><td style="border-style: none; background-color: #ffffff;">&nbsp;</td></tr>
                <tr *ngIf="item.TemperatureSensorCombination != null">
                    <th>{{'Temperature'}}</th>
                    <td colspan="10" style="background-color: #f0f0f0;">
                        <label><span class="caption">{{'Sensor combination aggregation:'|translate}}</span>
                            <select required [(ngModel)]="item.TemperatureSensorCombination.Aggregation" name="item.TemperatureSensorCombination.Aggregation">
                                <option *ngFor="let option of sensorCombinationAggregations|keys" [ngValue]="option">
                                    {{sensorCombinationAggregations[option]}}
                                </option>
                            </select>
                        </label>
                        <label><span class="caption">{{'Sensor count:'|translate}}</span> <input required type="number" step="1" min="1" [(ngModel)]="item.TemperatureSensorCombination.SampleCount" name="item.TemperatureSensorCombination.SampleCount" style="width: 50px;"/></label>
                        <label><span class="caption">{{'Minimum sensor count:'|translate}}</span> <input required type="number" step="1" min="1" max="item.TemperatureSensorCombination.SampleCount" [(ngModel)]="item.TemperatureSensorCombination.MinimumSampleCount" name="item.TemperatureSensorCombination.MinimumSampleCount" style="width: 50px;"/></label>
                    </td>
                </tr>
                <tr sensor-selection-configuration-header type="Temperature"></tr>
                <tr *ngIf="stationType == 'Gen2'" [sensor-selection-configuration]="item.TemperatureVaisalaTempProbe" property="item.TemperatureVaisalaTempProbe" type="Temperature" [group]="groups.Temperature" [name]="'Vaisala/Digital Temp Probe'|translate"></tr>
                <tr [sensor-selection-configuration]="item.TemperatureThiesTempProbe" property="item.TemperatureThiesTempProbe" type="Temperature" [group]="groups.Temperature" [name]="'Thies Temp Probe'|translate"></tr>
                <tr [sensor-selection-configuration]="item.TemperatureThiesLPM" property="item.TemperatureThiesLPM" type="Temperature" [group]="groups.Temperature" [name]="'Thies LPM'|translate"></tr>
                <tr *ngIf="stationType == 'Gen2'" [sensor-selection-configuration]="item.TemperatureVaisalaFD12P" property="item.TemperatureVaisalaFD12P" type="Temperature" [group]="groups.Temperature" [name]="'Vaisala FD12P'|translate"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.TemperatureDigitalTempProbe" property="item.TemperatureDigitalTempProbe" type="Temperature" [group]="groups.Temperature" [name]="'Digital Temp Probe'|translate"></tr>
                <tr [sensor-selection-configuration]="item.TemperatureThies2DCompact" property="item.TemperatureThies2DCompact" type="Temperature" [group]="groups.Temperature" [name]="'Thies 2D Compact'|translate"></tr>
                <tr [sensor-selection-configuration]="item.TemperatureCampbellPWS100" property="item.TemperatureCampbellPWS100" type="Temperature" [group]="groups.Temperature" [name]="'Campbell PWS100'|translate"></tr>
                <tr [sensor-selection-configuration]="item.TemperatureBiralSWS200" property="item.TemperatureBiralSWS200" type="Temperature" [group]="groups.Temperature" [name]="'Biral SWS-200/250'|translate"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.TemperatureVaisalaPWD22" property="item.TemperatureVaisalaPWD22" type="Temperature" [group]="groups.Temperature" [name]="'Vaisala PWD22'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>

                <tr><td style="border-style: none; background-color: #ffffff;">&nbsp;</td></tr>
                <tr *ngIf="item.HumiditySensorCombination != null">
                    <th>{{'Humidity'}}</th>
                    <td colspan="10" style="background-color: #f0f0f0;">
                        <label><span class="caption">{{'Sensor combination aggregation:'|translate}}</span>
                            <select required [(ngModel)]="item.HumiditySensorCombination.Aggregation" name="item.HumiditySensorCombination.Aggregation">
                                <option *ngFor="let option of sensorCombinationAggregations|keys" [ngValue]="option">
                                    {{sensorCombinationAggregations[option]}}
                                </option>
                            </select>
                        </label>
                        <label><span class="caption">{{'Sensor count:'|translate}}</span> <input required type="number" step="1" min="1" [(ngModel)]="item.HumiditySensorCombination.SampleCount" name="item.HumiditySensorCombination.SampleCount" style="width: 50px;"/></label>
                        <label><span class="caption">{{'Minimum sensor count:'|translate}}</span> <input required type="number" step="1" min="1" max="item.HumiditySensorCombination.SampleCount" [(ngModel)]="item.HumiditySensorCombination.MinimumSampleCount" name="item.HumiditySensorCombination.MinimumSampleCount" style="width: 50px;"/></label>
                    </td>
                </tr>
                <tr sensor-selection-configuration-header></tr>
                <tr *ngIf="stationType == 'Gen2'" [sensor-selection-configuration]="item.HumidityVaisalaTempProbe" property="item.HumidityVaisalaTempProbe" [group]="groups.Humidity" [name]="'Vaisala/Digital Temp Probe'|translate"></tr>
                <tr [sensor-selection-configuration]="item.HumidityThiesTempProbe" property="item.HumidityThiesTempProbe" [group]="groups.Humidity" [name]="'Thies Temp Probe'|translate"></tr>
                <tr *ngIf="stationType != 'Gen2'" [sensor-selection-configuration]="item.HumidityDigitalTempProbe" property="item.HumidityDigitalTempProbe" [group]="groups.Humidity" [name]="'Digital Temp Probe'|translate"></tr>
                <tr [sensor-selection-configuration]="item.HumidityCampbellPWS100" property="item.HumidityCampbellPWS100" [group]="groups.Humidity" [name]="'Campbell PWS100'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>

                <tr><td style="border-style: none; background-color: #ffffff;">&nbsp;</td></tr>
                <tr><th>{{'Active frost'}}</th><td colspan="10" style="background-color: #f0f0f0;"></td></tr>
                <tr sensor-selection-configuration-header type="ActiveFrost"></tr>
                <tr [sensor-selection-configuration]="item.ActiveFrost" property="item.ActiveFrost" type="ActiveFrost" [group]="groups.ActiveFrost" [name]="'Active frost'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>
            </table>
        </fieldset>
    </div>
</form>
