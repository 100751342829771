import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import {ChangesService, FileDownloadService, SwxModule} from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { OrderByPipe } from 'swx.front-end-lib';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'InstantWeatherTypeRuleProfileDetail.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, NgFor, HasPermissionPipe]
})
export class InstantWeatherTypeRuleProfileDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', {static: true}) ngForm;
    tab;
    //this.instantWeatherTypeSensors = enums('InstantWeatherTypeSensor');
    // available sensors changes based on assigned sensors
    instantWeatherTypeSensors = {
        'Sensor1': 'Sensor 1'
    };
    weatherTypeSensorTypes = this.api.WeatherTypeSensorType.query();
    //this.metarWarningFlags = enums('MetarWarningFlags');
    // only this subset is valid for instant weather type rules
    metarWarningFlags = {
        'BLSN': 'BLSN',
        'DRSN': 'DRSN'
    };
    weatherTypeNgs = this.api.WeatherTypeNg.query();
    weatherTypeNgFlags = this.api.WeatherTypeNgFlags.query();

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        public orderByPipe: OrderByPipe,
        private fileDownload: FileDownloadService,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = api.InstantWeatherTypeRuleProfile.get({id: copyId});
            this.item.$promise.then(item => {
                delete this.item.Id;

                if (this.item.InstantWeatherTypeRules) {
                    this.item.InstantWeatherTypeRules.forEach(related => {
                        delete related.InstantWeatherTypeRuleProfileId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.InstantWeatherTypeRuleProfile.create({
                Active: true,
            });
        } else {
            this.item = api.InstantWeatherTypeRuleProfile.get({id: id});
        }

        this.item.$promise.then(item => {
            item.InstantWeatherTypeRules = item.InstantWeatherTypeRules || [];
            item.InstantWeatherTypeRules = this.orderByPipe.transform(item.InstantWeatherTypeRules, ['MaxTemp', 'Number']);
            this.refreshInstantWeatherTypeSensors();
        });
    }
    
    save() {
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['InstantWeatherTypeRuleProfile'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    refreshInstantWeatherTypeSensors() {
        this.instantWeatherTypeSensors = {
            'Sensor1': 'Sensor 1'
        };

        if (this.item.WeatherTypeSensor2 != null) {
            this.instantWeatherTypeSensors['Sensor2'] = 'Sensor 2';
        }

        if (this.item.WeatherTypeSensor3 != null) {
            this.instantWeatherTypeSensors['Sensor3'] = 'Sensor 3';
        }

        if (this.item.WeatherTypeSensor4 != null) {
            this.instantWeatherTypeSensors['Sensor4'] = 'Sensor 4';
        }

        if (this.item.WeatherTypeSensor5 != null) {
            this.instantWeatherTypeSensors['Sensor5'] = 'Sensor 5';
        }
    }

    addRule() {
        this.item.InstantWeatherTypeRules = this.item.InstantWeatherTypeRules || [];
        this.item.InstantWeatherTypeRules.push({});
        this.ngForm.form.markAsDirty();
    }

    removeRule(rule) {
        this.item.InstantWeatherTypeRules.splice(this.item.InstantWeatherTypeRules.indexOf(rule), 1);
        this.ngForm.form.markAsDirty();
    }

    exportInstantWeatherTypeRules() {
        Promise.all([this.item.$promise, this.weatherTypeSensorTypes.$promise]).then(([item, weatherTypeSensorTypes]) => {
            let columnDefs = [
                { colId: "Number", field: "Number", headerName: "Number" },
                { colId: "MaxTemp", field: "MaxTemp", headerName: "Temp <= (°C)" },
            ];

            columnDefs = [...columnDefs, { colId: 'Sensor1WeatherTypeNg', field: 'Sensor1WeatherTypeNg', headerName: `${weatherTypeSensorTypes[item.WeatherTypeSensor1]} weather types`}];
            
            if (item.WeatherTypeSensor2) {
                columnDefs = [...columnDefs, { colId: 'Sensor1WeatherTypeNg', field: 'Sensor1WeatherTypeNg', headerName: `${weatherTypeSensorTypes[item.WeatherTypeSensor1]} weather types`}];
            }
            if (item.WeatherTypeSensor3) {
                columnDefs = [...columnDefs, { colId: 'Sensor2WeatherTypeNg', field: 'Sensor2WeatherTypeNg', headerName: `${weatherTypeSensorTypes[item.WeatherTypeSensor1]} weather types`}];
            }
            if (item.WeatherTypeSensor4) {
                columnDefs = [...columnDefs, { colId: 'Sensor3WeatherTypeNg', field: 'Sensor3WeatherTypeNg', headerName: `${weatherTypeSensorTypes[item.WeatherTypeSensor1]} weather types`}];
            }
            if (item.WeatherTypeSensor5) {
                columnDefs = [...columnDefs, { colId: 'Sensor4WeatherTypeNg', field: 'Sensor4WeatherTypeNg', headerName: `${weatherTypeSensorTypes[item.WeatherTypeSensor1]} weather types`}];
            }
            
            columnDefs = [...columnDefs,
                { colId: 'MetarWarning', field: 'MetarWarning', headerName: 'METAR warnings'},
                { colId: 'MetarWeatherCodeRegex', field: 'MetarWeatherCodeRegex', headerName: 'Metar weather code regular expression'},
                { colId: 'FinalWeatherTypeNg', field: 'FinalWeatherTypeNg', headerName: 'Final weather type'},
                { colId: 'FinalSensor', field: 'FinalSensor', headerName: 'Final sensor'},
            ]
            const query = {
                Id: this.item.Id,
                ColumnDefs: [{
                    children: columnDefs
                }]
            };

            this.api.InstantWeatherTypeRulesExport
                .exportPost(query)
                .then(response => {
                    this.fileDownload.download(response.body, (header) => response.headers.get(header));
                });
        })
    };
}
