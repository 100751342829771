import {
    Component,
    ElementRef,
    forwardRef,
    OnInit,
    Input,
    NgZone,
    OnChanges,
    ViewEncapsulation,
    Output, EventEmitter
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import jQuery from 'jquery';
import 'jquery-ui/ui/widgets/sortable.js';
import {SelectionChangedEvent} from "ag-grid-community";
import { NgIf } from '@angular/common';

@Component({
    selector: 'portlets',
    template: `<ng-content></ng-content>`,
    styleUrls: ['./Portlets.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
})
export class PortletsComponent implements OnInit, OnChanges {
    @Input('options') options: JQueryUI.SortableOptions;
    element: JQuery;
    
    constructor(
        private elementRef: ElementRef,
        private ngZone: NgZone
    ) {
    }

    ngOnInit(): void {
        this.element = jQuery(this.elementRef.nativeElement);

        const baseOptions: JQueryUI.SortableOptions = {
            items: '> portlet',
            tolerance: 'intersect',
            handle: '.mdi-drag-horizontal',
            axis: 'y',
            update: (e, ui) => {
                if (ui.item.sortable['received']) {
                    ui.item.sortable['received'] = false;
                } else {
                    this.ngZone.run(() => this.element
                        .children(this.element.sortable('option', 'item'))
                        .each((index, el) => {
                            el['valueAccessor'].setOrder(index);
                        }));
                }
            }
        };

        const options: JQueryUI.SortableOptions = <JQueryUI.SortableOptions>(Object.assign(baseOptions, this.options || {}));

        this.element.sortable(options);
    }

    ngOnChanges() {
        if (this.element == null) return;

        Object.keys(this.options).forEach(key => {
            this.element.sortable('option', key, this.options[key]);
        });
    }
}

@Component({
    selector: 'portlet',
    template: `
<div>
    <div class="toolbar">
        <span class="mdi mdi-drag-horizontal"></span>
        <h3 (click)="setOpen(!open);">{{title}}</h3>
        <span class="mdi mdi-chevron-down" *ngIf="open" (click)="setOpen(false);"></span>
        <span class="mdi mdi-chevron-up" *ngIf="!open" (click)="setOpen(true);"></span>
    </div>
    <div class="content" *ngIf="open"><ng-content></ng-content></div>
</div>`,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PortletComponent),
            multi: true
        }
    ],
    standalone: true,
    imports: [NgIf]
})
export class PortletComponent implements OnInit {
    @Input('title') title: string;
    @Input('name') name: string;
    order: number;
    open = false;
    @Output() openChanged: EventEmitter<Boolean> = new EventEmitter<Boolean>();
    onchange: (_: any) => void = () => { };
    ontouched: (_: any) => void = () => { };
    element: JQuery;

    constructor(
        private elementRef: ElementRef
    ) {
    }

    ngOnInit(): void {
        this.elementRef.nativeElement['valueAccessor'] = this;

        if (localStorage[this.name + '_order']) {
            this.order = JSON.parse(localStorage[this.name + '_order']);
            this.elementRef.nativeElement.style.order = this.order;
        }
        
        if (localStorage[this.name + '_open']) {
            this.open = JSON.parse(localStorage[this.name + '_open']);
        } else {
            this.open = true;
        }

        this.openChanged.emit(this.open);
    }

    setOrder(order) {
        this.order = order;
        this.onchange(order);
        this.ontouched(order);
        this.elementRef.nativeElement.style.order = order;

        localStorage[this.name + '_order'] = JSON.stringify(order);
    }

    setOpen(open) {
        this.open = open;
        localStorage[this.name + '_open'] = JSON.stringify(open);
        if (open) {
            setTimeout(() => jQuery(window).trigger('resize'));
        }
        this.openChanged.emit(open);
    }

    writeValue(value): void {
        if (value) {
            if (typeof value === "string") {
                this.order = parseInt(value);
            } else {
                this.order = value;
            }
        } else {
            this.order = null;
        }
    }

    registerOnChange(fn): void {
        this.onchange = fn;
    }

    registerOnTouched(fn): void {
        this.ontouched = fn;
    }
}
