import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { FiltersComponent, IAgGridColGroupDef, SortableComponent, ValueFormatters, SwxModule } from "swx.front-end-lib";
import {ApiService} from "../shared/Api.service";
import { ControlContainer, NgModelGroup, FormsModule } from "@angular/forms";
import {Field, FieldGroup} from "swx.front-end-lib/lib/grid/Query.model";
import { HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'station-monitoring-condition-list',
    templateUrl: 'StationMonitoringConditionList.component.html',
    styleUrls: ['./StationMonitoringConditionList.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
    standalone: true,
    imports: [SwxModule, NgFor, FormsModule, NgIf, HasPermissionPipe]
})
export class StationMonitoringConditionListComponent implements OnInit {
    @Input() conditions;
    @Input() parent;
    @Input() parentList: StationMonitoringConditionListComponent;
    @Input() ngForm;
    @ViewChild('sortable', { static: true }) sortable: SortableComponent;
    availableFields: Map<string, Field>;
    fieldGroups: FieldGroup[];
    sensorSelectMatrices = this.api.SensorSelectMatrix.query();
    metarWeatherMappingProfiles = this.api.MetarWeatherMappingProfile.query();
    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: 'Station alarm',
            children: [
                { field: "Station.Name", headerName: "Station", },
                { field: "Station.UnderMaintenance", headerName: "Under maintenance?", filterType: "boolean" },
                { field: "StationProblemType", headerName: "Alarms", filterType: "enum", source: "StationProblemType" },
                { field: "HasNoticeAlarm", headerName: "Has notice alarm?", filterType: "boolean" },
                { field: "HasWarningAlarm", headerName: "Has warning alarm?", filterType: "boolean" },
                { field: "HasCriticalAlarm", headerName: "Has critical alarm?", filterType: "boolean" },
                { field: "Airport.ICAOCode", headerName: "Airport ICAO code", },
                { field: "Airport.City", headerName: "Airport city", },
                { field: "Airport.Country", headerName: "Airport country", },
                { field: "Airport.Region", headerName: "Airport region", },
                { field: "Airport.Latitude", headerName: "Airport latitude", filterType: "float" },
                { field: "Airport.Longitudfe", headerName: "Airport longitude", filterType: "float" },
            ]
        },
        {
            headerName: 'Metar reading',
            children: [
                { field: "MetarReadingAgeMinutes", headerName: "Age (minutes)", filterType: "float" },
                { field: "MetarReading.WxString", headerName: "Weather string", },
                { field: "MetarReading.TempC", headerName: "Temperature (°C)", filterType: "float" },
                { field: "MetarReading.IsClearSky", headerName: "Clear sky?", filterType: "boolean" },
                { field: "MetarReading.IsActiveFrost", headerName: "Active frost?", filterType: "boolean" },
                { field: "MetarReading.VisibilityStatuteMi", headerName: "Visibility (SM)", filterType: "float" },
            ],
        },
        {
            headerName: 'Power relay module',
            children: [
                { field: "PowerRelayModule.Port3_1", headerName: "P3.1", filterType: "boolean" },
                { field: "PowerRelayModule.Port3_2", headerName: "P3.2", filterType: "boolean" },
                { field: "PowerRelayModule.Port3_3", headerName: "P3.3", filterType: "boolean" },
                { field: "PowerRelayModule.Port3_4", headerName: "P3.4", filterType: "boolean" },
                { field: "PowerRelayModule.Port3_5", headerName: "P3.5", filterType: "boolean" },
                { field: "PowerRelayModule.Port3_6", headerName: "P3.6", filterType: "boolean" },
                { field: "PowerRelayModule.Port3_7", headerName: "P3.7", filterType: "boolean" },
                { field: "PowerRelayModule.Port3_8", headerName: "P3.8", filterType: "boolean" },

                { field: "PowerRelayModule.Port5_1", headerName: "P5.1 on", filterType: "boolean" },
                { field: "PowerRelayModule.Port5_2", headerName: "P5.2 on", filterType: "boolean" },
                { field: "PowerRelayModule.Port5_3", headerName: "P5.3 on", filterType: "boolean" },
                { field: "PowerRelayModule.Port5_4", headerName: "P5.4 on", filterType: "boolean" },
                { field: "PowerRelayModule.Port5_5", headerName: "P5.5 on", filterType: "boolean" },
                { field: "PowerRelayModule.Port5_6", headerName: "P5.6 on", filterType: "boolean" },
                { field: "PowerRelayModule.Port5_7", headerName: "P5.7 on", filterType: "boolean" },
                { field: "PowerRelayModule.Port5_8", headerName: "P5.8 on", filterType: "boolean" },

                { field: "PowerRelayModule.Port6_1", headerName: "ADC.1 (V)", filterType: "float" },
                { field: "PowerRelayModule.Port6_2", headerName: "ADC.2 (V)", filterType: "float" },
                { field: "PowerRelayModule.Port6_3", headerName: "ADC.3 (V)", filterType: "float" },
                { field: "PowerRelayModule.Port6_4", headerName: "ADC.4 (V)", filterType: "float" },
                { field: "PowerRelayModule.Port6_5", headerName: "ADC.5 (V)", filterType: "float" },
                { field: "PowerRelayModule.Port6_6", headerName: "ADC.6 (V)", filterType: "float" },
                { field: "PowerRelayModule.Port6_7", headerName: "ADC.7 (V)", filterType: "float" },
                { field: "PowerRelayModule.Port6_8", headerName: "ADC.8 (V)", filterType: "float" },

                { field: "PowerRelayModule.ACOK", headerName: "AC OK", filterType: "boolean" },
                { field: "PowerRelayModule.DCOK", headerName: "DC OK", filterType: "boolean" },
                { field: "PowerRelayModule.BattLow", headerName: "Batt Low", filterType: "boolean" },
            ]
        },
        {
            headerName: 'Forecast next hour',
            children: [
                { field: "ForecastNextHour.TempC", headerName: "Temperature (°C)", filterType: "float", },
                { field: "ForecastNextHour.Precipitation1Hour", headerName: "Precipitation (mm)", filterType: "float", },
                { field: "ForecastNextHour.PrecipitationProbability", headerName: "Precipitation probability", filterType: "float", },
                { field: "ForecastNextHour.WMOCode", headerName: "WMO", filterType: "integer", },
                { field: "ForecastNextHour.VisibilityM", headerName: "Visibility (m)", filterType: "float", },
                { field: "ForecastNextHour.WindSpeedMs", headerName: "Wind speed (m/s)", filterType: "float", },
            ],
        },
        {
            headerName: 'Forecast next 6 hours',
            children: [
                { field: "ForecastNext6Hours.TempC", headerName: "Min. temperature (°C)", filterType: "float", },
                { field: "ForecastNext6Hours.Precipitation1Hour", headerName: "Total precipitation (mm)", filterType: "float", },
                { field: "ForecastNext6Hours.PrecipitationProbability", headerName: "Max. precipitation probability", filterType: "float", },
            ],
        },
        {
            headerName: 'Forecast next 24 hours',
            children: [
                { field: "ForecastNext24Hours.TempC", headerName: "Min. temperature (°C)", filterType: "float", },
                { field: "ForecastNext24Hours.Precipitation1Hour", headerName: "Total precipitation (mm)", filterType: "float", },
                { field: "ForecastNext24Hours.PrecipitationProbability", headerName: "Max. precipitation probability", filterType: "float", },
            ],
        },

        {
            headerName: 'Sensor reading',
            children: [
                { colId: "StationReading.QueueSize", field: "StationReading.QueueSize", headerName: "Queue size", width: 50, filterType: "integer" },
                { colId: "StationReading.PowerErrorCount", field: "StationReading.PowerErrorCount", headerName: "Power error count", width: 60, filterType: "integer" },
                { colId: "StationReading.SerialMaintenanceMode", field: "StationReading.SerialMaintenanceMode", headerName: "Serial maintenance mode?", width: 70, filterType: "boolean" },
            ]
        },
        {
            headerName: 'Thies LPM',
            children: [
                { colId: "StationReading.ThiesLPM.SensorFailed", field: "StationReading.ThiesLPM.SensorFailed", headerName: "Sensor failed?", width: 80, filterType: "boolean" },
                { colId: "StationReading.ThiesLPM.WMOCode", field: "StationReading.ThiesLPM.WMOCode", headerName: "WMO", width: 60, filterType: "integer" },
                { colId: "StationReading.ThiesLPM.Intensity", field: "StationReading.ThiesLPM.Intensity", headerName: "Intensity (mm/h)", width: 75, filterType: "float" },
                { colId: "StationReading.ThiesLPM.Visibility", field: "StationReading.ThiesLPM.Visibility", headerName: "Visibility (m)", width: 80, filterType: "float" },
                { colId: "StationReading.ThiesLPM.MeasuringQuality", field: "StationReading.ThiesLPM.MeasuringQuality", headerName: "Measuring quality (%)", width: 90, filterType: "float" },
                { colId: "StationReading.ThiesLPM.MaximumDiameterHail", field: "StationReading.ThiesLPM.MaximumDiameterHail", headerName: "Maximum diameter hail (mm)", width: 105, filterType: "float" },
            ]
        },
        {
            headerName: 'Campbell PWS100',
            children: [
                { colId: "StationReading.CampbellPWS100.FaultStatus", field: "StationReading.CampbellPWS100.FaultStatus", headerName: "Fault status", width: 100, filterType: "enum", source: "CampbellPWS100FaultStatus" },
                { colId: "StationReading.CampbellPWS100.WarmingUp", field: "StationReading.CampbellPWS100.WarmingUp", headerName: "Warming up?", width: 100 },
                { colId: "StationReading.CampbellPWS100.WMOCode", field: "StationReading.CampbellPWS100.WMOCode", headerName: "WMO", width: 100, filterType: "integer" },
                { colId: "StationReading.CampbellPWS100.Intensity", field: "StationReading.CampbellPWS100.Intensity", headerName: "Intensity (mm/h)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.Visibility", field: "StationReading.CampbellPWS100.Visibility", headerName: "Visibility (m)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.RelativeHumidity", field: "StationReading.CampbellPWS100.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.Temperature", field: "StationReading.CampbellPWS100.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.WetBulb", field: "StationReading.CampbellPWS100.WetBulb", headerName: "Wet bulb (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.UpperDetectorTemperature", field: "StationReading.CampbellPWS100.UpperDetectorTemperature", headerName: "Upper detector temp (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.LowerDetectorTemperature", field: "StationReading.CampbellPWS100.LowerDetectorTemperature", headerName: "Lower detector temp (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.LaserHoodTemperature", field: "StationReading.CampbellPWS100.LaserHoodTemperature", headerName: "Laser hood temp (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.LaserTemperature", field: "StationReading.CampbellPWS100.LaserTemperature", headerName: "Laser temp (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.LaserDriveCurrent", field: "StationReading.CampbellPWS100.LaserDriveCurrent", headerName: "Laser drive current (mA)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Thies 2D Compact',
            children: [
                { colId: "StationReading.Thies2DCompact.SensorFailed", field: "StationReading.Thies2DCompact.SensorFailed", headerName: "Sensor failed?", width: 100, filterType: "boolean" },
                { colId: "StationReading.Thies2DCompact.WindSpeed", field: "StationReading.Thies2DCompact.WindSpeed", headerName: "Wind speed (m/s)", width: 100, filterType: "float" },
                { colId: "StationReading.Thies2DCompact.WindDirection", field: "StationReading.Thies2DCompact.WindDirection", headerName: "Wind direction (°)", width: 100, filterType: "float" },
                { colId: "StationReading.Thies2DCompact.Temperature", field: "StationReading.Thies2DCompact.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Digital temp probe',
            children: [
                { colId: "StationReading.DigitalTempProbe.RelativeHumidity", field: "StationReading.DigitalTempProbe.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 100, filterType: "float" },
                { colId: "StationReading.DigitalTempProbe.Temperature", field: "StationReading.DigitalTempProbe.Temperature", headerName: "Temperature (°C)", width: 105, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Parsivel 2',
            children: [
                { colId: "StationReading.OTTParsivel2.SensorStatus", field: "StationReading.OTTParsivel2.SensorStatus", headerName: "Sensor status", width: 100, filterType: "enum", source: "OTTParsivel2SensorStatus" },
                { colId: "StationReading.OTTParsivel2.WMOCode", field: "StationReading.OTTParsivel2.WMOCode", headerName: "WMO", width: 100, filterType: "integer" },
                { colId: "StationReading.OTTParsivel2.RainIntensity", field: "StationReading.OTTParsivel2.RainIntensity", headerName: "Rain intensity (mm/h)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTParsivel2.MORVisibility", field: "StationReading.OTTParsivel2.MORVisibility", headerName: "MOR visibility (m)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTParsivel2.InternalTemperature", field: "StationReading.OTTParsivel2.InternalTemperature", headerName: "Internal temperature (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTParsivel2.ParticleCount", field: "StationReading.OTTParsivel2.ParticleCount", headerName: "Particle count (validated)", width: 100, filterType: "integer" },
                { colId: "StationReading.OTTParsivel2.RainAmount", field: "StationReading.OTTParsivel2.RainAmount", headerName: "Rain amount (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTParsivel2.RadarReflectivity", field: "StationReading.OTTParsivel2.RadarReflectivity", headerName: "Radar reflectivity", width: 100, filterType: "float" },
                { colId: "StationReading.OTTParsivel2.KineticEnergy", field: "StationReading.OTTParsivel2.KineticEnergy", headerName: "Kinetic energy (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTParsivel2.SignalAmplitude", field: "StationReading.OTTParsivel2.SignalAmplitude", headerName: "Signal amplitude (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTParsivel2.Error", field: "StationReading.OTTParsivel2.Error", headerName: "Error", width: 100 },
                { colId: "StationReading.OTTParsivel2.MetarWeatherCode", field: "StationReading.OTTParsivel2.MetarWeatherCode", headerName: "METAR code", width: 100 },
                { colId: "StationReading.OTTParsivel2.SnowDepthIntensity", field: "StationReading.OTTParsivel2.SnowDepthIntensity", headerName: "Snow depth intensity (mm/h)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTParsivel2.ParticleCountAll", field: "StationReading.OTTParsivel2.ParticleCountAll", headerName: "Particle count (all)", width: 100, filterType: "integer" },
                { colId: "StationReading.OTTParsivel2.SampleInterval", field: "StationReading.OTTParsivel2.SampleInterval", headerName: "Sample interval (s)", width: 100, filterType: "integer" },
                { colId: "StationReading.OTTParsivel2.TemperaturePCB", field: "StationReading.OTTParsivel2.TemperaturePCB", headerName: "Temperature PCB (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTParsivel2.TemperatureRightSensorHead", field: "StationReading.OTTParsivel2.TemperatureRightSensorHead", headerName: "Temperature right sensor head (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTParsivel2.TemperatureLeftSensorHead", field: "StationReading.OTTParsivel2.TemperatureLeftSensorHead", headerName: "Temperature left sensor head (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTParsivel2.PowerSupplyVoltage", field: "StationReading.OTTParsivel2.PowerSupplyVoltage", headerName: "Power supply voltage (V)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTParsivel2.SensorHeadHeatingCurrent", field: "StationReading.OTTParsivel2.SensorHeadHeatingCurrent", headerName: "Sensor head heating current (A)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTParsivel2.FirmwareIOPVersion", field: "StationReading.OTTParsivel2.FirmwareIOPVersion", headerName: "Firmware IOP version", width: 100 },
                { colId: "StationReading.OTTParsivel2.FirmwareDSPVersion", field: "StationReading.OTTParsivel2.FirmwareDSPVersion", headerName: "Firmware DSP version", width: 100 },
            ]
        },
        {
            headerName: 'Vaisala FD71P',
            children: [
                { colId: "StationReading.VaisalaFD71P.HardwareAlert", field: "StationReading.VaisalaFD71P.HardwareAlert", headerName: "Hardware alert", width: 80, filterType: "integer" },
                { colId: "StationReading.VaisalaFD71P.MaintenanceAlert", field: "StationReading.VaisalaFD71P.MaintenanceAlert", headerName: "Maintenance alert", width: 100, filterType: "integer" },
                { colId: "StationReading.VaisalaFD71P.WMOCode", field: "StationReading.VaisalaFD71P.WMOCode", headerName: "WMO", width: 100, filterType: "integer" },
                { colId: "StationReading.VaisalaFD71P.WMOCode15Minute", field: "StationReading.VaisalaFD71P.WMOCode15Minute", headerName: "WMO 15 min.", width: 100, filterType: "integer" },
                { colId: "StationReading.VaisalaFD71P.WMOCode1Hour", field: "StationReading.VaisalaFD71P.WMOCode1Hour", headerName: "WMO 1 hour", width: 100, filterType: "integer" },
                { colId: "StationReading.VaisalaFD71P.Intensity", field: "StationReading.VaisalaFD71P.Intensity", headerName: "Intensity (mm/h)", width: 100, filterType: "float" },
                { colId: "StationReading.VaisalaFD71P.PrecipitationAccumulation", field: "StationReading.VaisalaFD71P.PrecipitationAccumulation", headerName: "Precipitation accumulation (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.VaisalaFD71P.SnowAccumulation", field: "StationReading.VaisalaFD71P.SnowAccumulation", headerName: "Snow accumulation (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.VaisalaFD71P.Temperature", field: "StationReading.VaisalaFD71P.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.VaisalaFD71P.DewPoint", field: "StationReading.VaisalaFD71P.DewPoint", headerName: "Dew point (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.VaisalaFD71P.RelativeHumidity", field: "StationReading.VaisalaFD71P.RelativeHumidity", headerName: "Relative humidity (%)", width: 100, filterType: "float" },
                { colId: "StationReading.VaisalaFD71P.MORVisibility", field: "StationReading.VaisalaFD71P.MORVisibility", headerName: "MOR visibility (m)", width: 100, filterType: "float" },
                { colId: "StationReading.VaisalaFD71P.MORVisibility10Minute", field: "StationReading.VaisalaFD71P.MORVisibility10Minute", headerName: "MOR visibility 10 min. (m)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Thies LPM extra',
            children: [
                { colId: "StationReading.ThiesLPM.InteriorTemperature", field: "StationReading.ThiesLPM.InteriorTemperature", headerName: "Interior temperature (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.ThiesLPM.LaserDriveTemperature", field: "StationReading.ThiesLPM.LaserDriveTemperature", headerName: "Laser drive temperature (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.ThiesLPM.LaserCurrent", field: "StationReading.ThiesLPM.LaserCurrent", headerName: "Laser current (0.01 mA)", width: 100, filterType: "float" },
                { colId: "StationReading.ThiesLPM.ControlVoltage", field: "StationReading.ThiesLPM.ControlVoltage", headerName: "Control voltage (mV)", width: 100, filterType: "float" },
                { colId: "StationReading.ThiesLPM.OpticalControlOutput", field: "StationReading.ThiesLPM.OpticalControlOutput", headerName: "Optical control output (mV)", width: 100, filterType: "float" },
                { colId: "StationReading.ThiesLPM.SensorSupplyVoltage", field: "StationReading.ThiesLPM.SensorSupplyVoltage", headerName: "Sensor supply voltage (0.1 V)", width: 100, filterType: "float" },
                { colId: "StationReading.ThiesLPM.PanHeatingLaserHead", field: "StationReading.ThiesLPM.PanHeatingLaserHead", headerName: "Pan heating laser head (mA)", width: 100, filterType: "float" },
                { colId: "StationReading.ThiesLPM.PanHeatingReceiverHead", field: "StationReading.ThiesLPM.PanHeatingReceiverHead", headerName: "Pan heating receiver head", width: 100, filterType: "float" },
                { colId: "StationReading.ThiesLPM.AmbientTemperature", field: "StationReading.ThiesLPM.AmbientTemperature", headerName: "Ambient temperature (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.ThiesLPM.HeatingSupplyVoltage", field: "StationReading.ThiesLPM.HeatingSupplyVoltage", headerName: "Heating supply voltage (0.1 V)", width: 100, filterType: "float" },
                { colId: "StationReading.ThiesLPM.HeatingHousingCurrent", field: "StationReading.ThiesLPM.HeatingHousingCurrent", headerName: "Heating housing current (mA)", width: 100, filterType: "float" },
                { colId: "StationReading.ThiesLPM.HeatingHeadsCurrent", field: "StationReading.ThiesLPM.HeatingHeadsCurrent", headerName: "Heating heads current (mA)", width: 100, filterType: "float" },
                { colId: "StationReading.ThiesLPM.HeatingCarriersCurrent", field: "StationReading.ThiesLPM.HeatingCarriersCurrent", headerName: "Heating carriers current (mA)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Vaisala WMT700',
            children: [
                { colId: "StationReading.VaisalaWMT700.WindSpeed", field: "StationReading.VaisalaWMT700.WindSpeed", headerName: "Wind speed (m/s)", width: 100, filterType: "float" },
                { colId: "StationReading.VaisalaWMT700.WindDirection", field: "StationReading.VaisalaWMT700.WindDirection", headerName: "Wind direction (°)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Vaisala PWD22',
            children: [
                { colId: "StationReading.VaisalaFD12P.Alarm1", field: "StationReading.VaisalaFD12P.Alarm1", headerName: "Alarm 1", width: 80, filterType: "integer" },
                { colId: "StationReading.VaisalaFD12P.Alarm2", field: "StationReading.VaisalaFD12P.Alarm2", headerName: "Alarm 2", width: 80, filterType: "integer" },
                { colId: "StationReading.VaisalaFD12P.WMOCode", field: "StationReading.VaisalaFD12P.WMOCode", headerName: "WMO", width: 100, filterType: "integer" },
                { colId: "StationReading.VaisalaFD12P.WMOCode15Minute", field: "StationReading.VaisalaFD12P.WMOCode15Minute", headerName: "WMO 15 min.", width: 100, filterType: "integer" },
                { colId: "StationReading.VaisalaFD12P.Intensity", field: "StationReading.VaisalaFD12P.Intensity", headerName: "Intensity (mm/h)", width: 100, filterType: "float" },
                { colId: "StationReading.VaisalaFD12P.CumulativeWater", field: "StationReading.VaisalaFD12P.CumulativeWater", headerName: "Water precipitation sum (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.VaisalaFD12P.CumulativeSnow", field: "StationReading.VaisalaFD12P.CumulativeSnow", headerName: "Snow precipitation sum (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.VaisalaFD12P.Temperature", field: "StationReading.VaisalaFD12P.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.VaisalaFD12P.Visibility", field: "StationReading.VaisalaFD12P.Visibility", headerName: "Visibility (m)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Pluvio2',
            children: [
                { colId: "StationReading.OTTPluvio2.PrecipitationIntensity", field: "StationReading.OTTPluvio2.PrecipitationIntensity", headerName: "Precipitation intensity (mm/h)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2.AccumulatedPrecipitationUnfiltered", field: "StationReading.OTTPluvio2.AccumulatedPrecipitationUnfiltered", headerName: "Accumulated precipitation unfiltered (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2.AccumulatedPrecipitationFiltered", field: "StationReading.OTTPluvio2.AccumulatedPrecipitationFiltered", headerName: "Accumulated precipitation filtered (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2.AccumulatedPrecipitationTotal", field: "StationReading.OTTPluvio2.AccumulatedPrecipitationTotal", headerName: "Accumulated precipitation total (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2.BucketUnfiltered", field: "StationReading.OTTPluvio2.BucketUnfiltered", headerName: "Bucket unfiltered (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2.BucketFiltered", field: "StationReading.OTTPluvio2.BucketFiltered", headerName: "Bucket filtered (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2.LoadCellTemperature", field: "StationReading.OTTPluvio2.LoadCellTemperature", headerName: "Load cell temperature (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2.HeaterStatus", field: "StationReading.OTTPluvio2.HeaterStatus", headerName: "Heater status", width: 250, filterType: "enum", source: "OTTPluvio2HeaterStatus"  },
                { colId: "StationReading.OTTPluvio2.SensorStatus", field: "StationReading.OTTPluvio2.PStatus", headerName: "Sensor status", width: 250, filterType: "enum", source: "OTTPluvio2SensorStatus"  },
                { colId: "StationReading.OTTPluvio2.ElectronicsUnitTemperature", field: "StationReading.OTTPluvio2.ElectronicsUnitTemperature", headerName: "Electronics unit temperature (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2.SupplyVoltage", field: "StationReading.OTTPluvio2.SupplyVoltage", headerName: "Supply voltage (V)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2.OrificeRingRimTemperature", field: "StationReading.OTTPluvio2.OrificeRingRimTemperature", headerName: "Orifice ring rim temperature (°C)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Pluvio2 DFAR',
            children: [
                { colId: "StationReading.OTTPluvio2DFAR.PrecipitationIntensity", field: "StationReading.OTTPluvio2DFAR.PrecipitationIntensity", headerName: "Precipitation intensity (mm/h)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2DFAR.AccumulatedPrecipitationUnfiltered", field: "StationReading.OTTPluvio2DFAR.AccumulatedPrecipitationUnfiltered", headerName: "Accumulated precipitation unfiltered (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2DFAR.AccumulatedPrecipitationFiltered", field: "StationReading.OTTPluvio2DFAR.AccumulatedPrecipitationFiltered", headerName: "Accumulated precipitation filtered (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2DFAR.AccumulatedPrecipitationTotal", field: "StationReading.OTTPluvio2DFAR.AccumulatedPrecipitationTotal", headerName: "Accumulated precipitation total (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2DFAR.BucketUnfiltered", field: "StationReading.OTTPluvio2DFAR.BucketUnfiltered", headerName: "Bucket unfiltered (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2DFAR.BucketFiltered", field: "StationReading.OTTPluvio2DFAR.BucketFiltered", headerName: "Bucket filtered (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2DFAR.LoadCellTemperature", field: "StationReading.OTTPluvio2DFAR.LoadCellTemperature", headerName: "Load cell temperature (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2DFAR.HeaterStatus", field: "StationReading.OTTPluvio2DFAR.HeaterStatus", headerName: "Heater status", width: 250, filterType: "enum", source: "OTTPluvio2HeaterStatus"  },
                { colId: "StationReading.OTTPluvio2DFAR.SensorStatus", field: "StationReading.OTTPluvio2DFAR.PStatus", headerName: "Sensor status", width: 250, filterType: "enum", source: "OTTPluvio2SensorStatus"  },
                { colId: "StationReading.OTTPluvio2DFAR.ElectronicsUnitTemperature", field: "StationReading.OTTPluvio2DFAR.ElectronicsUnitTemperature", headerName: "Electronics unit temperature (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2DFAR.SupplyVoltage", field: "StationReading.OTTPluvio2DFAR.SupplyVoltage", headerName: "Supply voltage (V)", width: 100, filterType: "float" },
                { colId: "StationReading.OTTPluvio2DFAR.OrificeRingRimTemperature", field: "StationReading.OTTPluvio2DFAR.OrificeRingRimTemperature", headerName: "Orifice ring rim temperature (°C)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Pond Hot Plate',
            children: [
                { colId: "StationReading.PondHotPlate.PrecipitationRate", field: "StationReading.PondHotPlate.PrecipitationRate", headerName: "Precipitation rate (mm/h)", width: 100, filterType: "float" },
                { colId: "StationReading.PondHotPlate.Accumulation24Hour", field: "StationReading.PondHotPlate.Accumulation24Hour", headerName: "Accumulation 24 hour (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.PondHotPlate.AmbientTemperature", field: "StationReading.PondHotPlate.AmbientTemperature", headerName: "Ambient temperature (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.PondHotPlate.BarometricPressure1Min", field: "StationReading.PondHotPlate.BarometricPressure1Min", headerName: "Barometric pressure 1 min. (mbar)", width: 100, filterType: "float" },
                { colId: "StationReading.PondHotPlate.RelativeHumidity1Min", field: "StationReading.PondHotPlate.RelativeHumidity1Min", headerName: "Relative humidity 1 min. (%)", width: 100, filterType: "float" },
                { colId: "StationReading.PondHotPlate.WindSpeed", field: "StationReading.PondHotPlate.WindSpeed", headerName: "Wind speed (m/s)", width: 100, filterType: "float" },
                { colId: "StationReading.PondHotPlate.SystemStatus", field: "StationReading.PondHotPlate.SystemStatus", headerName: "System status", width: 100, filterType: "enum", source: "SensorReadingPondHotPlateSystemStatus" },
                { colId: "StationReading.PondHotPlate.SensorPlatePower1Min", field: "StationReading.PondHotPlate.SensorPlatePower1Min", headerName: "Sensor plate power 1 min. (W)", width: 100, filterType: "float" },
                { colId: "StationReading.PondHotPlate.ReferencePlatePower1Min", field: "StationReading.PondHotPlate.ReferencePlatePower1Min", headerName: "Reference plate power 1 min. (W)", width: 100, filterType: "float" },
                { colId: "StationReading.PondHotPlate.AlarmCode", field: "StationReading.PondHotPlate.AlarmCode", headerName: "Alarm code", width: 100, filterType: "float" },
                { colId: "StationReading.PondHotPlate.SensorPlateTemp", field: "StationReading.PondHotPlate.SensorPlateTemp", headerName: "Sensor plate temp (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.PondHotPlate.ReferencePlateTemp", field: "StationReading.PondHotPlate.ReferencePlateTemp", headerName: "Reference plate temp (°C)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'MiniOFS',
            children: [
                { colId: "StationReading.MiniOFS.AmbientSolarLightLevel", field: "StationReading.MiniOFS.AmbientSolarLightLevel", headerName: "Ambient solar light level", width: 120, filterType: "float" },
                { colId: "StationReading.MiniOFS.ExtinctionCoefficient", field: "StationReading.MiniOFS.ExtinctionCoefficient", headerName: "Extinction coefficient", width: 120, filterType: "float" },
                { colId: "StationReading.MiniOFS.Visibility", field: "StationReading.MiniOFS.Visibility", headerName: "Visibility (m)", width: 120, filterType: "float" },
            ]
        },
        {
            headerName: 'Thies FZRA',
            children: [
                { colId: "StationReading.ThiesFZRA.Intensity", field: "StationReading.ThiesFZRA.Intensity", headerName: "Intensity (mm/h)", width: 80 },
                { colId: "StationReading.ThiesFZRA.Temperature", field: "StationReading.ThiesFZRA.Temperature", headerName: "Temperature (°C)", width: 80 },
            ]
        },
    ];
    
    constructor(
        private formatters: ValueFormatters,
        private api: ApiService,
    ) {
    }

    ngOnInit() {
        Promise.all([this.sensorSelectMatrices.$promise, this.metarWeatherMappingProfiles.$promise]).then(() => {
            let columnDefs = this.columnDefs
                .concat(this.sensorSelectMatrices.map(ssm => ({
                    headerName: `Weather profile (${ssm.Name})`,
                    children: [
                        { field: `WeatherProfiles[${ssm.Id}].LWE`, headerName: "LWE (g/dm²/h)", filterType: "float", },
                        { field: `WeatherProfiles[${ssm.Id}].Temperature`, headerName: "Temperature (°C)", filterType: "float", },
                        { field: `WeatherProfiles[${ssm.Id}].WeatherType`, headerName: "Weather type", filterType: "enum", source: "ClientWeatherType" },
                        { field: `WeatherProfiles[${ssm.Id}].Errors`, headerName: "Errors", filterType: "enum", source: "WeatherProfileError" },
                        { field: `WeatherProfiles[${ssm.Id}].SensorSelectMatrixWeatherTypeSensorMappingOrder`, headerName: "Weather type sensor mapping order", filterType: "integer" },
                        { field: `WeatherProfiles[${ssm.Id}].SensorSelectMatrixIntensitySensorMappingOrder`, headerName: "Intensity sensor mapping order", filterType: "integer" },
                    ]
                })))
                .concat(this.sensorSelectMatrices.map(ssm => ({
                    headerName: `Weather profile at other stations at airport (${ssm.Name})`,
                    children: [
                        { field: `OtherStationWeatherProfiles[${ssm.Id}].LWE`, headerName: "LWE (g/dm²/h)", filterType: "float", },
                        { field: `OtherStationWeatherProfiles[${ssm.Id}].Temperature`, headerName: "Temperature (°C)", filterType: "float", },
                        { field: `OtherStationWeatherProfiles[${ssm.Id}].WeatherType`, headerName: "Weather type", filterType: "enum", source: "ClientWeatherType" },
                        { field: `OtherStationWeatherProfiles[${ssm.Id}].Errors`, headerName: "Errors", filterType: "enum", source: "WeatherProfileError" },
                    ]
                })))
                .concat(this.metarWeatherMappingProfiles.map(p => ({
                    headerName: `METAR final weather type (${p.Name})`,
                    children: [
                        { field: `MetarFinalWeatherTypes[${p.Id}]`, headerName: "METAR weather type", filterType: "enum", source: "MetarWeatherType" },
                    ]
                })));
            this.availableFields = FiltersComponent.mapColumnDefs(this.api, columnDefs);
            this.fieldGroups = FiltersComponent.getFieldGroups(columnDefs);
        });
    }
    
    addCondition(parent, type: string) {
        var maxOrder = Math.max.apply(null, this.conditions.filter(c => c.ParentConditionId == this.parent?.Id)
            .map(h => h.Order));
        this.conditions.push({
            Id: StationMonitoringConditionListComponent.uuidv4(),
            ParentConditionId: this.parent?.Id,
            Order: Math.max(1, maxOrder + 1),
            ConditionType: type,
            MinimumDurationMinutes: 1,
        });
        this.ngForm.form.markAsDirty();
    }

    //https://stackoverflow.com/a/2117523/530630
    static uuidv4() {
        return `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, c =>
            (parseInt(c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> parseInt(c) / 4).toString(16)
        );
    }
    
    childConditions() {
        return this.conditions?.filter(c => c.ParentConditionId == this.parent?.Id) ?? [];
    }

    removeStationMonitoringCondition(parent, condition) {
        this.conditions.splice(this.conditions.indexOf(condition), 1);
        this.ngForm.form.markAsDirty();
    }
}
