/// <reference path="../../../../node_modules/@types/jquery.ui.datetimepicker/index.d.ts"/>
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { AgGridModule } from "ag-grid-angular";
import { SwxModule } from "../Swx.module";
import { PaginationComponent } from './Pagination.component';
import { FiltersComponent } from './Filters.component';
import { FilterComponent } from './Filter.component';
import { ExportDialogComponent } from "./ExportDialog.component";
import { GridComponent } from './Grid.component';
import { ConfigGridComponent } from './ConfigGrid.component';
import {ColumnSelectionDialogComponent} from "./ColumnSelectionDialog.component";

@NgModule({
    imports: [
        CommonModule,
        AgGridModule,
        SwxModule,
        FormsModule,
        FiltersComponent,
        FilterComponent,
        PaginationComponent,
        ExportDialogComponent,
        ColumnSelectionDialogComponent,
        GridComponent,
        ConfigGridComponent,
    ],
    exports: [
        FiltersComponent,
        FilterComponent,
        PaginationComponent,
        ExportDialogComponent,
        ColumnSelectionDialogComponent,
        GridComponent,
        ConfigGridComponent,
    ],
})
export class GridModule {
}
