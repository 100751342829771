import {Component, OnInit, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import { ChangesService, NotificationService, TranslateService, SwxModule } from "swx.front-end-lib";
import {ActivatedRoute, Router} from "@angular/router";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";
import { HasPermissionPipe } from "../shared/HasPermission.pipe";
import { FormsModule } from "@angular/forms";
import { NgClass, NgIf } from "@angular/common";

@Component({
    templateUrl: './HoldoverTaxiTimePredictionEdit.component.html',
    standalone: true,
    imports: [SwxModule, NgClass, NgIf, FormsModule, HasPermissionPipe]
})
export class HoldoverTaxiTimePredictionEditComponent implements OnInit {
    tab = 'basicInfo';
    returnPath = '';
    item: any;
    
    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private notification: NotificationService,
                private route: ActivatedRoute,
                private router: Router,
                private translate: TranslateService,
                private ticketTrackingSystem: TicketTrackingSystemService,
                private changes: ChangesService) {
    }
    switchTab(tab: string) {
        this.tab = tab;
    }
    
    ngOnInit() {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.HoldoverTaxiTimeModelConfiguration.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;
            });
        } else if (isNew) {
            this.item = this.api.HoldoverTaxiTimeModelConfiguration.create();
        } else {
            this.item = this.api.HoldoverTaxiTimeModelConfiguration.get({ id: id });
        }

        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
    }


    save() {
        try {
            const array = JSON.parse(this.item.RegressionCoefficientsJson);
            if (!Array.isArray(array)) {
                this.notification.show(this.translate.translate('Regression coefficients is not a valid JSON array'), { type: 'error' });
                return;
            }
            if (array.length !== (this.item.NumberOfEvents + 1)) {
                this.notification.show(this.translate.translate('Regression coefficients should have (Number of input flights + 1) items'), { type: 'error' });
                return;
            }
        } catch (e) {
            this.notification.show(this.translate.translate('Regression coefficients is not a valid JSON array'), { type: 'error' });
            return;
        }

        try {
            const matrix = JSON.parse(this.item.MatrixJson);
            if (!Array.isArray(matrix)) {
                this.notification.show(this.translate.translate('Matrix is not a valid JSON array'), { type: 'error' });
                return;
            }
            if (matrix.length !== (this.item.NumberOfEvents + 1)) {
                this.notification.show(this.translate.translate('Matrix should have (Number of input flights + 1) rows'), { type: 'error' });
                return;
            }
            if (matrix[0].length !== (this.item.NumberOfEvents + 1)) {
                this.notification.show(this.translate.translate('Matrix should have (Number of input flights + 1) columns'), { type: 'error' });
                return;
            }
        } catch (e) {
            this.notification.show(this.translate.translate('Matrix is not a valid JSON array'), { type: 'error' });
            return;
        }

        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
    };

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    };

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: 'HoldoverTaxiTimeModelConfiguration',
            SubjectId: this.item.Id
        });
    };
}
