import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FileDownloadService} from '../FileDownload.service';
import { TranslatePipe } from '../pipes/Translate.pipe';
import { NgIf } from '@angular/common';

@Component({
    selector: 'file',
    templateUrl: './File.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileComponent),
            multi: true
        }
    ],
    standalone: true,
    imports: [NgIf, TranslatePipe]
})
export class FileComponent implements ControlValueAccessor {
    value: string;
    onchange = (_: any) => { };
    ontouched = (_: any) => { };
    accept: string;
    @Output() filenameChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() filename: string;
    @Output() contentTypeChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() contentType: string;

    @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

    constructor(
        private fileDownloadService: FileDownloadService
    ) {
    }

    fileChange(e: Event) {
        const target = e.target as HTMLInputElement;
        const files = target.files as FileList;
        const file = files[0];
        
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                this.value = (reader.result as string)
                    .replace('data:', '')
                    .replace(/^.+,/, '');
                this.filename = file.name;
                this.filenameChange.emit(file.name);
                this.contentType = file.type;
                this.contentTypeChange.emit(file.type);
                this.onchange(this.value);
                this.ontouched(this.value);
            }
            
            reader.readAsDataURL(file);
        }
    }

    writeValue(value): void {
        this.value = value;
    }

    registerOnChange(fn): void {
        this.onchange = fn;
    }

    registerOnTouched(fn): void {
        this.ontouched = fn;
    }

    browse() {
        this.fileInput.nativeElement.click();
    }

    clear() {
        this.filename = null;
        this.filenameChange.emit(this.filename);
        this.value = null;
        this.onchange(this.value);
        this.ontouched(this.value);
    }

    download() {
        const bytes = atob(this.value);
        let byteArray = new Uint8Array(bytes.length);
        for (let i = 0; i < bytes.length; i++) {
            byteArray[i] = bytes.charCodeAt(i);
        }
        const blob = new Blob([byteArray], { type: this.contentType });
        this.fileDownloadService.download(blob, this.filename);
    }
}
