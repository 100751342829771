import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[password-match]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PasswordMatchDirective),
            multi: true
        }
    ],
    standalone: true
})
export class PasswordMatchDirective implements Validator {
    @Input('password-match') password;

    validate(control: AbstractControl): { [index: string]: any; } {
        return !control.value || !this.password || control.value === this.password
            ? null
            : {
                'password-match': false
            };
    }
}
