import {Component, Input} from "@angular/core";
import {TestFlightGroup} from "../../model/TestFlightGroup";
import { TesterListComponent } from "../testerList/testerList.component";
import { NgIf, DatePipe } from "@angular/common";

@Component({
    selector: 'swx-test-flight-group-details',
    templateUrl: './testFlightGroupDetails.component.html',
    standalone: true,
    imports: [NgIf, TesterListComponent, DatePipe]
})
export class TestFlightGroupDetailsComponent {
    @Input() group: TestFlightGroup;
}
