<page-title>{{('Instant weather type rule profile'|translate) + ': ' + item.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" >
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('rules')" [class.active]="tab == 'rules'" [class.invalid]="!ngForm.form.controls.rulesForm?.valid">{{'Rules'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" class="green" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}"/>
        <input type="button" *ngIf="'DataChanges'|hasPermission" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}"/>
        <button *ngIf="('ConfigAddInstantWeatherTypeRuleProfiles'|hasPermission) && tab == 'rules'" type="button" (click)="addRule()" class="green">{{'Add rule'|translate}}</button>
        <button type="button" (click)="exportInstantWeatherTypeRules()" [disabled]="!item.Id"><span class="mdi mdi-download"></span>{{'Excel'|translate}}</button>
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditInstantWeatherTypeRuleProfiles'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
        </label>
        <label>
            <span class="caption">{{'Sensor 1'|translate}}</span>
            <select required [(ngModel)]="item.WeatherTypeSensor1" name="item.WeatherTypeSensor1">
                <option *ngFor="let option of weatherTypeSensorTypes|keys" [ngValue]="option">
                    {{weatherTypeSensorTypes[option]}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Sensor 2'|translate}}</span>
            <select [(ngModel)]="item.WeatherTypeSensor2" name="item.WeatherTypeSensor2" [disabled]="!item.WeatherTypeSensor1" (ngModelChange)="refreshInstantWeatherTypeSensors()">
                <option [ngValue]="null" *ngIf="item.WeatherTypeSensor3 == null">{{'None'|translate}}</option>
                <option *ngFor="let option of weatherTypeSensorTypes|keys" [ngValue]="option">
                    {{weatherTypeSensorTypes[option]}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Sensor 3'|translate}}</span>
            <select [(ngModel)]="item.WeatherTypeSensor3" name="item.WeatherTypeSensor3" [disabled]="!item.WeatherTypeSensor2" (ngModelChange)="refreshInstantWeatherTypeSensors()">
                <option [ngValue]="null" *ngIf="item.WeatherTypeSensor4 == null">{{'None'|translate}}</option>
                <option *ngFor="let option of weatherTypeSensorTypes|keys" [ngValue]="option">
                    {{weatherTypeSensorTypes[option]}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Sensor 4'|translate}}</span>
            <select [(ngModel)]="item.WeatherTypeSensor4" name="item.WeatherTypeSensor4" [disabled]="!item.WeatherTypeSensor3" (ngModelChange)="refreshInstantWeatherTypeSensors()">
                <option [ngValue]="null" *ngIf="item.WeatherTypeSensor5 == null">{{'None'|translate}}</option>
                <option *ngFor="let option of weatherTypeSensorTypes|keys" [ngValue]="option">
                    {{weatherTypeSensorTypes[option]}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Sensor 5'|translate}}</span>
            <select [(ngModel)]="item.WeatherTypeSensor5" name="item.WeatherTypeSensor5" [disabled]="!item.WeatherTypeSensor4" (ngModelChange)="refreshInstantWeatherTypeSensors()">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let option of weatherTypeSensorTypes|keys" [ngValue]="option">
                    {{weatherTypeSensorTypes[option]}}
                </option>
            </select>
        </label>
    </fieldset>

    <fieldset class="form" [disabled]="!('ConfigEditInstantWeatherTypeRuleProfiles'|hasPermission)" [hidden]="tab != 'rules'" ngModelGroup="rulesForm">
        <table class="table">
            <tr>
                <th>{{'Number'|translate}}</th>
                <th style="width: 80px;">{{'Temp &lt;= (°C)'|translate}}</th>
                <th style="width: 80px;">{{weatherTypeSensorTypes[item.WeatherTypeSensor1]}}<br />{{'weather types'|translate}}</th>
                <th *ngIf="item.WeatherTypeSensor2 != null" style="width: 170px;">{{weatherTypeSensorTypes[item.WeatherTypeSensor2]}}<br />{{'weather types'|translate}}</th>
                <th *ngIf="item.WeatherTypeSensor3 != null" style="width: 170px;">{{weatherTypeSensorTypes[item.WeatherTypeSensor3]}}<br />{{'weather types'|translate}}</th>
                <th *ngIf="item.WeatherTypeSensor4 != null" style="width: 170px;">{{weatherTypeSensorTypes[item.WeatherTypeSensor4]}}<br />{{'weather types'|translate}}</th>
                <th *ngIf="item.WeatherTypeSensor5 != null" style="width: 170px;">{{weatherTypeSensorTypes[item.WeatherTypeSensor5]}}<br />{{'weather types'|translate}}</th>
                <th style="width: 100px;">{{'METAR warnings'|translate}}</th>
                <th style="width: 150px;">{{'METAR weather code regular expression'|translate}}</th>
                <th style="width: 150px;">{{'Final weather type'|translate}}</th>
                <th style="width: 100px;">{{'Final sensor'|translate}}</th>
                <th></th>
            </tr>
            <tr *ngFor="let rule of item.InstantWeatherTypeRules; let i = index">
                <td><input required type="number" step="1" min="0" [(ngModel)]="rule.Number" [name]="'rule[' + i + '].Number'" style="width: 70px;" /></td>
                <td><input required type="number" step="any" [(ngModel)]="rule.MaxTemp" [name]="'rule[' + i + '].MaxTemp'" style="width: 70px;" /></td>
                <td>
                    <multiselect [all]="false" [none]="'Any'" [format]="'string'" [(ngModel)]="rule.Sensor1WeatherTypeNg" [name]="'rule[' + i + '].Sensor1WeatherTypeNg'">
                        <multiselect-option *ngFor="let option of weatherTypeNgFlags|keys" [value]="option" [name]="weatherTypeNgFlags[option]"></multiselect-option>
                    </multiselect>
                </td>
                <td *ngIf="item.WeatherTypeSensor2 != null">
                    <multiselect [all]="false" [none]="'Any'" [format]="'string'" [(ngModel)]="rule.Sensor2WeatherTypeNg" [name]="'rule[' + i + '].Sensor2WeatherTypeNg'">
                        <multiselect-option *ngFor="let option of weatherTypeNgFlags|keys" [value]="option" [name]="weatherTypeNgFlags[option]"></multiselect-option>
                    </multiselect>
                </td>
                <td *ngIf="item.WeatherTypeSensor3 != null">
                    <multiselect [all]="false" [none]="'Any'" [format]="'string'" [(ngModel)]="rule.Sensor3WeatherTypeNg" [name]="'rule[' + i + '].Sensor3WeatherTypeNg'">
                        <multiselect-option *ngFor="let option of weatherTypeNgFlags|keys" [value]="option" [name]="weatherTypeNgFlags[option]"></multiselect-option>
                    </multiselect>
                </td>
                <td *ngIf="item.WeatherTypeSensor4 != null">
                    <multiselect [all]="false" [none]="'Any'" [format]="'string'" [(ngModel)]="rule.Sensor4WeatherTypeNg" [name]="'rule[' + i + '].Sensor4WeatherTypeNg'">
                        <multiselect-option *ngFor="let option of weatherTypeNgFlags|keys" [value]="option" [name]="weatherTypeNgFlags[option]"></multiselect-option>
                    </multiselect>
                </td>
                <td *ngIf="item.WeatherTypeSensor5 != null">
                    <multiselect [all]="false" [none]="'Any'" [format]="'string'" [(ngModel)]="rule.Sensor5WeatherTypeNg" [name]="'rule[' + i + '].Sensor5WeatherTypeNg'">
                        <multiselect-option *ngFor="let option of weatherTypeNgFlags|keys" [value]="option" [name]="weatherTypeNgFlags[option]"></multiselect-option>
                    </multiselect>
                </td>
                <td>
                    <multiselect [all]="false" [none]="'Any'" [format]="'string'" [(ngModel)]="rule.MetarWarning" [name]="'rule[' + i + '].MetarWarning'">
                        <multiselect-option *ngFor="let option of metarWarningFlags|keys" [value]="option" [name]="metarWarningFlags[option]"></multiselect-option>
                    </multiselect>
                </td>
                <td><input type="text" [(ngModel)]="rule.MetarWeatherCodeRegex" [name]="'rule[' + i + '].MetarWeatherCodeRegex'" style="width: 150px;" /></td>
                <td>
                    <select style="width: 150px;" [(ngModel)]="rule.FinalWeatherTypeNg" [name]="'rule[' + i + '].FinalWeatherTypeNg'">
                        <option [ngValue]="null">{{'Use final sensor'|translate}}</option>
                        <option *ngFor="let option of weatherTypeNgs|keys" [ngValue]="option">
                            {{weatherTypeNgs[option]}}
                        </option>
                    </select>
                </td>
                <td>
                    <select style="width: 100px;" *ngIf="rule.FinalWeatherTypeNg == null" [required]="rule.FinalWeatherTypeNg == null" [(ngModel)]="rule.FinalSensor" [name]="'rule[' + i + '].FinalSensor'">
                        <option *ngFor="let option of instantWeatherTypeSensors|keys" [ngValue]="option">
                            {{instantWeatherTypeSensors[option]}}
                        </option>
                    </select>
                </td>
                <td>
                    <a *ngIf="'ConfigDeleteInstantWeatherTypeRuleProfiles'|hasPermission" (click)="removeRule(rule)" class="mdi mdi-delete"></a>
                </td>
            </tr>
        </table>
    </fieldset>
</form>
