import { Component, Input } from '@angular/core';
import { SwxModule } from 'swx.front-end-lib';
import { NgFor, NgIf } from '@angular/common';

@Component({
    template: `
        <div class="alert" *ngFor="let alert of alerts|orderBy:'CreatedDate'" [style.width]="alert.EmailBody != null ? '600px' : '240px'">
            <h3>{{alert.CreatedDate|utcDateTimeFormat:'HH:mm'}}</h3>
            <div class="field" *ngIf="alerts[0].RawData != null">
                <textarea readonly cols="24" rows="10">{{alert.RawData}}</textarea>
            </div>
            <div class="field" *ngIf="alerts[0].EmailRecipients != null">
                <div class="recipients">{{'To: '|translate}} {{alert.EmailRecipients}}</div>
            </div>
            <div class="field" *ngIf="alerts[0].EmailRecipients != null">
                <div class="subject">{{alert.EmailSubject}}</div>
            </div>
            <div class="field" *ngIf="alerts[0].EmailRecipients != null" style="border: 1px solid #ddd;">
                <div class="body" [innerHTML]="getBody(alert.EmailBody)"></div>
            </div>
        </div>
    `,
    styles: [`
        .alert {
            float: left;
            border: 1px solid #000000;
            padding: 9px;
            margin: 5px;
        }
        
        .alert h3 {
            margin: 0 0 5px 0;
        }
        
        .alert textarea {
            font-family: monospace;
            white-space: pre;
            overflow: auto;
        }

        .alert .recipients {
            width: 100%;
            height: 40px;
            overflow: auto;
        }

        .alert .subject {
            width: 100%;
            height: 40px;
            overflow: auto;
        }
        
        .alert .body {
            width: 100%;
            height: 240px;
            overflow: auto;
        }
        
        .alert::after {
            content: "";
            display: block;
            clear: both;
        }
    `],
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        SwxModule,
    ],
})
export class FreezingFogAlertDialogComponent {
    @Input() alerts;

    getBody(html) {
        var bodyIndex = html?.lastIndexOf("<body>");
        return bodyIndex > -1 ? (html?.substring(bodyIndex + 6, html.lastIndexOf("</body>"))) : html;
    }
}
