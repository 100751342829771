import { Directive, Host, Input, OnDestroy } from "@angular/core";
import { MultiselectComponent } from "./Multiselect.component";

@Directive({
    selector: 'multiselect-option[value][name]',
    standalone: true,
})
export class MultiselectOptionComponent implements OnDestroy {
    _value: any;
    visible = true;
    @Input() name;

    constructor(
        @Host() public multiselect: MultiselectComponent
    ) {
        if (this.multiselect) {
            this.multiselect._registerOption(this);
        }
    }

    @Input('value')
    set value(value: any) {
        if (this.multiselect && this._value !== value) {
            this._value = value;
            this.multiselect.writeValue(this.multiselect.selected);
        }
    }

    ngOnDestroy() {
        if (this.multiselect) {
            var index = this.multiselect.options.indexOf(this);
            if (index > -1) {
                this.multiselect.options.splice(index, 1);
            }
            this.multiselect.setValue(this.multiselect.selected);
        }
    }
}
