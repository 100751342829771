<script src="../../../node_modules/@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter.js"></script>
<page-title>{{('Station monitoring profile'|translate) + ': ' + (item.Name || 'new')}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" id="StationMonitoringProfileDetail">
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('conditions')" [class.active]="tab == 'conditions'" [class.invalid]="!ngForm.form.controls.conditionsForm?.valid">{{'Conditions'|translate}}</a>
        <a class="tab" (click)="switchTab('actions')" [class.active]="tab == 'actions'" [class.invalid]="!ngForm.form.controls.actionsForm?.valid">{{'Actions'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditStationMonitoringProfiles'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
        </label>
        <label>
            <span class="caption">{{'Minimum duration between alerts (minutes)'|translate}}</span>
            <input required type="number" min="1" step="1" [(ngModel)]="item.MinimumAlertAgeMinutes" name="item.MinimumAlertAgeMinutes" style="width: 60px;" />
        </label>
        <label>
            <span class="caption">{{'Email sender'|translate}}</span>
            <select required [(ngModel)]="item.EmailFromId" name="item.EmailFromId">
                <option *ngFor="let emailFrom of emailFroms|orderBy:'Id'" [ngValue]="emailFrom.Id">
                    {{emailFrom.EmailAddress}}
                </option>
            </select>
        </label>
    </fieldset>

    <fieldset class="form contentMargins" [disabled]="!('ConfigEditStationMonitoringProfiles'|hasPermission)" [hidden]="tab != 'conditions'" ngModelGroup="conditionsForm">
        <select required [(ngModel)]="item.ConditionType" [name]="'item.ConditionType'" style="margin: 0 0 12px 3px; display: block;">
            <option [ngValue]="'OrGroup'">{{'Any of'|translate}}</option>
            <option [ngValue]="'AndGroup'">{{'All of'|translate}}</option>
        </select>
        <station-monitoring-condition-list #list [ngForm]="ngForm" [conditions]="item.StationMonitoringConditions" [parent]="null"></station-monitoring-condition-list>
    </fieldset>

    <fieldset class="form contentMargins" [disabled]="!('ConfigEditStationMonitoringProfiles'|hasPermission)" [hidden]="tab != 'actions'" ngModelGroup="actionsForm">
        <table class="table" *ngFor="let action of item.StationMonitoringActions; let i = index" style="margin-bottom: 20px; width: 100%;">
            <tr>
                <th style="width: 100px;">{{'Type'|translate}}</th>
                <th>{{(action.Type === 'Ticket' ? 'Title': 'Recipient')|translate}} <span *ngIf="action.Type === 'Email'">{{'(comma separated)'|translate}}</span></th>
                <td style="width: 40px;">
                    <a *ngIf="'ConfigEditStationMonitoringProfiles'|hasPermission" (click)="removeAction(action)" class="mdi mdi-delete"></a>
                </td>
            </tr>
            <tr>
                <td>
                    <select required [(ngModel)]="action.Type" [name]="'action[' + i + '].Type'">
                        <option *ngFor="let kv of stationMonitoringActionTypes|keyvalue" [ngValue]="kv.key">
                            {{kv.value}}
                        </option>
                    </select>
                </td>
                <td colspan="2">
                    <input type="text" required style="width: 100%;" [(ngModel)]="action.Recipient" [name]="'action[' + i + '].Recipient'" />
                </td>
            </tr>
            <tr *ngIf="action.Type === 'Email'">
                <td colspan="3">
                    <label>
                        <span class="caption">{{'Available tokens'|translate}}</span>
                        <span class="value" style="width: auto;">{{'{StationName} {StationAlarmsList}'}}</span>
                    </label>
                </td>
            </tr>
            <tr *ngIf="action.Type === 'Ticket'">
                <td colspan="3">
                    <label>
                        <span class="caption">{{'Available Title tokens'|translate}}</span>
                        <span class="value" style="width: auto;">{{'{StationName}'}}</span>
                    </label>
                </td>
            </tr>
            <tr *ngIf="action.Type === 'Email'">
                <td colspan="3">
                    <input type="text" required style="width: 100%;" [(ngModel)]="action.Subject" [name]="'action[' + i + '].Subject'" placeholder="Subject" />
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <div *ngIf="action.Type !== 'PhoneCall'">
                        <label>
                            <span class="caption">{{'Available tokens'|translate}}</span>
                            <span class="value">{{'{StationName} {StationAlarmsList} {StationAlarmsTable} {StationRawData}'}}</span>
                        </label>
                    </div>
                    <div *ngIf="action.Type === 'PhoneCall'">
                        <label>
                            <span class="caption">{{'Available tokens'|translate}}</span>
                            <span class="value">{{'{StationName}'}}</span>
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <ckeditor *ngIf="action.Type !== 'PhoneCall'" required [editor]="Editor" [config]="ckConfig" style="width: 100%;" [(ngModel)]="action.Content" [name]="'action[' + i + '].Content'"></ckeditor>
                    <textarea *ngIf="action.Type === 'PhoneCall'" required style="width: 100%;" [(ngModel)]="action.Content" [name]="'action[' + i + '].Content'" placeholder="Body"></textarea>
                </td>
            </tr>
        </table>
        <input type="button" class="green" *ngIf="('ConfigAddStationMonitoringProfiles'|hasPermission)" (click)="addAction()" value="{{'Add action'|translate}}" />
    </fieldset>
</form>
