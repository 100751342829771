import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { AuthenticationService, BusyIndicatorService, PageTitleService, SwxModule } from 'swx.front-end-lib';
import { ApiService } from './shared/Api.service';
import { HasPermissionService } from './shared/HasPermission.pipe';
import { AuthGuard } from './shared/AuthGuard.service';
import { MenuComponent } from './menu/Menu.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, RouterLink, SwxModule, MenuComponent, RouterOutlet]
})
export class AppComponent implements OnInit {
    currentUser;
    logo;
    @ViewChild('title', { static: true }) title;
    @ViewChild('menuContainer', { static: true }) menuContainer;
    menuVisible = false;
    environment: any = {};
    
    constructor(
        private router: Router,
        private api: ApiService,
        private authentication: AuthenticationService,
        public busyIndicatorService: BusyIndicatorService,
        private pageTitleService: PageTitleService,
        public hasPermissionService: HasPermissionService,
        private authGuard: AuthGuard,
    ) {
    }

    ngOnInit(): void {
        this.pageTitleService.registerTitleElement(this.title.nativeElement);
        this.pageTitleService.setSiteName('SureWx Admin');
        
        this.api.Environment.queryObject().$promise.then(e => {
            this.environment = e;
        });
        
        this.authentication.userChange.subscribe(user => {
            this.currentUser = user;

            if (window.localStorage['menuVisible']) {
                this.menuVisible = JSON.parse(window.localStorage['menuVisible']);
                if (this.menuVisible) {
                    this.showMenu();
                }
            } else {
                this.showMenu();
            }
        });
    }

    toggleMenu() {
        if (this.menuVisible) {
            this.hideMenu();
        } else {
            this.showMenu();
        }
    }

    showMenu() {
        document.documentElement.style.setProperty('--menu-width', '240px');
        this.menuVisible = true;
        window.localStorage['menuVisible'] = JSON.stringify(this.menuVisible);
        setTimeout(() => window.dispatchEvent(new Event('resize')), 300);
    }

    hideMenu() {
        document.documentElement.style.setProperty('--menu-width', '0');
        this.menuVisible = false;
        window.localStorage['menuVisible'] = JSON.stringify(this.menuVisible);
        setTimeout(() => window.dispatchEvent(new Event('resize')), 300);
    }

    logout() {
        if (this.environment.UseSaml2Authentication) {
            this.authGuard.samlLogoutRedirect();
        } else {
            this.authentication.clearCredentials();
            this.router.navigateByUrl('/login');
        }
    }
}
