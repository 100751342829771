import { Component, Input } from '@angular/core';

@Component({
    selector: 'swx-heat-flaps-alert-dialog',
    template: '<textarea readonly cols="24" rows="10" style="font-family: monospace; white-space: pre; overflow: auto;">{{message}}</textarea>',
    standalone: true,
})
export class HeatFlapsAlertDialogComponent {
    @Input() message;
}
