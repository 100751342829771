import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import { DialogService, IAgGridColumnDef, StorageService, ValueFormatters, SwxModule, GridModule } from "swx.front-end-lib";
import {ApiService} from "../shared/Api.service";
import {StationDataRawDataDialogComponent} from "../stationDataGen2/StationDataRawDataDialog.component";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './FinnairInit.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, GridModule]
})
export class FinnairInitComponent implements OnInit {
    @ViewChild('grid') grid;
    storeKey = 'finnairInitQuery';
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    query: any;

    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    resource = this.api.FinnairInit;
    exportResourcePost = this.api.FinnairInitExport;
    
    constructor(private storage: StorageService,
                private viewContainerRef: ViewContainerRef,
                private api: ApiService,
                private formatters: ValueFormatters,
                private dialog: DialogService) {
    }

    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery){
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    };

    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }


    columnDefs: IAgGridColumnDef[] = [
        { colId: "FinnairInit.Id", field: "FinnairInit.Id", headerName: "#", width: 80, filterType: "integer", pinned: 'left' },
        { colId: "FinnairInit.TimeStampDate", field: "FinnairInit.TimeStamp", headerName: "Date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left', filterType: "date" },
        { colId: "FinnairInit.TimeStampTime", field: "FinnairInit.TimeStamp", headerName: "Time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, pinned: 'left', "searchable": false },
        { colId: "FinnairInit.MessageId", field: "FinnairInit.MessageId", headerName: "Message ID", width: 100, pinned: 'left', cellTemplate: "<div><a (click)='parent.showRawData(params.data)'>{{params.data?.FinnairInit.MessageId}}</a></div>" },
        { colId: "FinnairInit.RawData", field: "FinnairInit.RawData", headerName: "Raw data", width: 80, initialHide: true },
        { colId: "FinnairInit.DepartureAirportCode", field: "FinnairInit.DepartureAirportCode", headerName: "Departure airport code", width: 80 },
        { colId: "FlightIdentifier", field: "FlightIdentifier", headerName: "Call sign", width: 80 },
        { colId: "FinnairInit.EquipmentTypeIdentifier", field: "FinnairInit.EquipmentTypeIdentifier", headerName: "Aircraft type", width: 80 },
        { colId: "FinnairInit.TailNumber", field: "FinnairInit.TailNumber", headerName: "Tail #", width: 80 },
        { colId: "FinnairInit.WingMaterialType", field: "FinnairInit.WingMaterialType", headerName: "Wing material type", width: 80 },
        { colId: "FinnairInit.From", field: "FinnairInit.From", headerName: "From", width: 300 },
        { colId: "FinnairInit.To", field: "FinnairInit.To", headerName: "To", width: 300 },
        { colId: "FinnairInit.Subject", field: "FinnairInit.Subject", headerName: "Subject", width: 300 },
    ];
    
    showRawData(data) {
        console.log('data=', data);
        this.dialog.show(this.viewContainerRef, StationDataRawDataDialogComponent, {
            rawData: data?.FinnairInit?.RawData?.trim() // TODO: fix these dialogs
        }, {
            title: 'Raw data',
            width: 800,
            height: 300,
            modal: true,
        })
    }
} 
