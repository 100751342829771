import {Component, ViewContainerRef} from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { DialogService } from "swx.front-end-lib";
import { ReportDeicingHotErrorsDialogComponent } from "./ReportDeicingHotErrorsDialog.component";
import { NgIf } from "@angular/common";

@Component({
    template: '<span><i *ngIf="hasErrors" class="mdi mdi-information" (click)="showErrors()" style="cursor: pointer"></i> {{value}}</span>',
    standalone: true,
    imports: [NgIf]
})
export class ReportDeicingErrorCellRendererComponent implements ICellRendererAngularComp {
    data: any;
    value: any;

    constructor(private dialog: DialogService,
                private viewContainerRef: ViewContainerRef) {
    }

    agInit(params: ICellRendererParams): void {
        this.data = params.data;
        this.value = params.value;
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }

    get hasErrors(): boolean {
        return this.data.HotResponse?.Error || this.data.HotResponseHot?.Errors || this.data.WeatherProfile?.Errors || this.data.AcarsRequest?.Errors;
    }

    showErrors() {
        this.dialog.show(this.viewContainerRef, ReportDeicingHotErrorsDialogComponent, {
            hot: this.data,
        },
        {
            title: 'Errors',
            width: 400,
            modal: true,
        })
    }
}
