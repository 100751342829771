import {Component, OnInit, ViewChild} from "@angular/core";
import { IAgGridColumnDef, StorageService, ValueFormatters, yesNo, SwxModule, GridModule } from "swx.front-end-lib";
import {ApiService} from "../shared/Api.service";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './FlightAwareFirehoseMessage.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, GridModule]
})
export class FlightAwareFirehoseMessageComponent implements OnInit {
    @ViewChild('grid') grid;
    storeKey = 'flightAwareFirehoseMessageQuery';
    query: any;
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };

    airlines = this.api.DeicingAirline.query();
    airports = this.api.Airport.query();
    resource = this.api.FlightAwareFirehoseMessage;
    exportResourcePost = this.api.FlightAwareFirehoseMessageExport;
    
    constructor(private storage: StorageService, 
                private api: ApiService,
                private formatters: ValueFormatters) {
    }

    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery){
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    };

    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }
    
    columnDefs: IAgGridColumnDef[] = [
        { colId: "FlightAwareFirehoseMessage.Id", field: "FlightAwareFirehoseMessage.Id", headerName: "#", width: 80, filterType: "integer", pinned: 'left' },
        { colId: "FlightAwareFirehoseMessage.ReceivedDateTimeDate", field: "FlightAwareFirehoseMessage.ReceivedDateTime", headerName: "Date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left', filterType: "date" },
        { colId: "FlightAwareFirehoseMessage.ReceivedDateTimeTime", field: "FlightAwareFirehoseMessage.ReceivedDateTime", headerName: "Time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, pinned: 'left', "searchable": false },
        { colId: "FlightAwareFirehoseMessage.FlightAwareFlightId", field: "FlightAwareFirehoseMessage.FlightAwareFlightId", headerName: "FlightAware ID", width: 250, pinned: 'left' },
        { colId: "AirlineName", field: "AirlineName", headerName: "Airline", width: 150 },
        { colId: "FlightAwareFirehoseMessage.OriginAirportCode", field: "FlightAwareFirehoseMessage.OriginAirportCode", headerName: "Origin airport code", width: 80 },
        { colId: "FlightAwareFirehoseMessage.DestinationAirportCode", field: "FlightAwareFirehoseMessage.DestinationAirportCode", headerName: "Destination airport code", width: 80 },
        { colId: "FlightIdentifier", field: "FlightIdentifier", headerName: "Flight #", width: 80 },
        { colId: "FlightAwareFirehoseMessage.RegistrationNumber", field: "FlightAwareFirehoseMessage.RegistrationNumber", headerName: "Registration #", width: 100 },
        { colId: "FlightAwareFirehoseMessage.EquipmentTypeIdentifier", field: "FlightAwareFirehoseMessage.EquipmentTypeIdentifier", headerName: "Aircraft type", width: 80 },
        { colId: "FlightAwareFirehoseMessage.TransponderCode", field: "FlightAwareFirehoseMessage.TransponderCode", headerName: "Transponder code", width: 100 },
        { colId: "FlightAwareFirehoseMessage.Type", field: "FlightAwareFirehoseMessage.Type", headerName: "Event type", width: 120 },
        { colId: "FlightAwareFirehoseMessage.FacilityName", field: "FlightAwareFirehoseMessage.FacilityName", headerName: "Facility", width: 160 },
        { colId: "FlightAwareFirehoseMessage.TimeType", field: "FlightAwareFirehoseMessage.TimeType", headerName: "Time type", width: 80 },
        { colId: "FlightAwareFirehoseMessage.IsSynthetic", field: "FlightAwareFirehoseMessage.IsSynthetic", headerName: "Synthetic?", width: 80, filterType: "boolean", cellRenderer: yesNo },
        { colId: "FlightAwareFirehoseMessage.EventDate", field: "FlightAwareFirehoseMessage.EventTime", headerName: "Event date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "FlightAwareFirehoseMessage.EventTime", field: "FlightAwareFirehoseMessage.EventTime", headerName: "Event time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
        { colId: "FlightAwareFirehoseMessage.Latitude", field: "FlightAwareFirehoseMessage.Latitude", headerName: "Latitude", width: 80, filterType: "float" },
        { colId: "FlightAwareFirehoseMessage.Longitude", field: "FlightAwareFirehoseMessage.Longitude", headerName: "Longitude", width: 80, filterType: "float" },
        { colId: "FlightAwareFirehoseMessage.ScheduledOffBlockDate", field: "FlightAwareFirehoseMessage.ScheduledOffBlockTime", headerName: "Scheduled off block date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "FlightAwareFirehoseMessage.ScheduledOffBlockTime", field: "FlightAwareFirehoseMessage.ScheduledOffBlockTime", headerName: "Scheduled off block time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
        { colId: "FlightAwareFirehoseMessage.EstimatedOffBlockDate", field: "FlightAwareFirehoseMessage.EstimatedOffBlockTime", headerName: "Estimated off block date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "FlightAwareFirehoseMessage.EstimatedOffBlockTime", field: "FlightAwareFirehoseMessage.EstimatedOffBlockTime", headerName: "Estimated off block time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
        { colId: "FlightAwareFirehoseMessage.ActualOffBlockDate", field: "FlightAwareFirehoseMessage.ActualOffBlockTime", headerName: "Actual off block date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "FlightAwareFirehoseMessage.ActualOffBlockTime", field: "FlightAwareFirehoseMessage.ActualOffBlockTime", headerName: "Actual off block time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
        { colId: "FlightAwareFirehoseMessage.ScheduledOnBlockDate", field: "FlightAwareFirehoseMessage.ScheduledOnBlockTime", headerName: "Scheduled on block date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "FlightAwareFirehoseMessage.ScheduledOnBlockTime", field: "FlightAwareFirehoseMessage.ScheduledOnBlockTime", headerName: "Scheduled on block time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
        { colId: "FlightAwareFirehoseMessage.EstimatedOnBlockDate", field: "FlightAwareFirehoseMessage.EstimatedOnBlockTime", headerName: "Estimated on block date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "FlightAwareFirehoseMessage.EstimatedOnBlockTime", field: "FlightAwareFirehoseMessage.EstimatedOnBlockTime", headerName: "Estimated on block time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
        { colId: "FlightAwareFirehoseMessage.ActualOnBlockDate", field: "FlightAwareFirehoseMessage.ActualOnBlockTime", headerName: "Actual on block date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "FlightAwareFirehoseMessage.ActualOnBlockTime", field: "FlightAwareFirehoseMessage.ActualOnBlockTime", headerName: "Actual on block time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
        { colId: "FlightAwareFirehoseMessage.ScheduledTouchdownDate", field: "FlightAwareFirehoseMessage.ScheduledTouchdownTime", headerName: "Scheduled touchdown date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "FlightAwareFirehoseMessage.ScheduledTouchdownTime", field: "FlightAwareFirehoseMessage.ScheduledTouchdownTime", headerName: "Scheduled touchdown time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
        { colId: "FlightAwareFirehoseMessage.EstimatedTouchdownDate", field: "FlightAwareFirehoseMessage.EstimatedTouchdownTime", headerName: "Estimated touchdown date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "FlightAwareFirehoseMessage.EstimatedTouchdownTime", field: "FlightAwareFirehoseMessage.EstimatedTouchdownTime", headerName: "Estimated touchdown time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
        { colId: "FlightAwareFirehoseMessage.ActualTouchdownDate", field: "FlightAwareFirehoseMessage.ActualTouchdownTime", headerName: "Actual touchdown date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "FlightAwareFirehoseMessage.ActualTouchdownTime", field: "FlightAwareFirehoseMessage.ActualTouchdownTime", headerName: "Actual touchdown time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
        { colId: "FlightAwareFirehoseMessage.ScheduledTakeOffDate", field: "FlightAwareFirehoseMessage.ScheduledTakeOffTime", headerName: "Scheduled take off date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "FlightAwareFirehoseMessage.ScheduledTakeOffTime", field: "FlightAwareFirehoseMessage.ScheduledTakeOffTime", headerName: "Scheduled take off time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
        { colId: "FlightAwareFirehoseMessage.EstimatedTakeOffDate", field: "FlightAwareFirehoseMessage.EstimatedTakeOffTime", headerName: "Estimated take off date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "FlightAwareFirehoseMessage.EstimatedTakeOffTime", field: "FlightAwareFirehoseMessage.EstimatedTakeOffTime", headerName: "Estimated take off time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
        { colId: "FlightAwareFirehoseMessage.ActualTakeOffDate", field: "FlightAwareFirehoseMessage.ActualTakeOffTime", headerName: "Actual take off date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "FlightAwareFirehoseMessage.ActualTakeOffTime", field: "FlightAwareFirehoseMessage.ActualTakeOffTime", headerName: "Actual take off time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
        { colId: "FlightAwareFirehoseMessage.PowerOnDate", field: "FlightAwareFirehoseMessage.PowerOnTime", headerName: "Power on date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "FlightAwareFirehoseMessage.PowerOnTime", field: "FlightAwareFirehoseMessage.PowerOnTime", headerName: "Power on time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, "searchable": false },
        { colId: "FlightAwareFirehoseMessage.ActualArrivalGate", field: "FlightAwareFirehoseMessage.ActualArrivalGate", headerName: "Arrival gate", width: 80 },
        { colId: "FlightAwareFirehoseMessage.ActualArrivalTerminal", field: "FlightAwareFirehoseMessage.ActualArrivalTerminal", headerName: "Arrival terminal", width: 80 },
        { colId: "FlightAwareFirehoseMessage.ActualDepartureGate", field: "FlightAwareFirehoseMessage.ActualDepartureGate", headerName: "Departure gate", width: 80 },
        { colId: "FlightAwareFirehoseMessage.ActualDepartureTerminal", field: "FlightAwareFirehoseMessage.ActualDepartureTerminal", headerName: "Departure terminal", width: 80 },
        { colId: "FlightAwareFirehoseMessage.ActualRunwayOff", field: "FlightAwareFirehoseMessage.ActualRunwayOff", headerName: "Runway off", width: 80 },
        { colId: "FlightAwareFirehoseMessage.ActualRunwayOn", field: "FlightAwareFirehoseMessage.ActualRunwayOn", headerName: "Runway on", width: 80 },
    ];
} 
