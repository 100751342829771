import { Component, Input } from '@angular/core';
import { Filter, Field } from "./Query.model";
import { KeysPipe } from '../pipes/Keys.pipe';
import { MultiselectOptionComponent } from '../multiselect/MultiselectOption.component';
import { MultiselectComponent } from '../multiselect/Multiselect.component';
import { DateDirective } from '../date/Date.directive';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'filter',
    templateUrl: 'Filter.component.html',
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, DateDirective, MultiselectComponent, MultiselectOptionComponent, KeysPipe]
})
export class FilterComponent {
    datePickerOptions: JQueryUI.DatepickerOptions;
    @Input('filter') filter: Filter = null;
    @Input('field') field: Field = null;
    @Input('caption') caption = true;

    constructor() {
        var maxDateTime = new Date();
        maxDateTime.setDate(maxDateTime.getDate() + 1);
        this.datePickerOptions = {
            maxDate: maxDateTime,
        }
    }
}
