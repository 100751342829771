import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'applySort',
    pure: false,
    standalone: true
})
export class ApplySortPipe implements PipeTransform {

    constructor(
    ) {
        
    }

    transform(items: any[], expression: string, reverse: boolean, context: Object, itemName: string): any[] {
        // if (typeof items === 'undefined' || items === null || typeof items.length === 'undefined' || items.length === 0)
        //     return [];
        //
        //
        // itemName = itemName || "item";
        // var parsed = $parse(expression);
        // var locals = context.clone();
        //
        // return items.slice().sort(function (a, b) {
        //     locals[itemName] = a;
        //     var resulta = parsed(locals);
        //     locals[itemName] = b;
        //     var resultb = parsed(locals);
        //
        //     if (resulta === null || typeof resulta === 'undefined' || resulta < resultb) return reverse ? 1 : -1;
        //     if (resultb === null || typeof resultb === 'undefined' || resulta > resultb) return reverse ? -1 : 1;
        //
        //     return 0;
        // });
        //
        //
        return items;
    }
}
