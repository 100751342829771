import { Component, Input } from '@angular/core';
import { SwxModule } from 'swx.front-end-lib';
import { NgIf } from '@angular/common';

@Component({
    selector: '[sensor-selection-configuration-header]',
    templateUrl: 'SensorSelectionConfigurationHeader.component.html',
    standalone: true,
    imports: [NgIf, SwxModule]
})
export class SensorSelectionConfigurationHeaderComponent {
    @Input() type;
}
