<page-title>{{('Station alarm profile'|translate) + ': ' + (item.Name || 'new')}}</page-title>
<div class="noMargins">
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('alarms')" [class.active]="tab == 'alarms'" [class.invalid]="!ngForm.form.controls.alarmsForm?.valid">{{'Alarms'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" (click)="save()" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
    </div>
    
    <form #ngForm="ngForm" (submit)="save()" class="form">
        <fieldset class="contentMargins" [disabled]="!('ConfigEditStations'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'Name'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
            </label>
        </fieldset>

        <fieldset [disabled]="!('ConfigEditStations'|hasPermission)" [hidden]="tab != 'alarms'" ngModelGroup="alarmsForm">
            <table class="table">
                <tr>
                    <th>{{'Alarm'|translate}}</th>
                    <th style="width: 80px;">{{'Min. # of consecutive events'|translate}}</th>
                    <th style="width: 80px;">{{'Min. # of events per hour'|translate}}</th>
                    <th style="width: 80px;">{{'Time to persist on station status (minutes)'|translate}}</th>
                    <th>{{'Options'|translate}}</th>
                    <th>{{'Level'|translate}}</th>
                    <th></th>
                </tr>
                <tr *ngFor="let stationProblemConfiguration of item.StationProblemConfigurations|orderBy:'Type'; let i = index">
                    <td>{{stationProblemTypes[stationProblemConfiguration.Type]}}</td>
                    <td><input type="number" required min="1" [(ngModel)]="stationProblemConfiguration.MinimumNumberOfConsecutiveEvents" [name]="'stationProblemConfiguration[' + i +'].MinimumNumberOfConsecutiveEvents'" style="width: 50px;" /></td>
                    <td><input type="number" required min="1" max="60" [(ngModel)]="stationProblemConfiguration.MinimumEventsPerHour" [name]="'stationProblemConfiguration[' + i +'].MinimumEventsPerHour'" style="width: 50px;" /></td>
                    <td><input type="number" required min="1" max="60" [(ngModel)]="stationProblemConfiguration.MinutesToPersist" [name]="'stationProblemConfiguration[' + i +'].MinutesToPersist'" style="width: 50px;" /></td>
                    <td>
                        <div *ngIf="stationProblemConfiguration.Type == 'DigitalProbeThiesProbeRelativeHumidityDisagreement'
							 || stationProblemConfiguration.Type == 'DigitalProbeThiesProbeTemperatureDisagreement'
							 || stationProblemConfiguration.Type == 'CampbellProbeThiesProbeRelativeHumidityDisagreement'
							 || stationProblemConfiguration.Type == 'CampbellProbeThiesProbeTemperatureDisagreement'
							 || stationProblemConfiguration.Type == 'VaisalaFD12PThiesProbeTemperatureDisagreement'
							 || stationProblemConfiguration.Type == 'VaisalaFD12PDigitalProbeTemperatureDisagreement'
							 || stationProblemConfiguration.Type == 'VaisalaFD12PMETARTemperatureDisagreement'
							 || stationProblemConfiguration.Type == 'VaisalaPWD22DigitalProbeTemperatureDisagreement'
							 || stationProblemConfiguration.Type == 'VaisalaPWD22METARTemperatureDisagreement'
							 || stationProblemConfiguration.Type == 'DigitalProbeMETARTemperatureDisagreement'
							 || stationProblemConfiguration.Type == 'CampbellProbeMETARTemperatureDisagreement'
							 || stationProblemConfiguration.Type == 'Thies2DMETARTemperatureDisagreement'
							 || stationProblemConfiguration.Type == 'ThiesProbeMETARTemperatureDisagreement'
							 || stationProblemConfiguration.Type == 'DigitalProbeCampbellProbeRelativeHumidityDisagreement'
							 || stationProblemConfiguration.Type == 'DigitalProbeCampbellProbeTemperatureDisagreement'
							 || stationProblemConfiguration.Type == 'ThiesProbeMETARRelativeHumidityDisagreement'
							 || stationProblemConfiguration.Type == 'CampbellProbeMETARRelativeHumidityDisagreement'
							 || stationProblemConfiguration.Type == 'DigitalProbeMETARRelativeHumidityDisagreement'
							 || stationProblemConfiguration.Type == 'DigitalProbeThiesLPMAmbientTemperatureDisagreement'
							 || stationProblemConfiguration.Type == 'ThiesLPMAmbientMETARTemperatureDisagreement'">
                            Diff &gt;
                            <br /><input type="number" step="any" min="0" required [(ngModel)]="stationProblemConfiguration.Options.MinimumDifference" [name]="'stationProblemConfiguration[' + i +'].Options.MinimumDifference'" style="width: 60px;" />
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'SartoriusHighWeightWarning' || stationProblemConfiguration.Type == 'SartoriusHighWeightAlarm'">
                            Weight (g) &gt;
                            <br /><input type="number" step="any" min="0" required [(ngModel)]="stationProblemConfiguration.Options.MinimumWeight" [name]="'stationProblemConfiguration[' + i +'].Options.MinimumWeight'" style="width: 60px;" />
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'SartoriusLowWeightAlarm'">
                            Weight (g) &lt;
                            <br /><input type="number" step="any" min="0" required [(ngModel)]="stationProblemConfiguration.Options.MinimumWeight" [name]="'stationProblemConfiguration[' + i +'].Options.MinimumWeight'" style="width: 60px;" />
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'SartoriusPrecipitationRateTooLow'">
                            LWE (g/dm²/h) &lt;
                            <br /><input type="number" step="any" required [(ngModel)]="stationProblemConfiguration.Options.MinimumLWE" [name]="'stationProblemConfiguration[' + i +'].Options.MinimumLWE'" style="width: 60px;" />
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'Thies2DCompactWindSpeed' || stationProblemConfiguration.Type == 'VaisalaWS425WindSpeed' || stationProblemConfiguration.Type == 'VaisalaWMT700WindSpeed'">
                            Wind speed (m/s) &gt;
                            <br /><input type="number" step="1" min="0" required [(ngModel)]="stationProblemConfiguration.Options.MinimumWindSpeed" [name]="'stationProblemConfiguration[' + i +'].Options.MinimumWindSpeed'" style="width: 60px;" />
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'TelegramNumberUnchanged'">
                            Minutes
                            <br /><input type="number" step="1" min="2" required [(ngModel)]="stationProblemConfiguration.Options.DurationMinutes" [name]="'stationProblemConfiguration[' + i +'].Options.DurationMinutes'" style="width: 60px;" />
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'ThiesLPMSensorFailed'">
                            Temperature is "FF" or LWE is "FF.F"
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'ThiesLPMWMOCodeError'">
                            WMO code is -1
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'ThiesLPMWMOCode'">
                            WMO code =
                            <br /><input type="number" step="1" min="0" max="100" required [(ngModel)]="stationProblemConfiguration.Options.WMOCode" [name]="'stationProblemConfiguration[' + i +'].Options.WMOCode'" style="width: 60px;" />
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'Thies2DCompactSensorFailed'">
                            Wind speed is "FF.F"
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'CampbellPWS100FaultStatus'">
                            Fault status &gt; 0
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'CampbellPWS100TemperatureError'">
                            Temperature is -999 or 999
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'VaisalaFD12PHardwareError'">
                            Second alarm digit = 1
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'VaisalaFD12PHardwareWarning'">
                            Second alarm digit = 2
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'VaisalaPWD22HardwareAlarm1'">
                            Second alarm digit = 1
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'VaisalaPWD22HardwareAlarm2'">
                            Second alarm digit = 2
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'VaisalaPWD22HardwareAlarm3'">
                            Second alarm digit = 3
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'VaisalaPWD22HardwareAlarm4'">
                            Second alarm digit = 4
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'VaisalaPWD22HardwareStatusDrift'">
                            Drift &lt; <input type="number" step="any" [max]="stationProblemConfiguration.Options.MaximumValidValue - 0.01" required [(ngModel)]="stationProblemConfiguration.Options.MinimumValidValue" [name]="'stationProblemConfiguration[' + i +'].Options.MinimumValidValue'" style="width: 60px;" />
                            <br />or &gt; <input type="number" step="any" [min]="stationProblemConfiguration.Options.MinimumValidValue + 0.01" required [(ngModel)]="stationProblemConfiguration.Options.MaximumValidValue" [name]="'stationProblemConfiguration[' + i +'].Options.MaximumValidValue'" style="width: 60px;" />
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'VaisalaWS425Status'">
                            Status not = "A"
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'VaisalaWMT700Status'">
                            <div required [(ngModel)]="stationProblemConfiguration.Options" [name]="'stationProblemConfiguration[' + i +'].Options'">
                                <label *ngFor="let option of SensorReadingVaisalaWMT700Statuses|keys">
                                    <input type="checkbox" [checked]="isAlarmOptionEnabled(stationProblemConfiguration, option)" (click)="toggleAlarmOption(stationProblemConfiguration, option)" />{{SensorReadingVaisalaWMT700Statuses[option]}}
                                </label>
                            </div>
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'BiralSWS200Status'">
                            Status not = "XOO" and Status not = "OOO"
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'DataTakerAlarm'">
                            Alarm not = "D"
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'DigitalProbeTemperatureMalfunctioning' || stationProblemConfiguration.Type == 'ThiesProbeTemperatureMalfunctioning' || stationProblemConfiguration.Type == 'VaisalaFD12PTemperatureMalfunctioning' || stationProblemConfiguration.Type == 'VaisalaPWD22TemperatureMalfunctioning' || stationProblemConfiguration.Type == 'CampbellPWS100UpperDetectorTemperatureOutOfRange' || stationProblemConfiguration.Type == 'CampbellPWS100LowerDetectorTemperatureOutOfRange' || stationProblemConfiguration.Type == 'CampbellPWS100LaserHoodTemperatureOutOfRange' || stationProblemConfiguration.Type == 'CampbellPWS100LaserTemperatureOutOfRange' || stationProblemConfiguration.Type == 'ThiesLPMAmbientTemperatureMalfunctioning'">
                            Temperature &lt; <input type="number" step="any" [max]="stationProblemConfiguration.Options.MaximumValidTemperature - 0.01" required [(ngModel)]="stationProblemConfiguration.Options.MinimumValidTemperature" [name]="'stationProblemConfiguration[' + i +'].Options.MinimumValidTemperature'" style="width: 60px;" />
                            <br />or &gt; <input type="number" step="any" [min]="stationProblemConfiguration.Options.MinimumValidTemperature + 0.01" required [(ngModel)]="stationProblemConfiguration.Options.MaximumValidTemperature" [name]="'stationProblemConfiguration[' + i +'].Options.MaximumValidTemperature'" style="width: 60px;" />
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'DigitalProbeRelativeHumidityMalfunctioning' || stationProblemConfiguration.Type == 'VaisalaProbeRelativeHumidityMalfunctioning' || stationProblemConfiguration.Type == 'CampbellProbeRelativeHumidityMalfunctioning' || stationProblemConfiguration.Type == 'ThiesProbeRelativeHumidityMalfunctioning'">
                            RH% &lt; <input type="number" step="any" [max]="stationProblemConfiguration.Options.MaximumValidRelativeHumidity - 0.01" required [(ngModel)]="stationProblemConfiguration.Options.MinimumValidRelativeHumidity" [name]="'stationProblemConfiguration[' + i +'].Options.MinimumValidRelativeHumidity'" style="width: 60px;" />
                            <br />or &gt; <input type="number" step="any" [min]="stationProblemConfiguration.Options.MinimumValidRelativeHumidity + 0.01" required [(ngModel)]="stationProblemConfiguration.Options.MaximumValidRelativeHumidity" [name]="'stationProblemConfiguration[' + i +'].Options.MaximumValidRelativeHumidity'" style="width: 60px;" />
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'CampbellPWS100LaserDriveCurrentOutOfRange'">
                            mA &lt; <input type="number" step="any" [max]="stationProblemConfiguration.Options.MaximumValidCurrent - 0.01" required [(ngModel)]="stationProblemConfiguration.Options.MinimumValidCurrent" [name]="'stationProblemConfiguration[' + i +'].Options.MinimumValidCurrent'" style="width: 60px;" />
                            <br />or &gt; <input type="number" step="any" [min]="stationProblemConfiguration.Options.MinimumValidCurrent + 0.01" required [(ngModel)]="stationProblemConfiguration.Options.MaximumValidCurrent" [name]="'stationProblemConfiguration[' + i +'].Options.MaximumValidCurrent'" style="width: 60px;" />
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'QueueSize' || stationProblemConfiguration.Type == 'PowerErrorCount'">
                            Count &ge; <input type="number" step="1" required [(ngModel)]="stationProblemConfiguration.Options.MinimumCount" [name]="'stationProblemConfiguration[' + i +'].Options.MinimumCount'" style="width: 60px;" />
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'SartoriusAlarmEmergencySwitch'">
                            Alarm = EmergencySwitch
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'SartoriusAlarmDomeFejl'">
                            Alarm = DomeFejl
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'SartoriusAlarmValveErr'">
                            Alarm = ValveErr
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'SartoriusAlarmBlowerErr'">
                            Alarm = BlowerErr
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'SartoriusAlarmVFDMotorErr'">
                            Alarm = VFDMotorErr
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'OTTPluvio2HeaterStatus' || stationProblemConfiguration.Type == 'OTTPluvio2DFARHeaterStatus'">
                            <div required [(ngModel)]="stationProblemConfiguration.Options" [name]="'stationProblemConfiguration[' + i +'].Options'">
                                <label *ngFor="let option of OTTPluvio2HeaterStatuses|keys">
                                    <input type="checkbox" [checked]="isAlarmOptionEnabled(stationProblemConfiguration, option)" (click)="toggleAlarmOption(stationProblemConfiguration, option)" />{{OTTPluvio2HeaterStatuses[option]}}
                                </label>
                            </div>
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'OTTPluvio2SensorStatus' || stationProblemConfiguration.Type == 'OTTPluvio2DFARSensorStatus'">
                            <div required [(ngModel)]="stationProblemConfiguration.Options" [name]="'stationProblemConfiguration[' + i +'].Options'">
                                <label *ngFor="let option of OTTPluvio2SensorStatuses|keys">
                                    <input type="checkbox" [checked]="isAlarmOptionEnabled(stationProblemConfiguration, option)" (click)="toggleAlarmOption(stationProblemConfiguration, option)" />{{OTTPluvio2SensorStatuses[option]}}
                                </label>
                            </div>
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'PondHotPlateSystemStatus'">
                            <div required [(ngModel)]="stationProblemConfiguration.Options" [name]="'stationProblemConfiguration[' + i +'].Options'">
                                <label *ngFor="let option of SensorReadingPondHotPlateSystemStatuses|keys">
                                    <input type="checkbox" [checked]="isAlarmOptionEnabled(stationProblemConfiguration, option)" (click)="toggleAlarmOption(stationProblemConfiguration, option)" />{{SensorReadingPondHotPlateSystemStatuses[option]}}
                                </label>
                            </div>
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'WeatherProfileError'">
                            <div required [(ngModel)]="stationProblemConfiguration.Options" [name]="'stationProblemConfiguration[' + i +'].Options'">
                                <label *ngFor="let option of weatherProfileErrors|keys">
                                    <input type="checkbox" [checked]="isAlarmOptionEnabled(stationProblemConfiguration, option)" (click)="toggleAlarmOption(stationProblemConfiguration, option)" />{{weatherProfileErrors[option]}}
                                </label>
                            </div>
                        </div>
                        <div *ngIf="stationProblemConfiguration.Type == 'OTTParsivel2SensorStatus'">
                            <div required [(ngModel)]="stationProblemConfiguration.Options" [name]="'stationProblemConfiguration[' + i +'].Options'">
                                <label *ngFor="let option of OTTParsivel2SensorStatuses|keys">
                                    <input type="checkbox" [checked]="isAlarmOptionEnabled(stationProblemConfiguration, option)" (click)="toggleAlarmOption(stationProblemConfiguration, option)" />{{OTTParsivel2SensorStatuses[option]}}
                                </label>
                            </div>
                        </div>
                    </td>
                    <td>
                        <select [(ngModel)]="stationProblemConfiguration.Level" [name]="'stationProblemConfiguration[' + i +'].Level'" required [selectedIndex]="-1" class="stationProblemLevel" [class]="stationProblemConfiguration.Level">
                            <option *ngFor="let option of stationProblemLevels|keys" [ngValue]="option" [class]="option">
                                {{stationProblemLevels[option]}}
                            </option>
                        </select>
                    </td>
                    <td>
                        <a *ngIf="('ConfigDeleteStations'|hasPermission)" (click)="removeStationProblemConfiguration(stationProblemConfiguration)" class="mdi mdi-delete"></a>
                    </td>
                </tr>
            </table>
            <br />
            <select [(ngModel)]="stationProblemType" name="stationProblemType" [selectedIndex]="-1">
                <option *ngFor="let option of stationProblemTypesNotEnabled()" [ngValue]="option">
                    {{stationProblemTypes[option]}}
                </option>
            </select>
            <input type="button" class="green" *ngIf="('ConfigAddStations'|hasPermission)" (click)="addStationProblemType()" value="{{'Add alarm'|translate}}" [disabled]="stationProblemType == null" />
        </fieldset>
    </form>
</div>
