import {Component, EventEmitter, Output, ViewChild, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import { FileDownloadService, IAgGridColumnDef, NotificationService, ValueFormatters, SwxModule, GridModule } from "swx.front-end-lib";
import {RootScope} from "../shared/RootScope.service";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    selector: 'swx-active-frost-list',
    templateUrl: 'ActiveFrostList.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule, NgFor, GridModule]
})
export class ActiveFrostListComponent {

    @ViewChild('grid') grid;
    @Output() queryChange = new EventEmitter();
    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    resource = this.api.ActiveFrostMonitoringData;
    query: any = {};
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };

    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private formatters: ValueFormatters,
                private fileDownload: FileDownloadService,
                private notification: NotificationService,
                private root: RootScope,
                private ticketTracking: TicketTrackingSystemService,
    ) {
    }

    columnDefs: IAgGridColumnDef[] = [
        { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 140, pinned: 'left', valueFormatter: this.formatters.utcDateTimeFormatter() },  
        { colId: "Id", field: "Id", headerName: "Id", width: 90 },
        { colId: "ClientName", field: "ClientName", headerName: "Client", editable: false },
        { colId: "AirportICAOCode", field: "AirportICAOCode", headerName: "Airport", editable: false },
        { colId: "Station.Name", field: "Station.Name", headerName: "Station" },
        { colId: "ReachedDate", field: "ReachedDate", headerName: "Reached date", width: 160, valueFormatter: this.formatters.utcDateTimeFormatter() },
        { colId: "ExitedDate", field: "ExitedDate", headerName: "Exited date", width: 160, valueFormatter: this.formatters.utcDateTimeFormatter() }
    ];
    
    refresh() {
        this.queryChange.emit(this.query);
        this.grid.refresh();
    }
    
    export() {
        this.api.ActiveFrostExport
            .export(this.query)
            .then((response) => this.fileDownload.download(response.body, (header) => response.headers.get(header)));
    }
    
    exit(query) {
        this.api.ActiveFrostManual.post(query).then((result) => {
            if (result.Message) {
                alert(result.Message);
            } else {
                this.notification.show('Active frost monitoring has been reset for this client-airport. If the last email was an entry, then an exit email has been sent.');
            }
        });
    }

    exitAF() {
        if (
            typeof this.query.ClientId === 'undefined' ||
            this.query.ClientId.length !== 1 ||
            (typeof this.query.AirportId === 'undefined') ||
            this.query.AirportId.length !== 1
        ) {
            alert("Please select one client and one airport");
            return;
        }

        if (this.root.currentUser.TicketTrackingRequired) {
            this.ticketTracking.getComment(this.viewContainerRef).then(result => {
                this.exit({...result, ...this.query});
            });
        } else {
            this.exit(this.query);
        }
    }
}
