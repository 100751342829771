import {Component} from "@angular/core";
import { ActiveFrostEmailsComponent } from "./ActiveFrostEmails.component";
import { ActiveFrostListComponent } from "./ActiveFrostList.component";
import { SwxModule } from "swx.front-end-lib";

@Component({
    selector: 'swx-active-frost',
    templateUrl: './ActiveFrost.component.html',
    styles: [':host { display: flex; flex-direction: column; height: 100%; }'],
    standalone: true,
    imports: [SwxModule, ActiveFrostListComponent, ActiveFrostEmailsComponent]
})
export class ActiveFrostComponent {
    query: any = {};
    
    onQueryChange(query) {
        this.query = {...query};
    }
} 
