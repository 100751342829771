import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { CellRendererFactories, ChangesService, ConfigGridComponent, SwxModule, GridModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'RemoteEndpointDetail.component.html',
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgIf,
        NgClass,
        NgFor,
        GridModule,
        HasPermissionPipe,
    ],
})
export class RemoteEndpointDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', { static: true }) ngForm;
    tab;
    remoteEndpointTypes = this.api.RemoteEndpointType.query();
    remoteEndpointFormats = this.api.RemoteEndpointFormat.query();
    @ViewChild('columnsGrid', { static: true }) columnsGrid: ConfigGridComponent;
    columnDefs = [
        {
            headerName: "",
            children: [
                {
                    colId: `ColumnName`,
                    field: `ColumnName`,
                    headerName: "Column name",
                    width: 300,
                    cellRenderer: params => this.cellRenderers.input('text', {
                        required: true,
                        value: params.value,
                        onchange: e => params.data.ColumnName = (<HTMLInputElement>e.target).value,
                    }),
                },
                {
                    colId: `MinimumValueCount`,
                    field: `MinimumValueCount`,
                    headerName: "Minimum value count",
                    width: 100,
                    cellRenderer: params => this.cellRenderers.input('number', {
                        required: true,
                        value: params.value,
                        min: '0',
                        onchange: e => params.data.MinimumValueCount = (<HTMLInputElement>e.target).value,
                    }),
                },
                {
                    colId: "_spacer",
                    flex: 1,
                    excelIgnore: true,
                },
                {
                    colId: "Actions",
                    field: "Id",
                    headerName: "",
                    width: 80,
                    pinned: 'right',
                    excelIgnore: true,
                    cellTemplate: `<a *ngIf="parent.hasPermissionService.hasPermission('ConfigEditRemoteEndpoints')" (click)="parent.removeColumn(params.data)" class="mdi mdi-delete" title="{{'Delete'|translate}}"></a>`,
                },
            ],
        },
    ];
    
    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        private cellRenderers: CellRendererFactories,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
        
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.RemoteEndpoint.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.RemoteEndpointColumns) {
                    this.item.RemoteEndpointColumns.forEach(related => {
                        delete related.RemoteEndpointId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.RemoteEndpoint.create({
                Active: true,
            });
        } else {
            this.item = this.api.RemoteEndpoint.get({ id: id });
        }
    }
    
    save() {
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['RemoteEndpoint'],
            SubjectId: this.item.Id
        });
    }

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    }

    addColumn() {
        this.item.RemoteEndpointColumns = this.item.RemoteEndpointColumns || [];
        this.item.RemoteEndpointColumns.unshift({
            ColumnName: '',
            MinimumValueCount: null,
        });
        this.columnsGrid.gridApi.applyTransaction({ addIndex: 0, add: this.item.RemoteEndpointColumns.slice(0, 1) })
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removeColumn(item) {
        this.item.RemoteEndpointColumns.splice(this.item.RemoteEndpointColumns.indexOf(item), 1);

        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();

        this.columnsGrid.updateFilteredRows();
    }
}
